import { withStaffUnpretend } from '@poolware/api';
import * as React from 'react';
import { useAppConfig } from '../app-config';
import { useViewerContext } from '../Viewer';
import { Icon, Navbar } from '@ez/components';

export const AppSwitcherMenuItems = withStaffUnpretend()(({ unpretend }) => {
    const appConfig = useAppConfig();
    const { isPretending, isAdmin, viewer } = useViewerContext();

    const unpretendMenuItem = () => {
        if (!isPretending) return null;

        return (
            <>
                <Navbar.Label className={'hidden lg:block text-yellow-500'}>{viewer?.franchise?.name}</Navbar.Label>
                <Navbar.Item onClick={unpretend} icon={<Icon name={'hide'} />} content={'Unpretend'} />
            </>
        );
    };

    const menuItems = [
        {
            icon: 'shield alternate',
            text: 'Main App',
            url: appConfig?.apps?.frontend,
        },
        {
            icon: 'shield alternate',
            text: 'Admin Console',
            url: appConfig?.apps?.admin,
        },
        {
            icon: 'shield alternate',
            text: 'Water Test Config',
            url: appConfig?.apps?.configurator,
        },
        {
            icon: 'shield alternate',
            text: 'Warranty HQ',
            url: appConfig?.apps?.warrantyHQ,
        },
    ];

    menuItems.push({
        icon: 'shield alternate',
        text: 'My Account',
        url: appConfig?.apps?.account,
    });

    return (
        <>
            {unpretendMenuItem()}
            {isAdmin && (
                <Navbar.Dropdown.Menu
                    // color="yellow"
                    label={'Apps'}
                    icon={<Icon name={'grid layout'} />}
                >
                    {menuItems.map((item, index) => {
                        return (
                            <Navbar.Dropdown.Item
                                key={index}
                                icon={<Icon name={'circle outline'} />}
                                // content={item.text}
                                // href={item.url}
                                // target={'_blank'}
                                // asChild={true}
                            >
                                <a className={'text-'} href={item.url} target={'_blank'}>
                                    {item.text}
                                </a>
                            </Navbar.Dropdown.Item>
                        );
                    })}
                </Navbar.Dropdown.Menu>
            )}
        </>
    );
});
