import { ThemeInterface } from '../app-theme';
import { createThemeByHue, getDefaultTheme } from '@ez/components';
import { hslParams } from '@ez/components/dist/app-theme/utils';

export const getTheme = (publicPrefix) => {
    // Dracula >:-[
    // https://github.com/dracula

    const huePrim = 234;
    const hueSec = 28;
    const primary_sat = 14;
    const theme = createThemeByHue({
        publicPrefix: publicPrefix,
        huePrim: huePrim,
        hueSec: hueSec,
        primSat: primary_sat,
        primLum: 30,
        customisation: {
            logoPath: `${publicPrefix}/admin-logo.png`,
            iconPath: `${publicPrefix}/admin-icon.png`,
            themeName: 'poolware-admin',
            navbar: {
                separatorColor: 'hsl(48,100%,62%)',
                logo: {
                    backgroundColor: hslParams(huePrim, 25, 20),
                },
            },
            button: {
                color: {
                    primary: `hsl(${huePrim}, ${primary_sat}%, 32%)`,
                    primaryHover: `hsl(${huePrim}, ${primary_sat}%, 80%)`,
                    secondary: `hsl(${hueSec}, 25%, 50%)`,
                    secondaryHover: `hsl(${hueSec}, 25%, 80%)`,
                    tertiary: `hsl(${huePrim}, 15%, 85%)`,
                    tertiaryHover: `hsl(${huePrim}, 15%, 60%)`,
                },
            },
        },
    });

    return theme;
};
