import * as React from 'react';
import { confirmModalImperative, PageLayout, Panel, toastError } from '@ez/components';
import { fromEdges, NodeType } from '@poolware/api';
import { PhotoGroups } from '../../../Scheduler/PageAppointmentMobile/PagePhotoPicker/PhotoGroups';
import { useWorkOrderViewContext } from '../WorkOrderPageContext';

export interface PhotoAttachmentEditorProps {
    onDone?: () => void;
}

export const FileAttachmentEditor: React.FC<PhotoAttachmentEditorProps> = ({ onDone }) => {
    const { workOrderMutators, workOrder, refetchQueries, addFilesToWO } = useWorkOrderViewContext();
    const { removeFile } = workOrderMutators;

    const files = fromEdges(workOrder?.files);

    const onDeleteFiles = async (filesToDelete: NodeType.FileUpload[]) => {
        try {
            const res = await confirmModalImperative({
                confirmMessage: {
                    header: 'Delete Selected Files',
                    content: 'Are you sure you want to delete the selected files?',
                },
                negative: true,
                confirmButton: {
                    content: 'Delete Files',
                    icon: 'trash',
                },
            });
            if (!res) {
                return;
            }
            await removeFile(workOrder, filesToDelete);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete files', description: e.message });
        }
    };

    return (
        <PageLayout.BodySection width={'screen-lg'}>
            <Panel>
                <Panel.Header content={'File Attachements'} button={{ content: 'Done', onClick: onDone }} />
                <Panel.Body>
                    <PhotoGroups
                        files={files}
                        onFileUploadRequest={addFilesToWO}
                        onDeleteFiles={onDeleteFiles}
                        refetchQueries={refetchQueries}
                    />
                </Panel.Body>
            </Panel>
        </PageLayout.BodySection>
    );
};
