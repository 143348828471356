import * as React from 'react';
import { JSONTree } from 'react-json-tree';
import styled from 'styled-components';

const theme = {
    scheme: 'default',
    //transparent main background
    base00: 'rgba(0, 0, 0, 0)',
    base01: 'rgb(245, 245, 245)',
    base02: 'rgb(235, 235, 235)',
    base03: '#93a1a1',
    base04: 'rgba(0, 0, 0, 0.3)',
    base05: '#586e75',
    base06: '#073642',
    base07: '#002b36',
    base08: '#d33682',
    base09: '#cb4b16',
    base0A: '#dc322f',
    base0B: '#859900',
    base0C: '#6c71c4',
    base0D: 'var(--ez-text-color-base, #282a86)',
    // base0D: '#586e75',
    base0E: '#2aa198',
    base0F: '#268bd2',
};

const JSONTreeContainer = styled.div`
    text-align: left;
    margin: 0;
    padding: 4px;
    border-radius: 4px;
    background-color: var(--ez-main-background-color-tertiary, rgb(245, 245, 245));
    &&& > ul {
        margin: 0 !important;
        & > li {
            padding-top: 0 !important;
            font-size: 0.85em;
        }
    }
`;

export interface ViewJSONProps {
    data: any;
    jsonExpandLevel?: number;
    hidden?: boolean;
    sortObjectKeys?;
    shouldExpandNode?: (key: string[], data: any, level: number) => boolean;
    hideFunctionTypes?: boolean;
}

export const JSONView: React.FC<ViewJSONProps> = ({
    sortObjectKeys,
    shouldExpandNode,
    jsonExpandLevel = 3,
    data,
    hideFunctionTypes,
}) => {
    const valueRenderer = (valueAsString: any, value: any, ...keyPath: (string | number)[]) => {
        if (typeof value === 'function') {
            if (hideFunctionTypes) return null;
            // Don't print out full body of the function, only its name
            return 'function ' + value.name + '(...)';
        }
        return valueAsString;
    };

    const labelRenderer = (
        keyPath: (string | number)[],
        nodeType: string,
        expanded: boolean,
        expandable: boolean
    ): React.ReactNode => {
        if (nodeType === 'Function' && hideFunctionTypes) return null;
        return keyPath?.[0];
    };

    const sen = shouldExpandNode || ((key, data, level) => level < jsonExpandLevel);
    return (
        <JSONTreeContainer>
            <JSONTree
                theme={theme}
                invertTheme={false}
                sortObjectKeys={sortObjectKeys}
                data={data || {}}
                shouldExpandNodeInitially={sen}
                valueRenderer={valueRenderer}
                labelRenderer={labelRenderer}
            />
        </JSONTreeContainer>
    );
};

// alias
export const ViewJSON = JSONView;
