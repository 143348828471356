import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Button } from '@ez/components';
import { BottomButtonContainer } from '../components';
import { PageSectionJobDetails } from './PageSectionJobDetails';
import { PageSectionApptDetails } from './PageSectionApptDetails';
import { PageSectionNotesAndPhotos } from './PageSectionNotesAndPhotos';
import { StageButton } from './StageButton';
import { PageSectionQuickActions } from '../components/QuickActionButtons';

export interface PageProps {}

export const Page: React.FC<PageProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();

    const onGoBack = () => {
        AppNavigator.navigateToOrigin('/scheduler');
    };

    return (
        <>
            {/*<StickyMenuBar>*/}
            {/*    <MenuBar.Section>*/}
            {/*        <MenuBar.Item icon={'chevron left'} onClick={onGoBack} />*/}
            {/*        <MenuBar.HeaderItem content={'Pool Cleaning'} />*/}
            {/*    </MenuBar.Section>*/}
            {/*</StickyMenuBar>*/}

            <StageButton />

            <PageSectionQuickActions />

            <PageSectionApptDetails />

            <PageSectionJobDetails />

            <PageSectionNotesAndPhotos />

            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Close'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </>
    );
};
