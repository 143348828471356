import * as React from 'react';
import { PropsWithChildren } from 'react';
import { HeaderButtons, HeaderButtonType } from './HeaderButtons';
import { HeaderIconProp, iconPropToComp } from '../Icon/helpers';
import { useAppBreakpoints } from '../use-app-breakpoints';
import { ButtonWithDropdownMenu, ButtonWithDropdownMenuProps, ButtonWithPopup, ButtonWithPopupProps } from '../buttons';
import { clsxm } from '../utils';

export interface SectionHeaderProps {
    content?: string | React.ReactNode;
    headerComp?: any;
    dividing?: boolean;
    icon?: HeaderIconProp;
    button?: HeaderButtonType | HeaderButtonType[];
    autoHideButtonsContent?: boolean;
    size?: 'small' | 'large';
}

const SectionHeaderComp: React.FC<SectionHeaderProps> = (props) => {
    const { isMobile } = useAppBreakpoints();
    let { size, autoHideButtonsContent, headerComp, content, icon, button, children, dividing } = props;

    let iconComp = iconPropToComp(icon);
    return (
        <div
            className={clsxm(
                'flex flex-row flex-wrap justify-between items-center font-bold text-base mt-2 first:mt-0 mb-2',
                dividing && 'pb-1 border-0 border-b border-tertiary border-solid mb-2',
                size === 'small' && 'text-sm',
                size === 'large' && 'text-xl'
            )}
        >
            <div className={'flex flex-row items-center flex-grow'}>
                {iconComp}
                {content}
                {children}
                {headerComp}
            </div>
            <div className={'print:hidden flex flex-row gap-1 -mt-1'}>
                <HeaderButtons buttons={button} autoHideButtonsContent={autoHideButtonsContent && isMobile} />
            </div>
        </div>
    );
};

export const SectionHeaderButtonGroup: React.FC<PropsWithChildren<any>> = (props) => (
    <div className={'flex flex-nowrap flex-row'} {...props} />
);

export const SectionHeaderButton: React.FC<ButtonWithPopupProps> = (props) => {
    return (
        <ButtonWithPopup //
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export const SectionHeaderDropdownButton: React.FC<ButtonWithDropdownMenuProps> = (props) => {
    return (
        <ButtonWithDropdownMenu
            iconRight={'ellipsis vertical'}
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export class SectionHeader extends React.Component<SectionHeaderProps> {
    public static ButtonGroup = SectionHeaderButtonGroup;
    public static Button = SectionHeaderButton;
    public static DropdownButton = SectionHeaderDropdownButton;

    render() {
        return <SectionHeaderComp {...this.props} />;
    }
}

export interface SectionHeader extends React.FC<SectionHeaderProps> {
    ButtonGroup: React.FC<PropsWithChildren<any>>;
    Button: React.FC<ButtonWithPopupProps>;
    DropdownButton: React.FC<ButtonWithDropdownMenuProps>;
}
