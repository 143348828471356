import * as React from 'react';
import {
    Display,
    DropdownMenuOptionType,
    FormikDefaultForm,
    MenuButtonItemConf,
    Modal,
    Panel,
    toastError,
    useModalCtrl,
} from '@ez/components';
import { FormikCustomerAndLocationInputFields } from '@poolware/app-shell';
import { ModuleLink } from '@poolware/react-app-navigator';
import { NodeType, useMutationEZCDeviceLink } from '@poolware/api';

import * as Yup from 'yup';
import * as URLBuilder from '../../../routes/url-builder';

const validationSchema = Yup.object().shape({
    customer: Yup.mixed().required('Required'),
});

export interface PanelOwnerDetailsProps {
    deviceLink: NodeType.EZCDeviceLink;
    refetchQueries: any[];
}

export const PanelOwnerDetails: React.FC<PanelOwnerDetailsProps> = ({ deviceLink, refetchQueries }) => {
    const owner = deviceLink?.owner;
    const pool = deviceLink?.pool;
    const canDelete = deviceLink?.checkMutations?.update;
    const canEdit = deviceLink?.checkMutations?.update;
    const modalCtrl = useModalCtrl();

    const { update } = useMutationEZCDeviceLink({ refetchQueries });

    const onChangeCustomer = async (values: { customer: NodeType.Customer; pool?: NodeType.Pool }) => {
        try {
            await update({
                id: deviceLink.id,
                owner: values.customer?.id || null,
                pool: values.pool?.id || null,
            });
            modalCtrl.onClose();
        } catch (e) {
            toastError(e);
        }
    };
    const onDelete = async () => {
        const res = window.confirm('Are you sure you want to remove the owner?');
        if (!res) return;
        try {
            await update({
                id: deviceLink.id,
                owner: null,
                pool: null,
            });
        } catch (e) {
            toastError(e);
        }
    };

    const menuItems: DropdownMenuOptionType[] = [
        {
            content: 'Change',
            icon: 'exchange',
            disabled: !canEdit,
            onClick: modalCtrl.onOpen,
        },
        canDelete && {
            content: 'Remove',
            icon: 'cancel',
            onClick: onDelete,
        },
    ];

    const poolLink = owner && pool ? URLBuilder.Customer(owner.id).Pool(pool.id).view : null;

    return (
        <>
            <Panel>
                <Panel.Header
                    content={'Owner'}
                    button={{
                        content: 'Edit',
                        menuItems: menuItems,
                    }}
                />
                <Panel.Body>
                    <Panel.Item label={'Owner Name'}>
                        {owner && (
                            <ModuleLink moduleId={'CUSTOMERS'} to={`/${owner?.id}`}>
                                <Display.Entity value={owner} />
                            </ModuleLink>
                        )}
                    </Panel.Item>
                    <Panel.Item label={'Address'}>
                        <Display.Address value={owner?.primaryAddress} defaultValue={' '} />
                    </Panel.Item>
                    <Panel.Item label={'Phone'}>
                        <Display.Phone value={owner?.primaryPhone?.data} />
                        {owner?.primaryPhone?.label && (
                            <span className={'pl-2 text-tertiary italic'}>({owner?.primaryPhone?.label})</span>
                        )}
                    </Panel.Item>
                    <Panel.Item label={'Email'}>
                        <Display.Email value={owner?.primaryEmail?.data} />
                        {owner?.primaryEmail?.label && (
                            <span className={'pl-2 text-tertiary italic'}>({owner?.primaryEmail?.label})</span>
                        )}
                    </Panel.Item>
                    {pool && (
                        <>
                            <Panel.Divider />
                            <Panel.Item label={'Pool'}>
                                {poolLink ? (
                                    <ModuleLink relativeToModule={false} to={poolLink}>
                                        {pool.name}
                                    </ModuleLink>
                                ) : (
                                    pool.name
                                )}
                            </Panel.Item>
                            <Panel.Item label={'Pool Volume'}>{pool.volume}L</Panel.Item>
                        </>
                    )}
                </Panel.Body>
            </Panel>
            {modalCtrl.open && (
                <Modal {...modalCtrl} closeOnDimmerClick={false}>
                    <FormikDefaultForm
                        icon={'user'}
                        initialValues={{
                            customer: owner,
                            pool: pool,
                            address: null,
                        }}
                        onSubmit={onChangeCustomer}
                        header={'Customer'}
                        onCancel={modalCtrl.onClose}
                        validationSchema={validationSchema}
                    >
                        <FormikCustomerAndLocationInputFields customerRequired={true} />
                    </FormikDefaultForm>
                </Modal>
            )}
        </>
    );
};
