//
// DO NOT EDIT! THIS IS A GENERATED FILE
//

export namespace NodeType {
    export type ID = string;

    export type EdgeNode<T> = {
        node?: Maybe<T>;
    };

    export type Edge<T> = Maybe<{
        edges?: Maybe<Maybe<EdgeNode<T>>[]>;
    }>;

    export type NodeOrId<T extends Node> = T | ID;

    export const isId = (data: NodeOrId<any>): data is ID => {
        return typeof data === 'string';
    };

    export const extractId = (data: NodeOrId<any>): ID => {
        return isId(data) ? data : data.id;
    };

    export const extractIdOrUndefined = (data: NodeOrId<any> | undefined): ID | undefined => {
        return isId(data) ? data : data?.id;
    };

    /** Information about pagination in a connection. */
    export interface PageMeta {
        /** Total count of items in collection */
        totalCount?: Maybe<number>;
        /** Number of pages calculated by totalCount/pageSize */
        pageCount?: Maybe<number>;
    }

    export type AnyNode = {
        id: ID;
        [key: string]: any;
    };

    export type Maybe<T> = T | null;
    export type InputMaybe<T> = Maybe<T>;
    export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
    export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
    export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
    /** All built-in and custom scalars, mapped to their actual values */
    export type Scalars = {
        ID: NodeType.ID;
        String: string;
        Boolean: boolean;
        Int: number;
        Float: number;
        Date: any;
        JSON: any;
    };

    export type AbstractDocument = Node & {
        __typename?: 'AbstractDocument';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<AbstractDocumentMutationCheck>;
        completedAt?: Maybe<Scalars['Date']>;
        completedById?: Maybe<Staff>;
        completionNote?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        customer?: Maybe<Customer>;
        dateDue?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        issuedAt?: Maybe<Scalars['Date']>;
        jsonBody?: Maybe<Scalars['JSON']>;
        refNumber?: Maybe<Scalars['String']>;
        serviceJob?: Maybe<ServiceJob>;
        statusTag?: Maybe<Scalars['String']>;
        typeTag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type AbstractDocumentMutationCheck = {
        __typename?: 'AbstractDocumentMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type AbstractDocumentMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateAbstractDocumentMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteAbstractDocumentMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAbstractDocumentMutationInput>>>;
    };

    export type AbstractDocumentMutationPayload = {
        __typename?: 'AbstractDocumentMutationPayload';
        AbstractDocument?: Maybe<AbstractDocument>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAbstractDocumentMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedCompletedById?: Maybe<Staff>;
        updatedCustomer?: Maybe<Customer>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedWorkOrder?: Maybe<WorkOrder>;
        viewer?: Maybe<Viewer>;
    };

    export type AbstractDocumentServiceJobAbstractDocumentsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        completedAt?: InputMaybe<DateRangeSearchCommon>;
        completedById?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        dateDue?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        issuedAt?: InputMaybe<DateRangeSearchCommon>;
        refNumber?: InputMaybe<StringMatchSearchCommon>;
        statusTag?: InputMaybe<StringMatchSearchCommon>;
        typeTag?: InputMaybe<StringMatchSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type AbstractDocumentServiceJobAbstractDocumentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dateDue?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AbstractDocument** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        issuedAt?: InputMaybe<Scalars['Boolean']>;
        refNumber?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type AbstractDocumentViewerAbstractDocumentsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        completedAt?: InputMaybe<DateRangeSearchCommon>;
        completedById?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        dateDue?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        issuedAt?: InputMaybe<DateRangeSearchCommon>;
        refNumber?: InputMaybe<StringMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        statusTag?: InputMaybe<StringMatchSearchCommon>;
        typeTag?: InputMaybe<StringMatchSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type AbstractDocumentViewerAbstractDocumentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dateDue?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AbstractDocument** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        issuedAt?: InputMaybe<Scalars['Boolean']>;
        refNumber?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type AbstractDocumentWorkOrderAbstractDocumentsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        completedAt?: InputMaybe<DateRangeSearchCommon>;
        completedById?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        dateDue?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        issuedAt?: InputMaybe<DateRangeSearchCommon>;
        refNumber?: InputMaybe<StringMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        statusTag?: InputMaybe<StringMatchSearchCommon>;
        typeTag?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AbstractDocumentWorkOrderAbstractDocumentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dateDue?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AbstractDocument** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        issuedAt?: InputMaybe<Scalars['Boolean']>;
        refNumber?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type AbstractDocumentsForServiceJobToAbstractDocumentConnection = {
        __typename?: 'AbstractDocumentsForServiceJobToAbstractDocumentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AbstractDocumentsForServiceJobToAbstractDocumentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AbstractDocumentsForServiceJobToAbstractDocumentMeta>;
    };

    /** An edge in a connection. */
    export type AbstractDocumentsForServiceJobToAbstractDocumentEdge = {
        __typename?: 'AbstractDocumentsForServiceJobToAbstractDocumentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AbstractDocument>;
    };

    export type AbstractDocumentsForServiceJobToAbstractDocumentMeta = {
        __typename?: 'AbstractDocumentsForServiceJobToAbstractDocumentMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type AbstractDocumentsForWorkOrderToAbstractDocumentConnection = {
        __typename?: 'AbstractDocumentsForWorkOrderToAbstractDocumentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AbstractDocumentsForWorkOrderToAbstractDocumentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AbstractDocumentsForWorkOrderToAbstractDocumentMeta>;
    };

    /** An edge in a connection. */
    export type AbstractDocumentsForWorkOrderToAbstractDocumentEdge = {
        __typename?: 'AbstractDocumentsForWorkOrderToAbstractDocumentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AbstractDocument>;
    };

    export type AbstractDocumentsForWorkOrderToAbstractDocumentMeta = {
        __typename?: 'AbstractDocumentsForWorkOrderToAbstractDocumentMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type AccessTokensForStaffToStaffAccessTokenConnection = {
        __typename?: 'AccessTokensForStaffToStaffAccessTokenConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AccessTokensForStaffToStaffAccessTokenEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AccessTokensForStaffToStaffAccessTokenMeta>;
    };

    /** An edge in a connection. */
    export type AccessTokensForStaffToStaffAccessTokenEdge = {
        __typename?: 'AccessTokensForStaffToStaffAccessTokenEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<StaffAccessToken>;
    };

    export type AccessTokensForStaffToStaffAccessTokenMeta = {
        __typename?: 'AccessTokensForStaffToStaffAccessTokenMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type ActivitySample = {
        __typename?: 'ActivitySample';
        count?: Maybe<Scalars['Int']>;
        date?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        franchiseId?: Maybe<Scalars['String']>;
        franchiseName?: Maybe<Scalars['String']>;
        staff?: Maybe<Staff>;
    };

    /** A connection to a list of items. */
    export type ActualChemicalTargetsForPoolToPoolChemicalTargetConnection = {
        __typename?: 'ActualChemicalTargetsForPoolToPoolChemicalTargetConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ActualChemicalTargetsForPoolToPoolChemicalTargetEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ActualChemicalTargetsForPoolToPoolChemicalTargetEdge = {
        __typename?: 'ActualChemicalTargetsForPoolToPoolChemicalTargetEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolChemicalTarget>;
    };

    /** A connection to a list of items. */
    export type AddedAppointmentsForStaffToAppointmentItemConnection = {
        __typename?: 'AddedAppointmentsForStaffToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AddedAppointmentsForStaffToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AddedAppointmentsForStaffToAppointmentItemEdge = {
        __typename?: 'AddedAppointmentsForStaffToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    export type Address = Node & {
        __typename?: 'Address';
        buildingName?: Maybe<Scalars['String']>;
        checkMutations?: Maybe<AddressMutationCheck>;
        city?: Maybe<Scalars['String']>;
        coordinates?: Maybe<Coordinate>;
        country?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        destinationName?: Maybe<Scalars['String']>;
        entity?: Maybe<Entity>;
        id: Scalars['ID'];
        isBill?: Maybe<Scalars['Boolean']>;
        isPrimary?: Maybe<Scalars['Boolean']>;
        poBoxNumber?: Maybe<Scalars['String']>;
        postCode?: Maybe<Scalars['String']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        sites?: Maybe<SitesForAddressToSiteConnection>;
        state?: Maybe<Scalars['String']>;
        streetName?: Maybe<Scalars['String']>;
        streetNumber?: Maybe<Scalars['String']>;
        suburb?: Maybe<Scalars['String']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type AddressSitesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SiteAddressSitesSearch>;
        sort?: InputMaybe<SiteAddressSitesSort>;
    };

    export type AddressEntityAddressesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AddressEntityAddressesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Address** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type AddressInputBaseCommon = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state: Scalars['String'];
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
    };

    export type AddressMutationCheck = {
        __typename?: 'AddressMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type AddressMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateAddressMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAddressMutationInput>>>;
    };

    export type AddressMutationPayload = {
        __typename?: 'AddressMutationPayload';
        Address?: Maybe<Address>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAddressMutationOutput>>>;
        updatedEntity?: Maybe<Entity>;
        viewer?: Maybe<Viewer>;
    };

    export type AddressNodeMatchSearchCommon = {
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
    };

    /** A connection to a list of items. */
    export type AddressesForEntityToAddressConnection = {
        __typename?: 'AddressesForEntityToAddressConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AddressesForEntityToAddressEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AddressesForEntityToAddressMeta>;
    };

    /** An edge in a connection. */
    export type AddressesForEntityToAddressEdge = {
        __typename?: 'AddressesForEntityToAddressEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Address>;
    };

    export type AddressesForEntityToAddressMeta = {
        __typename?: 'AddressesForEntityToAddressMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type AffectsForRecommendedTreatmentToSolutionAffectConnection = {
        __typename?: 'AffectsForRecommendedTreatmentToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AffectsForRecommendedTreatmentToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AffectsForRecommendedTreatmentToSolutionAffectEdge = {
        __typename?: 'AffectsForRecommendedTreatmentToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    /** A connection to a list of items. */
    export type AffectsForTestReportSolutionToSolutionAffectConnection = {
        __typename?: 'AffectsForTestReportSolutionToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AffectsForTestReportSolutionToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AffectsForTestReportSolutionToSolutionAffectEdge = {
        __typename?: 'AffectsForTestReportSolutionToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    /** A connection to a list of items. */
    export type AffectsForTestTreatmentToTreatmentAffectConnection = {
        __typename?: 'AffectsForTestTreatmentToTreatmentAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AffectsForTestTreatmentToTreatmentAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AffectsForTestTreatmentToTreatmentAffectEdge = {
        __typename?: 'AffectsForTestTreatmentToTreatmentAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TreatmentAffect>;
    };

    /** A connection to a list of items. */
    export type AffectsForTreatmentResultTypeToSolutionAffectConnection = {
        __typename?: 'AffectsForTreatmentResultTypeToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AffectsForTreatmentResultTypeToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AffectsForTreatmentResultTypeToSolutionAffectEdge = {
        __typename?: 'AffectsForTreatmentResultTypeToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    export type Announcement = Node & {
        __typename?: 'Announcement';
        addedBy?: Maybe<Staff>;
        authorName?: Maybe<Scalars['String']>;
        body?: Maybe<Scalars['String']>;
        checkMutations?: Maybe<AnnouncementMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        link?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        preview?: Maybe<Scalars['String']>;
        publishedAt?: Maybe<Scalars['Date']>;
        subject?: Maybe<Scalars['String']>;
    };

    export type AnnouncementMutationCheck = {
        __typename?: 'AnnouncementMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        publish?: Maybe<Scalars['Boolean']>;
        unpublish?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type AnnouncementMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateAnnouncementMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteAnnouncementMutationInput>>>;
        publish?: InputMaybe<Array<InputMaybe<AnnouncementPublishMutationInput>>>;
        unpublish?: InputMaybe<Array<InputMaybe<AnnouncementUnpublishMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAnnouncementMutationInput>>>;
    };

    export type AnnouncementMutationPayload = {
        __typename?: 'AnnouncementMutationPayload';
        Announcement?: Maybe<Announcement>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAnnouncementMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type AnnouncementPublishMutationInput = {
        date?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Announcement** */
        id: Scalars['ID'];
    };

    export type AnnouncementUnpublishMutationInput = {
        /** Expects node: **Announcement** */
        id: Scalars['ID'];
    };

    export type AnnouncementViewerAnnouncementsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AnonymousCoefficientNodeInitInput = {
        solutionCoefficient: Scalars['Float'];
        /** Expects node: **Measurement** */
        type: Scalars['ID'];
    };

    export type AnonymousRecommendation = {
        __typename?: 'AnonymousRecommendation';
        problem?: Maybe<TestProblem>;
        recommendedTreatments?: Maybe<Array<Maybe<AnonymousRecommendedTreatment>>>;
        selectedTreatment?: Maybe<AnonymousRecommendedTreatment>;
    };

    export type AnonymousRecommendedTreatment = {
        __typename?: 'AnonymousRecommendedTreatment';
        dosage?: Maybe<Scalars['Float']>;
        problem?: Maybe<TestProblem>;
        sampleInstruction?: Maybe<Scalars['String']>;
        treatment?: Maybe<TestTreatment>;
    };

    export type AnonymousSample = {
        __typename?: 'AnonymousSample';
        type?: Maybe<Measurement>;
        value?: Maybe<Scalars['Float']>;
    };

    export type AnonymousSampleNodeInitInput = {
        /** Expects node: **Measurement** */
        type: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type AnonymousTarget = {
        __typename?: 'AnonymousTarget';
        higherThreshold?: Maybe<Scalars['Float']>;
        lowerThreshold?: Maybe<Scalars['Float']>;
        measurementType?: Maybe<Measurement>;
        target?: Maybe<Scalars['Float']>;
    };

    export type AnonymousTargetNodeInitInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        target: Scalars['Float'];
    };

    export type AnonymousTreatment = {
        __typename?: 'AnonymousTreatment';
        /** Array size expected to be from 1 to 20 */
        affects?: Maybe<Array<Maybe<AnonymousSample>>>;
        name?: Maybe<Scalars['String']>;
    };

    export type AnonymousTreatmentNodeInitInput = {
        /** Array size expected to be from 1 to 20 */
        affects: Array<InputMaybe<AnonymousSampleNodeInitInput>>;
        name: Scalars['String'];
    };

    export type Appointment = Node & {
        __typename?: 'Appointment';
        addedBy?: Maybe<Staff>;
        baseInstance?: Maybe<AppointmentInstance>;
        checkMutations?: Maybe<AppointmentMutationCheck>;
        endPatternDate?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        items?: Maybe<ItemsForAppointmentToAppointmentItemConnection>;
        parentAppointment?: Maybe<Appointment>;
        patternBlockId?: Maybe<Scalars['String']>;
        recurrence?: Maybe<Recurrence>;
        startDate?: Maybe<Scalars['Date']>;
        workOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type AppointmentItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type AppointmentChangeBlockEndDateMutationInput = {
        endDate?: InputMaybe<Scalars['Date']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
    };

    export type AppointmentChangeEndDateMutationInput = {
        endDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Appointment** */
        id: Scalars['ID'];
    };

    export type AppointmentDeleteBlockMutationInput = {
        patternBlockId?: InputMaybe<Scalars['String']>;
    };

    export type AppointmentGroup = Node & {
        __typename?: 'AppointmentGroup';
        appointments?: Maybe<AppointmentsForAppointmentGroupToAppointmentItemConnection>;
        checkMutations?: Maybe<AppointmentGroupMutationCheck>;
        colorPalette?: Maybe<Scalars['JSON']>;
        createdAt?: Maybe<Scalars['Date']>;
        defaultColor?: Maybe<Scalars['String']>;
        description?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        priority?: Maybe<Scalars['Int']>;
        statusTitleCustom?: Maybe<Scalars['String']>;
        statusTitleFailed?: Maybe<Scalars['String']>;
        statusTitleFinished?: Maybe<Scalars['String']>;
        statusTitlePending?: Maybe<Scalars['String']>;
        statusTitleStarted?: Maybe<Scalars['String']>;
        statusVisibilityCustom?: Maybe<Scalars['Boolean']>;
        statusVisibilityStarted?: Maybe<Scalars['Boolean']>;
        title?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type AppointmentGroupAppointmentsArgs = {
        addedBy?: InputMaybe<Scalars['ID']>;
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type AppointmentGroupMutationCheck = {
        __typename?: 'AppointmentGroupMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type AppointmentGroupMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateAppointmentGroupMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteAppointmentGroupMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAppointmentGroupMutationInput>>>;
    };

    export type AppointmentGroupMutationPayload = {
        __typename?: 'AppointmentGroupMutationPayload';
        AppointmentGroup?: Maybe<AppointmentGroup>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAppointmentGroupMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type AppointmentGroupViewerAppointmentGroupsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentGroupViewerAppointmentGroupsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AppointmentGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type AppointmentInstance = Node & {
        __typename?: 'AppointmentInstance';
        address?: Maybe<Address>;
        appointment?: Maybe<Appointment>;
        checkMutations?: Maybe<AppointmentInstanceMutationCheck>;
        color?: Maybe<Scalars['String']>;
        completed?: Maybe<Scalars['Boolean']>;
        createdAt?: Maybe<Scalars['Date']>;
        customer?: Maybe<Customer>;
        duration?: Maybe<Scalars['Int']>;
        group?: Maybe<AppointmentGroup>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        originalDate?: Maybe<Scalars['Date']>;
        patternExcepted?: Maybe<Scalars['Boolean']>;
        paymentUrl?: Maybe<Scalars['String']>;
        pool?: Maybe<Pool>;
        selectedColor?: Maybe<Scalars['String']>;
        serviceJob?: Maybe<ServiceJob>;
        site?: Maybe<Site>;
        staff?: Maybe<Staff>;
        startDate?: Maybe<Scalars['Date']>;
        status?: Maybe<AppointmentStatusEnum>;
        statusMap?: Maybe<AppointmentStateMap>;
        vendSale?: Maybe<VendAppointmentSaleType>;
        workOrder?: Maybe<WorkOrder>;
        workOrders?: Maybe<WorkOrdersForAppointmentInstanceToWorkOrderConnection>;
    };

    export type AppointmentInstanceWorkOrdersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearch>;
        sort?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSort>;
    };

    export type AppointmentInstanceAddSaleMutationInput = {
        /** Expects node: **AppointmentInstance** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type AppointmentInstanceMutationCheck = {
        __typename?: 'AppointmentInstanceMutationCheck';
        addSale?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        startWorkOrder?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type AppointmentInstanceMutationInput = {
        addSale?: InputMaybe<Array<InputMaybe<AppointmentInstanceAddSaleMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteAppointmentInstanceMutationInput>>>;
        startWorkOrder?: InputMaybe<Array<InputMaybe<AppointmentInstanceStartWorkOrderMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAppointmentInstanceMutationInput>>>;
    };

    export type AppointmentInstanceMutationPayload = {
        __typename?: 'AppointmentInstanceMutationPayload';
        AppointmentInstance?: Maybe<AppointmentInstance>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAppointmentInstanceMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedAppointment?: Maybe<Appointment>;
        updatedCustomer?: Maybe<Customer>;
        updatedGroup?: Maybe<AppointmentGroup>;
        updatedPool?: Maybe<Pool>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedSite?: Maybe<Site>;
        updatedStaff?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type AppointmentInstanceStartWorkOrderMutationInput = {
        /** Expects node: **AppointmentInstance** */
        id: Scalars['ID'];
    };

    export type AppointmentItem = Node & {
        __typename?: 'AppointmentItem';
        address?: Maybe<Address>;
        adhoc?: Maybe<Scalars['Boolean']>;
        appointment?: Maybe<Appointment>;
        checkMutations?: Maybe<AppointmentItemMutationCheck>;
        color?: Maybe<Scalars['String']>;
        customer?: Maybe<Customer>;
        deleted?: Maybe<Scalars['Boolean']>;
        duration?: Maybe<Scalars['Int']>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<AppointmentGroup>;
        id: Scalars['ID'];
        isEnd?: Maybe<Scalars['Boolean']>;
        isRecurrentOrigin?: Maybe<Scalars['Boolean']>;
        isRecurring?: Maybe<Scalars['Boolean']>;
        isStart?: Maybe<Scalars['Boolean']>;
        isWorkOrderPending?: Maybe<Scalars['Boolean']>;
        note?: Maybe<Scalars['String']>;
        originPattern?: Maybe<RecurrencePattern>;
        originalDate?: Maybe<Scalars['Date']>;
        patternBlockId?: Maybe<Scalars['String']>;
        paymentUrl?: Maybe<Scalars['String']>;
        pool?: Maybe<Pool>;
        serviceJob?: Maybe<ServiceJob>;
        splitAppointment?: Maybe<Appointment>;
        staff?: Maybe<Staff>;
        startDate?: Maybe<Scalars['Date']>;
        status?: Maybe<AppointmentStatusEnum>;
        statusMap?: Maybe<AppointmentStateMap>;
        vendSale?: Maybe<VendAppointmentSaleType>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type AppointmentItemAddSaleMutationInput = {
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type AppointmentItemChangeRecurrenceFromMutationInput = {
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        recurrence?: InputMaybe<RecurrenceNodeUpdateInput>;
    };

    export type AppointmentItemChangeRecurrenceMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        deleteExcepted?: InputMaybe<Scalars['Boolean']>;
        duration?: InputMaybe<Scalars['Int']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        recurrence: RecurrenceNodeInitInput;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        startNewFrom: Scalars['Date'];
        status?: InputMaybe<AppointmentStatusEnum>;
        stopCurrentAt?: InputMaybe<Scalars['Date']>;
        withinPatternBlockId?: InputMaybe<Scalars['Boolean']>;
    };

    export type AppointmentItemDeleteMutationInput = {
        all?: InputMaybe<Scalars['Boolean']>;
        future?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        withinSharedPatternBlockId?: InputMaybe<Scalars['Boolean']>;
    };

    export type AppointmentItemMutationCheck = {
        __typename?: 'AppointmentItemMutationCheck';
        addSale?: Maybe<Scalars['Boolean']>;
        changeRecurrence?: Maybe<Scalars['Boolean']>;
        changeRecurrenceFrom?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        startWorkOrder?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateFuture?: Maybe<Scalars['Boolean']>;
        updateSingle?: Maybe<Scalars['Boolean']>;
    };

    export type AppointmentItemMutationInput = {
        addSale?: InputMaybe<Array<InputMaybe<AppointmentItemAddSaleMutationInput>>>;
        changeRecurrence?: InputMaybe<Array<InputMaybe<AppointmentItemChangeRecurrenceMutationInput>>>;
        changeRecurrenceFrom?: InputMaybe<Array<InputMaybe<AppointmentItemChangeRecurrenceFromMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        delete?: InputMaybe<Array<InputMaybe<AppointmentItemDeleteMutationInput>>>;
        startWorkOrder?: InputMaybe<Array<InputMaybe<AppointmentItemStartWorkOrderMutationInput>>>;
        update?: InputMaybe<Array<InputMaybe<AppointmentItemUpdateMutationInput>>>;
        updateFuture?: InputMaybe<Array<InputMaybe<AppointmentItemUpdateFutureMutationInput>>>;
        updateSingle?: InputMaybe<Array<InputMaybe<AppointmentItemUpdateSingleMutationInput>>>;
    };

    export type AppointmentItemMutationPayload = {
        __typename?: 'AppointmentItemMutationPayload';
        AppointmentItem?: Maybe<AppointmentItem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAppointmentItemMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedSplitAppointment?: Maybe<Appointment>;
        viewer?: Maybe<Viewer>;
    };

    export type AppointmentItemSearchCommon = {
        serviceJob?: InputMaybe<AppointmentItemSearchCommonServiceJob>;
        staff?: InputMaybe<AppointmentItemSearchCommonStaff>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrder?: InputMaybe<AppointmentItemSearchCommonWorkOrder>;
    };

    export type AppointmentItemSearchCommonServiceJob = {
        /** Expects node: **ServiceJobGroup** */
        excludeGroup?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ServiceJobTemplate** */
        excludeUsedTemplate?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ServiceJobTemplate** */
        usedTemplate?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type AppointmentItemSearchCommonStaff = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        /** Expects node: **StaffTeam** */
        team?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type AppointmentItemSearchCommonWorkOrder = {
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type AppointmentItemStartWorkOrderMutationInput = {
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
    };

    export type AppointmentItemUpdateFutureMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        duration?: InputMaybe<Scalars['Int']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        status?: InputMaybe<AppointmentStatusEnum>;
        time?: InputMaybe<Scalars['Date']>;
    };

    export type AppointmentItemUpdateMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        duration?: InputMaybe<Scalars['Int']>;
        endDate?: InputMaybe<Scalars['Date']>;
        future?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        mergeStates?: InputMaybe<Scalars['Boolean']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        recurrence?: InputMaybe<RecurrenceNodeInitInput>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
        status?: InputMaybe<AppointmentStatusEnum>;
    };

    export type AppointmentItemUpdateSingleMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        date?: InputMaybe<Scalars['Date']>;
        duration?: InputMaybe<Scalars['Int']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        status?: InputMaybe<AppointmentStatusEnum>;
    };

    /** A connection to a list of items. */
    export type AppointmentItemsForServiceJobToAppointmentItemConnection = {
        __typename?: 'AppointmentItemsForServiceJobToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AppointmentItemsForServiceJobToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AppointmentItemsForServiceJobToAppointmentItemEdge = {
        __typename?: 'AppointmentItemsForServiceJobToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    export type AppointmentMutationCheck = {
        __typename?: 'AppointmentMutationCheck';
        changeEndDate?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        split?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateTimeZone?: Maybe<Scalars['Boolean']>;
    };

    export type AppointmentMutationInput = {
        changeBlockEndDate?: InputMaybe<Array<InputMaybe<AppointmentChangeBlockEndDateMutationInput>>>;
        changeEndDate?: InputMaybe<Array<InputMaybe<AppointmentChangeEndDateMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateAppointmentMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteAppointmentMutationInput>>>;
        deleteBlock?: InputMaybe<Array<InputMaybe<AppointmentDeleteBlockMutationInput>>>;
        split?: InputMaybe<Array<InputMaybe<AppointmentSplitMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateAppointmentMutationInput>>>;
        updateTimeZone?: InputMaybe<Array<InputMaybe<AppointmentUpdateTimeZoneMutationInput>>>;
        updateTimeZoneFrom?: InputMaybe<Array<InputMaybe<AppointmentUpdateTimeZoneFromMutationInput>>>;
    };

    export type AppointmentMutationPayload = {
        __typename?: 'AppointmentMutationPayload';
        Appointment?: Maybe<Appointment>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkAppointmentMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedBaseInstance?: Maybe<AppointmentInstance>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParentAppointment?: Maybe<Appointment>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type AppointmentSplitMutationInput = {
        /** Used to specify a new hour and minute */
        dateTime?: InputMaybe<Scalars['Date']>;
        endDate?: InputMaybe<Scalars['Date']>;
        from: Scalars['Date'];
        /** Expects node: **Appointment** */
        id: Scalars['ID'];
        recurrence?: InputMaybe<RecurrenceNodeInitInput>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type AppointmentStateField = {
        __typename?: 'AppointmentStateField';
        isDefault?: Maybe<Scalars['Boolean']>;
        isVisible?: Maybe<Scalars['Boolean']>;
        title?: Maybe<Scalars['String']>;
    };

    export type AppointmentStateMap = {
        __typename?: 'AppointmentStateMap';
        custom?: Maybe<AppointmentStateField>;
        failed?: Maybe<AppointmentStateField>;
        finished?: Maybe<AppointmentStateField>;
        pending?: Maybe<AppointmentStateField>;
        started?: Maybe<AppointmentStateField>;
    };

    export enum AppointmentStatusEnum {
        ActionRequired = 'ActionRequired',
        Cancelled = 'Cancelled',
        Finished = 'Finished',
        InProgress = 'InProgress',
        NotStarted = 'NotStarted',
    }

    export type AppointmentUpdateTimeZoneFromMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        from: Scalars['Date'];
        /** Expects node: **Timezone** */
        timeZone: Scalars['ID'];
    };

    export type AppointmentUpdateTimeZoneMutationInput = {
        /** Expects node: **Appointment** */
        id: Scalars['ID'];
        /** Expects node: **Timezone** */
        timeZone?: InputMaybe<Scalars['ID']>;
    };

    export type AppointmentViewerAppointmentsSearch = {
        addedBy?: InputMaybe<AppointmentViewerAppointmentsSearchAddedBy>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByRegistration>;
        teams?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByTeams>;
        user?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUser>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserCustomer>;
        entity?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserCustomerTags>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntity = {
        addresses?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPools>;
        serviceJob?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJob>;
        sites?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSite>;
        type?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSite = {
        address?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJob = {
        address?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobWorkOrders>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerTags>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntitySites = {
        address?: InputMaybe<AppointmentViewerAppointmentsSearchAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type AppointmentViewerAppointmentsSearchAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type AppointmentsForAppointmentGroupToAppointmentItemConnection = {
        __typename?: 'AppointmentsForAppointmentGroupToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AppointmentsForAppointmentGroupToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AppointmentsForAppointmentGroupToAppointmentItemEdge = {
        __typename?: 'AppointmentsForAppointmentGroupToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type AppointmentsForCustomerToAppointmentItemConnection = {
        __typename?: 'AppointmentsForCustomerToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AppointmentsForCustomerToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AppointmentsForCustomerToAppointmentItemEdge = {
        __typename?: 'AppointmentsForCustomerToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type AppointmentsForPoolToAppointmentItemConnection = {
        __typename?: 'AppointmentsForPoolToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AppointmentsForPoolToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AppointmentsForPoolToAppointmentItemEdge = {
        __typename?: 'AppointmentsForPoolToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type AssignedAppointmentsForStaffToAppointmentItemConnection = {
        __typename?: 'AssignedAppointmentsForStaffToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AssignedAppointmentsForStaffToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AssignedAppointmentsForStaffToAppointmentItemEdge = {
        __typename?: 'AssignedAppointmentsForStaffToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type AssignedTreatmentsForTestReportToTestTreatmentConnection = {
        __typename?: 'AssignedTreatmentsForTestReportToTestTreatmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AssignedTreatmentsForTestReportToTestTreatmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type AssignedTreatmentsForTestReportToTestTreatmentEdge = {
        __typename?: 'AssignedTreatmentsForTestReportToTestTreatmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatment>;
    };

    /** A connection to a list of items. */
    export type AssignedWorkOrdersForStaffToWorkOrderConnection = {
        __typename?: 'AssignedWorkOrdersForStaffToWorkOrderConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AssignedWorkOrdersForStaffToWorkOrderEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AssignedWorkOrdersForStaffToWorkOrderMeta>;
    };

    /** An edge in a connection. */
    export type AssignedWorkOrdersForStaffToWorkOrderEdge = {
        __typename?: 'AssignedWorkOrdersForStaffToWorkOrderEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrder>;
    };

    export type AssignedWorkOrdersForStaffToWorkOrderMeta = {
        __typename?: 'AssignedWorkOrdersForStaffToWorkOrderMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type AssociatedStagesForServiceJobGroupToServiceJobStageConnection = {
        __typename?: 'AssociatedStagesForServiceJobGroupToServiceJobStageConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AssociatedStagesForServiceJobGroupToServiceJobStageEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AssociatedStagesForServiceJobGroupToServiceJobStageMeta>;
    };

    /** An edge in a connection. */
    export type AssociatedStagesForServiceJobGroupToServiceJobStageEdge = {
        __typename?: 'AssociatedStagesForServiceJobGroupToServiceJobStageEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobStage>;
    };

    export type AssociatedStagesForServiceJobGroupToServiceJobStageMeta = {
        __typename?: 'AssociatedStagesForServiceJobGroupToServiceJobStageMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type AttachmentsForTestReportToTestReportAttachmentConnection = {
        __typename?: 'AttachmentsForTestReportToTestReportAttachmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<AttachmentsForTestReportToTestReportAttachmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<AttachmentsForTestReportToTestReportAttachmentMeta>;
    };

    /** An edge in a connection. */
    export type AttachmentsForTestReportToTestReportAttachmentEdge = {
        __typename?: 'AttachmentsForTestReportToTestReportAttachmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReportAttachment>;
    };

    export type AttachmentsForTestReportToTestReportAttachmentMeta = {
        __typename?: 'AttachmentsForTestReportToTestReportAttachmentMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type BooleanMatchSearchCommon = {
        is?: InputMaybe<Scalars['Boolean']>;
        not?: InputMaybe<Scalars['Boolean']>;
    };

    export type Brand = Node & {
        __typename?: 'Brand';
        checkMutations?: Maybe<BrandMutationCheck>;
        company?: Maybe<ProductCompany>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        identification?: Maybe<Scalars['String']>;
        name?: Maybe<Scalars['String']>;
        products?: Maybe<ProductsForBrandToProductConnection>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type BrandProductsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductBrandProductsSearch>;
        sort?: InputMaybe<ProductBrandProductsSort>;
    };

    /** A connection to a list of items. */
    export type BrandCompaniesForProductSearchHelperToProductCompanyConnection = {
        __typename?: 'BrandCompaniesForProductSearchHelperToProductCompanyConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<BrandCompaniesForProductSearchHelperToProductCompanyEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<BrandCompaniesForProductSearchHelperToProductCompanyMeta>;
    };

    /** An edge in a connection. */
    export type BrandCompaniesForProductSearchHelperToProductCompanyEdge = {
        __typename?: 'BrandCompaniesForProductSearchHelperToProductCompanyEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductCompany>;
    };

    export type BrandCompaniesForProductSearchHelperToProductCompanyMeta = {
        __typename?: 'BrandCompaniesForProductSearchHelperToProductCompanyMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type BrandMutationCheck = {
        __typename?: 'BrandMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type BrandMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateBrandMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteBrandMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateBrandMutationInput>>>;
    };

    export type BrandMutationPayload = {
        __typename?: 'BrandMutationPayload';
        Brand?: Maybe<Brand>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkBrandMutationOutput>>>;
        updatedCompany?: Maybe<ProductCompany>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type BrandNodeMatchSearchCommon = {
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type BrandProductCompanyBrandsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type BrandProductCompanyBrandsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Brand** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type BrandViewerBrandsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<BrandViewerBrandsSearchCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type BrandViewerBrandsSearchCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type BrandViewerBrandsSearchTraits = {
        /** Expects node: **ProductTraitDeclaration** */
        declarations?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type BrandViewerBrandsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Brand** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type BrandsForProductCompanyToBrandConnection = {
        __typename?: 'BrandsForProductCompanyToBrandConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<BrandsForProductCompanyToBrandEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<BrandsForProductCompanyToBrandMeta>;
    };

    /** An edge in a connection. */
    export type BrandsForProductCompanyToBrandEdge = {
        __typename?: 'BrandsForProductCompanyToBrandEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Brand>;
    };

    export type BrandsForProductCompanyToBrandMeta = {
        __typename?: 'BrandsForProductCompanyToBrandMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type BrandsForProductSearchHelperToBrandConnection = {
        __typename?: 'BrandsForProductSearchHelperToBrandConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<BrandsForProductSearchHelperToBrandEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<BrandsForProductSearchHelperToBrandMeta>;
    };

    /** An edge in a connection. */
    export type BrandsForProductSearchHelperToBrandEdge = {
        __typename?: 'BrandsForProductSearchHelperToBrandEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Brand>;
    };

    export type BrandsForProductSearchHelperToBrandMeta = {
        __typename?: 'BrandsForProductSearchHelperToBrandMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type BulkAbstractDocumentMutationOutput = {
        __typename?: 'BulkAbstractDocumentMutationOutput';
        AbstractDocument?: Maybe<AbstractDocument>;
        updatedAddedBy?: Maybe<Staff>;
        updatedCompletedById?: Maybe<Staff>;
        updatedCustomer?: Maybe<Customer>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedWorkOrder?: Maybe<WorkOrder>;
    };

    export type BulkAddressMutationOutput = {
        __typename?: 'BulkAddressMutationOutput';
        Address?: Maybe<Address>;
        updatedEntity?: Maybe<Entity>;
    };

    export type BulkAnnouncementMutationOutput = {
        __typename?: 'BulkAnnouncementMutationOutput';
        Announcement?: Maybe<Announcement>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkAppointmentGroupMutationOutput = {
        __typename?: 'BulkAppointmentGroupMutationOutput';
        AppointmentGroup?: Maybe<AppointmentGroup>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkAppointmentInstanceMutationOutput = {
        __typename?: 'BulkAppointmentInstanceMutationOutput';
        AppointmentInstance?: Maybe<AppointmentInstance>;
        updatedAddress?: Maybe<Address>;
        updatedAppointment?: Maybe<Appointment>;
        updatedCustomer?: Maybe<Customer>;
        updatedGroup?: Maybe<AppointmentGroup>;
        updatedPool?: Maybe<Pool>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedSite?: Maybe<Site>;
        updatedStaff?: Maybe<Staff>;
    };

    export type BulkAppointmentItemMutationOutput = {
        __typename?: 'BulkAppointmentItemMutationOutput';
        AppointmentItem?: Maybe<AppointmentItem>;
        updatedAddress?: Maybe<Address>;
        updatedSplitAppointment?: Maybe<Appointment>;
    };

    export type BulkAppointmentMutationOutput = {
        __typename?: 'BulkAppointmentMutationOutput';
        Appointment?: Maybe<Appointment>;
        updatedAddedBy?: Maybe<Staff>;
        updatedBaseInstance?: Maybe<AppointmentInstance>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParentAppointment?: Maybe<Appointment>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type BulkBrandMutationOutput = {
        __typename?: 'BulkBrandMutationOutput';
        Brand?: Maybe<Brand>;
        updatedCompany?: Maybe<ProductCompany>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkConsumableProductMutationOutput = {
        __typename?: 'BulkConsumableProductMutationOutput';
        ConsumableProduct?: Maybe<ConsumableProduct>;
    };

    export type BulkContactMutationOutput = {
        __typename?: 'BulkContactMutationOutput';
        Contact?: Maybe<Contact>;
        updatedEntity?: Maybe<Entity>;
        updatedType?: Maybe<ContactType>;
    };

    export type BulkContactTypeMutationOutput = {
        __typename?: 'BulkContactTypeMutationOutput';
        ContactType?: Maybe<ContactType>;
    };

    export type BulkCustomContractMutationOutput = {
        __typename?: 'BulkCustomContractMutationOutput';
        CustomContract?: Maybe<CustomContract>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedEntity?: Maybe<Entity>;
    };

    export type BulkCustomerMutationOutput = {
        __typename?: 'BulkCustomerMutationOutput';
        Customer?: Maybe<Customer>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedBillAddress?: Maybe<Address>;
        updatedPrimaryAddress?: Maybe<Address>;
    };

    export type BulkCustomerNoteMutationOutput = {
        __typename?: 'BulkCustomerNoteMutationOutput';
        CustomerNote?: Maybe<CustomerNote>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Customer>;
    };

    export type BulkCustomerTagMutationOutput = {
        __typename?: 'BulkCustomerTagMutationOutput';
        CustomerTag?: Maybe<CustomerTag>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkEZCDeviceLinkMutationOutput = {
        __typename?: 'BulkEZCDeviceLinkMutationOutput';
        EZCDeviceLink?: Maybe<EZCDeviceLink>;
        updatedDevice?: Maybe<EZCDevice>;
        updatedFranchise?: Maybe<Franchise>;
        updatedItem?: Maybe<Item>;
        updatedOwner?: Maybe<Customer>;
        updatedPool?: Maybe<Pool>;
        updatedProduct?: Maybe<Product>;
        updatedSite?: Maybe<Site>;
    };

    export type BulkEZCDeviceStateMutationOutput = {
        __typename?: 'BulkEZCDeviceStateMutationOutput';
        EZCDeviceState?: Maybe<EZCDeviceState>;
    };

    export type BulkEZCFranchiseLinkMutationOutput = {
        __typename?: 'BulkEZCFranchiseLinkMutationOutput';
        EZCFranchiseLink?: Maybe<EZCFranchiseLink>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceProvider?: Maybe<EZCServiceProvider>;
    };

    export type BulkEmailGlobalSelectionMutationOutput = {
        __typename?: 'BulkEmailGlobalSelectionMutationOutput';
        EmailGlobalSelection?: Maybe<EmailGlobalSelection>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
    };

    export type BulkEmailMessageMutationOutput = {
        __typename?: 'BulkEmailMessageMutationOutput';
        EmailMessage?: Maybe<EmailMessage>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedMailTo?: Maybe<Entity>;
        updatedReport?: Maybe<TestReport>;
        updatedSubmittedBy?: Maybe<Entity>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
    };

    export type BulkEmailTemplateMutationOutput = {
        __typename?: 'BulkEmailTemplateMutationOutput';
        EmailTemplate?: Maybe<EmailTemplate>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedType?: Maybe<EmailType>;
    };

    export type BulkEmailTemplateOrganisationSelectionMutationOutput = {
        __typename?: 'BulkEmailTemplateOrganisationSelectionMutationOutput';
        EmailTemplateOrganisationSelection?: Maybe<EmailTemplateOrganisationSelection>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
    };

    export type BulkEmailTemplateSelectionMutationOutput = {
        __typename?: 'BulkEmailTemplateSelectionMutationOutput';
        EmailTemplateSelection?: Maybe<EmailTemplateSelection>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
    };

    export type BulkEntityMutationOutput = {
        __typename?: 'BulkEntityMutationOutput';
        Entity?: Maybe<Entity>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkFeatureFlagMutationOutput = {
        __typename?: 'BulkFeatureFlagMutationOutput';
        FeatureFlag?: Maybe<FeatureFlag>;
    };

    export type BulkFeatureFlagVariationMutationOutput = {
        __typename?: 'BulkFeatureFlagVariationMutationOutput';
        FeatureFlagVariation?: Maybe<FeatureFlagVariation>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedRole?: Maybe<Role>;
    };

    export type BulkFileUploadMutationOutput = {
        __typename?: 'BulkFileUploadMutationOutput';
        FileUpload?: Maybe<FileUpload>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUploadedBy?: Maybe<Staff>;
    };

    export type BulkFranchiseMutationOutput = {
        __typename?: 'BulkFranchiseMutationOutput';
        Franchise?: Maybe<Franchise>;
        updatedHeadOffice?: Maybe<FranchiseShop>;
        updatedLocales?: Maybe<Locales>;
        updatedManager?: Maybe<Staff>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParentFranchise?: Maybe<Franchise>;
        updatedTimeZone?: Maybe<Timezone>;
    };

    export type BulkFranchiseSequenceStoreMutationOutput = {
        __typename?: 'BulkFranchiseSequenceStoreMutationOutput';
        FranchiseSequenceStore?: Maybe<FranchiseSequenceStore>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkFranchiseShopMutationOutput = {
        __typename?: 'BulkFranchiseShopMutationOutput';
        FranchiseShop?: Maybe<FranchiseShop>;
        updatedAddress?: Maybe<Address>;
        updatedManager?: Maybe<Staff>;
    };

    export type BulkFranchiseTestTreatmentViewMutationOutput = {
        __typename?: 'BulkFranchiseTestTreatmentViewMutationOutput';
        FranchiseTestTreatmentView?: Maybe<FranchiseTestTreatmentView>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTestTreatment?: Maybe<TestTreatment>;
    };

    export type BulkFranchiseValueStoreMutationOutput = {
        __typename?: 'BulkFranchiseValueStoreMutationOutput';
        FranchiseValueStore?: Maybe<FranchiseValueStore>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkFranchiseWarranterMutationOutput = {
        __typename?: 'BulkFranchiseWarranterMutationOutput';
        FranchiseWarranter?: Maybe<FranchiseWarranter>;
        updatedActingFranchise?: Maybe<Franchise>;
    };

    export type BulkHeaterRecommendationMutationOutput = {
        __typename?: 'BulkHeaterRecommendationMutationOutput';
        HeaterRecommendation?: Maybe<HeaterRecommendation>;
        updatedAddedBy?: Maybe<Staff>;
        updatedEntity?: Maybe<Entity>;
        updatedFranchise?: Maybe<Franchise>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkInstallableProductMutationOutput = {
        __typename?: 'BulkInstallableProductMutationOutput';
        InstallableProduct?: Maybe<InstallableProduct>;
    };

    export type BulkItemMutationOutput = {
        __typename?: 'BulkItemMutationOutput';
        Item?: Maybe<Item>;
        updatedInstalledByStaff?: Maybe<Staff>;
        updatedOwner?: Maybe<Entity>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkJobTodoItemMutationOutput = {
        __typename?: 'BulkJobTodoItemMutationOutput';
        JobTodoItem?: Maybe<JobTodoItem>;
        updatedCompletedBy?: Maybe<Staff>;
        updatedJob?: Maybe<JobTodo>;
    };

    export type BulkJobTodoMutationOutput = {
        __typename?: 'BulkJobTodoMutationOutput';
        JobTodo?: Maybe<JobTodo>;
        updatedCompletedBy?: Maybe<Staff>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedUsedTemplate?: Maybe<JobTodoTemplate>;
        updatedWorkOrder?: Maybe<WorkOrder>;
    };

    export type BulkJobTodoTemplateAssocMutationOutput = {
        __typename?: 'BulkJobTodoTemplateAssocMutationOutput';
        JobTodoTemplateAssoc?: Maybe<JobTodoTemplateAssoc>;
        updatedJobTodoTemplate?: Maybe<JobTodoTemplate>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type BulkJobTodoTemplateItemMutationOutput = {
        __typename?: 'BulkJobTodoTemplateItemMutationOutput';
        JobTodoTemplateItem?: Maybe<JobTodoTemplateItem>;
        updatedTemplate?: Maybe<JobTodoTemplate>;
    };

    export type BulkJobTodoTemplateMutationOutput = {
        __typename?: 'BulkJobTodoTemplateMutationOutput';
        JobTodoTemplate?: Maybe<JobTodoTemplate>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedOwnedByWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type BulkManualProblemMutationOutput = {
        __typename?: 'BulkManualProblemMutationOutput';
        ManualProblem?: Maybe<ManualProblem>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkMeasurementMutationOutput = {
        __typename?: 'BulkMeasurementMutationOutput';
        Measurement?: Maybe<Measurement>;
        updatedLinearForm?: Maybe<Measurement>;
        updatedUnit?: Maybe<Unit>;
    };

    export type BulkPermissionMutationOutput = {
        __typename?: 'BulkPermissionMutationOutput';
        Permission?: Maybe<Permission>;
    };

    export type BulkPoolAttachedFlagFieldMutationOutput = {
        __typename?: 'BulkPoolAttachedFlagFieldMutationOutput';
        PoolAttachedFlagField?: Maybe<PoolAttachedFlagField>;
        updatedFieldDeclaration?: Maybe<PoolDeclarationFlagField>;
        updatedFranchise?: Maybe<Franchise>;
        updatedPool?: Maybe<Pool>;
        updatedSetBy?: Maybe<Staff>;
    };

    export type BulkPoolBottleLeaseMutationOutput = {
        __typename?: 'BulkPoolBottleLeaseMutationOutput';
        PoolBottleLease?: Maybe<PoolBottleLease>;
        updatedBottle?: Maybe<PoolBottle>;
        updatedLeaseTo?: Maybe<Entity>;
        updatedLeasedBy?: Maybe<Staff>;
        updatedPool?: Maybe<Pool>;
        updatedReceivedBy?: Maybe<Staff>;
    };

    export type BulkPoolBottleMutationOutput = {
        __typename?: 'BulkPoolBottleMutationOutput';
        PoolBottle?: Maybe<PoolBottle>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkPoolCalibrationMutationOutput = {
        __typename?: 'BulkPoolCalibrationMutationOutput';
        PoolCalibration?: Maybe<PoolCalibration>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedPool?: Maybe<Pool>;
    };

    export type BulkPoolDeclarationFlagFieldMutationOutput = {
        __typename?: 'BulkPoolDeclarationFlagFieldMutationOutput';
        PoolDeclarationFlagField?: Maybe<PoolDeclarationFlagField>;
        updatedOrganisation?: Maybe<OrganisationType>;
    };

    export type BulkPoolMutationOutput = {
        __typename?: 'BulkPoolMutationOutput';
        Pool?: Maybe<Pool>;
        updatedEntity?: Maybe<Entity>;
        updatedSanitiser?: Maybe<Sanitiser>;
        updatedSite?: Maybe<Site>;
        updatedType?: Maybe<PoolType>;
    };

    export type BulkPoolNoteMutationOutput = {
        __typename?: 'BulkPoolNoteMutationOutput';
        PoolNote?: Maybe<PoolNote>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Pool>;
    };

    export type BulkPoolProductDeclarationMutationOutput = {
        __typename?: 'BulkPoolProductDeclarationMutationOutput';
        PoolProductDeclaration?: Maybe<PoolProductDeclaration>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
    };

    export type BulkPoolTypeMutationOutput = {
        __typename?: 'BulkPoolTypeMutationOutput';
        PoolType?: Maybe<PoolType>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkProductCompanyMutationOutput = {
        __typename?: 'BulkProductCompanyMutationOutput';
        ProductCompany?: Maybe<ProductCompany>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParentCompany?: Maybe<ProductCompany>;
    };

    export type BulkProductDeclarationFlagFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationFlagFieldMutationOutput';
        ProductDeclarationFlagField?: Maybe<ProductDeclarationFlagField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationFloatFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationFloatFieldMutationOutput';
        ProductDeclarationFloatField?: Maybe<ProductDeclarationFloatField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationIntegerFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationIntegerFieldMutationOutput';
        ProductDeclarationIntegerField?: Maybe<ProductDeclarationIntegerField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationMeasurementFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationMeasurementFieldMutationOutput';
        ProductDeclarationMeasurementField?: Maybe<ProductDeclarationMeasurementField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationSelectionChoiceMutationOutput = {
        __typename?: 'BulkProductDeclarationSelectionChoiceMutationOutput';
        ProductDeclarationSelectionChoice?: Maybe<ProductDeclarationSelectionChoice>;
        updatedSelection?: Maybe<ProductDeclarationSelectionField>;
    };

    export type BulkProductDeclarationSelectionFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationSelectionFieldMutationOutput';
        ProductDeclarationSelectionField?: Maybe<ProductDeclarationSelectionField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationStringFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationStringFieldMutationOutput';
        ProductDeclarationStringField?: Maybe<ProductDeclarationStringField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDeclarationUnitFieldMutationOutput = {
        __typename?: 'BulkProductDeclarationUnitFieldMutationOutput';
        ProductDeclarationUnitField?: Maybe<ProductDeclarationUnitField>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
    };

    export type BulkProductDocumentMutationOutput = {
        __typename?: 'BulkProductDocumentMutationOutput';
        ProductDocument?: Maybe<ProductDocument>;
        updatedBrand?: Maybe<Brand>;
        updatedCompany?: Maybe<ProductCompany>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUploadedBy?: Maybe<Staff>;
    };

    export type BulkProductMutationOutput = {
        __typename?: 'BulkProductMutationOutput';
        Product?: Maybe<Product>;
        updatedAddedBy?: Maybe<Staff>;
        updatedBrand?: Maybe<Brand>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedRetiredBy?: Maybe<Staff>;
        updatedSupplier?: Maybe<ProductCompany>;
    };

    export type BulkProductTraitDeclarationMutationOutput = {
        __typename?: 'BulkProductTraitDeclarationMutationOutput';
        ProductTraitDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParent?: Maybe<ProductTraitDeclaration>;
    };

    export type BulkProductTraitFlagValueMutationOutput = {
        __typename?: 'BulkProductTraitFlagValueMutationOutput';
        ProductTraitFlagValue?: Maybe<ProductTraitFlagValue>;
        updatedField?: Maybe<ProductDeclarationFlagField>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkProductTraitFloatValueMutationOutput = {
        __typename?: 'BulkProductTraitFloatValueMutationOutput';
        ProductTraitFloatValue?: Maybe<ProductTraitFloatValue>;
        updatedField?: Maybe<ProductDeclarationFloatField>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkProductTraitIntegerValueMutationOutput = {
        __typename?: 'BulkProductTraitIntegerValueMutationOutput';
        ProductTraitIntegerValue?: Maybe<ProductTraitIntegerValue>;
        updatedField?: Maybe<ProductDeclarationIntegerField>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkProductTraitMeasurementValueMutationOutput = {
        __typename?: 'BulkProductTraitMeasurementValueMutationOutput';
        ProductTraitMeasurementValue?: Maybe<ProductTraitMeasurementValue>;
        updatedField?: Maybe<ProductDeclarationMeasurementField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<Measurement>;
    };

    export type BulkProductTraitMutationOutput = {
        __typename?: 'BulkProductTraitMutationOutput';
        ProductTrait?: Maybe<ProductTrait>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkProductTraitSelectionValueMutationOutput = {
        __typename?: 'BulkProductTraitSelectionValueMutationOutput';
        ProductTraitSelectionValue?: Maybe<ProductTraitSelectionValue>;
        updatedField?: Maybe<ProductDeclarationSelectionField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<ProductDeclarationSelectionChoice>;
    };

    export type BulkProductTraitStringValueMutationOutput = {
        __typename?: 'BulkProductTraitStringValueMutationOutput';
        ProductTraitStringValue?: Maybe<ProductTraitStringValue>;
        updatedField?: Maybe<ProductDeclarationStringField>;
        updatedProduct?: Maybe<Product>;
    };

    export type BulkProductTraitUnitValueMutationOutput = {
        __typename?: 'BulkProductTraitUnitValueMutationOutput';
        ProductTraitUnitValue?: Maybe<ProductTraitUnitValue>;
        updatedField?: Maybe<ProductDeclarationUnitField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<Unit>;
    };

    export type BulkProjectMutationOutput = {
        __typename?: 'BulkProjectMutationOutput';
        Project?: Maybe<Project>;
        updatedDocumentationProject?: Maybe<Project>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedPinnedVersion?: Maybe<ProjectVersion>;
        updatedThumbnailFile?: Maybe<FileUpload>;
    };

    export type BulkProjectVersionMutationOutput = {
        __typename?: 'BulkProjectVersionMutationOutput';
        ProjectVersion?: Maybe<ProjectVersion>;
        updatedFileUpload?: Maybe<FileUpload>;
        updatedProject?: Maybe<Project>;
    };

    export type BulkRecommendedTreatmentMutationOutput = {
        __typename?: 'BulkRecommendedTreatmentMutationOutput';
        RecommendedTreatment?: Maybe<RecommendedTreatment>;
        updatedProblem?: Maybe<TestProblem>;
        updatedReport?: Maybe<TestReport>;
        updatedTreatment?: Maybe<TestTreatment>;
    };

    export type BulkRoleFlagMutationOutput = {
        __typename?: 'BulkRoleFlagMutationOutput';
        RoleFlag?: Maybe<RoleFlag>;
    };

    export type BulkRoleMutationOutput = {
        __typename?: 'BulkRoleMutationOutput';
        Role?: Maybe<Role>;
    };

    export type BulkSampleConverterMutationOutput = {
        __typename?: 'BulkSampleConverterMutationOutput';
        SampleConverter?: Maybe<SampleConverter>;
    };

    export type BulkSampleConverterTemplateMutationOutput = {
        __typename?: 'BulkSampleConverterTemplateMutationOutput';
        SampleConverterTemplate?: Maybe<SampleConverterTemplate>;
        updatedConverter?: Maybe<SampleConverter>;
        updatedMeasurement?: Maybe<Measurement>;
    };

    export type BulkSampleMutationOutput = {
        __typename?: 'BulkSampleMutationOutput';
        Sample?: Maybe<Sample>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedPool?: Maybe<Pool>;
        updatedSource?: Maybe<SampleSource>;
    };

    export type BulkSampleSetMutationOutput = {
        __typename?: 'BulkSampleSetMutationOutput';
        SampleSet?: Maybe<SampleSet>;
        updatedAddedBy?: Maybe<Staff>;
        updatedPool?: Maybe<Pool>;
    };

    export type BulkSampleSourceMutationOutput = {
        __typename?: 'BulkSampleSourceMutationOutput';
        SampleSource?: Maybe<SampleSource>;
    };

    export type BulkSanitiserMutationOutput = {
        __typename?: 'BulkSanitiserMutationOutput';
        Sanitiser?: Maybe<Sanitiser>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkScheduleEventMutationOutput = {
        __typename?: 'BulkScheduleEventMutationOutput';
        ScheduleEvent?: Maybe<ScheduleEvent>;
    };

    export type BulkServiceJobGroupMutationOutput = {
        __typename?: 'BulkServiceJobGroupMutationOutput';
        ServiceJobGroup?: Maybe<ServiceJobGroup>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParent?: Maybe<ServiceJobGroup>;
    };

    export type BulkServiceJobMutationOutput = {
        __typename?: 'BulkServiceJobMutationOutput';
        ServiceJob?: Maybe<ServiceJob>;
        updatedAddress?: Maybe<Address>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedCustomer?: Maybe<Customer>;
        updatedEzcDeviceLink?: Maybe<EZCDeviceLink>;
        updatedFollowedFrom?: Maybe<ServiceJob>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedPool?: Maybe<Pool>;
        updatedPreferredContact?: Maybe<Contact>;
        updatedStaff?: Maybe<Staff>;
        updatedStage?: Maybe<ServiceJobStage>;
        updatedUsedTemplate?: Maybe<ServiceJobTemplate>;
    };

    export type BulkServiceJobNoteMutationOutput = {
        __typename?: 'BulkServiceJobNoteMutationOutput';
        ServiceJobNote?: Maybe<ServiceJobNote>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<ServiceJob>;
    };

    export type BulkServiceJobStageMutationOutput = {
        __typename?: 'BulkServiceJobStageMutationOutput';
        ServiceJobStage?: Maybe<ServiceJobStage>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkServiceJobTemplateMutationOutput = {
        __typename?: 'BulkServiceJobTemplateMutationOutput';
        ServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        updatedAddedBy?: Maybe<Staff>;
        updatedDefaultStage?: Maybe<ServiceJobStage>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkSettingStoreMutationOutput = {
        __typename?: 'BulkSettingStoreMutationOutput';
        SettingStore?: Maybe<SettingStore>;
    };

    export type BulkSharedEntityMutationOutput = {
        __typename?: 'BulkSharedEntityMutationOutput';
        SharedEntity?: Maybe<SharedEntity>;
        updatedEntity?: Maybe<Entity>;
        updatedOriginFranchise?: Maybe<Franchise>;
        updatedSharedBy?: Maybe<Staff>;
        updatedSharedToFranchise?: Maybe<Franchise>;
    };

    export type BulkSiteMutationOutput = {
        __typename?: 'BulkSiteMutationOutput';
        Site?: Maybe<Site>;
        updatedAddress?: Maybe<Address>;
        updatedEntity?: Maybe<Entity>;
    };

    export type BulkSiteNoteMutationOutput = {
        __typename?: 'BulkSiteNoteMutationOutput';
        SiteNote?: Maybe<SiteNote>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Site>;
    };

    export type BulkStaffAccessTokenMutationOutput = {
        __typename?: 'BulkStaffAccessTokenMutationOutput';
        StaffAccessToken?: Maybe<StaffAccessToken>;
        updatedDisabledBy?: Maybe<Staff>;
        updatedStaff?: Maybe<Staff>;
    };

    export type BulkStaffAccessTokenRegistrationMutationOutput = {
        __typename?: 'BulkStaffAccessTokenRegistrationMutationOutput';
        StaffAccessTokenRegistration?: Maybe<StaffAccessTokenRegistration>;
        updatedTokenRef?: Maybe<StaffAccessToken>;
    };

    export type BulkStaffMutationOutput = {
        __typename?: 'BulkStaffMutationOutput';
        Staff?: Maybe<Staff>;
        updatedArchivedBy?: Maybe<Staff>;
        updatedManagedBy?: Maybe<Staff>;
        updatedRole?: Maybe<Role>;
    };

    export type BulkStaffRegistrationMutationOutput = {
        __typename?: 'BulkStaffRegistrationMutationOutput';
        StaffRegistration?: Maybe<StaffRegistration>;
        updatedDisabledBy?: Maybe<Staff>;
    };

    export type BulkStaffTeamLinkMutationOutput = {
        __typename?: 'BulkStaffTeamLinkMutationOutput';
        StaffTeamLink?: Maybe<StaffTeamLink>;
        updatedAddedBy?: Maybe<Staff>;
        updatedStaff?: Maybe<Staff>;
        updatedTeam?: Maybe<StaffTeamLink>;
    };

    export type BulkStaffTeamMutationOutput = {
        __typename?: 'BulkStaffTeamMutationOutput';
        StaffTeam?: Maybe<StaffTeam>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkStaffValueStoreMutationOutput = {
        __typename?: 'BulkStaffValueStoreMutationOutput';
        StaffValueStore?: Maybe<StaffValueStore>;
        updatedStaff?: Maybe<Staff>;
    };

    export type BulkTestCriteriaMutationOutput = {
        __typename?: 'BulkTestCriteriaMutationOutput';
        TestCriteria?: Maybe<TestCriteria>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedToHighValueProblem?: Maybe<TestProblem>;
        updatedToLowValueProblem?: Maybe<TestProblem>;
    };

    export type BulkTestProblemMutationOutput = {
        __typename?: 'BulkTestProblemMutationOutput';
        TestProblem?: Maybe<TestProblem>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkTestRecommendationMutationOutput = {
        __typename?: 'BulkTestRecommendationMutationOutput';
        TestRecommendation?: Maybe<TestRecommendation>;
        updatedProblem?: Maybe<TestProblem>;
        updatedReport?: Maybe<TestReport>;
    };

    export type BulkTestReportAttachmentMutationOutput = {
        __typename?: 'BulkTestReportAttachmentMutationOutput';
        TestReportAttachment?: Maybe<TestReportAttachment>;
        updatedFile?: Maybe<FileUpload>;
        updatedTestReport?: Maybe<TestReport>;
    };

    export type BulkTestReportMutationOutput = {
        __typename?: 'BulkTestReportMutationOutput';
        TestReport?: Maybe<TestReport>;
        updatedFranchise?: Maybe<Franchise>;
        updatedPool?: Maybe<Pool>;
        updatedReportBy?: Maybe<Staff>;
        updatedReportTo?: Maybe<Entity>;
        updatedSampleSet?: Maybe<SampleSet>;
        updatedWorkOrder?: Maybe<WorkOrder>;
    };

    export type BulkTestReportSolutionMutationOutput = {
        __typename?: 'BulkTestReportSolutionMutationOutput';
        TestReportSolution?: Maybe<TestReportSolution>;
        updatedReport?: Maybe<TestReport>;
        updatedTreatment?: Maybe<TestTreatment>;
    };

    export type BulkTestTreatmentFranchiseGroupMutationOutput = {
        __typename?: 'BulkTestTreatmentFranchiseGroupMutationOutput';
        TestTreatmentFranchiseGroup?: Maybe<TestTreatmentFranchiseGroup>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTreatmentGroup?: Maybe<TestTreatmentGroup>;
    };

    export type BulkTestTreatmentFranchisePriorityMutationOutput = {
        __typename?: 'BulkTestTreatmentFranchisePriorityMutationOutput';
        TestTreatmentFranchisePriority?: Maybe<TestTreatmentFranchisePriority>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTestTreatment?: Maybe<TestTreatment>;
    };

    export type BulkTestTreatmentGroupMutationOutput = {
        __typename?: 'BulkTestTreatmentGroupMutationOutput';
        TestTreatmentGroup?: Maybe<TestTreatmentGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
    };

    export type BulkTestTreatmentMutationOutput = {
        __typename?: 'BulkTestTreatmentMutationOutput';
        TestTreatment?: Maybe<TestTreatment>;
        updatedConsumableProduct?: Maybe<ConsumableProduct>;
        updatedGroup?: Maybe<TestTreatmentGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUnit?: Maybe<Unit>;
    };

    export type BulkTreatmentAffectMutationOutput = {
        __typename?: 'BulkTreatmentAffectMutationOutput';
        TreatmentAffect?: Maybe<TreatmentAffect>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedTreatment?: Maybe<TestTreatment>;
    };

    export type BulkUnitConversionMutationOutput = {
        __typename?: 'BulkUnitConversionMutationOutput';
        UnitConversion?: Maybe<UnitConversion>;
        updatedDestination?: Maybe<Unit>;
        updatedMethod?: Maybe<UnitConverter>;
        updatedSource?: Maybe<Unit>;
    };

    export type BulkUnitMutationOutput = {
        __typename?: 'BulkUnitMutationOutput';
        Unit?: Maybe<Unit>;
        updatedLinearForm?: Maybe<Unit>;
    };

    export type BulkUniversalCriteriaMutationOutput = {
        __typename?: 'BulkUniversalCriteriaMutationOutput';
        UniversalCriteria?: Maybe<UniversalCriteria>;
        updatedCriterion?: Maybe<TestCriteria>;
    };

    export type BulkVendAPIMutationOutput = {
        __typename?: 'BulkVendAPIMutationOutput';
        VendAPI?: Maybe<VendAPI>;
        updatedAddedCustomerId?: Maybe<Customer>;
        updatedFranchise?: Maybe<Franchise>;
    };

    export type BulkVendCustomerMutationOutput = {
        __typename?: 'BulkVendCustomerMutationOutput';
        VendCustomer?: Maybe<VendCustomer>;
        updatedCustomer?: Maybe<Customer>;
    };

    export type BulkVendLinkMutationOutput = {
        __typename?: 'BulkVendLinkMutationOutput';
        VendLink?: Maybe<VendLink>;
        updatedLocalCustomer?: Maybe<Customer>;
    };

    export type BulkVendSaleMutationOutput = {
        __typename?: 'BulkVendSaleMutationOutput';
        VendSale?: Maybe<VendSale>;
    };

    export type BulkWarrantyClaimCaseMutationOutput = {
        __typename?: 'BulkWarrantyClaimCaseMutationOutput';
        WarrantyClaimCase?: Maybe<WarrantyClaimCase>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceJob?: Maybe<ServiceJob>;
    };

    export type BulkWarrantyClaimFileAttachMutationOutput = {
        __typename?: 'BulkWarrantyClaimFileAttachMutationOutput';
        WarrantyClaimFileAttach?: Maybe<WarrantyClaimFileAttach>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedFile?: Maybe<FileUpload>;
    };

    export type BulkWarrantyClaimMessageMutationOutput = {
        __typename?: 'BulkWarrantyClaimMessageMutationOutput';
        WarrantyClaimMessage?: Maybe<WarrantyClaimMessage>;
        updatedAddedBy?: Maybe<Staff>;
        updatedClaim?: Maybe<WarrantyClaim>;
    };

    export type BulkWarrantyClaimMutationOutput = {
        __typename?: 'BulkWarrantyClaimMutationOutput';
        WarrantyClaim?: Maybe<WarrantyClaim>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAddress?: Maybe<Address>;
        updatedFranchise?: Maybe<Franchise>;
        updatedReceiverStaff?: Maybe<Staff>;
        updatedReceiverWarranter?: Maybe<FranchiseWarranter>;
        updatedRefAsset?: Maybe<Item>;
        updatedRefCustomer?: Maybe<Customer>;
        updatedRefSite?: Maybe<Site>;
    };

    export type BulkWaterTestingRevisionMutationOutput = {
        __typename?: 'BulkWaterTestingRevisionMutationOutput';
        WaterTestingRevision?: Maybe<WaterTestingRevision>;
        updatedOrganisation?: Maybe<OrganisationType>;
    };

    export type BulkWorkOrderFeedItemMutationOutput = {
        __typename?: 'BulkWorkOrderFeedItemMutationOutput';
        WorkOrderFeedItem?: Maybe<WorkOrderFeedItem>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedWorkOrder?: Maybe<WorkOrder>;
    };

    export type BulkWorkOrderMutationOutput = {
        __typename?: 'BulkWorkOrderMutationOutput';
        WorkOrder?: Maybe<WorkOrder>;
        updatedAddress?: Maybe<Address>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedEzcDeviceLink?: Maybe<EZCDeviceLink>;
        updatedFollowUpFrom?: Maybe<WorkOrder>;
        updatedPool?: Maybe<Pool>;
        updatedPreferredContact?: Maybe<Contact>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedStage?: Maybe<ServiceJobStage>;
        updatedUsedTemplate?: Maybe<WorkOrderTemplate>;
        updatedWorkFor?: Maybe<Entity>;
    };

    export type BulkWorkOrderNoteMutationOutput = {
        __typename?: 'BulkWorkOrderNoteMutationOutput';
        WorkOrderNote?: Maybe<WorkOrderNote>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<WorkOrder>;
    };

    export type BulkWorkOrderTemplateAssocMutationOutput = {
        __typename?: 'BulkWorkOrderTemplateAssocMutationOutput';
        WorkOrderTemplateAssoc?: Maybe<WorkOrderTemplateAssoc>;
        updatedAddedBy?: Maybe<Staff>;
        updatedServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type BulkWorkOrderTemplateMutationOutput = {
        __typename?: 'BulkWorkOrderTemplateMutationOutput';
        WorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        updatedAddedBy?: Maybe<Staff>;
        updatedDefaultStage?: Maybe<ServiceJobStage>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedOwnedByServiceJobTemplate?: Maybe<ServiceJobTemplate>;
    };

    /** A connection to a list of items. */
    export type CalibrationsForPoolToPoolCalibrationConnection = {
        __typename?: 'CalibrationsForPoolToPoolCalibrationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CalibrationsForPoolToPoolCalibrationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CalibrationsForPoolToPoolCalibrationEdge = {
        __typename?: 'CalibrationsForPoolToPoolCalibrationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolCalibration>;
    };

    /** A connection to a list of items. */
    export type ChemicalTargetsForPoolToPoolChemicalTargetConnection = {
        __typename?: 'ChemicalTargetsForPoolToPoolChemicalTargetConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ChemicalTargetsForPoolToPoolChemicalTargetEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ChemicalTargetsForPoolToPoolChemicalTargetEdge = {
        __typename?: 'ChemicalTargetsForPoolToPoolChemicalTargetEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolChemicalTarget>;
    };

    /** A connection to a list of items. */
    export type ChildFranchisesForFranchiseToFranchiseConnection = {
        __typename?: 'ChildFranchisesForFranchiseToFranchiseConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ChildFranchisesForFranchiseToFranchiseEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ChildFranchisesForFranchiseToFranchiseMeta>;
    };

    /** An edge in a connection. */
    export type ChildFranchisesForFranchiseToFranchiseEdge = {
        __typename?: 'ChildFranchisesForFranchiseToFranchiseEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Franchise>;
    };

    export type ChildFranchisesForFranchiseToFranchiseMeta = {
        __typename?: 'ChildFranchisesForFranchiseToFranchiseMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ChildrenForServiceJobGroupToServiceJobGroupConnection = {
        __typename?: 'ChildrenForServiceJobGroupToServiceJobGroupConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ChildrenForServiceJobGroupToServiceJobGroupEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ChildrenForServiceJobGroupToServiceJobGroupMeta>;
    };

    /** An edge in a connection. */
    export type ChildrenForServiceJobGroupToServiceJobGroupEdge = {
        __typename?: 'ChildrenForServiceJobGroupToServiceJobGroupEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobGroup>;
    };

    export type ChildrenForServiceJobGroupToServiceJobGroupMeta = {
        __typename?: 'ChildrenForServiceJobGroupToServiceJobGroupMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type CommonProductValuesTraitSearchCommon = {
        flag?: InputMaybe<CommonProductValuesTraitSearchCommonFlag>;
        float?: InputMaybe<CommonProductValuesTraitSearchCommonFloat>;
        integer?: InputMaybe<CommonProductValuesTraitSearchCommonInteger>;
        selection?: InputMaybe<CommonProductValuesTraitSearchCommonSelection>;
    };

    export type CommonProductValuesTraitSearchCommonFlag = {
        /** Expects node: **ProductDeclarationFlagField** */
        id?: InputMaybe<Scalars['ID']>;
        missing?: InputMaybe<Scalars['Boolean']>;
        tag?: InputMaybe<ProductTraitTagResolveCommon>;
        value?: InputMaybe<Scalars['Boolean']>;
    };

    export type CommonProductValuesTraitSearchCommonFloat = {
        /** Expects node: **ProductDeclarationFloatField** */
        id?: InputMaybe<Scalars['ID']>;
        isOr?: InputMaybe<Array<Scalars['Float']>>;
        missing?: InputMaybe<Scalars['Boolean']>;
        range?: InputMaybe<Array<CommonProductValuesTraitSearchCommonFloatRange>>;
        tag?: InputMaybe<ProductTraitTagResolveCommon>;
    };

    export type CommonProductValuesTraitSearchCommonFloatRange = {
        from?: InputMaybe<Scalars['Float']>;
        to?: InputMaybe<Scalars['Float']>;
    };

    export type CommonProductValuesTraitSearchCommonInteger = {
        /** Expects node: **ProductDeclarationIntegerField** */
        id?: InputMaybe<Scalars['ID']>;
        isOr?: InputMaybe<Array<Scalars['Int']>>;
        missing?: InputMaybe<Scalars['Boolean']>;
        range?: InputMaybe<Array<CommonProductValuesTraitSearchCommonIntegerRange>>;
        tag?: InputMaybe<ProductTraitTagResolveCommon>;
    };

    export type CommonProductValuesTraitSearchCommonIntegerRange = {
        from?: InputMaybe<Scalars['Int']>;
        to?: InputMaybe<Scalars['Int']>;
    };

    export type CommonProductValuesTraitSearchCommonSelection = {
        /** Expects node: **ProductDeclarationSelectionField** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductDeclarationSelectionChoice** */
        isOr?: InputMaybe<Array<Scalars['ID']>>;
        missing?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type ConnectedFranchisesForEZCServiceProviderToFranchiseConnection = {
        __typename?: 'ConnectedFranchisesForEZCServiceProviderToFranchiseConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ConnectedFranchisesForEZCServiceProviderToFranchiseEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ConnectedFranchisesForEZCServiceProviderToFranchiseEdge = {
        __typename?: 'ConnectedFranchisesForEZCServiceProviderToFranchiseEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Franchise>;
    };

    export type ConsumableProduct = Node & {
        __typename?: 'ConsumableProduct';
        checkMutations?: Maybe<ConsumableProductMutationCheck>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
    };

    export type ConsumableProductMutationCheck = {
        __typename?: 'ConsumableProductMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ConsumableProductMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateConsumableProductMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteConsumableProductMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateConsumableProductMutationInput>>>;
    };

    export type ConsumableProductMutationPayload = {
        __typename?: 'ConsumableProductMutationPayload';
        ConsumableProduct?: Maybe<ConsumableProduct>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkConsumableProductMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type ConsumableProductNodeMatchSearchCommon = {
        /** Expects node: **ConsumableProduct** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ConsumableProduct** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ConsumableProductViewerConsumableProductsSearch = {
        product?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProduct>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProduct = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        declarations?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarations>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        relatedSanitisers?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPools =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
            /** Expects node: **Pool** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Pool** */
            not?: InputMaybe<Scalars['ID']>;
            ownershipHistory?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
            reports?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports>;
            sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
            site?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite>;
            type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
            volume?: InputMaybe<FloatRangeSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity =
        {
            addresses?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
            sites?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **TestReport** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestReport** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            refId?: InputMaybe<StringMatchSearchCommon>;
            reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            reportTo?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
            sampleSet?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo =
        {
            addresses?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
            sites?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity =
        {
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity =
        {
            addresses?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob =
        {
            address?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer =
        {
            addedBy?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
            teams?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ConsumableProductViewerConsumableProductsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        customSku?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ConsumableProduct** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        identification?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        sku?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type Contact = Node & {
        __typename?: 'Contact';
        checkMutations?: Maybe<ContactMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        data?: Maybe<Scalars['String']>;
        entity?: Maybe<Entity>;
        id: Scalars['ID'];
        isPrimary?: Maybe<Scalars['Boolean']>;
        label?: Maybe<Scalars['String']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        type?: Maybe<ContactType>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type ContactEntityContactsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ContactEntityContactsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        data?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Contact** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ContactMakePrimaryMutationInput = {
        force?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Contact** */
        id: Scalars['ID'];
    };

    export type ContactMutationCheck = {
        __typename?: 'ContactMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        makePrimary?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ContactMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateContactMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteContactMutationInput>>>;
        makePrimary?: InputMaybe<Array<InputMaybe<ContactMakePrimaryMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateContactMutationInput>>>;
    };

    export type ContactMutationPayload = {
        __typename?: 'ContactMutationPayload';
        Contact?: Maybe<Contact>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkContactMutationOutput>>>;
        updatedEntity?: Maybe<Entity>;
        updatedType?: Maybe<ContactType>;
        viewer?: Maybe<Viewer>;
    };

    export type ContactType = Node & {
        __typename?: 'ContactType';
        checkMutations?: Maybe<ContactTypeMutationCheck>;
        iconPath?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        identificationTag?: Maybe<Scalars['String']>;
        name?: Maybe<Scalars['String']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        uuid?: Maybe<Scalars['String']>;
        validatorTag?: Maybe<Scalars['String']>;
    };

    export type ContactTypeMutationCheck = {
        __typename?: 'ContactTypeMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ContactTypeMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateContactTypeMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateContactTypeMutationInput>>>;
    };

    export type ContactTypeMutationPayload = {
        __typename?: 'ContactTypeMutationPayload';
        ContactType?: Maybe<ContactType>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkContactTypeMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type ContactTypeNodeMatchSearchCommon = {
        /** Expects node: **ContactType** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ContactType** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ContactTypeViewerContactTypesSearch = {
        identificationTag?: InputMaybe<StringMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
        validatorTag?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ContactTypeViewerContactTypesSort = {
        /** Expects node: **ContactType** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type ContactsForEntityToContactConnection = {
        __typename?: 'ContactsForEntityToContactConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ContactsForEntityToContactEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ContactsForEntityToContactMeta>;
    };

    /** An edge in a connection. */
    export type ContactsForEntityToContactEdge = {
        __typename?: 'ContactsForEntityToContactEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Contact>;
    };

    export type ContactsForEntityToContactMeta = {
        __typename?: 'ContactsForEntityToContactMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ConversionTemplatesForMeasurementToSampleConverterTemplateConnection = {
        __typename?: 'ConversionTemplatesForMeasurementToSampleConverterTemplateConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ConversionTemplatesForMeasurementToSampleConverterTemplateEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ConversionTemplatesForMeasurementToSampleConverterTemplateEdge = {
        __typename?: 'ConversionTemplatesForMeasurementToSampleConverterTemplateEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleConverterTemplate>;
    };

    export type Coordinate = {
        __typename?: 'Coordinate';
        lat?: Maybe<Scalars['Float']>;
        lng?: Maybe<Scalars['Float']>;
    };

    export type CoordinateNodeInitInput = {
        lat: Scalars['Float'];
        lng: Scalars['Float'];
    };

    export type CoordinateNodeUpdateInput = {
        lat?: InputMaybe<Scalars['Float']>;
        lng?: InputMaybe<Scalars['Float']>;
    };

    /** Creates a new instance of: AbstractDocument */
    export type CreateAbstractDocumentMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        dateDue?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        issuedAt?: InputMaybe<Scalars['Date']>;
        jsonBody?: InputMaybe<Scalars['JSON']>;
        refNumber?: InputMaybe<Scalars['String']>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        statusTag?: InputMaybe<Scalars['String']>;
        typeTag: Scalars['String'];
        /** Expects node: **WorkOrder** */
        workOrder?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Address */
    export type CreateAddressMutationInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: Announcement */
    export type CreateAnnouncementMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        authorName?: InputMaybe<Scalars['String']>;
        body: Scalars['String'];
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        link?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        preview: Scalars['String'];
        subject: Scalars['String'];
    };

    /** Creates a new instance of: AppointmentGroup */
    export type CreateAppointmentGroupMutationInput = {
        colorPalette?: InputMaybe<Scalars['JSON']>;
        defaultColor?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<Scalars['Int']>;
        statusTitleCustom?: InputMaybe<Scalars['String']>;
        statusTitleFailed?: InputMaybe<Scalars['String']>;
        statusTitleFinished?: InputMaybe<Scalars['String']>;
        statusTitlePending?: InputMaybe<Scalars['String']>;
        statusTitleStarted?: InputMaybe<Scalars['String']>;
        statusVisibilityCustom?: InputMaybe<Scalars['Boolean']>;
        statusVisibilityStarted?: InputMaybe<Scalars['Boolean']>;
        title: Scalars['String'];
    };

    /** Creates a new instance of: Appointment */
    export type CreateAppointmentMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        duration?: InputMaybe<Scalars['Int']>;
        endPatternDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        recurrence?: InputMaybe<RecurrenceNodeInitInput>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<AppointmentStatusEnum>;
        /** Expects node: **WorkOrder** */
        workOrder?: InputMaybe<Scalars['ID']>;
        workOrderTemplate?: InputMaybe<CreateAppointmentWorkOrderTemplateSwitchInput>;
    };

    /** Creates a new instances of: WorkOrderTemplate */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<
            Array<InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput>>
        >;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /**
         * Expects node: **ServiceJobTemplate**
         *
         * If null then meant for sharing with many ServiceJobTemplate
         */
        ownedByServiceJobTemplate?: InputMaybe<Scalars['ID']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        jobTodoTemplate: CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
        /** Expects node: **JobTodoTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: JobTodoTemplate */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
            items?: InputMaybe<
                Array<
                    InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
                >
            >;
            maxTimeEstimate?: InputMaybe<Scalars['Int']>;
            minTimeEstimate?: InputMaybe<Scalars['Int']>;
            recommendedPrice?: InputMaybe<Scalars['Int']>;
            /** Time Estimate in minutes */
            timeEstimate?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type CreateAppointmentSwitchCreateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput>>
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type CreateAppointmentSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: WorkOrderTemplate
     */
    export type CreateAppointmentWorkOrderTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateAppointmentSwitchCreateWorkOrderTemplateInput>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Brand */
    export type CreateBrandMutationInput = {
        company?: InputMaybe<CreateBrandProductCompanySwitchInput>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ConsumableProduct */
    export type CreateConsumableProductMutationInput = {
        product: CreateConsumableProductProductSwitchInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateConsumableProductProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateConsumableProductSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateConsumableProductSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateConsumableProductSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateConsumableProductSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreateConsumableProductSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateConsumableProductSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateConsumableProductSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateConsumableProductSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateConsumableProductSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitFloatValueInput>>
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateConsumableProductSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateConsumableProductSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateConsumableProductSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateConsumableProductSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateConsumableProductSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateConsumableProductSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateConsumableProductSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateConsumableProductSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateConsumableProductSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Contact */
    export type CreateContactMutationInput = {
        data: Scalars['String'];
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instance of: ContactType */
    export type CreateContactTypeMutationInput = {
        iconPath: Scalars['String'];
        identificationTag?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        validatorTag?: InputMaybe<Scalars['String']>;
    };

    export type CreateCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instance of: CustomContract */
    export type CreateCustomContractMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: Address */
    export type CreateCustomerAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: CustomerNote */
    export type CreateCustomerListCustomerNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type CreateCustomerListCustomerTag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateCustomerListCustomerTagCreateInput>>>;
    };

    /** Creates a new instances of: CustomerTag */
    export type CreateCustomerListCustomerTagCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Customer */
    export type CreateCustomerMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        billAddress?: InputMaybe<CreateCustomerAddressInput>;
        companyName?: InputMaybe<Scalars['String']>;
        contactName?: InputMaybe<Scalars['String']>;
        createSiteWithName?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of CustomerNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateCustomerListCustomerNoteInput>>>;
        primaryAddress?: InputMaybe<CreateCustomerAddressInput>;
        registeredAt?: InputMaybe<Scalars['Date']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        tags?: InputMaybe<CreateCustomerListCustomerTag>;
        user: CreateCustomerUserSwitchInput;
    };

    /** Creates a new instance of: CustomerNote */
    export type CreateCustomerNoteMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Customer** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateCustomerSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateCustomerSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateCustomerSwitchCreateUserInput = {
        entity: CreateCustomerSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListCustomContractInput>>
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput =
        {
            brand?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateCustomerSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: CustomerTag */
    export type CreateCustomerTagMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateCustomerUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateCustomerSwitchCreateUserInput;
    };

    /** Creates a new instance of: EZCDeviceLink */
    export type CreateEZCDeviceLinkMutationInput = {
        /** Expects node: **EZCDevice** */
        device: Scalars['ID'];
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **Customer** */
        owner?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Site** */
        site?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: EmailTemplate */
    export type CreateEmailTemplateMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        provider?: InputMaybe<EmailTemplateProvider>;
        templateBody: Scalars['String'];
        templateMetadata?: InputMaybe<Scalars['JSON']>;
        /** Expects node: **EmailType** */
        type: Scalars['ID'];
    };

    /** Creates a new instance of: EmailTemplateOrganisationSelection */
    export type CreateEmailTemplateOrganisationSelectionMutationInput = {
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailTemplate** */
        template: Scalars['ID'];
        /** Expects node: **EmailType** */
        type: Scalars['ID'];
    };

    /** Creates a new instance of: FeatureFlagVariation */
    export type CreateFeatureFlagVariationMutationInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        tag: Scalars['String'];
        value?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: FranchiseShop
     */
    export type CreateFranchiseFranchiseShopSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopInput>;
        /** Expects node: **FranchiseShop** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type CreateFranchiseListRoleFlag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseListRoleFlagCreateInput>>>;
    };

    /** Creates a new instances of: RoleFlag */
    export type CreateFranchiseListRoleFlagCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        tag: Scalars['String'];
    };

    /** Creates a new instance of: Franchise */
    export type CreateFranchiseMutationInput = {
        contactExport?: InputMaybe<Scalars['Boolean']>;
        email?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flags?: InputMaybe<CreateFranchiseListRoleFlag>;
        headOffice?: InputMaybe<CreateFranchiseFranchiseShopSwitchInput>;
        /** Expects node: **Locales** */
        locales?: InputMaybe<Scalars['ID']>;
        manager?: InputMaybe<CreateFranchiseStaffSwitchInput>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        phoneNumber?: InputMaybe<Scalars['String']>;
        /** Expects node: **Timezone** */
        timeZone?: InputMaybe<Scalars['ID']>;
        /**
         * Array size minimum: 1
         * Array size minimum: 1
         */
        types?: InputMaybe<Array<InputMaybe<FranchiseType>>>;
        waterTestBranding?: InputMaybe<OrganisationBrandingEnum>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateFranchiseShopEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseShopSwitchCreateEntityInput;
    };

    /** Creates a new instance of: FranchiseShop */
    export type CreateFranchiseShopMutationInput = {
        address?: InputMaybe<CreateFranchiseShopAddressInput>;
        entity: CreateFranchiseShopEntitySwitchInput;
        manager?: InputMaybe<CreateFranchiseShopStaffSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type CreateFranchiseShopStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateFranchiseShopSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateFranchiseShopSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateFranchiseShopSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseShopSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateFranchiseShopSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseShopSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseShopSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseShopSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseShopSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseShopSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Staff */
    export type CreateFranchiseShopSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<CreateFranchiseShopSwitchCreateStaffListStaffTeam>;
        user: CreateFranchiseShopSwitchCreateStaffUserSwitchInput;
    };

    export type CreateFranchiseShopSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type CreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput = {
        entity: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
            >
        >;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateFranchiseShopSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type CreateFranchiseStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateFranchiseSwitchCreateFranchiseShopEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityInput;
    };

    /** Creates a new instances of: FranchiseShop */
    export type CreateFranchiseSwitchCreateFranchiseShopInput = {
        address?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopAddressInput>;
        entity: CreateFranchiseSwitchCreateFranchiseShopEntitySwitchInput;
        manager?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopStaffSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type CreateFranchiseSwitchCreateFranchiseShopStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandInput = {
        company?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductInput = {
        brand?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Staff */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeam>;
        user: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffUserSwitchInput;
    };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput>>
        >;
    };

    /** Creates a new instances of: StaffTeam */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput = {
        entity: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>
            >
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>
            >
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>
            >
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>
            >
        >;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Contact */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput =
        {
            data: Scalars['String'];
            isPrimary?: InputMaybe<Scalars['Boolean']>;
            label?: InputMaybe<Scalars['String']>;
            /** Expects node: **ContactType** */
            type: Scalars['ID'];
        };

    /** Creates a new instances of: CustomContract */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput =
        {
            /** Expects nodes: **StaffRegistration**, **Staff** */
            assignedTo?: InputMaybe<Scalars['ID']>;
            contractNumber?: InputMaybe<Scalars['String']>;
            endDate: Scalars['Date'];
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
            frequency?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            rate?: InputMaybe<Scalars['Int']>;
            startDate: Scalars['Date'];
            status?: InputMaybe<CustomContractStatusEnum>;
            term?: InputMaybe<Scalars['String']>;
            terminationDate?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput =
        {
            expiryDate?: InputMaybe<Scalars['Date']>;
            newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
            note?: InputMaybe<Scalars['String']>;
            payload?: InputMaybe<Scalars['JSON']>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            product?: InputMaybe<Scalars['ID']>;
            recNumber?: InputMaybe<Scalars['String']>;
            status?: InputMaybe<HeaterRecommendationStatusEnum>;
        };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            site: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
                >
            >;
        };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
                >
            >;
            /** Creates new instances of Pool. New instances will be appended to the edge */
            pools?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
                >
            >;
        };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput;
    };

    /** Creates a new instances of: Staff */
    export type CreateFranchiseSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<CreateFranchiseSwitchCreateStaffListStaffTeam>;
        user: CreateFranchiseSwitchCreateStaffUserSwitchInput;
    };

    export type CreateFranchiseSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseSwitchCreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type CreateFranchiseSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserInput = {
        entity: CreateFranchiseSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateFranchiseSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateFranchiseSwitchCreateStaffSwitchCreateUserInput;
    };

    /** Creates a new instance of: FranchiseWarranter */
    export type CreateFranchiseWarranterMutationInput = {
        /** Expects node: **Franchise** */
        actingFranchise?: InputMaybe<Scalars['ID']>;
        disabled?: InputMaybe<Scalars['Boolean']>;
        email: Scalars['String'];
        name: Scalars['String'];
    };

    /** Creates a new instance of: HeaterRecommendation */
    export type CreateHeaterRecommendationMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity?: InputMaybe<Scalars['ID']>;
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    export type CreateInstallableProductListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateInstallableProductListTestCriteriaCreateInput>>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type CreateInstallableProductListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: CreateInstallableProductListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<CreateInstallableProductListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<CreateInstallableProductListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateInstallableProductListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>
            >
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type CreateInstallableProductListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: InstallableProduct */
    export type CreateInstallableProductMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<CreateInstallableProductListTestCriteria>;
        product: CreateInstallableProductProductSwitchInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateInstallableProductProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateInstallableProductSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateInstallableProductSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreateInstallableProductSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateInstallableProductSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateInstallableProductSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateInstallableProductSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateInstallableProductSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateInstallableProductSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateInstallableProductSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateInstallableProductSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateInstallableProductSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateInstallableProductSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateInstallableProductSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateInstallableProductSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateInstallableProductSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Item */
    export type CreateItemMutationInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        owner: Scalars['ID'];
        product: CreateItemProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateItemProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateItemSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateItemSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateItemSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateItemSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreateItemSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateItemSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateItemSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateItemSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateItemSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitFloatValueInput>>
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>>
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>>
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>>
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitStringValueInput>>
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<CreateItemSwitchCreateProductListProductTraitListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateItemSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateItemSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateItemSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateItemSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateItemSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateItemSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateItemSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateItemSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateItemSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: JobTodoItem */
    export type CreateJobTodoItemMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodo** */
        job?: InputMaybe<Scalars['ID']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type CreateJobTodoListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: JobTodo */
    export type CreateJobTodoMutationInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<CreateJobTodoListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
        /** Expects node: **WorkOrder** */
        workOrder: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type CreateJobTodoTemplateAssocJobTodoTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
        /** Expects node: **JobTodoTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: JobTodoTemplateAssoc */
    export type CreateJobTodoTemplateAssocMutationInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        jobTodoTemplate: CreateJobTodoTemplateAssocJobTodoTemplateSwitchInput;
        priority?: InputMaybe<Scalars['Int']>;
        /** Expects node: **WorkOrderTemplate** */
        workOrderTemplate: Scalars['ID'];
    };

    /** Creates a new instances of: JobTodoTemplate */
    export type CreateJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
        items?: InputMaybe<
            Array<InputMaybe<CreateJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>>
        >;
        maxTimeEstimate?: InputMaybe<Scalars['Int']>;
        minTimeEstimate?: InputMaybe<Scalars['Int']>;
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrderTemplate** */
        ownedByWorkOrderTemplate?: InputMaybe<Scalars['ID']>;
        recommendedPrice?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput = {
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: JobTodoTemplateItem */
    export type CreateJobTodoTemplateItemMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateJobTodoTemplateListJobTodoTemplateItemInput = {
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: JobTodoTemplate */
    export type CreateJobTodoTemplateMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<CreateJobTodoTemplateListJobTodoTemplateItemInput>>>;
        maxTimeEstimate?: InputMaybe<Scalars['Int']>;
        minTimeEstimate?: InputMaybe<Scalars['Int']>;
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrderTemplate** */
        ownedByWorkOrderTemplate?: InputMaybe<Scalars['ID']>;
        recommendedPrice?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ManualProblem */
    export type CreateManualProblemMutationInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        solution?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: Measurement */
    export type CreateMeasurementMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Permission */
    export type CreatePermissionMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: PoolBottle */
    export type CreatePoolBottleMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        scanCode?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: PoolCalibration */
    export type CreatePoolCalibrationMutationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instance of: PoolDeclarationFlagField */
    export type CreatePoolDeclarationFlagFieldMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        exportName?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisation: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        useInExport?: InputMaybe<Scalars['Boolean']>;
    };

    export type CreatePoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreatePoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreatePoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreatePoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreatePoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreatePoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreatePoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreatePoolListItemCreateSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreatePoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreatePoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreatePoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreatePoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type CreatePoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreatePoolListPoolNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: Pool */
    export type CreatePoolMutationInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<CreatePoolListPoolCalibrationInput>>>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreatePoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreatePoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreatePoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreatePoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    /** Creates a new instance of: PoolNote */
    export type CreatePoolNoteMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: PoolProductDeclaration */
    export type CreatePoolProductDeclarationMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type CreatePoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type CreatePoolSwitchCreateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolSwitchCreateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Site */
    export type CreatePoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreatePoolSwitchCreateSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreatePoolSwitchCreateSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreatePoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreatePoolSwitchCreateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type CreatePoolTypeListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreatePoolTypeListTestCriteriaCreateInput>>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type CreatePoolTypeListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: CreatePoolTypeListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<CreatePoolTypeListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<CreatePoolTypeListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreatePoolTypeListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>>
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type CreatePoolTypeListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: PoolType */
    export type CreatePoolTypeMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<CreatePoolTypeListTestCriteria>;
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ProductCompany */
    export type CreateProductCompanyMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ProductDeclarationFlagField */
    export type CreateProductDeclarationFlagFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationFloatField */
    export type CreateProductDeclarationFloatFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationIntegerField */
    export type CreateProductDeclarationIntegerFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationMeasurementField */
    export type CreateProductDeclarationMeasurementFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationSelectionChoice */
    export type CreateProductDeclarationSelectionChoiceMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        /** Expects node: **ProductDeclarationSelectionField** */
        selection?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type CreateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput = {
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
    };

    /** Creates a new instance of: ProductDeclarationSelectionField */
    export type CreateProductDeclarationSelectionFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationSelectionChoice. New instances will be appended to the edge */
        options?: InputMaybe<
            Array<InputMaybe<CreateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput>>
        >;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationStringField */
    export type CreateProductDeclarationStringFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProductDeclarationUnitField */
    export type CreateProductDeclarationUnitFieldMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type CreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitListProductTraitFlagValueInput>>>;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitListProductTraitFloatValueInput>>>;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitListProductTraitIntegerValueInput>>>;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<InputMaybe<CreateProductListProductTraitListProductTraitMeasurementValueInput>>
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<InputMaybe<CreateProductListProductTraitListProductTraitSelectionValueInput>>
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitListProductTraitStringValueInput>>>;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitListProductTraitUnitValueInput>>>;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /** Creates a new instance of: Product */
    export type CreateProductMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<CreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductDeclarationFlagField */
    export type CreateProductTraitDeclarationListProductDeclarationFlagFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationFloatField */
    export type CreateProductTraitDeclarationListProductDeclarationFloatFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationIntegerField */
    export type CreateProductTraitDeclarationListProductDeclarationIntegerFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationMeasurementField */
    export type CreateProductTraitDeclarationListProductDeclarationMeasurementFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionField */
    export type CreateProductTraitDeclarationListProductDeclarationSelectionFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationSelectionChoice. New instances will be appended to the edge */
        options?: InputMaybe<
            Array<
                InputMaybe<CreateProductTraitDeclarationListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput>
            >
        >;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type CreateProductTraitDeclarationListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name: Scalars['String'];
            priority?: InputMaybe<Scalars['Int']>;
        };

    /** Creates a new instances of: ProductDeclarationStringField */
    export type CreateProductTraitDeclarationListProductDeclarationStringFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationUnitField */
    export type CreateProductTraitDeclarationListProductDeclarationUnitFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type CreateProductTraitDeclarationListSanitiser = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitDeclarationListSanitiserCreateInput>>>;
    };

    /** Creates a new instances of: Sanitiser */
    export type CreateProductTraitDeclarationListSanitiserCreateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteria>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        reportName?: InputMaybe<Scalars['String']>;
    };

    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateInput>>
        >;
    };

    /** Creates a new instances of: TestCriteria */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: TestProblem */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>
                >
            >;
        };

    /** Creates a new instances of: TestTreatment */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput =
        {
            /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
            affects?: InputMaybe<
                Array<
                    InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
                >
            >;
            /** Expects node: **ConsumableProduct** */
            consumableProduct?: InputMaybe<Scalars['ID']>;
            createdAt?: InputMaybe<Scalars['Date']>;
            decimalPlaces?: InputMaybe<Scalars['Int']>;
            /** Expects node: **TestTreatmentGroup** */
            group?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<Scalars['String']>;
            isRadical?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType: Scalars['ID'];
            priority?: InputMaybe<Scalars['Int']>;
            unit?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ProductTraitDeclaration */
    export type CreateProductTraitDeclarationMutationInput = {
        /** Creates new instances of ProductDeclarationFlagField. New instances will be appended to the edge */
        flagFields?: InputMaybe<Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationFlagFieldInput>>>;
        /** Creates new instances of ProductDeclarationFloatField. New instances will be appended to the edge */
        floatFields?: InputMaybe<Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationFloatFieldInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProductDeclarationIntegerField. New instances will be appended to the edge */
        integerFields?: InputMaybe<
            Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationIntegerFieldInput>>
        >;
        isCategory?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationMeasurementField. New instances will be appended to the edge */
        measurementFields?: InputMaybe<
            Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationMeasurementFieldInput>>
        >;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductTraitDeclaration** */
        parent?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        relatedSanitisers?: InputMaybe<CreateProductTraitDeclarationListSanitiser>;
        /** Creates new instances of ProductDeclarationSelectionField. New instances will be appended to the edge */
        selectionFields?: InputMaybe<
            Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationSelectionFieldInput>>
        >;
        /** Creates new instances of ProductDeclarationStringField. New instances will be appended to the edge */
        stringFields?: InputMaybe<
            Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationStringFieldInput>>
        >;
        tag?: InputMaybe<Scalars['String']>;
        /** Creates new instances of ProductDeclarationUnitField. New instances will be appended to the edge */
        unitFields?: InputMaybe<Array<InputMaybe<CreateProductTraitDeclarationListProductDeclarationUnitFieldInput>>>;
    };

    /** Creates a new instance of: ProductTraitFlagValue */
    export type CreateProductTraitFlagValueMutationInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instance of: ProductTraitFloatValue */
    export type CreateProductTraitFloatValueMutationInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instance of: ProductTraitIntegerValue */
    export type CreateProductTraitIntegerValueMutationInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /** Creates a new instance of: ProductTraitMeasurementValue */
    export type CreateProductTraitMeasurementValueMutationInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instance of: ProductTrait */
    export type CreateProductTraitMutationInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitFlagValueInput>>>;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitFloatValueInput>>>;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitIntegerValueInput>>>;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitMeasurementValueInput>>>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitSelectionValueInput>>>;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitStringValueInput>>>;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<Array<InputMaybe<CreateProductTraitListProductTraitUnitValueInput>>>;
    };

    /** Creates a new instance of: ProductTraitSelectionValue */
    export type CreateProductTraitSelectionValueMutationInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instance of: ProductTraitStringValue */
    export type CreateProductTraitStringValueMutationInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instance of: ProductTraitUnitValue */
    export type CreateProductTraitUnitValueMutationInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProjectVersion */
    export type CreateProjectListProjectVersionInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: Project */
    export type CreateProjectMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Project** */
        documentationProject?: InputMaybe<Scalars['ID']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        franchiseRestricted?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        pinnedVersion?: InputMaybe<CreateProjectProjectVersionInput>;
        platformKey?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        remoteThumbnailURL?: InputMaybe<Scalars['String']>;
        /** Expects node: **FileUpload** */
        thumbnailFile?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProjectVersion. New instances will be appended to the edge */
        versions?: InputMaybe<Array<InputMaybe<CreateProjectListProjectVersionInput>>>;
    };

    /** Creates a new instances of: ProjectVersion */
    export type CreateProjectProjectVersionInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ProjectVersion */
    export type CreateProjectVersionMutationInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Project** */
        project: Scalars['ID'];
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: RoleFlag */
    export type CreateRoleFlagMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        tag: Scalars['String'];
    };

    export type CreateRoleListRoleFlag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateRoleListRoleFlagCreateInput>>>;
    };

    /** Creates a new instances of: RoleFlag */
    export type CreateRoleListRoleFlagCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        tag: Scalars['String'];
    };

    /** Creates a new instance of: Role */
    export type CreateRoleMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flags?: InputMaybe<CreateRoleListRoleFlag>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SampleConverterTemplate */
    export type CreateSampleConverterListSampleConverterTemplateInput = {
        data?: InputMaybe<Scalars['JSON']>;
        measurement: CreateSampleConverterListSampleConverterTemplateMeasurementSwitchInput;
        tag: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateSampleConverterListSampleConverterTemplateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: SampleConverter */
    export type CreateSampleConverterMutationInput = {
        name: Scalars['String'];
        tag: Scalars['String'];
        /** Creates new instances of SampleConverterTemplate. New instances will be appended to the edge */
        templates?: InputMaybe<Array<InputMaybe<CreateSampleConverterListSampleConverterTemplateInput>>>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateSampleConverterTemplateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSampleConverterTemplateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: SampleConverterTemplate */
    export type CreateSampleConverterTemplateMutationInput = {
        /** Expects node: **SampleConverter** */
        converter: Scalars['ID'];
        data?: InputMaybe<Scalars['JSON']>;
        measurement: CreateSampleConverterTemplateMeasurementSwitchInput;
        tag: Scalars['String'];
    };

    /** Creates a new instances of: Measurement */
    export type CreateSampleConverterTemplateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Sample */
    export type CreateSampleMutationInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **Pool** */
        pool: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    export type CreateSampleSetListSample = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateSampleSetListSampleCreateInput>>>;
    };

    /** Creates a new instances of: Sample */
    export type CreateSampleSetListSampleCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    /** Creates a new instance of: SampleSet */
    export type CreateSampleSetMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        samples?: InputMaybe<CreateSampleSetListSample>;
    };

    /** Creates a new instance of: SampleSource */
    export type CreateSampleSourceMutationInput = {
        name?: InputMaybe<Scalars['String']>;
    };

    export type CreateSanitiserListProductTraitDeclaration = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateInput>>>;
    };

    /** Creates a new instances of: ProductTraitDeclaration */
    export type CreateSanitiserListProductTraitDeclarationCreateInput = {
        /** Creates new instances of ProductDeclarationFlagField. New instances will be appended to the edge */
        flagFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationFlagFieldInput>>
        >;
        /** Creates new instances of ProductDeclarationFloatField. New instances will be appended to the edge */
        floatFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationFloatFieldInput>>
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProductDeclarationIntegerField. New instances will be appended to the edge */
        integerFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationIntegerFieldInput>>
        >;
        isCategory?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationMeasurementField. New instances will be appended to the edge */
        measurementFields?: InputMaybe<
            Array<
                InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationMeasurementFieldInput>
            >
        >;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductTraitDeclaration** */
        parent?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProductDeclarationSelectionField. New instances will be appended to the edge */
        selectionFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldInput>>
        >;
        /** Creates new instances of ProductDeclarationStringField. New instances will be appended to the edge */
        stringFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationStringFieldInput>>
        >;
        tag?: InputMaybe<Scalars['String']>;
        /** Creates new instances of ProductDeclarationUnitField. New instances will be appended to the edge */
        unitFields?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationUnitFieldInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationFlagField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationFlagFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationFloatField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationFloatFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationIntegerField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationIntegerFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationMeasurementField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationMeasurementFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationSelectionChoice. New instances will be appended to the edge */
        options?: InputMaybe<
            Array<
                InputMaybe<CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput>
            >
        >;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name: Scalars['String'];
            priority?: InputMaybe<Scalars['Int']>;
        };

    /** Creates a new instances of: ProductDeclarationStringField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationStringFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationUnitField */
    export type CreateSanitiserListProductTraitDeclarationCreateListProductDeclarationUnitFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type CreateSanitiserListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateSanitiserListTestCriteriaCreateInput>>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type CreateSanitiserListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: CreateSanitiserListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<CreateSanitiserListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<CreateSanitiserListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateSanitiserListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>>
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type CreateSanitiserListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSanitiserListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: Sanitiser */
    export type CreateSanitiserMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<CreateSanitiserListTestCriteria>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        relatedDeclarations?: InputMaybe<CreateSanitiserListProductTraitDeclaration>;
        reportName?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateServiceJobEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateServiceJobSwitchCreateEntityInput;
    };

    /** Creates a new instance of: ServiceJobGroup */
    export type CreateServiceJobGroupMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        parent?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
    };

    export type CreateServiceJobListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobNote */
    export type CreateServiceJobListServiceJobNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WorkOrder */
    export type CreateServiceJobListWorkOrderInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        appointment?: InputMaybe<WorkOrderBookingCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        autoInflateTemplate?: InputMaybe<Scalars['Boolean']>;
        checkedInAt?: InputMaybe<Scalars['Date']>;
        checkedOutAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        dueRangeEnd?: InputMaybe<Scalars['Date']>;
        dueRangeStart?: InputMaybe<Scalars['Date']>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateServiceJobListWorkOrderListFileUpload>;
        /** Expects node: **WorkOrder** */
        followUpFrom?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of JobTodo. New instances will be appended to the edge */
        jobs?: InputMaybe<Array<InputMaybe<CreateServiceJobListWorkOrderListJobTodoInput>>>;
        /** Creates new instances of WorkOrderNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobListWorkOrderListWorkOrderNoteInput>>>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresAppointment?: InputMaybe<Scalars['Boolean']>;
        requiresAssignedTo?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresPreferredContact?: InputMaybe<Scalars['Boolean']>;
        requiresTestReport?: InputMaybe<Scalars['Boolean']>;
        requiresWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        stageType?: InputMaybe<ServiceJobStageType>;
        /** Expects node: **WorkOrderTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        todoTemplates?: InputMaybe<Array<Scalars['ID']>>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        workFor?: InputMaybe<Scalars['ID']>;
    };

    export type CreateServiceJobListWorkOrderListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: JobTodo */
    export type CreateServiceJobListWorkOrderListJobTodoInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<CreateServiceJobListWorkOrderListJobTodoListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type CreateServiceJobListWorkOrderListJobTodoListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WorkOrderNote */
    export type CreateServiceJobListWorkOrderListWorkOrderNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: ServiceJob */
    export type CreateServiceJobMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        autoInflateTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        dueDate?: InputMaybe<Scalars['Date']>;
        entity: CreateServiceJobEntitySwitchInput;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateServiceJobListFileUpload>;
        /** Expects node: **ServiceJob** */
        followedFrom?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnum>;
        isRecurring?: InputMaybe<Scalars['Boolean']>;
        isSimpleMode?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ServiceJobNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobListServiceJobNoteInput>>>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        /** Use for recurrent services */
        recurrentAppointment?: InputMaybe<ServiceJobBookingNodeInitInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        /** To be used with recurrent services */
        stopsAt?: InputMaybe<Scalars['Date']>;
        /** Expects node: **ServiceJobTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of WorkOrder. New instances will be appended to the edge */
        workOrders?: InputMaybe<Array<InputMaybe<CreateServiceJobListWorkOrderInput>>>;
    };

    /** Creates a new instance of: ServiceJobNote */
    export type CreateServiceJobNoteMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **ServiceJob** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ServiceJobStage */
    export type CreateServiceJobStageMutationInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instances of: Entity */
    export type CreateServiceJobSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<CreateServiceJobSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateServiceJobSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateServiceJobSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateServiceJobSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateServiceJobSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateServiceJobSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateServiceJobSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateServiceJobSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateServiceJobSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateServiceJobSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateServiceJobSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateServiceJobSwitchCreateEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateServiceJobSwitchCreateEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateServiceJobSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateServiceJobSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateServiceJobSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        address: Scalars['ID'];
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListPoolSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateServiceJobSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateServiceJobSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        address: Scalars['ID'];
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListSiteNoteInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateServiceJobSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateServiceJobSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type CreateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobTemplateListServiceJobStageCreateInput>>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type CreateServiceJobTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        workOrderTemplate: CreateServiceJobTemplateListWorkOrderTemplateAssocWorkOrderTemplateSwitchInput;
    };

    /** Creates a new instances of: WorkOrderTemplate */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput>
            >
        >;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput =
        {
            isSuggestion?: InputMaybe<Scalars['Boolean']>;
            jobTodoTemplate: CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput;
            priority?: InputMaybe<Scalars['Int']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
            /** Expects node: **JobTodoTemplate** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: JobTodoTemplate */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
            items?: InputMaybe<
                Array<
                    InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
                >
            >;
            maxTimeEstimate?: InputMaybe<Scalars['Int']>;
            minTimeEstimate?: InputMaybe<Scalars['Int']>;
            recommendedPrice?: InputMaybe<Scalars['Int']>;
            /** Time Estimate in minutes */
            timeEstimate?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput>
            >
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput =
        {
            color?: InputMaybe<Scalars['String']>;
            hidden?: InputMaybe<Scalars['Boolean']>;
            isDefault?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
            title: Scalars['String'];
            type?: InputMaybe<ServiceJobStageType>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: WorkOrderTemplate
     */
    export type CreateServiceJobTemplateListWorkOrderTemplateAssocWorkOrderTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateInput>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: ServiceJobTemplate */
    export type CreateServiceJobTemplateMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<CreateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        isPublished?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances of WorkOrderTemplateAssoc. New instances will be appended to the edge */
        workOrderTemplateAssocs?: InputMaybe<
            Array<InputMaybe<CreateServiceJobTemplateListWorkOrderTemplateAssocInput>>
        >;
    };

    /** Creates a new instance of: SettingStore */
    export type CreateSettingStoreMutationInput = {
        group: Scalars['String'];
        name: Scalars['String'];
        value?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: SharedEntity */
    export type CreateSharedEntityMutationInput = {
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        sharedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        sharedToFranchise?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type CreateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Pool */
    export type CreateSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<CreateSiteListPoolListPoolCalibrationInput>>>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateSiteListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateSiteListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateSiteListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateSiteListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type CreateSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type CreateSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateSiteListPoolListPoolNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateSiteListSiteNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: Site */
    export type CreateSiteMutationInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateSiteAddressSwitchInput;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateSiteListSiteNoteInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateSiteListPoolInput>>>;
    };

    /** Creates a new instance of: SiteNote */
    export type CreateSiteNoteMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Site** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Address */
    export type CreateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type CreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type CreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /** Creates a new instance of: Staff */
    export type CreateStaffMutationInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<CreateStaffListStaffTeam>;
        user: CreateStaffUserSwitchInput;
    };

    /** Creates a new instance of: StaffRegistration */
    export type CreateStaffRegistrationMutationInput = {
        email: Scalars['String'];
        password: Scalars['String'];
        staff: CreateStaffRegistrationStaffSwitchInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type CreateStaffRegistrationStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Staff */
    export type CreateStaffRegistrationSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffListStaffTeam>;
        user: CreateStaffRegistrationSwitchCreateStaffUserSwitchInput;
    };

    export type CreateStaffRegistrationSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateStaffRegistrationSwitchCreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type CreateStaffRegistrationSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserInput = {
        entity: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>
            >
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>
            >
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput =
        {
            expiryDate?: InputMaybe<Scalars['Date']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
            note?: InputMaybe<Scalars['String']>;
            payload?: InputMaybe<Scalars['JSON']>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            product?: InputMaybe<Scalars['ID']>;
            recNumber?: InputMaybe<Scalars['String']>;
            status?: InputMaybe<HeaterRecommendationStatusEnum>;
        };

    /** Creates a new instances of: Pool */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput =
        {
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
            >
        >;
    };

    /** Creates a new instances of: Pool */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateStaffRegistrationSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateStaffRegistrationSwitchCreateStaffSwitchCreateUserInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type CreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type CreateStaffSwitchCreateUserInput = {
        entity: CreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>>
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput = {
        brand?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type CreateStaffTeamListStaff = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instance of: StaffTeam */
    export type CreateStaffTeamMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        staff?: InputMaybe<CreateStaffTeamListStaff>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type CreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: CreateStaffSwitchCreateUserInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type CreateTestCriteriaMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestCriteriaSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: TestCriteria */
    export type CreateTestCriteriaMutationInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: CreateTestCriteriaMeasurementSwitchInput;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<CreateTestCriteriaTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<CreateTestCriteriaTestProblemSwitchInput>;
    };

    /** Creates a new instances of: Measurement */
    export type CreateTestCriteriaSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<CreateTestCriteriaSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type CreateTestCriteriaSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateTestCriteriaSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestCriteriaSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type CreateTestCriteriaSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreateTestCriteriaSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateTestCriteriaSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateInput>>>;
    };

    /** Creates a new instances of: TestTreatment */
    export type CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<InputMaybe<CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>>
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instances of: Unit */
    export type CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type CreateTestCriteriaTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestCriteriaSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type CreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateTestProblemListTestTreatmentCreateInput>>>;
    };

    /** Creates a new instances of: TestTreatment */
    export type CreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<Array<InputMaybe<CreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>>>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateTestProblemListTestTreatmentCreateListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instances of: Unit */
    export type CreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: TestProblem */
    export type CreateTestProblemMutationInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<CreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instance of: TestReportAttachment */
    export type CreateTestReportAttachmentMutationInput = {
        comment?: InputMaybe<Scalars['String']>;
        /** Expects node: **FileUpload** */
        file: Scalars['ID'];
        hidden?: InputMaybe<Scalars['Boolean']>;
        smallView?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReport** */
        testReport?: InputMaybe<Scalars['ID']>;
    };

    export type CreateTestReportListManualProblem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateTestReportListManualProblemCreateInput>>>;
    };

    /** Creates a new instances of: ManualProblem */
    export type CreateTestReportListManualProblemCreateInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        solution?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: TestReportAttachment */
    export type CreateTestReportListTestReportAttachmentInput = {
        comment?: InputMaybe<Scalars['String']>;
        /** Expects node: **FileUpload** */
        file: Scalars['ID'];
        hidden?: InputMaybe<Scalars['Boolean']>;
        smallView?: InputMaybe<Scalars['Boolean']>;
    };

    /** Creates a new instance of: TestReport */
    export type CreateTestReportMutationInput = {
        /** Creates new instances of TestReportAttachment. New instances will be appended to the edge */
        attachments?: InputMaybe<Array<InputMaybe<CreateTestReportListTestReportAttachmentInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        manualProblems?: InputMaybe<CreateTestReportListManualProblem>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool: Scalars['ID'];
        refId?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        reportBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        reportTo: Scalars['ID'];
        sampleSet: CreateTestReportSampleSetSwitchInput;
        submissionMetadata?: InputMaybe<TestReportSubmissionNodeInitInput>;
        /** Expects node: **WorkOrder** */
        workOrder?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: SampleSet
     */
    export type CreateTestReportSampleSetSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestReportSwitchCreateSampleSetInput>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Deletes previous instance and replaces it with new created instance */
        replace?: InputMaybe<CreateTestReportSwitchCreateSampleSetInput>;
    };

    /** Creates a new instance of: TestReportSolution */
    export type CreateTestReportSolutionMutationInput = {
        amount?: InputMaybe<Scalars['Float']>;
        comment?: InputMaybe<Scalars['String']>;
        instructionOverride?: InputMaybe<Scalars['String']>;
        manual?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReport** */
        report: Scalars['ID'];
        /** Expects node: **TestTreatment** */
        treatment: Scalars['ID'];
    };

    /** Creates a new instances of: SampleSet */
    export type CreateTestReportSwitchCreateSampleSetInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        samples?: InputMaybe<CreateTestReportSwitchCreateSampleSetListSample>;
    };

    export type CreateTestReportSwitchCreateSampleSetListSample = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateTestReportSwitchCreateSampleSetListSampleCreateInput>>>;
    };

    /** Creates a new instances of: Sample */
    export type CreateTestReportSwitchCreateSampleSetListSampleCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    /** Creates a new instance of: TestTreatmentGroup */
    export type CreateTestTreatmentGroupMutationInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type CreateTestTreatmentListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instance of: TestTreatment */
    export type CreateTestTreatmentMutationInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<Array<InputMaybe<CreateTestTreatmentListTreatmentAffectInput>>>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<CreateTestTreatmentUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: Unit */
    export type CreateTestTreatmentSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type CreateTestTreatmentUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateTestTreatmentSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: TreatmentAffect */
    export type CreateTreatmentAffectMutationInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestTreatment** */
        treatment: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instance of: UnitConversion */
    export type CreateUnitConversionMutationInput = {
        /** Expects node: **Unit** */
        destination: Scalars['ID'];
        factors: Array<InputMaybe<Scalars['Float']>>;
        /** Expects node: **UnitConverter** */
        method: Scalars['ID'];
        /** Expects node: **Unit** */
        source: Scalars['ID'];
    };

    /** Creates a new instance of: Unit */
    export type CreateUnitMutationInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: UniversalCriteria */
    export type CreateUniversalCriteriaMutationInput = {
        /** Expects node: **TestCriteria** */
        criterion?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: VendAPI */
    export type CreateVendAPIMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        key: Scalars['String'];
        shopUri: Scalars['String'];
    };

    /** Creates a new instance of: VendSale */
    export type CreateVendSaleMutationInput = {
        note?: InputMaybe<Scalars['String']>;
        totalPriceInclTax?: InputMaybe<Scalars['Float']>;
        vendLink: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type CreateWarrantyClaimAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: WarrantyClaimCase */
    export type CreateWarrantyClaimCaseMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WarrantyClaim** */
        claim?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type CreateWarrantyClaimEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type CreateWarrantyClaimEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type CreateWarrantyClaimEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type CreateWarrantyClaimEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateWarrantyClaimEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type CreateWarrantyClaimEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type CreateWarrantyClaimEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type CreateWarrantyClaimEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateWarrantyClaimEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateWarrantyClaimEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **Site** */
        site: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateWarrantyClaimEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateWarrantyClaimEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateWarrantyClaimEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateWarrantyClaimEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateWarrantyClaimEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type CreateWarrantyClaimEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateWarrantyClaimEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateWarrantyClaimEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type CreateWarrantyClaimEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: CreateWarrantyClaimEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteListSiteNoteInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteListPoolInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type CreateWarrantyClaimEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type CreateWarrantyClaimEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type CreateWarrantyClaimEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: CreateWarrantyClaimEntityListSiteListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type CreateWarrantyClaimEntityListSiteListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type CreateWarrantyClaimEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type CreateWarrantyClaimEntityListSiteListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type CreateWarrantyClaimEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: WarrantyClaimFileAttach */
    export type CreateWarrantyClaimFileAttachMutationInput = {
        /** Expects node: **WarrantyClaim** */
        claim?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FileUpload** */
        file?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WarrantyClaimFileAttach */
    export type CreateWarrantyClaimListWarrantyClaimFileAttachInput = {
        /** Expects node: **FileUpload** */
        file?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: WarrantyClaim */
    export type CreateWarrantyClaimMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Address for a location of claimed item */
        address?: InputMaybe<CreateWarrantyClaimAddressSwitchInput>;
        assetInstallDate?: InputMaybe<Scalars['Date']>;
        assetLocation?: InputMaybe<Scalars['String']>;
        assetName?: InputMaybe<Scalars['String']>;
        assetSerial?: InputMaybe<Scalars['String']>;
        claimedAt?: InputMaybe<Scalars['Date']>;
        comment?: InputMaybe<Scalars['String']>;
        customerCompanyName?: InputMaybe<Scalars['String']>;
        customerEmail?: InputMaybe<Scalars['String']>;
        customerFirstName?: InputMaybe<Scalars['String']>;
        customerLastName?: InputMaybe<Scalars['String']>;
        customerPhone?: InputMaybe<Scalars['String']>;
        emailCC?: InputMaybe<Scalars['String']>;
        emailReplyTo?: InputMaybe<Scalars['String']>;
        entity: CreateWarrantyClaimEntityInput;
        faultDescription?: InputMaybe<Scalars['String']>;
        /** Creates new instances of WarrantyClaimFileAttach. New instances will be appended to the edge */
        files?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimListWarrantyClaimFileAttachInput>>>;
        installerName?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        receiverStaff?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FranchiseWarranter** */
        receiverWarranter: Scalars['ID'];
        /** Expects node: **Item** */
        refAsset?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        refCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Site** */
        refSite?: InputMaybe<Scalars['ID']>;
        siteAccessKey?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type CreateWarrantyClaimSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: WaterTestingRevision */
    export type CreateWaterTestingRevisionMutationInput = {
        /** Expects node: **OrganisationType** */
        organisation: Scalars['ID'];
    };

    export type CreateWorkOrderListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: JobTodo */
    export type CreateWorkOrderListJobTodoInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<CreateWorkOrderListJobTodoListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type CreateWorkOrderListJobTodoListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WorkOrderNote */
    export type CreateWorkOrderListWorkOrderNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instance of: WorkOrder */
    export type CreateWorkOrderMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        appointment?: InputMaybe<WorkOrderBookingCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        autoInflateTemplate?: InputMaybe<Scalars['Boolean']>;
        checkedInAt?: InputMaybe<Scalars['Date']>;
        checkedOutAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        dueRangeEnd?: InputMaybe<Scalars['Date']>;
        dueRangeStart?: InputMaybe<Scalars['Date']>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<CreateWorkOrderListFileUpload>;
        /** Expects node: **WorkOrder** */
        followUpFrom?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of JobTodo. New instances will be appended to the edge */
        jobs?: InputMaybe<Array<InputMaybe<CreateWorkOrderListJobTodoInput>>>;
        /** Creates new instances of WorkOrderNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<CreateWorkOrderListWorkOrderNoteInput>>>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresAppointment?: InputMaybe<Scalars['Boolean']>;
        requiresAssignedTo?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresPreferredContact?: InputMaybe<Scalars['Boolean']>;
        requiresTestReport?: InputMaybe<Scalars['Boolean']>;
        requiresWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        serviceJob: Scalars['ID'];
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        stageType?: InputMaybe<ServiceJobStageType>;
        /** Expects node: **WorkOrderTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        todoTemplates?: InputMaybe<Array<Scalars['ID']>>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        workFor?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: WorkOrderNote */
    export type CreateWorkOrderNoteMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **WorkOrder** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instance of: WorkOrderTemplateAssoc */
    export type CreateWorkOrderTemplateAssocMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        /** Expects node: **ServiceJobTemplate** */
        serviceJobTemplate: Scalars['ID'];
        workOrderTemplate: CreateWorkOrderTemplateAssocWorkOrderTemplateSwitchInput;
    };

    /** Creates a new instances of: WorkOrderTemplate */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<
            Array<InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput>>
        >;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /**
         * Expects node: **ServiceJobTemplate**
         *
         * If null then meant for sharing with many ServiceJobTemplate
         */
        ownedByServiceJobTemplate?: InputMaybe<Scalars['ID']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        jobTodoTemplate: CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
            /** Expects node: **JobTodoTemplate** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: JobTodoTemplate */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
            items?: InputMaybe<
                Array<
                    InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
                >
            >;
            maxTimeEstimate?: InputMaybe<Scalars['Int']>;
            minTimeEstimate?: InputMaybe<Scalars['Int']>;
            recommendedPrice?: InputMaybe<Scalars['Int']>;
            /** Time Estimate in minutes */
            timeEstimate?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput>>
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: WorkOrderTemplate
     */
    export type CreateWorkOrderTemplateAssocWorkOrderTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWorkOrderTemplateAssocSwitchCreateWorkOrderTemplateInput>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type CreateWorkOrderTemplateListJobTodoTemplateAssocInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        jobTodoTemplate: CreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type CreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<CreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
        /** Expects node: **JobTodoTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: JobTodoTemplate */
    export type CreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
        items?: InputMaybe<
            Array<
                InputMaybe<CreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
            >
        >;
        maxTimeEstimate?: InputMaybe<Scalars['Int']>;
        minTimeEstimate?: InputMaybe<Scalars['Int']>;
        recommendedPrice?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type CreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type CreateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<CreateWorkOrderTemplateListServiceJobStageCreateInput>>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type CreateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instance of: WorkOrderTemplate */
    export type CreateWorkOrderTemplateMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<CreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<Array<InputMaybe<CreateWorkOrderTemplateListJobTodoTemplateAssocInput>>>;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /**
         * Expects node: **ServiceJobTemplate**
         *
         * If null then meant for sharing with many ServiceJobTemplate
         */
        ownedByServiceJobTemplate?: InputMaybe<Scalars['ID']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** A connection to a list of items. */
    export type CreatedWorkOrdersForStaffToWorkOrderConnection = {
        __typename?: 'CreatedWorkOrdersForStaffToWorkOrderConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CreatedWorkOrdersForStaffToWorkOrderEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<CreatedWorkOrdersForStaffToWorkOrderMeta>;
    };

    /** An edge in a connection. */
    export type CreatedWorkOrdersForStaffToWorkOrderEdge = {
        __typename?: 'CreatedWorkOrdersForStaffToWorkOrderEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrder>;
    };

    export type CreatedWorkOrdersForStaffToWorkOrderMeta = {
        __typename?: 'CreatedWorkOrdersForStaffToWorkOrderMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type CriteriaForInstallableProductToTestCriteriaConnection = {
        __typename?: 'CriteriaForInstallableProductToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CriteriaForInstallableProductToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CriteriaForInstallableProductToTestCriteriaEdge = {
        __typename?: 'CriteriaForInstallableProductToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    /** A connection to a list of items. */
    export type CriteriaForPoolToTestCriteriaConnection = {
        __typename?: 'CriteriaForPoolToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CriteriaForPoolToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CriteriaForPoolToTestCriteriaEdge = {
        __typename?: 'CriteriaForPoolToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    /** A connection to a list of items. */
    export type CriteriaForPoolTypeToTestCriteriaConnection = {
        __typename?: 'CriteriaForPoolTypeToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CriteriaForPoolTypeToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CriteriaForPoolTypeToTestCriteriaEdge = {
        __typename?: 'CriteriaForPoolTypeToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    /** A connection to a list of items. */
    export type CriteriaForSanitiserToTestCriteriaConnection = {
        __typename?: 'CriteriaForSanitiserToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CriteriaForSanitiserToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CriteriaForSanitiserToTestCriteriaEdge = {
        __typename?: 'CriteriaForSanitiserToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    /** A connection to a list of items. */
    export type CriteriaForTestProblemToTestCriteriaConnection = {
        __typename?: 'CriteriaForTestProblemToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CriteriaForTestProblemToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type CriteriaForTestProblemToTestCriteriaEdge = {
        __typename?: 'CriteriaForTestProblemToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    export type CustomContract = Node & {
        __typename?: 'CustomContract';
        addedBy?: Maybe<Staff>;
        assignedTo?: Maybe<Staff>;
        checkMutations?: Maybe<CustomContractMutationCheck>;
        contractNumber?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        endDate?: Maybe<Scalars['Date']>;
        entity?: Maybe<Entity>;
        files?: Maybe<FilesForCustomContractToFileUploadConnection>;
        frequency?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        rate?: Maybe<Scalars['Int']>;
        startDate?: Maybe<Scalars['Date']>;
        status?: Maybe<CustomContractStatusEnum>;
        term?: Maybe<Scalars['String']>;
        terminationDate?: Maybe<Scalars['Date']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type CustomContractFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadCustomContractFilesSearch>;
        sort?: InputMaybe<FileUploadCustomContractFilesSort>;
    };

    export type CustomContractEntityCustomContractsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        contractNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        endDate?: InputMaybe<DateRangeSearchCommon>;
        startDate?: InputMaybe<DateRangeSearchCommon>;
        status?: InputMaybe<CustomContractStatusEnumEnumMatchSearchCommon>;
    };

    export type CustomContractEntityCustomContractsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **CustomContract** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        startDate?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type CustomContractMutationCheck = {
        __typename?: 'CustomContractMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type CustomContractMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateCustomContractMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteCustomContractMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomContractMutationInput>>>;
    };

    export type CustomContractMutationPayload = {
        __typename?: 'CustomContractMutationPayload';
        CustomContract?: Maybe<CustomContract>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkCustomContractMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedEntity?: Maybe<Entity>;
        viewer?: Maybe<Viewer>;
    };

    export enum CustomContractStatusEnum {
        Active = 'Active',
        Closed = 'Closed',
        OnHold = 'OnHold',
    }

    export type CustomContractStatusEnumEnumMatchSearchCommon = {
        is?: InputMaybe<CustomContractStatusEnum>;
        not?: InputMaybe<CustomContractStatusEnum>;
    };

    export type CustomContractViewerCustomContractsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        contractNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        endDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<CustomContractViewerCustomContractsSearchEntity>;
        startDate?: InputMaybe<DateRangeSearchCommon>;
        status?: InputMaybe<CustomContractStatusEnumEnumMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntity = {
        addresses?: InputMaybe<CustomContractViewerCustomContractsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<CustomContractViewerCustomContractsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPools>;
        serviceJob?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJob>;
        sites?: InputMaybe<CustomContractViewerCustomContractsSearchEntitySites>;
        user?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSite>;
        type?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSite = {
        address?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJob = {
        address?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobWorkOrders>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUser>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSite>;
        type?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSite = {
        address?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomContractViewerCustomContractsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type CustomContractViewerCustomContractsSearchEntitySites = {
        address?: InputMaybe<CustomContractViewerCustomContractsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserStaff>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerTags>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserCustomerAddedByTeams>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<CustomContractViewerCustomContractsSearchEntityUserStaffTeams>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomContractViewerCustomContractsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomContractViewerCustomContractsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **CustomContract** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        startDate?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type CustomContractsForEntityToCustomContractConnection = {
        __typename?: 'CustomContractsForEntityToCustomContractConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CustomContractsForEntityToCustomContractEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<CustomContractsForEntityToCustomContractMeta>;
    };

    /** An edge in a connection. */
    export type CustomContractsForEntityToCustomContractEdge = {
        __typename?: 'CustomContractsForEntityToCustomContractEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<CustomContract>;
    };

    export type CustomContractsForEntityToCustomContractMeta = {
        __typename?: 'CustomContractsForEntityToCustomContractMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type Customer = Node & {
        __typename?: 'Customer';
        addedBy?: Maybe<Staff>;
        appointments?: Maybe<AppointmentsForCustomerToAppointmentItemConnection>;
        assignedTo?: Maybe<Staff>;
        billAddress?: Maybe<Address>;
        checkMutations?: Maybe<CustomerMutationCheck>;
        companyName?: Maybe<Scalars['String']>;
        contactName?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        crn?: Maybe<Scalars['String']>;
        ezcDeviceLinks?: Maybe<EzcDeviceLinksForCustomerToEZCDeviceLinkConnection>;
        id: Scalars['ID'];
        nextAppointment?: Maybe<AppointmentItem>;
        note?: Maybe<Scalars['String']>;
        notes?: Maybe<NotesForCustomerToCustomerNoteConnection>;
        /** @deprecated Use Entity Pools Instead, this field will be removed in future version */
        pools?: Maybe<PoolsForCustomerToPoolConnection>;
        primaryAddress?: Maybe<Address>;
        primaryEmail?: Maybe<Contact>;
        primaryPhone?: Maybe<Contact>;
        refId?: Maybe<Scalars['String']>;
        registeredAt?: Maybe<Scalars['Date']>;
        root?: Maybe<Entity>;
        serviceJobs?: Maybe<ServiceJobsForCustomerToServiceJobConnection>;
        /** @deprecated Use Entity Sites Instead, this field will be removed in future version */
        sites?: Maybe<SitesForCustomerToSiteConnection>;
        tags?: Maybe<TagsForCustomerToCustomerTagConnection>;
        updatedAt?: Maybe<Scalars['Date']>;
        user?: Maybe<User>;
        vendLink?: Maybe<VendLink>;
    };

    export type CustomerAppointmentsArgs = {
        addedBy?: InputMaybe<Scalars['ID']>;
        address?: InputMaybe<Scalars['ID']>;
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        serviceJob?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type CustomerEzcDeviceLinksArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EZCDeviceLinkCustomerEzcDeviceLinksSearch>;
        sort?: InputMaybe<EZCDeviceLinkCustomerEzcDeviceLinksSort>;
    };

    export type CustomerNotesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomerNoteCustomerNotesSearch>;
        sort?: InputMaybe<CustomerNoteCustomerNotesSort>;
    };

    export type CustomerPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type CustomerServiceJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobCustomerServiceJobsSearch>;
        sort?: InputMaybe<ServiceJobCustomerServiceJobsSort>;
    };

    export type CustomerSitesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type CustomerTagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomerTagCustomerTagsSearch>;
        sort?: InputMaybe<CustomerTagCustomerTagsSort>;
    };

    export type CustomerMutationCheck = {
        __typename?: 'CustomerMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type CustomerMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateCustomerMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteCustomerMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerMutationInput>>>;
    };

    export type CustomerMutationPayload = {
        __typename?: 'CustomerMutationPayload';
        Customer?: Maybe<Customer>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkCustomerMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedBillAddress?: Maybe<Address>;
        updatedPrimaryAddress?: Maybe<Address>;
        viewer?: Maybe<Viewer>;
    };

    export type CustomerNodeMatchSearchCommon = {
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerNote = Node & {
        __typename?: 'CustomerNote';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<CustomerNoteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        parent?: Maybe<Customer>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type CustomerNoteCustomerNotesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerNoteCustomerNotesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **CustomerNote** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type CustomerNoteMutationCheck = {
        __typename?: 'CustomerNoteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type CustomerNoteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateCustomerNoteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteCustomerNoteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerNoteMutationInput>>>;
    };

    export type CustomerNoteMutationPayload = {
        __typename?: 'CustomerNoteMutationPayload';
        CustomerNote?: Maybe<CustomerNote>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkCustomerNoteMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Customer>;
        viewer?: Maybe<Viewer>;
    };

    export type CustomerStaffCustomersAddedSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        billAddress?: InputMaybe<CustomerStaffCustomersAddedSearchBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        primaryAddress?: InputMaybe<CustomerStaffCustomersAddedSearchPrimaryAddress>;
        tags?: InputMaybe<CustomerStaffCustomersAddedSearchTags>;
        user?: InputMaybe<CustomerStaffCustomersAddedSearchUser>;
    };

    export type CustomerStaffCustomersAddedSearchBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntity = {
        addresses?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPools>;
        serviceJob?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJob>;
        sites?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsReports>;
        sanitiser?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiser>;
        site?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSite>;
        type?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSite = {
        address?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJob = {
        address?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobWorkOrders>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSite>;
        type?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSite = {
        address?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomerStaffCustomersAddedSearchUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntitySites = {
        address?: InputMaybe<CustomerStaffCustomersAddedSearchUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSearchUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerStaffCustomersAddedSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        contactName?: InputMaybe<Scalars['Boolean']>;
        crn?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        firstName?: InputMaybe<Scalars['Boolean']>;
        lastName?: InputMaybe<Scalars['Boolean']>;
    };

    export type CustomerTag = Node & {
        __typename?: 'CustomerTag';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<CustomerTagMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        description?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
    };

    export type CustomerTagCustomerTagsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomerTagCustomerTagsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **CustomerTag** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
    };

    export type CustomerTagMutationCheck = {
        __typename?: 'CustomerTagMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type CustomerTagMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateCustomerTagMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteCustomerTagMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerTagMutationInput>>>;
    };

    export type CustomerTagMutationPayload = {
        __typename?: 'CustomerTagMutationPayload';
        CustomerTag?: Maybe<CustomerTag>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkCustomerTagMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type CustomerTagViewerCustomerTagsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomerTagViewerCustomerTagsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **CustomerTag** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
    };

    export type CustomerViewerCustomersSearch = {
        addedBy?: InputMaybe<CustomerViewerCustomersSearchAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<CustomerViewerCustomersSearchBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        primaryAddress?: InputMaybe<CustomerViewerCustomersSearchPrimaryAddress>;
        tags?: InputMaybe<CustomerViewerCustomersSearchTags>;
        user?: InputMaybe<CustomerViewerCustomersSearchUser>;
    };

    export type CustomerViewerCustomersSearchAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomerViewerCustomersSearchAddedByRegistration>;
        teams?: InputMaybe<CustomerViewerCustomersSearchAddedByTeams>;
        user?: InputMaybe<CustomerViewerCustomersSearchAddedByUser>;
    };

    export type CustomerViewerCustomersSearchAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntity = {
        addresses?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPools>;
        serviceJob?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJob>;
        sites?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSite>;
        type?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSite = {
        address?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJob = {
        address?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobWorkOrders>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchAddedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntitySites = {
        address?: InputMaybe<CustomerViewerCustomersSearchAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<CustomerViewerCustomersSearchUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<CustomerViewerCustomersSearchUserStaff>;
    };

    export type CustomerViewerCustomersSearchUserEntity = {
        addresses?: InputMaybe<CustomerViewerCustomersSearchUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<CustomerViewerCustomersSearchUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<CustomerViewerCustomersSearchUserEntityPools>;
        serviceJob?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJob>;
        sites?: InputMaybe<CustomerViewerCustomersSearchUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsReports>;
        sanitiser?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiser>;
        site?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSite>;
        type?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSite = {
        address?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJob = {
        address?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobWorkOrders>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSite>;
        type?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSite = {
        address?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type CustomerViewerCustomersSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type CustomerViewerCustomersSearchUserEntitySites = {
        address?: InputMaybe<CustomerViewerCustomersSearchUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSearchUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<CustomerViewerCustomersSearchUserStaffRegistration>;
        teams?: InputMaybe<CustomerViewerCustomersSearchUserStaffTeams>;
    };

    export type CustomerViewerCustomersSearchUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type CustomerViewerCustomersSearchUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type CustomerViewerCustomersSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        contactName?: InputMaybe<Scalars['Boolean']>;
        crn?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        firstName?: InputMaybe<Scalars['Boolean']>;
        lastName?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type CustomersAddedForStaffToCustomerConnection = {
        __typename?: 'CustomersAddedForStaffToCustomerConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CustomersAddedForStaffToCustomerEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<CustomersAddedForStaffToCustomerMeta>;
    };

    /** An edge in a connection. */
    export type CustomersAddedForStaffToCustomerEdge = {
        __typename?: 'CustomersAddedForStaffToCustomerEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Customer>;
    };

    export type CustomersAddedForStaffToCustomerMeta = {
        __typename?: 'CustomersAddedForStaffToCustomerMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type CustomersForVendAPIToVendCustomerConnection = {
        __typename?: 'CustomersForVendAPIToVendCustomerConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<CustomersForVendAPIToVendCustomerEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<CustomersForVendAPIToVendCustomerMeta>;
    };

    /** An edge in a connection. */
    export type CustomersForVendAPIToVendCustomerEdge = {
        __typename?: 'CustomersForVendAPIToVendCustomerEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<VendCustomer>;
    };

    export type CustomersForVendAPIToVendCustomerMeta = {
        __typename?: 'CustomersForVendAPIToVendCustomerMeta';
        totalCount?: Maybe<Scalars['Int']>;
    };

    export enum DateRangeResolutionEnum {
        Day = 'Day',
        Month = 'Month',
        Quarter = 'Quarter',
        Week = 'Week',
        Year = 'Year',
    }

    export type DateRangeSearchCommon = {
        after?: InputMaybe<Scalars['Date']>;
        before?: InputMaybe<Scalars['Date']>;
        is?: InputMaybe<Scalars['Date']>;
    };

    /** A connection to a list of items. */
    export type DeclarationsForProductSearchHelperToProductTraitDeclarationConnection = {
        __typename?: 'DeclarationsForProductSearchHelperToProductTraitDeclarationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<DeclarationsForProductSearchHelperToProductTraitDeclarationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<DeclarationsForProductSearchHelperToProductTraitDeclarationMeta>;
    };

    /** An edge in a connection. */
    export type DeclarationsForProductSearchHelperToProductTraitDeclarationEdge = {
        __typename?: 'DeclarationsForProductSearchHelperToProductTraitDeclarationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductTraitDeclaration>;
    };

    export type DeclarationsForProductSearchHelperToProductTraitDeclarationMeta = {
        __typename?: 'DeclarationsForProductSearchHelperToProductTraitDeclarationMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type DeclarationsForProductToProductTraitDeclarationConnection = {
        __typename?: 'DeclarationsForProductToProductTraitDeclarationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<DeclarationsForProductToProductTraitDeclarationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<DeclarationsForProductToProductTraitDeclarationMeta>;
    };

    /** An edge in a connection. */
    export type DeclarationsForProductToProductTraitDeclarationEdge = {
        __typename?: 'DeclarationsForProductToProductTraitDeclarationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductTraitDeclaration>;
    };

    export type DeclarationsForProductToProductTraitDeclarationMeta = {
        __typename?: 'DeclarationsForProductToProductTraitDeclarationMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type DeleteAbstractDocumentMutationInput = {
        /** Expected ID of Node: AbstractDocument */
        id: Scalars['ID'];
    };

    export type DeleteAnnouncementMutationInput = {
        /** Expected ID of Node: Announcement */
        id: Scalars['ID'];
    };

    export type DeleteAppointmentGroupMutationInput = {
        /** Expected ID of Node: AppointmentGroup */
        id: Scalars['ID'];
    };

    export type DeleteAppointmentInstanceMutationInput = {
        /** Expected ID of Node: AppointmentInstance */
        id: Scalars['ID'];
    };

    export type DeleteAppointmentMutationInput = {
        /** Expected ID of Node: Appointment */
        id: Scalars['ID'];
    };

    export type DeleteBrandMutationInput = {
        /** Expected ID of Node: Brand */
        id: Scalars['ID'];
    };

    export type DeleteConsumableProductMutationInput = {
        /** Expected ID of Node: ConsumableProduct */
        id: Scalars['ID'];
    };

    export type DeleteContactMutationInput = {
        /** Expected ID of Node: Contact */
        id: Scalars['ID'];
    };

    export type DeleteCustomContractMutationInput = {
        /** Expected ID of Node: CustomContract */
        id: Scalars['ID'];
    };

    export type DeleteCustomerMutationInput = {
        /** Expected ID of Node: Customer */
        id: Scalars['ID'];
    };

    export type DeleteCustomerNoteMutationInput = {
        /** Expected ID of Node: CustomerNote */
        id: Scalars['ID'];
    };

    export type DeleteCustomerTagMutationInput = {
        /** Expected ID of Node: CustomerTag */
        id: Scalars['ID'];
    };

    export type DeleteEZCDeviceLinkMutationInput = {
        /** Expected ID of Node: EZCDeviceLink */
        id: Scalars['ID'];
    };

    export type DeleteEZCFranchiseLinkMutationInput = {
        /** Expected ID of Node: EZCFranchiseLink */
        id: Scalars['ID'];
    };

    export type DeleteEmailGlobalSelectionMutationInput = {
        /** Expected ID of Node: EmailGlobalSelection */
        id: Scalars['ID'];
    };

    export type DeleteEmailTemplateMutationInput = {
        /** Expected ID of Node: EmailTemplate */
        id: Scalars['ID'];
    };

    export type DeleteEmailTemplateOrganisationSelectionMutationInput = {
        /** Expected ID of Node: EmailTemplateOrganisationSelection */
        id: Scalars['ID'];
    };

    export type DeleteEmailTemplateSelectionMutationInput = {
        /** Expected ID of Node: EmailTemplateSelection */
        id: Scalars['ID'];
    };

    export type DeleteFeatureFlagMutationInput = {
        /** Expected ID of Node: FeatureFlag */
        id: Scalars['ID'];
    };

    export type DeleteFeatureFlagVariationMutationInput = {
        /** Expected ID of Node: FeatureFlagVariation */
        id: Scalars['ID'];
    };

    export type DeleteFileUploadMutationInput = {
        /** Expected ID of Node: FileUpload */
        id: Scalars['ID'];
    };

    export type DeleteFranchiseMutationInput = {
        /** Expected ID of Node: Franchise */
        id: Scalars['ID'];
    };

    export type DeleteFranchiseShopMutationInput = {
        /** Expected ID of Node: FranchiseShop */
        id: Scalars['ID'];
    };

    export type DeleteFranchiseValueStoreMutationInput = {
        /** Expected ID of Node: FranchiseValueStore */
        id: Scalars['ID'];
    };

    export type DeleteFranchiseWarranterMutationInput = {
        /** Expected ID of Node: FranchiseWarranter */
        id: Scalars['ID'];
    };

    export type DeleteHeaterRecommendationMutationInput = {
        /** Expected ID of Node: HeaterRecommendation */
        id: Scalars['ID'];
    };

    export type DeleteInstallableProductMutationInput = {
        /** Expected ID of Node: InstallableProduct */
        id: Scalars['ID'];
    };

    export type DeleteItemMutationInput = {
        /** Expected ID of Node: Item */
        id: Scalars['ID'];
    };

    export type DeleteJobTodoItemMutationInput = {
        /** Expected ID of Node: JobTodoItem */
        id: Scalars['ID'];
    };

    export type DeleteJobTodoMutationInput = {
        /** Expected ID of Node: JobTodo */
        id: Scalars['ID'];
    };

    export type DeleteJobTodoTemplateAssocMutationInput = {
        /** Expected ID of Node: JobTodoTemplateAssoc */
        id: Scalars['ID'];
    };

    export type DeleteJobTodoTemplateItemMutationInput = {
        /** Expected ID of Node: JobTodoTemplateItem */
        id: Scalars['ID'];
    };

    export type DeleteJobTodoTemplateMutationInput = {
        /** Expected ID of Node: JobTodoTemplate */
        id: Scalars['ID'];
    };

    export type DeleteManualProblemMutationInput = {
        /** Expected ID of Node: ManualProblem */
        id: Scalars['ID'];
    };

    export type DeleteMeasurementMutationInput = {
        /** Expected ID of Node: Measurement */
        id: Scalars['ID'];
    };

    export type DeletePoolCalibrationMutationInput = {
        /** Expected ID of Node: PoolCalibration */
        id: Scalars['ID'];
    };

    export type DeletePoolDeclarationFlagFieldMutationInput = {
        /** Expected ID of Node: PoolDeclarationFlagField */
        id: Scalars['ID'];
    };

    export type DeletePoolMutationInput = {
        /** Expected ID of Node: Pool */
        id: Scalars['ID'];
    };

    export type DeletePoolNoteMutationInput = {
        /** Expected ID of Node: PoolNote */
        id: Scalars['ID'];
    };

    export type DeletePoolProductDeclarationMutationInput = {
        /** Expected ID of Node: PoolProductDeclaration */
        id: Scalars['ID'];
    };

    export type DeletePoolTypeMutationInput = {
        /** Expected ID of Node: PoolType */
        id: Scalars['ID'];
        /** Expects node: **PoolType** */
        replacement: Scalars['ID'];
    };

    export type DeleteProductCompanyMutationInput = {
        /** Expected ID of Node: ProductCompany */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationFlagFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationFlagField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationFloatFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationFloatField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationIntegerFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationIntegerField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationMeasurementFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationMeasurementField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationSelectionChoiceMutationInput = {
        /** Expected ID of Node: ProductDeclarationSelectionChoice */
        id: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        replacement?: InputMaybe<Scalars['ID']>;
    };

    export type DeleteProductDeclarationSelectionFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationSelectionField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationStringFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationStringField */
        id: Scalars['ID'];
    };

    export type DeleteProductDeclarationUnitFieldMutationInput = {
        /** Expected ID of Node: ProductDeclarationUnitField */
        id: Scalars['ID'];
    };

    export type DeleteProductDocumentMutationInput = {
        /** Expected ID of Node: ProductDocument */
        id: Scalars['ID'];
    };

    export type DeleteProductMutationInput = {
        /** Expected ID of Node: Product */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitDeclarationMutationInput = {
        /** Expected ID of Node: ProductTraitDeclaration */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitFlagValueMutationInput = {
        /** Expected ID of Node: ProductTraitFlagValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitFloatValueMutationInput = {
        /** Expected ID of Node: ProductTraitFloatValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitIntegerValueMutationInput = {
        /** Expected ID of Node: ProductTraitIntegerValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitMeasurementValueMutationInput = {
        /** Expected ID of Node: ProductTraitMeasurementValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitMutationInput = {
        /** Expected ID of Node: ProductTrait */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitSelectionValueMutationInput = {
        /** Expected ID of Node: ProductTraitSelectionValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitStringValueMutationInput = {
        /** Expected ID of Node: ProductTraitStringValue */
        id: Scalars['ID'];
    };

    export type DeleteProductTraitUnitValueMutationInput = {
        /** Expected ID of Node: ProductTraitUnitValue */
        id: Scalars['ID'];
    };

    export type DeleteProjectMutationInput = {
        /** Expected ID of Node: Project */
        id: Scalars['ID'];
    };

    export type DeleteProjectVersionMutationInput = {
        /** Expected ID of Node: ProjectVersion */
        id: Scalars['ID'];
    };

    export type DeleteRoleFlagMutationInput = {
        /** Expected ID of Node: RoleFlag */
        id: Scalars['ID'];
    };

    export type DeleteSampleConverterMutationInput = {
        /** Expected ID of Node: SampleConverter */
        id: Scalars['ID'];
    };

    export type DeleteSampleConverterTemplateMutationInput = {
        /** Expected ID of Node: SampleConverterTemplate */
        id: Scalars['ID'];
    };

    export type DeleteSampleMutationInput = {
        /** Expected ID of Node: Sample */
        id: Scalars['ID'];
    };

    export type DeleteSanitiserMutationInput = {
        /** Expected ID of Node: Sanitiser */
        id: Scalars['ID'];
        /** Expects node: **Sanitiser** */
        replacement: Scalars['ID'];
    };

    export type DeleteScheduleEventMutationInput = {
        /** Expected ID of Node: ScheduleEvent */
        id: Scalars['ID'];
    };

    export type DeleteServiceJobGroupMutationInput = {
        /** Expected ID of Node: ServiceJobGroup */
        id: Scalars['ID'];
    };

    export type DeleteServiceJobMutationInput = {
        /** Expected ID of Node: ServiceJob */
        id: Scalars['ID'];
        keepAppointments?: InputMaybe<Scalars['Boolean']>;
    };

    export type DeleteServiceJobNoteMutationInput = {
        /** Expected ID of Node: ServiceJobNote */
        id: Scalars['ID'];
    };

    export type DeleteServiceJobStageMutationInput = {
        /** Expected ID of Node: ServiceJobStage */
        id: Scalars['ID'];
    };

    export type DeleteServiceJobTemplateMutationInput = {
        /** Expected ID of Node: ServiceJobTemplate */
        id: Scalars['ID'];
    };

    export type DeleteSettingStoreMutationInput = {
        /** Expected ID of Node: SettingStore */
        id: Scalars['ID'];
    };

    export type DeleteSiteMutationInput = {
        /** Expected ID of Node: Site */
        id: Scalars['ID'];
    };

    export type DeleteSiteNoteMutationInput = {
        /** Expected ID of Node: SiteNote */
        id: Scalars['ID'];
    };

    export type DeleteStaffAccessTokenMutationInput = {
        /** Expected ID of Node: StaffAccessToken */
        id: Scalars['ID'];
    };

    export type DeleteStaffRegistrationMutationInput = {
        /** Expected ID of Node: StaffRegistration */
        id: Scalars['ID'];
    };

    export type DeleteStaffTeamLinkMutationInput = {
        /** Expected ID of Node: StaffTeamLink */
        id: Scalars['ID'];
    };

    export type DeleteStaffTeamMutationInput = {
        /** Expected ID of Node: StaffTeam */
        id: Scalars['ID'];
    };

    export type DeleteStaffValueStoreMutationInput = {
        /** Expected ID of Node: StaffValueStore */
        id: Scalars['ID'];
    };

    export type DeleteTestCriteriaMutationInput = {
        /** Expected ID of Node: TestCriteria */
        id: Scalars['ID'];
    };

    export type DeleteTestProblemMutationInput = {
        /** Expected ID of Node: TestProblem */
        id: Scalars['ID'];
    };

    export type DeleteTestReportAttachmentMutationInput = {
        /** Expected ID of Node: TestReportAttachment */
        id: Scalars['ID'];
    };

    export type DeleteTestReportMutationInput = {
        deleteSamples?: InputMaybe<Scalars['Boolean']>;
        /** Expected ID of Node: TestReport */
        id: Scalars['ID'];
        removeArchived?: InputMaybe<Scalars['Boolean']>;
    };

    export type DeleteTestReportSolutionMutationInput = {
        /** Expected ID of Node: TestReportSolution */
        id: Scalars['ID'];
    };

    export type DeleteTestTreatmentFranchiseGroupMutationInput = {
        /** Expected ID of Node: TestTreatmentFranchiseGroup */
        id: Scalars['ID'];
    };

    export type DeleteTestTreatmentFranchisePriorityMutationInput = {
        /** Expected ID of Node: TestTreatmentFranchisePriority */
        id: Scalars['ID'];
    };

    export type DeleteTestTreatmentGroupMutationInput = {
        /** Expected ID of Node: TestTreatmentGroup */
        id: Scalars['ID'];
    };

    export type DeleteTestTreatmentMutationInput = {
        /** Expected ID of Node: TestTreatment */
        id: Scalars['ID'];
    };

    export type DeleteTreatmentAffectMutationInput = {
        /** Expected ID of Node: TreatmentAffect */
        id: Scalars['ID'];
    };

    export type DeleteUnitConversionMutationInput = {
        /** Expected ID of Node: UnitConversion */
        id: Scalars['ID'];
    };

    export type DeleteUnitMutationInput = {
        /** Expected ID of Node: Unit */
        id: Scalars['ID'];
    };

    export type DeleteUniversalCriteriaMutationInput = {
        /** Expected ID of Node: UniversalCriteria */
        id: Scalars['ID'];
    };

    export type DeleteVendAPIMutationInput = {
        /** Expected ID of Node: VendAPI */
        id: Scalars['ID'];
    };

    export type DeleteWarrantyClaimCaseMutationInput = {
        /** Expected ID of Node: WarrantyClaimCase */
        id: Scalars['ID'];
    };

    export type DeleteWarrantyClaimFileAttachMutationInput = {
        /** Expected ID of Node: WarrantyClaimFileAttach */
        id: Scalars['ID'];
    };

    export type DeleteWarrantyClaimMessageMutationInput = {
        /** Expected ID of Node: WarrantyClaimMessage */
        id: Scalars['ID'];
    };

    export type DeleteWarrantyClaimMutationInput = {
        /** Expected ID of Node: WarrantyClaim */
        id: Scalars['ID'];
    };

    export type DeleteWorkOrderMutationInput = {
        /** Expected ID of Node: WorkOrder */
        id: Scalars['ID'];
        keepAppointment?: InputMaybe<Scalars['Boolean']>;
        keepImplicit?: InputMaybe<Scalars['Boolean']>;
    };

    export type DeleteWorkOrderNoteMutationInput = {
        /** Expected ID of Node: WorkOrderNote */
        id: Scalars['ID'];
    };

    export type DeleteWorkOrderTemplateAssocMutationInput = {
        /** Expected ID of Node: WorkOrderTemplateAssoc */
        id: Scalars['ID'];
    };

    export type DeleteWorkOrderTemplateMutationInput = {
        /** Expected ID of Node: WorkOrderTemplate */
        id: Scalars['ID'];
    };

    /** A connection to a list of items. */
    export type DiscoveredProblemsForTestReportToTestProblemConnection = {
        __typename?: 'DiscoveredProblemsForTestReportToTestProblemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<DiscoveredProblemsForTestReportToTestProblemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type DiscoveredProblemsForTestReportToTestProblemEdge = {
        __typename?: 'DiscoveredProblemsForTestReportToTestProblemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestProblem>;
    };

    /** A connection to a list of items. */
    export type DocumentsForProductToProductDocumentConnection = {
        __typename?: 'DocumentsForProductToProductDocumentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<DocumentsForProductToProductDocumentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<DocumentsForProductToProductDocumentMeta>;
    };

    /** An edge in a connection. */
    export type DocumentsForProductToProductDocumentEdge = {
        __typename?: 'DocumentsForProductToProductDocumentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductDocument>;
    };

    export type DocumentsForProductToProductDocumentMeta = {
        __typename?: 'DocumentsForProductToProductDocumentMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type EZCDevice = Node & {
        __typename?: 'EZCDevice';
        createdAt?: Maybe<Scalars['Date']>;
        definition?: Maybe<EZCDeviceDefinition>;
        deviceId?: Maybe<Scalars['String']>;
        events?: Maybe<Array<Maybe<EZCDeviceEvent>>>;
        ezcId?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        link?: Maybe<EZCDeviceLink>;
        title?: Maybe<Scalars['String']>;
        version?: Maybe<Scalars['String']>;
    };

    export type EZCDeviceEventsArgs = {
        after?: InputMaybe<Scalars['ID']>;
        limit?: InputMaybe<Scalars['Int']>;
        scope?: InputMaybe<Scalars['String']>;
    };

    export type EZCDeviceLinkArgs = {
        franchise?: InputMaybe<Scalars['ID']>;
    };

    export type EZCDeviceDefinition = Node & {
        __typename?: 'EZCDeviceDefinition';
        definitionId?: Maybe<Scalars['String']>;
        definitionVersion?: Maybe<Scalars['String']>;
        familyId?: Maybe<Scalars['String']>;
        familyVariation?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        openAPIURL?: Maybe<Scalars['String']>;
        presentationId?: Maybe<Scalars['String']>;
        setupStrategyType?: Maybe<Scalars['String']>;
    };

    export type EZCDeviceEvent = Node & {
        __typename?: 'EZCDeviceEvent';
        forDateAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        message?: Maybe<Scalars['String']>;
        messageContentType?: Maybe<Scalars['String']>;
        triggerValue?: Maybe<Scalars['String']>;
        type?: Maybe<EZCDeviceEventType>;
    };

    export type EZCDeviceEventType = {
        __typename?: 'EZCDeviceEventType';
        description?: Maybe<Scalars['String']>;
        descriptionContentType?: Maybe<Scalars['String']>;
        importance?: Maybe<Scalars['String']>;
        name?: Maybe<Scalars['String']>;
        presentationTag?: Maybe<Scalars['String']>;
        tag?: Maybe<Scalars['String']>;
    };

    export type EZCDeviceLink = Node & {
        __typename?: 'EZCDeviceLink';
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<EZCDeviceLinkMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        device?: Maybe<EZCDevice>;
        deviceName?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        item?: Maybe<Item>;
        label?: Maybe<Scalars['String']>;
        owner?: Maybe<Customer>;
        pool?: Maybe<Pool>;
        product?: Maybe<Product>;
        site?: Maybe<Site>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type EZCDeviceLinkCustomerEzcDeviceLinksSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        device?: InputMaybe<EZCDeviceNodeMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        product?: InputMaybe<ProductNodeMatchSearchCommon>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
    };

    export type EZCDeviceLinkCustomerEzcDeviceLinksSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EZCDeviceLink** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EZCDeviceLinkMutationCheck = {
        __typename?: 'EZCDeviceLinkMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type EZCDeviceLinkMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateEZCDeviceLinkMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEZCDeviceLinkMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateEZCDeviceLinkMutationInput>>>;
    };

    export type EZCDeviceLinkMutationPayload = {
        __typename?: 'EZCDeviceLinkMutationPayload';
        EZCDeviceLink?: Maybe<EZCDeviceLink>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEZCDeviceLinkMutationOutput>>>;
        updatedDevice?: Maybe<EZCDevice>;
        updatedFranchise?: Maybe<Franchise>;
        updatedItem?: Maybe<Item>;
        updatedOwner?: Maybe<Customer>;
        updatedPool?: Maybe<Pool>;
        updatedProduct?: Maybe<Product>;
        updatedSite?: Maybe<Site>;
        viewer?: Maybe<Viewer>;
    };

    export type EZCDeviceLinkNodeMatchSearchCommon = {
        /** Expects node: **EZCDeviceLink** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EZCDeviceLink** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EZCDeviceLinkPoolEzcDeviceLinksSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        device?: InputMaybe<EZCDeviceNodeMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        owner?: InputMaybe<CustomerNodeMatchSearchCommon>;
        product?: InputMaybe<ProductNodeMatchSearchCommon>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
    };

    export type EZCDeviceLinkPoolEzcDeviceLinksSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EZCDeviceLink** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EZCDeviceLinkSiteEzcDeviceLinksSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        device?: InputMaybe<EZCDeviceNodeMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        owner?: InputMaybe<CustomerNodeMatchSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        product?: InputMaybe<ProductNodeMatchSearchCommon>;
    };

    export type EZCDeviceLinkSiteEzcDeviceLinksSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EZCDeviceLink** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EZCDeviceLinkViewerEzcDeviceLinksSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        device?: InputMaybe<EZCDeviceNodeMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        owner?: InputMaybe<CustomerNodeMatchSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        product?: InputMaybe<ProductNodeMatchSearchCommon>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
    };

    export type EZCDeviceLinkViewerEzcDeviceLinksSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EZCDeviceLink** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EZCDeviceMetricsBundle = {
        __typename?: 'EZCDeviceMetricsBundle';
        data?: Maybe<Scalars['JSON']>;
        from?: Maybe<Scalars['Float']>;
        to?: Maybe<Scalars['Float']>;
        types?: Maybe<Array<Maybe<EZCDeviceMetricsEntryType>>>;
    };

    export type EZCDeviceMetricsEntryType = {
        __typename?: 'EZCDeviceMetricsEntryType';
        maxValue?: Maybe<Scalars['Float']>;
        minValue?: Maybe<Scalars['Float']>;
        name?: Maybe<Scalars['String']>;
        tag?: Maybe<Scalars['String']>;
        unit?: Maybe<Scalars['String']>;
    };

    export type EZCDeviceNodeMatchSearchCommon = {
        /** Expects node: **EZCDevice** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EZCDevice** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EZCDeviceSchema = {
        __typename?: 'EZCDeviceSchema';
        components?: Maybe<Scalars['JSON']>;
        openAPI3Schema?: Maybe<Scalars['JSON']>;
    };

    export type EZCDeviceState = {
        __typename?: 'EZCDeviceState';
        definitionId?: Maybe<Scalars['String']>;
        device?: Maybe<EZCDevice>;
        state?: Maybe<Scalars['JSON']>;
    };

    export type EZCDeviceStateMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        update?: InputMaybe<Array<InputMaybe<EZCDeviceStateUpdateMutationInput>>>;
    };

    export type EZCDeviceStateMutationPayload = {
        __typename?: 'EZCDeviceStateMutationPayload';
        EZCDeviceState?: Maybe<EZCDeviceState>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEZCDeviceStateMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type EZCDeviceStateUpdateMutationInput = {
        /** Expects node: **EZCDevice** */
        device: Scalars['ID'];
        input: Scalars['JSON'];
        scope: Scalars['String'];
    };

    export type EZCFranchiseLink = Node & {
        __typename?: 'EZCFranchiseLink';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<EZCFranchiseLinkMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        label?: Maybe<Scalars['String']>;
        serviceProvider?: Maybe<EZCServiceProvider>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type EZCFranchiseLinkLinkMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **EZCServiceProvider** */
        serviceProvider: Scalars['ID'];
    };

    export type EZCFranchiseLinkMutationCheck = {
        __typename?: 'EZCFranchiseLinkMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type EZCFranchiseLinkMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEZCFranchiseLinkMutationInput>>>;
        link?: InputMaybe<Array<InputMaybe<EZCFranchiseLinkLinkMutationInput>>>;
    };

    export type EZCFranchiseLinkMutationPayload = {
        __typename?: 'EZCFranchiseLinkMutationPayload';
        EZCFranchiseLink?: Maybe<EZCFranchiseLink>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEZCFranchiseLinkMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceProvider?: Maybe<EZCServiceProvider>;
        viewer?: Maybe<Viewer>;
    };

    export type EZCFranchiseLinkViewerEzcServiceProviderLinksSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        serviceProvider?: InputMaybe<EZCServiceProviderNodeMatchSearchCommon>;
    };

    export type EZCFranchiseLinkViewerEzcServiceProviderLinksSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EZCFranchiseLink** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type EZCServiceProvider = Node & {
        __typename?: 'EZCServiceProvider';
        connectedFranchises?: Maybe<ConnectedFranchisesForEZCServiceProviderToFranchiseConnection>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type EZCServiceProviderConnectedFranchisesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type EZCServiceProviderNodeMatchSearchCommon = {
        /** Expects node: **EZCServiceProvider** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EZCServiceProvider** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailContact = Node & {
        __typename?: 'EmailContact';
        baseContact?: Maybe<Contact>;
        createdAt?: Maybe<Scalars['Date']>;
        data?: Maybe<Scalars['String']>;
        entity?: Maybe<Entity>;
        id: Scalars['ID'];
        isPrimary?: Maybe<Scalars['Boolean']>;
        type?: Maybe<ContactType>;
    };

    export type EmailContactEntityEmailsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        type?: InputMaybe<EmailContactEntityEmailsSearchType>;
    };

    export type EmailContactEntityEmailsSearchType = {
        /** Expects node: **ContactType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ContactType** */
        not?: InputMaybe<Scalars['ID']>;
        validatorTag?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailContactEntityEmailsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        data?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EmailContact** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EmailGlobalSelection = Node & {
        __typename?: 'EmailGlobalSelection';
        checkMutations?: Maybe<EmailGlobalSelectionMutationCheck>;
        id: Scalars['ID'];
        template?: Maybe<EmailTemplate>;
        type?: Maybe<EmailType>;
    };

    export type EmailGlobalSelectionMutationCheck = {
        __typename?: 'EmailGlobalSelectionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type EmailGlobalSelectionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEmailGlobalSelectionMutationInput>>>;
    };

    export type EmailGlobalSelectionMutationPayload = {
        __typename?: 'EmailGlobalSelectionMutationPayload';
        EmailGlobalSelection?: Maybe<EmailGlobalSelection>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEmailGlobalSelectionMutationOutput>>>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
        viewer?: Maybe<Viewer>;
    };

    export type EmailGlobalSelectionViewerEmailGlobalTemplateSelectionsSearch = {
        template?: InputMaybe<EmailTemplateNodeMatchSearchCommon>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailMessage = Node & {
        __typename?: 'EmailMessage';
        claim?: Maybe<WarrantyClaim>;
        createdAt?: Maybe<Scalars['Date']>;
        emailCC?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        isSent?: Maybe<Scalars['Boolean']>;
        mailTo?: Maybe<Entity>;
        messageBody?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        previewBody?: Maybe<Scalars['String']>;
        /** Array size expected to be from 1 to 20 */
        recipientEmail?: Maybe<Array<Maybe<Scalars['String']>>>;
        replyTo?: Maybe<Scalars['String']>;
        report?: Maybe<TestReport>;
        subject?: Maybe<Scalars['String']>;
        submittedBy?: Maybe<Entity>;
        template?: Maybe<EmailTemplate>;
        type?: Maybe<EmailType>;
    };

    export type EmailMessageCreateForReportAndSendMutationInput = {
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        mailTo?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        recipient: Scalars['String'];
        /** Expects node: **TestReport** */
        report: Scalars['ID'];
    };

    export type EmailMessageEntityEmailMessagesSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        report?: InputMaybe<TestReportNodeMatchSearchCommon>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageEntityEmailMessagesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EmailMessage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EmailMessageMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        createForReportAndSend?: InputMaybe<Array<InputMaybe<EmailMessageCreateForReportAndSendMutationInput>>>;
    };

    export type EmailMessageMutationPayload = {
        __typename?: 'EmailMessageMutationPayload';
        EmailMessage?: Maybe<EmailMessage>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEmailMessageMutationOutput>>>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedMailTo?: Maybe<Entity>;
        updatedReport?: Maybe<TestReport>;
        updatedSubmittedBy?: Maybe<Entity>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
        viewer?: Maybe<Viewer>;
    };

    export type EmailMessageTestReportRelatedEmailsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        mailTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        submittedBy?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedBy>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedBy = {
        addresses?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPools>;
        serviceJob?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJob>;
        sites?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedBySites>;
        user?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiser>;
        site?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSite>;
        type?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarations>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSite = {
        address?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblem>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJob = {
        address?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobWorkOrders>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomer = {
        addedBy?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerTags>;
        user?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUser>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByUser>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaff>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaffTeams>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolOwnershipHistory>;
        sanitiser?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiser>;
        site?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSite>;
        type?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSite = {
        address?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedBySites = {
        address?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedBySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedBySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaff>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomer = {
        addedBy?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerTags>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedByRegistration>;
        teams?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedByTeams>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaffRegistration>;
        teams?: InputMaybe<EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaffTeams>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageTestReportRelatedEmailsSearchSubmittedByUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageTestReportRelatedEmailsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EmailMessage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EmailMessageViewerSentEmailsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        mailTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        report?: InputMaybe<TestReportNodeMatchSearchCommon>;
        submittedBy?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedBy>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedBy = {
        addresses?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPools>;
        serviceJob?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJob>;
        sites?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedBySites>;
        user?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsOwnershipHistory>;
        reports?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsReports>;
        sanitiser?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiser>;
        site?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSite>;
        type?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarations>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSite = {
        address?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblem>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJob = {
        address?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobWorkOrders>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomer = {
        addedBy?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerTags>;
        user?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUser>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByUser>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaff>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaffTeams>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolReports>;
        sanitiser?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiser>;
        site?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSite>;
        type?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSite = {
        address?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type EmailMessageViewerSentEmailsSearchSubmittedByServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedBySites = {
        address?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedBySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedBySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserStaff>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomer = {
        addedBy?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerTags>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedByRegistration>;
        teams?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedByTeams>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserStaffRegistration>;
        teams?: InputMaybe<EmailMessageViewerSentEmailsSearchSubmittedByUserStaffTeams>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailMessageViewerSentEmailsSearchSubmittedByUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EmailMessageViewerSentEmailsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EmailMessage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type EmailMessagesForEntityToEmailMessageConnection = {
        __typename?: 'EmailMessagesForEntityToEmailMessageConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EmailMessagesForEntityToEmailMessageEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EmailMessagesForEntityToEmailMessageMeta>;
    };

    /** An edge in a connection. */
    export type EmailMessagesForEntityToEmailMessageEdge = {
        __typename?: 'EmailMessagesForEntityToEmailMessageEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailMessage>;
    };

    export type EmailMessagesForEntityToEmailMessageMeta = {
        __typename?: 'EmailMessagesForEntityToEmailMessageMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type EmailTemplate = Node & {
        __typename?: 'EmailTemplate';
        checkMutations?: Maybe<EmailTemplateMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        isSelectedForOwnFranchise?: Maybe<Scalars['Boolean']>;
        isSelectedForOwnOrganisation?: Maybe<Scalars['Boolean']>;
        isSelectedGlobally?: Maybe<Scalars['Boolean']>;
        name?: Maybe<Scalars['String']>;
        organisation?: Maybe<OrganisationType>;
        preview?: Maybe<EmailTemplateMock>;
        provider?: Maybe<EmailTemplateProvider>;
        selectedForFranchises?: Maybe<Array<Maybe<Franchise>>>;
        selectedForOrganisations?: Maybe<Array<Maybe<OrganisationType>>>;
        templateBody?: Maybe<Scalars['String']>;
        templateMetadata?: Maybe<Scalars['JSON']>;
        type?: Maybe<EmailType>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type EmailTemplateMock = {
        __typename?: 'EmailTemplateMock';
        emailBody?: Maybe<Scalars['String']>;
        error?: Maybe<Scalars['String']>;
    };

    export type EmailTemplateMutationCheck = {
        __typename?: 'EmailTemplateMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        selectForFranchise?: Maybe<Scalars['Boolean']>;
        selectForOrganisation?: Maybe<Scalars['Boolean']>;
        selectGlobally?: Maybe<Scalars['Boolean']>;
        unselectForFranchise?: Maybe<Scalars['Boolean']>;
        unselectForOrganisation?: Maybe<Scalars['Boolean']>;
        unselectGlobally?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type EmailTemplateMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateEmailTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEmailTemplateMutationInput>>>;
        selectForFranchise?: InputMaybe<Array<InputMaybe<EmailTemplateSelectForFranchiseMutationInput>>>;
        selectForOrganisation?: InputMaybe<Array<InputMaybe<EmailTemplateSelectForOrganisationMutationInput>>>;
        selectGlobally?: InputMaybe<Array<InputMaybe<EmailTemplateSelectGloballyMutationInput>>>;
        unselectForFranchise?: InputMaybe<Array<InputMaybe<EmailTemplateUnselectForFranchiseMutationInput>>>;
        unselectForOrganisation?: InputMaybe<Array<InputMaybe<EmailTemplateUnselectForOrganisationMutationInput>>>;
        unselectGlobally?: InputMaybe<Array<InputMaybe<EmailTemplateUnselectGloballyMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateEmailTemplateMutationInput>>>;
    };

    export type EmailTemplateMutationPayload = {
        __typename?: 'EmailTemplateMutationPayload';
        EmailTemplate?: Maybe<EmailTemplate>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEmailTemplateMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedType?: Maybe<EmailType>;
        viewer?: Maybe<Viewer>;
    };

    export type EmailTemplateNodeMatchSearchCommon = {
        /** Expects node: **EmailTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailTemplate** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EmailTemplateOrganisationSelection = Node & {
        __typename?: 'EmailTemplateOrganisationSelection';
        checkMutations?: Maybe<EmailTemplateOrganisationSelectionMutationCheck>;
        id: Scalars['ID'];
        organisation?: Maybe<OrganisationType>;
        template?: Maybe<EmailTemplate>;
        type?: Maybe<EmailType>;
    };

    export type EmailTemplateOrganisationSelectionMutationCheck = {
        __typename?: 'EmailTemplateOrganisationSelectionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type EmailTemplateOrganisationSelectionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateEmailTemplateOrganisationSelectionMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEmailTemplateOrganisationSelectionMutationInput>>>;
    };

    export type EmailTemplateOrganisationSelectionMutationPayload = {
        __typename?: 'EmailTemplateOrganisationSelectionMutationPayload';
        EmailTemplateOrganisationSelection?: Maybe<EmailTemplateOrganisationSelection>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEmailTemplateOrganisationSelectionMutationOutput>>>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
        viewer?: Maybe<Viewer>;
    };

    export type EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSearch = {
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        template?: InputMaybe<EmailTemplateNodeMatchSearchCommon>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSort = {
        /** Expects node: **EmailTemplateOrganisationSelection** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export enum EmailTemplateProvider {
        EXTERNAL_PROVIDER = 'EXTERNAL_PROVIDER',
        HTML = 'HTML',
        UNLAYER = 'UNLAYER',
    }

    export type EmailTemplateSelectForFranchiseMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
    };

    export type EmailTemplateSelectForOrganisationMutationInput = {
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
    };

    export type EmailTemplateSelectGloballyMutationInput = {
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
    };

    export type EmailTemplateSelection = Node & {
        __typename?: 'EmailTemplateSelection';
        checkMutations?: Maybe<EmailTemplateSelectionMutationCheck>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        template?: Maybe<EmailTemplate>;
        type?: Maybe<EmailType>;
    };

    export type EmailTemplateSelectionMutationCheck = {
        __typename?: 'EmailTemplateSelectionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type EmailTemplateSelectionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteEmailTemplateSelectionMutationInput>>>;
        select?: InputMaybe<Array<InputMaybe<EmailTemplateSelectionSelectMutationInput>>>;
    };

    export type EmailTemplateSelectionMutationPayload = {
        __typename?: 'EmailTemplateSelectionMutationPayload';
        EmailTemplateSelection?: Maybe<EmailTemplateSelection>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEmailTemplateSelectionMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTemplate?: Maybe<EmailTemplate>;
        updatedType?: Maybe<EmailType>;
        viewer?: Maybe<Viewer>;
    };

    export type EmailTemplateSelectionSelectMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailTemplate** */
        template: Scalars['ID'];
    };

    export type EmailTemplateSelectionViewerEmailTemplateSelectionsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        template?: InputMaybe<EmailTemplateNodeMatchSearchCommon>;
        type?: InputMaybe<EmailTypeNodeMatchSearchCommon>;
    };

    export type EmailTemplateSelectionViewerEmailTemplateSelectionsSort = {
        /** Expects node: **EmailTemplateSelection** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type EmailTemplateUnselectForFranchiseMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
    };

    export type EmailTemplateUnselectForOrganisationMutationInput = {
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
        /** Expects node: **OrganisationType** */
        organisation: Scalars['ID'];
    };

    export type EmailTemplateUnselectGloballyMutationInput = {
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
    };

    export type EmailTemplateViewerEmailTemplatesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EmailTemplateViewerEmailTemplatesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **EmailTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type EmailType = Node & {
        __typename?: 'EmailType';
        defaultBody?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        mockTemplate?: Maybe<EmailTemplateMock>;
        name?: Maybe<Scalars['String']>;
        tagName?: Maybe<Scalars['String']>;
    };

    export type EmailTypeMockTemplateArgs = {
        template: Scalars['String'];
    };

    export type EmailTypeNodeMatchSearchCommon = {
        /** Expects node: **EmailType** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **EmailType** */
        not?: InputMaybe<Scalars['ID']>;
    };

    /** A connection to a list of items. */
    export type EmailsForEntityToEmailContactConnection = {
        __typename?: 'EmailsForEntityToEmailContactConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EmailsForEntityToEmailContactEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EmailsForEntityToEmailContactMeta>;
    };

    /** An edge in a connection. */
    export type EmailsForEntityToEmailContactEdge = {
        __typename?: 'EmailsForEntityToEmailContactEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailContact>;
    };

    export type EmailsForEntityToEmailContactMeta = {
        __typename?: 'EmailsForEntityToEmailContactMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type Entity = Node & {
        __typename?: 'Entity';
        active?: Maybe<Scalars['Boolean']>;
        addresses?: Maybe<AddressesForEntityToAddressConnection>;
        chainUpdate?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<EntityMutationCheck>;
        contacts?: Maybe<ContactsForEntityToContactConnection>;
        createdAt?: Maybe<Scalars['Date']>;
        customContracts?: Maybe<CustomContractsForEntityToCustomContractConnection>;
        dataOwnership?: Maybe<EntityOwnershipType>;
        emailMessages?: Maybe<EmailMessagesForEntityToEmailMessageConnection>;
        emails?: Maybe<EmailsForEntityToEmailContactConnection>;
        franchise?: Maybe<Franchise>;
        heaterRecommendations?: Maybe<HeaterRecommendationsForEntityToHeaterRecommendationConnection>;
        id: Scalars['ID'];
        items?: Maybe<ItemsForEntityToItemConnection>;
        lastReportActivity?: Maybe<Scalars['Date']>;
        pools?: Maybe<PoolsForEntityToPoolConnection>;
        previouslyOwnedPools?: Maybe<PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryConnection>;
        serviceJob?: Maybe<ServiceJob>;
        shop?: Maybe<FranchiseShop>;
        sites?: Maybe<SitesForEntityToSiteConnection>;
        transferGainedPools?: Maybe<TransferGainedPoolsForEntityToPoolOwnershipHistoryConnection>;
        user?: Maybe<User>;
        uuid?: Maybe<Scalars['String']>;
        warrantyClaim?: Maybe<WarrantyClaim>;
    };

    export type EntityAddressesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AddressEntityAddressesSearch>;
        sort?: InputMaybe<AddressEntityAddressesSort>;
    };

    export type EntityContactsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ContactEntityContactsSearch>;
        sort?: InputMaybe<ContactEntityContactsSort>;
    };

    export type EntityCustomContractsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomContractEntityCustomContractsSearch>;
        sort?: InputMaybe<CustomContractEntityCustomContractsSort>;
    };

    export type EntityEmailMessagesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailMessageEntityEmailMessagesSearch>;
        sort?: InputMaybe<EmailMessageEntityEmailMessagesSort>;
    };

    export type EntityEmailsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailContactEntityEmailsSearch>;
        sort?: InputMaybe<EmailContactEntityEmailsSort>;
    };

    export type EntityHeaterRecommendationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<HeaterRecommendationEntityHeaterRecommendationsSearch>;
        sort?: InputMaybe<HeaterRecommendationEntityHeaterRecommendationsSort>;
    };

    export type EntityItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ItemEntityItemsSearch>;
        sort?: InputMaybe<ItemEntityItemsSort>;
    };

    export type EntityPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolEntityPoolsSearch>;
        sort?: InputMaybe<PoolEntityPoolsSort>;
    };

    export type EntityPreviouslyOwnedPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearch>;
        sort?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSort>;
    };

    export type EntitySitesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SiteEntitySitesSearch>;
        sort?: InputMaybe<SiteEntitySitesSort>;
    };

    export type EntityTransferGainedPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearch>;
        sort?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSort>;
    };

    export type EntityMutationCheck = {
        __typename?: 'EntityMutationCheck';
        superDelete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type EntityMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        superDelete?: InputMaybe<Array<InputMaybe<EntitySuperDeleteMutationInput>>>;
    };

    export type EntityMutationPayload = {
        __typename?: 'EntityMutationPayload';
        Entity?: Maybe<Entity>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkEntityMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type EntityNodeMatchSearchCommon = {
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export enum EntityOwnershipType {
        Owned = 'Owned',
        SharedReadOnly = 'SharedReadOnly',
        SharedReadWrite = 'SharedReadWrite',
    }

    export type EntitySuperDeleteMutationInput = {
        /** Expects node: **Entity** */
        id: Scalars['ID'];
    };

    export type EntityViewerEntitiesSearch = {
        addresses?: InputMaybe<EntityViewerEntitiesSearchAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<EntityViewerEntitiesSearchContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        pools?: InputMaybe<EntityViewerEntitiesSearchPools>;
        serviceJob?: InputMaybe<EntityViewerEntitiesSearchServiceJob>;
        sites?: InputMaybe<EntityViewerEntitiesSearchSites>;
        user?: InputMaybe<EntityViewerEntitiesSearchUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EntityViewerEntitiesSearchPoolsOwnershipHistory>;
        reports?: InputMaybe<EntityViewerEntitiesSearchPoolsReports>;
        sanitiser?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiser>;
        site?: InputMaybe<EntityViewerEntitiesSearchPoolsSite>;
        type?: InputMaybe<EntityViewerEntitiesSearchPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<EntityViewerEntitiesSearchPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarations>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchPoolsSite = {
        address?: InputMaybe<EntityViewerEntitiesSearchPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblem>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJob = {
        address?: InputMaybe<EntityViewerEntitiesSearchServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<EntityViewerEntitiesSearchServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<EntityViewerEntitiesSearchServiceJobWorkOrders>;
    };

    export type EntityViewerEntitiesSearchServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomer = {
        addedBy?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerTags>;
        user?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerUser>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerAddedByUser>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerUserStaff>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<EntityViewerEntitiesSearchServiceJobCustomerUserStaffTeams>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolReports>;
        sanitiser?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiser>;
        site?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSite>;
        type?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSite = {
        address?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type EntityViewerEntitiesSearchSites = {
        address?: InputMaybe<EntityViewerEntitiesSearchSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type EntityViewerEntitiesSearchSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<EntityViewerEntitiesSearchUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<EntityViewerEntitiesSearchUserStaff>;
    };

    export type EntityViewerEntitiesSearchUserCustomer = {
        addedBy?: InputMaybe<EntityViewerEntitiesSearchUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<EntityViewerEntitiesSearchUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<EntityViewerEntitiesSearchUserCustomerPrimaryAddress>;
        tags?: InputMaybe<EntityViewerEntitiesSearchUserCustomerTags>;
    };

    export type EntityViewerEntitiesSearchUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EntityViewerEntitiesSearchUserCustomerAddedByRegistration>;
        teams?: InputMaybe<EntityViewerEntitiesSearchUserCustomerAddedByTeams>;
    };

    export type EntityViewerEntitiesSearchUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type EntityViewerEntitiesSearchUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<EntityViewerEntitiesSearchUserStaffRegistration>;
        teams?: InputMaybe<EntityViewerEntitiesSearchUserStaffTeams>;
    };

    export type EntityViewerEntitiesSearchUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type EntityViewerEntitiesSearchUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type EntriesForSampleMapToSampleConnection = {
        __typename?: 'EntriesForSampleMapToSampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EntriesForSampleMapToSampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EntriesForSampleMapToSampleMeta>;
    };

    /** An edge in a connection. */
    export type EntriesForSampleMapToSampleEdge = {
        __typename?: 'EntriesForSampleMapToSampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sample>;
    };

    export type EntriesForSampleMapToSampleMeta = {
        __typename?: 'EntriesForSampleMapToSampleMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type EzcDeviceLinksForCustomerToEZCDeviceLinkConnection = {
        __typename?: 'EzcDeviceLinksForCustomerToEZCDeviceLinkConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EzcDeviceLinksForCustomerToEZCDeviceLinkEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EzcDeviceLinksForCustomerToEZCDeviceLinkMeta>;
    };

    /** An edge in a connection. */
    export type EzcDeviceLinksForCustomerToEZCDeviceLinkEdge = {
        __typename?: 'EzcDeviceLinksForCustomerToEZCDeviceLinkEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDeviceLink>;
    };

    export type EzcDeviceLinksForCustomerToEZCDeviceLinkMeta = {
        __typename?: 'EzcDeviceLinksForCustomerToEZCDeviceLinkMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type EzcDeviceLinksForPoolToEZCDeviceLinkConnection = {
        __typename?: 'EzcDeviceLinksForPoolToEZCDeviceLinkConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EzcDeviceLinksForPoolToEZCDeviceLinkEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EzcDeviceLinksForPoolToEZCDeviceLinkMeta>;
    };

    /** An edge in a connection. */
    export type EzcDeviceLinksForPoolToEZCDeviceLinkEdge = {
        __typename?: 'EzcDeviceLinksForPoolToEZCDeviceLinkEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDeviceLink>;
    };

    export type EzcDeviceLinksForPoolToEZCDeviceLinkMeta = {
        __typename?: 'EzcDeviceLinksForPoolToEZCDeviceLinkMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type EzcDeviceLinksForSiteToEZCDeviceLinkConnection = {
        __typename?: 'EzcDeviceLinksForSiteToEZCDeviceLinkConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<EzcDeviceLinksForSiteToEZCDeviceLinkEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<EzcDeviceLinksForSiteToEZCDeviceLinkMeta>;
    };

    /** An edge in a connection. */
    export type EzcDeviceLinksForSiteToEZCDeviceLinkEdge = {
        __typename?: 'EzcDeviceLinksForSiteToEZCDeviceLinkEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDeviceLink>;
    };

    export type EzcDeviceLinksForSiteToEZCDeviceLinkMeta = {
        __typename?: 'EzcDeviceLinksForSiteToEZCDeviceLinkMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type FeatureFlag = Node & {
        __typename?: 'FeatureFlag';
        checkMutations?: Maybe<FeatureFlagMutationCheck>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        tag?: Maybe<Scalars['String']>;
        variations?: Maybe<Array<Maybe<FeatureFlagVariation>>>;
    };

    export type FeatureFlagMutationCheck = {
        __typename?: 'FeatureFlagMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FeatureFlagMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFeatureFlagMutationInput>>>;
        update?: InputMaybe<Array<InputMaybe<FeatureFlagUpdateMutationInput>>>;
    };

    export type FeatureFlagMutationPayload = {
        __typename?: 'FeatureFlagMutationPayload';
        FeatureFlag?: Maybe<FeatureFlag>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFeatureFlagMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type FeatureFlagUpdateMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **FeatureFlag** */
        id: Scalars['ID'];
    };

    export type FeatureFlagVariation = Node & {
        __typename?: 'FeatureFlagVariation';
        active?: Maybe<Scalars['Boolean']>;
        checkMutations?: Maybe<FeatureFlagVariationMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        organisation?: Maybe<OrganisationType>;
        role?: Maybe<Role>;
        tag?: Maybe<Scalars['String']>;
        value?: Maybe<Scalars['String']>;
    };

    export type FeatureFlagVariationMutationCheck = {
        __typename?: 'FeatureFlagVariationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FeatureFlagVariationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateFeatureFlagVariationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFeatureFlagVariationMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFeatureFlagVariationMutationInput>>>;
    };

    export type FeatureFlagVariationMutationPayload = {
        __typename?: 'FeatureFlagVariationMutationPayload';
        FeatureFlagVariation?: Maybe<FeatureFlagVariation>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFeatureFlagVariationMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedRole?: Maybe<Role>;
        viewer?: Maybe<Viewer>;
    };

    export type FeatureFlagVariationViewerFeatureFlagVariationsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        role?: InputMaybe<RoleNodeMatchSearchCommon>;
    };

    export type FeatureFlagVariationViewerFeatureFlagVariationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FeatureFlagVariation** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        tag?: InputMaybe<Scalars['Boolean']>;
    };

    export type FileUpload = Node & {
        __typename?: 'FileUpload';
        beforeAfterStatus?: Maybe<FileUploadBeforeAfterEnum>;
        checkMutations?: Maybe<FileUploadMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        fileName?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        imageUrl?: Maybe<Scalars['String']>;
        isImage?: Maybe<Scalars['Boolean']>;
        isPublic?: Maybe<Scalars['Boolean']>;
        location?: Maybe<Scalars['String']>;
        mimeType?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        uploadedBy?: Maybe<Staff>;
        url?: Maybe<Scalars['String']>;
    };

    export enum FileUploadBeforeAfterEnum {
        AFTER = 'AFTER',
        BEFORE = 'BEFORE',
    }

    export type FileUploadCustomContractFilesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        uploadedBy?: InputMaybe<FileUploadCustomContractFilesSearchUploadedBy>;
    };

    export type FileUploadCustomContractFilesSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByRegistration>;
        teams?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByTeams>;
        user?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUser>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserCustomer>;
        entity?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserCustomerTags>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntity = {
        addresses?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPools>;
        serviceJob?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobWorkOrders>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntitySites = {
        address?: InputMaybe<FileUploadCustomContractFilesSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadCustomContractFilesSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadCustomContractFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FileUploadMutationCheck = {
        __typename?: 'FileUploadMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FileUploadMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFileUploadMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFileUploadMutationInput>>>;
    };

    export type FileUploadMutationPayload = {
        __typename?: 'FileUploadMutationPayload';
        FileUpload?: Maybe<FileUpload>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFileUploadMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUploadedBy?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type FileUploadNodeMatchSearchCommon = {
        /** Expects node: **FileUpload** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FileUpload** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadPoolFilesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        uploadedBy?: InputMaybe<FileUploadPoolFilesSearchUploadedBy>;
    };

    export type FileUploadPoolFilesSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FileUploadPoolFilesSearchUploadedByRegistration>;
        teams?: InputMaybe<FileUploadPoolFilesSearchUploadedByTeams>;
        user?: InputMaybe<FileUploadPoolFilesSearchUploadedByUser>;
    };

    export type FileUploadPoolFilesSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadPoolFilesSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserCustomer>;
        entity?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserCustomerTags>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntity = {
        addresses?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobWorkOrders>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntitySites = {
        address?: InputMaybe<FileUploadPoolFilesSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadPoolFilesSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadPoolFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FileUploadServiceJobFilesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        uploadedBy?: InputMaybe<FileUploadServiceJobFilesSearchUploadedBy>;
    };

    export type FileUploadServiceJobFilesSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByRegistration>;
        teams?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByTeams>;
        user?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUser>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserCustomer>;
        entity?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserCustomerTags>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntity = {
        addresses?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPools>;
        sites?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntitySites = {
        address?: InputMaybe<FileUploadServiceJobFilesSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadServiceJobFilesSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadServiceJobFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FileUploadStaffFilesUploadedSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadStaffFilesUploadedSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FileUploadViewerFilesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        uploadedBy?: InputMaybe<FileUploadViewerFilesSearchUploadedBy>;
    };

    export type FileUploadViewerFilesSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FileUploadViewerFilesSearchUploadedByRegistration>;
        teams?: InputMaybe<FileUploadViewerFilesSearchUploadedByTeams>;
        user?: InputMaybe<FileUploadViewerFilesSearchUploadedByUser>;
    };

    export type FileUploadViewerFilesSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadViewerFilesSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserCustomer>;
        entity?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserCustomerTags>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntity = {
        addresses?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPools>;
        serviceJob?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobWorkOrders>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadViewerFilesSearchUploadedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntitySites = {
        address?: InputMaybe<FileUploadViewerFilesSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadViewerFilesSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadViewerFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FileUploadWorkOrderFilesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        uploadedBy?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedBy>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByRegistration>;
        teams?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByTeams>;
        user?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUser>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserCustomer>;
        entity?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserCustomerTags>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntity = {
        addresses?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPools>;
        serviceJob?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntitySites = {
        address?: InputMaybe<FileUploadWorkOrderFilesSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FileUploadWorkOrderFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FileUpload** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type FilesForCustomContractToFileUploadConnection = {
        __typename?: 'FilesForCustomContractToFileUploadConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesForCustomContractToFileUploadEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesForCustomContractToFileUploadMeta>;
    };

    /** An edge in a connection. */
    export type FilesForCustomContractToFileUploadEdge = {
        __typename?: 'FilesForCustomContractToFileUploadEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type FilesForCustomContractToFileUploadMeta = {
        __typename?: 'FilesForCustomContractToFileUploadMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FilesForPoolToFileUploadConnection = {
        __typename?: 'FilesForPoolToFileUploadConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesForPoolToFileUploadEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesForPoolToFileUploadMeta>;
    };

    /** An edge in a connection. */
    export type FilesForPoolToFileUploadEdge = {
        __typename?: 'FilesForPoolToFileUploadEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type FilesForPoolToFileUploadMeta = {
        __typename?: 'FilesForPoolToFileUploadMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FilesForServiceJobToFileUploadConnection = {
        __typename?: 'FilesForServiceJobToFileUploadConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesForServiceJobToFileUploadEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesForServiceJobToFileUploadMeta>;
    };

    /** An edge in a connection. */
    export type FilesForServiceJobToFileUploadEdge = {
        __typename?: 'FilesForServiceJobToFileUploadEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type FilesForServiceJobToFileUploadMeta = {
        __typename?: 'FilesForServiceJobToFileUploadMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FilesForWarrantyClaimToWarrantyClaimFileAttachConnection = {
        __typename?: 'FilesForWarrantyClaimToWarrantyClaimFileAttachConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesForWarrantyClaimToWarrantyClaimFileAttachEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesForWarrantyClaimToWarrantyClaimFileAttachMeta>;
    };

    /** An edge in a connection. */
    export type FilesForWarrantyClaimToWarrantyClaimFileAttachEdge = {
        __typename?: 'FilesForWarrantyClaimToWarrantyClaimFileAttachEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaimFileAttach>;
    };

    export type FilesForWarrantyClaimToWarrantyClaimFileAttachMeta = {
        __typename?: 'FilesForWarrantyClaimToWarrantyClaimFileAttachMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FilesForWorkOrderToFileUploadConnection = {
        __typename?: 'FilesForWorkOrderToFileUploadConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesForWorkOrderToFileUploadEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesForWorkOrderToFileUploadMeta>;
    };

    /** An edge in a connection. */
    export type FilesForWorkOrderToFileUploadEdge = {
        __typename?: 'FilesForWorkOrderToFileUploadEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type FilesForWorkOrderToFileUploadMeta = {
        __typename?: 'FilesForWorkOrderToFileUploadMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FilesUploadedForStaffToFileUploadConnection = {
        __typename?: 'FilesUploadedForStaffToFileUploadConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FilesUploadedForStaffToFileUploadEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FilesUploadedForStaffToFileUploadMeta>;
    };

    /** An edge in a connection. */
    export type FilesUploadedForStaffToFileUploadEdge = {
        __typename?: 'FilesUploadedForStaffToFileUploadEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type FilesUploadedForStaffToFileUploadMeta = {
        __typename?: 'FilesUploadedForStaffToFileUploadMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FlagsForFranchiseToRoleFlagConnection = {
        __typename?: 'FlagsForFranchiseToRoleFlagConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FlagsForFranchiseToRoleFlagEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FlagsForFranchiseToRoleFlagMeta>;
    };

    /** An edge in a connection. */
    export type FlagsForFranchiseToRoleFlagEdge = {
        __typename?: 'FlagsForFranchiseToRoleFlagEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<RoleFlag>;
    };

    export type FlagsForFranchiseToRoleFlagMeta = {
        __typename?: 'FlagsForFranchiseToRoleFlagMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FlagsForRoleToRoleFlagConnection = {
        __typename?: 'FlagsForRoleToRoleFlagConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FlagsForRoleToRoleFlagEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type FlagsForRoleToRoleFlagEdge = {
        __typename?: 'FlagsForRoleToRoleFlagEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<RoleFlag>;
    };

    /** A connection to a list of items. */
    export type FlagsForStaffToRoleFlagConnection = {
        __typename?: 'FlagsForStaffToRoleFlagConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FlagsForStaffToRoleFlagEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type FlagsForStaffToRoleFlagEdge = {
        __typename?: 'FlagsForStaffToRoleFlagEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<RoleFlag>;
    };

    export type FloatMatchSearchCommon = {
        is?: InputMaybe<Scalars['Float']>;
        not?: InputMaybe<Scalars['Float']>;
    };

    export type FloatRangeSearchCommon = {
        bigger?: InputMaybe<Scalars['Float']>;
        from?: InputMaybe<Scalars['Float']>;
        smaller?: InputMaybe<Scalars['Float']>;
        to?: InputMaybe<Scalars['Float']>;
    };

    export type Franchise = Node & {
        __typename?: 'Franchise';
        activities?: Maybe<RecentActivity>;
        address?: Maybe<Address>;
        checkMutations?: Maybe<FranchiseMutationCheck>;
        childFranchises?: Maybe<ChildFranchisesForFranchiseToFranchiseConnection>;
        contactExport?: Maybe<Scalars['Boolean']>;
        createdAt?: Maybe<Scalars['Date']>;
        currentLocales?: Maybe<Locales>;
        currentTimezone?: Maybe<Timezone>;
        customerCount?: Maybe<Scalars['Int']>;
        email?: Maybe<Scalars['String']>;
        ezcServiceProviderLink?: Maybe<EZCFranchiseLink>;
        fileSafeName?: Maybe<Scalars['String']>;
        flags?: Maybe<FlagsForFranchiseToRoleFlagConnection>;
        franchiseProblemViews?: Maybe<FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewConnection>;
        franchiseTreatmentViews?: Maybe<FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewConnection>;
        headOffice?: Maybe<FranchiseShop>;
        id: Scalars['ID'];
        locales?: Maybe<Locales>;
        manager?: Maybe<Staff>;
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        parentFranchise?: Maybe<Franchise>;
        phoneNumber?: Maybe<Scalars['String']>;
        poolCount?: Maybe<Scalars['Int']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        shops?: Maybe<ShopsForFranchiseToFranchiseShopConnection>;
        staff?: Maybe<StaffForFranchiseToStaffConnection>;
        staffAccessTokens?: Maybe<StaffAccessTokensForFranchiseToStaffAccessTokenConnection>;
        staffCount?: Maybe<Scalars['Int']>;
        timeZone?: Maybe<Timezone>;
        treatmentGroups?: Maybe<TreatmentGroupsForFranchiseToTestTreatmentGroupConnection>;
        /** Array size minimum: 1 */
        types?: Maybe<Array<Maybe<FranchiseType>>>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
        vend?: Maybe<VendAPI>;
        waterTestBranding?: Maybe<OrganisationBrandingEnum>;
    };

    export type FranchiseChildFranchisesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseFranchiseChildFranchisesSearch>;
        sort?: InputMaybe<FranchiseFranchiseChildFranchisesSort>;
    };

    export type FranchiseFlagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type FranchiseFranchiseProblemViewsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearch>;
    };

    export type FranchiseFranchiseTreatmentViewsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearch>;
    };

    export type FranchiseShopsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type FranchiseStaffArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type FranchiseStaffAccessTokensArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearch>;
        sort?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSort>;
    };

    export type FranchiseTreatmentGroupsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentGroupFranchiseTreatmentGroupsSearch>;
        sort?: InputMaybe<TestTreatmentGroupFranchiseTreatmentGroupsSort>;
    };

    /** A connection to a list of items. */
    export type FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistConnection = {
        __typename?: 'FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistMeta>;
    };

    /** An edge in a connection. */
    export type FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistEdge = {
        __typename?: 'FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatmentFranchiseBlacklist>;
    };

    export type FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistMeta = {
        __typename?: 'FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type FranchiseFranchiseChildFranchisesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        contactExport?: InputMaybe<BooleanMatchSearchCommon>;
        isWarranter?: InputMaybe<Scalars['Boolean']>;
        manager?: InputMaybe<StaffNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatmentGroups?: InputMaybe<FranchiseFranchiseChildFranchisesSearchTreatmentGroups>;
    };

    export type FranchiseFranchiseChildFranchisesSearchTreatmentGroups = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseFranchiseChildFranchisesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Franchise** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type FranchiseMutationCheck = {
        __typename?: 'FranchiseMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        setParentFranchise?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFranchiseMutationInput>>>;
        setParentFranchise?: InputMaybe<Array<InputMaybe<FranchiseSetParentFranchiseMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseMutationInput>>>;
    };

    export type FranchiseMutationPayload = {
        __typename?: 'FranchiseMutationPayload';
        Franchise?: Maybe<Franchise>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseMutationOutput>>>;
        updatedHeadOffice?: Maybe<FranchiseShop>;
        updatedLocales?: Maybe<Locales>;
        updatedManager?: Maybe<Staff>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParentFranchise?: Maybe<Franchise>;
        updatedTimeZone?: Maybe<Timezone>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseNodeMatchSearchCommon = {
        /** Expects node: **Franchise** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        not?: InputMaybe<Scalars['ID']>;
    };

    /** A connection to a list of items. */
    export type FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityConnection = {
        __typename?: 'FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityMeta>;
    };

    /** An edge in a connection. */
    export type FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityEdge = {
        __typename?: 'FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatmentFranchisePriority>;
    };

    export type FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityMeta = {
        __typename?: 'FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewConnection = {
        __typename?: 'FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewMeta>;
    };

    /** An edge in a connection. */
    export type FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewEdge = {
        __typename?: 'FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseTestProblemView>;
    };

    export type FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewMeta = {
        __typename?: 'FranchiseProblemViewsForFranchiseToFranchiseTestProblemViewMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type FranchiseSequenceStore = Node & {
        __typename?: 'FranchiseSequenceStore';
        checkMutations?: Maybe<FranchiseSequenceStoreMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        key?: Maybe<Scalars['String']>;
        sequence?: Maybe<Scalars['Int']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type FranchiseSequenceStoreIncrementMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
    };

    export type FranchiseSequenceStoreIncrementOwnMutationInput = {
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
    };

    export type FranchiseSequenceStoreMutationCheck = {
        __typename?: 'FranchiseSequenceStoreMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseSequenceStoreMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        increment?: InputMaybe<Array<InputMaybe<FranchiseSequenceStoreIncrementMutationInput>>>;
        incrementOwn?: InputMaybe<Array<InputMaybe<FranchiseSequenceStoreIncrementOwnMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseSequenceStoreMutationInput>>>;
    };

    export type FranchiseSequenceStoreMutationPayload = {
        __typename?: 'FranchiseSequenceStoreMutationPayload';
        FranchiseSequenceStore?: Maybe<FranchiseSequenceStore>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseSequenceStoreMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseSequenceStoreViewerFranchiseSequenceStoreSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<StringMatchSearchCommon>;
        key?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseSequenceStoreViewerFranchiseSequenceStoreSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FranchiseSequenceStore** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        key?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type FranchiseSetParentFranchiseMutationInput = {
        /** Expects node: **Franchise** */
        id: Scalars['ID'];
        /** Expects node: **Franchise** */
        parentFranchise?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShop = Node & {
        __typename?: 'FranchiseShop';
        address?: Maybe<Address>;
        checkMutations?: Maybe<FranchiseShopMutationCheck>;
        entity?: Maybe<Entity>;
        id: Scalars['ID'];
        manager?: Maybe<Staff>;
    };

    export type FranchiseShopMutationCheck = {
        __typename?: 'FranchiseShopMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseShopMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseShopMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFranchiseShopMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopMutationInput>>>;
    };

    export type FranchiseShopMutationPayload = {
        __typename?: 'FranchiseShopMutationPayload';
        FranchiseShop?: Maybe<FranchiseShop>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseShopMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedManager?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseShopViewerShopsSearch = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        entity?: InputMaybe<FranchiseShopViewerShopsSearchEntity>;
        manager?: InputMaybe<StaffNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntity = {
        addresses?: InputMaybe<FranchiseShopViewerShopsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<FranchiseShopViewerShopsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<FranchiseShopViewerShopsSearchEntityPools>;
        serviceJob?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJob>;
        sites?: InputMaybe<FranchiseShopViewerShopsSearchEntitySites>;
        user?: InputMaybe<FranchiseShopViewerShopsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSite>;
        type?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSite = {
        address?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJob = {
        address?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobWorkOrders>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerUser>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSite>;
        type?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSite = {
        address?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type FranchiseShopViewerShopsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type FranchiseShopViewerShopsSearchEntitySites = {
        address?: InputMaybe<FranchiseShopViewerShopsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserStaff>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerTags>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserCustomerAddedByTeams>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<FranchiseShopViewerShopsSearchEntityUserStaffTeams>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseShopViewerShopsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseShopViewerShopsSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FranchiseShop** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type FranchiseTestProblemView = Node & {
        __typename?: 'FranchiseTestProblemView';
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        testProblem?: Maybe<TestProblem>;
        treatmentViews?: Maybe<TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewConnection>;
    };

    export type FranchiseTestProblemViewTreatmentViewsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearch>;
    };

    export type FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearch = {
        affects?: InputMaybe<FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchFranchiseBlacklists>;
        group?: InputMaybe<FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchGroup>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        relatedProblems?: InputMaybe<FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchRelatedProblems>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseTestProblemViewFranchiseFranchiseProblemViewsSearchRelatedProblems = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseTestTreatmentView = Node & {
        __typename?: 'FranchiseTestTreatmentView';
        checkMutations?: Maybe<FranchiseTestTreatmentViewMutationCheck>;
        franchise?: Maybe<Franchise>;
        globalPriority?: Maybe<Scalars['Int']>;
        id: Scalars['ID'];
        isBlacklisted?: Maybe<Scalars['Boolean']>;
        localPriority?: Maybe<Scalars['Int']>;
        testTreatment?: Maybe<TestTreatment>;
    };

    export type FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearch = {
        affects?: InputMaybe<FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchFranchiseBlacklists>;
        group?: InputMaybe<FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchGroup>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        relatedProblems?: InputMaybe<FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchRelatedProblems>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseFranchiseTreatmentViewsSearchRelatedProblems = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatments = {
        affects?: InputMaybe<FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsFranchiseBlacklists>;
        group?: InputMaybe<FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewFranchiseTestProblemViewTreatmentViewsSearchTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseTestTreatmentViewMutationCheck = {
        __typename?: 'FranchiseTestTreatmentViewMutationCheck';
        setBlacklist?: Maybe<Scalars['Boolean']>;
        setLocalPriority?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseTestTreatmentViewMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        setBlacklist?: InputMaybe<Array<InputMaybe<FranchiseTestTreatmentViewSetBlacklistMutationInput>>>;
        setLocalPriority?: InputMaybe<Array<InputMaybe<FranchiseTestTreatmentViewSetLocalPriorityMutationInput>>>;
    };

    export type FranchiseTestTreatmentViewMutationPayload = {
        __typename?: 'FranchiseTestTreatmentViewMutationPayload';
        FranchiseTestTreatmentView?: Maybe<FranchiseTestTreatmentView>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseTestTreatmentViewMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTestTreatment?: Maybe<TestTreatment>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseTestTreatmentViewSetBlacklistMutationInput = {
        blacklist: Scalars['Boolean'];
        /** Expects node: **FranchiseTestTreatmentView** */
        id: Scalars['ID'];
    };

    export type FranchiseTestTreatmentViewSetLocalPriorityMutationInput = {
        /** Expects node: **FranchiseTestTreatmentView** */
        id: Scalars['ID'];
        priority: Scalars['Int'];
    };

    /** A connection to a list of items. */
    export type FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewConnection = {
        __typename?: 'FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewMeta>;
    };

    /** An edge in a connection. */
    export type FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewEdge = {
        __typename?: 'FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseTestTreatmentView>;
    };

    export type FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewMeta = {
        __typename?: 'FranchiseTreatmentViewsForFranchiseToFranchiseTestTreatmentViewMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export enum FranchiseType {
        HQ = 'HQ',
        Store = 'Store',
        StoreHQ = 'StoreHQ',
        Trial = 'Trial',
        Warranter = 'Warranter',
    }

    export type FranchiseValueStore = Node & {
        __typename?: 'FranchiseValueStore';
        checkMutations?: Maybe<FranchiseValueStoreMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        key?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        value?: Maybe<Scalars['String']>;
    };

    export type FranchiseValueStoreMutationCheck = {
        __typename?: 'FranchiseValueStoreMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseValueStoreMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFranchiseValueStoreMutationInput>>>;
        set?: InputMaybe<Array<InputMaybe<FranchiseValueStoreSetMutationInput>>>;
        setOwn?: InputMaybe<Array<InputMaybe<FranchiseValueStoreSetOwnMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseValueStoreMutationInput>>>;
    };

    export type FranchiseValueStoreMutationPayload = {
        __typename?: 'FranchiseValueStoreMutationPayload';
        FranchiseValueStore?: Maybe<FranchiseValueStore>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseValueStoreMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseValueStoreSetMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type FranchiseValueStoreSetOwnMutationInput = {
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type FranchiseValueStoreViewerFranchiseValuesStoreSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<StringMatchSearchCommon>;
        key?: InputMaybe<StringMatchSearchCommon>;
    };

    export type FranchiseValueStoreViewerFranchiseValuesStoreSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FranchiseValueStore** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        key?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type FranchiseViewerFranchisesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        contactExport?: InputMaybe<BooleanMatchSearchCommon>;
        isWarranter?: InputMaybe<Scalars['Boolean']>;
        manager?: InputMaybe<StaffNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parentFranchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        treatmentGroups?: InputMaybe<FranchiseViewerFranchisesSearchTreatmentGroups>;
    };

    export type FranchiseViewerFranchisesSearchTreatmentGroups = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type FranchiseViewerFranchisesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Franchise** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type FranchiseWarranter = Node & {
        __typename?: 'FranchiseWarranter';
        actingFranchise?: Maybe<Franchise>;
        checkMutations?: Maybe<FranchiseWarranterMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        disabled?: Maybe<Scalars['Boolean']>;
        email?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type FranchiseWarranterMutationCheck = {
        __typename?: 'FranchiseWarranterMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type FranchiseWarranterMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateFranchiseWarranterMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteFranchiseWarranterMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseWarranterMutationInput>>>;
    };

    export type FranchiseWarranterMutationPayload = {
        __typename?: 'FranchiseWarranterMutationPayload';
        FranchiseWarranter?: Maybe<FranchiseWarranter>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkFranchiseWarranterMutationOutput>>>;
        updatedActingFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type FranchiseWarranterNodeMatchSearchCommon = {
        /** Expects node: **FranchiseWarranter** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FranchiseWarranter** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type FranchiseWarranterViewerFranchiseWarrantersSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabled?: InputMaybe<BooleanMatchSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type FranchiseWarranterViewerFranchiseWarrantersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FranchiseWarranter** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type GroupsForServiceJobStageToServiceJobGroupConnection = {
        __typename?: 'GroupsForServiceJobStageToServiceJobGroupConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<GroupsForServiceJobStageToServiceJobGroupEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<GroupsForServiceJobStageToServiceJobGroupMeta>;
    };

    /** An edge in a connection. */
    export type GroupsForServiceJobStageToServiceJobGroupEdge = {
        __typename?: 'GroupsForServiceJobStageToServiceJobGroupEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobGroup>;
    };

    export type GroupsForServiceJobStageToServiceJobGroupMeta = {
        __typename?: 'GroupsForServiceJobStageToServiceJobGroupMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type HQFranchisesStats = {
        __typename?: 'HQFranchisesStats';
        newCustomers?: Maybe<NewCustomersForHQFranchisesStatsToActivitySampleConnection>;
        reportsVolumePerFranchise?: Maybe<ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleConnection>;
        reportsVolumeTotal?: Maybe<ReportsVolumeTotalForHQFranchisesStatsToActivitySampleConnection>;
        waterTests?: Maybe<WaterTestsForHQFranchisesStatsToActivitySampleConnection>;
    };

    export type HQFranchisesStatsNewCustomersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        resolution?: InputMaybe<DateRangeResolutionEnum>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type HQFranchisesStatsReportsVolumePerFranchiseArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        resolution?: InputMaybe<DateRangeResolutionEnum>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type HQFranchisesStatsReportsVolumeTotalArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        resolution?: InputMaybe<DateRangeResolutionEnum>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type HQFranchisesStatsWaterTestsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        groupBy?: InputMaybe<StatsGroupByEnum>;
        last?: InputMaybe<Scalars['Int']>;
        resolution?: InputMaybe<DateRangeResolutionEnum>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type HeaterRecommendation = Node & {
        __typename?: 'HeaterRecommendation';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<HeaterRecommendationMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        entity?: Maybe<Entity>;
        expiryDate?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        payload?: Maybe<Scalars['JSON']>;
        product?: Maybe<Product>;
        recNumber?: Maybe<Scalars['String']>;
        status?: Maybe<HeaterRecommendationStatusEnum>;
        updatedAt?: Maybe<Scalars['Date']>;
        urlPdf?: Maybe<Scalars['String']>;
    };

    export type HeaterRecommendationCustomerCreateCommon = {
        address?: InputMaybe<AddressInputBaseCommon>;
        companyName?: InputMaybe<Scalars['String']>;
        contactName?: InputMaybe<Scalars['String']>;
        email?: InputMaybe<Scalars['String']>;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        mobile?: InputMaybe<Scalars['String']>;
    };

    export type HeaterRecommendationEntityHeaterRecommendationsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        expiryDate?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        recNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        status?: InputMaybe<HeaterRecommendationStatusEnumEnumMatchSearchCommon>;
    };

    export type HeaterRecommendationEntityHeaterRecommendationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        expiryDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **HeaterRecommendation** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type HeaterRecommendationMutationCheck = {
        __typename?: 'HeaterRecommendationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type HeaterRecommendationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateHeaterRecommendationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteHeaterRecommendationMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateHeaterRecommendationMutationInput>>>;
    };

    export type HeaterRecommendationMutationPayload = {
        __typename?: 'HeaterRecommendationMutationPayload';
        HeaterRecommendation?: Maybe<HeaterRecommendation>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkHeaterRecommendationMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedEntity?: Maybe<Entity>;
        updatedFranchise?: Maybe<Franchise>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export enum HeaterRecommendationStatusEnum {
        Active = 'Active',
        Closed = 'Closed',
        OnHold = 'OnHold',
    }

    export type HeaterRecommendationStatusEnumEnumMatchSearchCommon = {
        is?: InputMaybe<HeaterRecommendationStatusEnum>;
        not?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntity>;
        expiryDate?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        recNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        status?: InputMaybe<HeaterRecommendationStatusEnumEnumMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntity = {
        addresses?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPools>;
        serviceJob?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJob>;
        sites?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntitySites>;
        user?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSite>;
        type?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSite = {
        address?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJob = {
        address?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobWorkOrders>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUser>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSite>;
        type?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSite = {
        address?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntitySites = {
        address?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaff>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerTags>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedByTeams>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaffTeams>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type HeaterRecommendationViewerHeaterRecommendationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        expiryDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **HeaterRecommendation** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type HeaterRecommendationsForEntityToHeaterRecommendationConnection = {
        __typename?: 'HeaterRecommendationsForEntityToHeaterRecommendationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<HeaterRecommendationsForEntityToHeaterRecommendationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<HeaterRecommendationsForEntityToHeaterRecommendationMeta>;
    };

    /** An edge in a connection. */
    export type HeaterRecommendationsForEntityToHeaterRecommendationEdge = {
        __typename?: 'HeaterRecommendationsForEntityToHeaterRecommendationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<HeaterRecommendation>;
    };

    export type HeaterRecommendationsForEntityToHeaterRecommendationMeta = {
        __typename?: 'HeaterRecommendationsForEntityToHeaterRecommendationMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocConnection = {
        __typename?: 'IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocMeta>;
    };

    /** An edge in a connection. */
    export type IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocEdge = {
        __typename?: 'IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoTemplateAssoc>;
    };

    export type IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocMeta = {
        __typename?: 'IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocConnection = {
        __typename?: 'IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocMeta>;
    };

    /** An edge in a connection. */
    export type IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocEdge = {
        __typename?: 'IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderTemplateAssoc>;
    };

    export type IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocMeta = {
        __typename?: 'IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type InstallableProduct = Node & {
        __typename?: 'InstallableProduct';
        checkMutations?: Maybe<InstallableProductMutationCheck>;
        criteria?: Maybe<CriteriaForInstallableProductToTestCriteriaConnection>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
    };

    export type InstallableProductCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type InstallableProductMutationCheck = {
        __typename?: 'InstallableProductMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type InstallableProductMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateInstallableProductMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteInstallableProductMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateInstallableProductMutationInput>>>;
    };

    export type InstallableProductMutationPayload = {
        __typename?: 'InstallableProductMutationPayload';
        InstallableProduct?: Maybe<InstallableProduct>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkInstallableProductMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type InstallableProductViewerInstallableProductsSearch = {
        product?: InputMaybe<InstallableProductViewerInstallableProductsSearchProduct>;
    };

    export type InstallableProductViewerInstallableProductsSearchProduct = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        declarations?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarations>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        relatedSanitisers?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            pools?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPools>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPools =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
            /** Expects node: **Pool** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Pool** */
            not?: InputMaybe<Scalars['ID']>;
            ownershipHistory?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
            reports?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports>;
            sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
            site?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite>;
            type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
            volume?: InputMaybe<FloatRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity =
        {
            addresses?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
            sites?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **TestReport** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestReport** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            refId?: InputMaybe<StringMatchSearchCommon>;
            reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            reportTo?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
            sampleSet?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo =
        {
            addresses?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
            sites?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity =
        {
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity =
        {
            addresses?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob =
        {
            address?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer =
        {
            addedBy?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
            teams?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSearchProductDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type InstallableProductViewerInstallableProductsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        customSku?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **InstallableProduct** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        identification?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        sku?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type IntMatchSearchCommon = {
        is?: InputMaybe<Scalars['Int']>;
        not?: InputMaybe<Scalars['Int']>;
    };

    export type Item = Node & {
        __typename?: 'Item';
        checkMutations?: Maybe<ItemMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        installedAt?: Maybe<Scalars['Date']>;
        installedByStaff?: Maybe<Staff>;
        label?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        owner?: Maybe<Entity>;
        product?: Maybe<Product>;
        serial?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type ItemEntityItemsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ItemEntityItemsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Item** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ItemMutationCheck = {
        __typename?: 'ItemMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ItemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateItemMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteItemMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateItemMutationInput>>>;
    };

    export type ItemMutationPayload = {
        __typename?: 'ItemMutationPayload';
        Item?: Maybe<Item>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkItemMutationOutput>>>;
        updatedInstalledByStaff?: Maybe<Staff>;
        updatedOwner?: Maybe<Entity>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ItemNodeMatchSearchCommon = {
        /** Expects node: **Item** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Item** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ItemPoolItemsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ItemPoolItemsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Item** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type ItemsForAppointmentToAppointmentItemConnection = {
        __typename?: 'ItemsForAppointmentToAppointmentItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ItemsForAppointmentToAppointmentItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ItemsForAppointmentToAppointmentItemEdge = {
        __typename?: 'ItemsForAppointmentToAppointmentItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type ItemsForEntityToItemConnection = {
        __typename?: 'ItemsForEntityToItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ItemsForEntityToItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ItemsForEntityToItemMeta>;
    };

    /** An edge in a connection. */
    export type ItemsForEntityToItemEdge = {
        __typename?: 'ItemsForEntityToItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Item>;
    };

    export type ItemsForEntityToItemMeta = {
        __typename?: 'ItemsForEntityToItemMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ItemsForJobTodoTemplateToJobTodoTemplateItemConnection = {
        __typename?: 'ItemsForJobTodoTemplateToJobTodoTemplateItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ItemsForJobTodoTemplateToJobTodoTemplateItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ItemsForJobTodoTemplateToJobTodoTemplateItemMeta>;
    };

    /** An edge in a connection. */
    export type ItemsForJobTodoTemplateToJobTodoTemplateItemEdge = {
        __typename?: 'ItemsForJobTodoTemplateToJobTodoTemplateItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoTemplateItem>;
    };

    export type ItemsForJobTodoTemplateToJobTodoTemplateItemMeta = {
        __typename?: 'ItemsForJobTodoTemplateToJobTodoTemplateItemMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ItemsForJobTodoToJobTodoItemConnection = {
        __typename?: 'ItemsForJobTodoToJobTodoItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ItemsForJobTodoToJobTodoItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ItemsForJobTodoToJobTodoItemMeta>;
    };

    /** An edge in a connection. */
    export type ItemsForJobTodoToJobTodoItemEdge = {
        __typename?: 'ItemsForJobTodoToJobTodoItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoItem>;
    };

    export type ItemsForJobTodoToJobTodoItemMeta = {
        __typename?: 'ItemsForJobTodoToJobTodoItemMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ItemsForPoolToItemConnection = {
        __typename?: 'ItemsForPoolToItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ItemsForPoolToItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ItemsForPoolToItemMeta>;
    };

    /** An edge in a connection. */
    export type ItemsForPoolToItemEdge = {
        __typename?: 'ItemsForPoolToItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Item>;
    };

    export type ItemsForPoolToItemMeta = {
        __typename?: 'ItemsForPoolToItemMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type JobTodo = Node & {
        __typename?: 'JobTodo';
        checkMutations?: Maybe<JobTodoMutationCheck>;
        completedAt?: Maybe<Scalars['Date']>;
        completedBy?: Maybe<Staff>;
        createdBy?: Maybe<Staff>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        index?: Maybe<Scalars['Int']>;
        items?: Maybe<ItemsForJobTodoToJobTodoItemConnection>;
        jobNumber?: Maybe<Scalars['String']>;
        price?: Maybe<Scalars['Int']>;
        status?: Maybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: Maybe<Scalars['Int']>;
        title?: Maybe<Scalars['String']>;
        usedTemplate?: Maybe<JobTodoTemplate>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type JobTodoItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoItemJobTodoItemsSearch>;
        sort?: InputMaybe<JobTodoItemJobTodoItemsSort>;
    };

    export type JobTodoCreateFromTemplateMutationInput = {
        /** Expects node: **JobTodoTemplate** */
        templateId: Scalars['ID'];
        /** Expects node: **WorkOrder** */
        workOrderId: Scalars['ID'];
    };

    export type JobTodoItem = Node & {
        __typename?: 'JobTodoItem';
        checkMutations?: Maybe<JobTodoItemMutationCheck>;
        completedAt?: Maybe<Scalars['Date']>;
        completedBy?: Maybe<Staff>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        job?: Maybe<JobTodo>;
        orderIndex?: Maybe<Scalars['Int']>;
        status?: Maybe<Scalars['Int']>;
        title?: Maybe<Scalars['String']>;
    };

    export type JobTodoItemJobTodoItemsSearch = {
        completedAt?: InputMaybe<DateRangeSearchCommon>;
        completedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        status?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type JobTodoItemJobTodoItemsSort = {
        /** Expects node: **JobTodoItem** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        orderIndex?: InputMaybe<Scalars['Boolean']>;
    };

    export type JobTodoItemMutationCheck = {
        __typename?: 'JobTodoItemMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type JobTodoItemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateJobTodoItemMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteJobTodoItemMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoItemMutationInput>>>;
    };

    export type JobTodoItemMutationPayload = {
        __typename?: 'JobTodoItemMutationPayload';
        JobTodoItem?: Maybe<JobTodoItem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkJobTodoItemMutationOutput>>>;
        updatedCompletedBy?: Maybe<Staff>;
        updatedJob?: Maybe<JobTodo>;
        viewer?: Maybe<Viewer>;
    };

    export type JobTodoMutationCheck = {
        __typename?: 'JobTodoMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type JobTodoMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateJobTodoMutationInput>>>;
        createFromTemplate?: InputMaybe<Array<InputMaybe<JobTodoCreateFromTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteJobTodoMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoMutationInput>>>;
    };

    export type JobTodoMutationPayload = {
        __typename?: 'JobTodoMutationPayload';
        JobTodo?: Maybe<JobTodo>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkJobTodoMutationOutput>>>;
        updatedCompletedBy?: Maybe<Staff>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedUsedTemplate?: Maybe<JobTodoTemplate>;
        updatedWorkOrder?: Maybe<WorkOrder>;
        viewer?: Maybe<Viewer>;
    };

    export type JobTodoTemplate = Node & {
        __typename?: 'JobTodoTemplate';
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<JobTodoTemplateMutationCheck>;
        description?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<ServiceJobGroup>;
        id: Scalars['ID'];
        includedInJobTodoTemplateAssocs?: Maybe<IncludedInJobTodoTemplateAssocsForJobTodoTemplateToJobTodoTemplateAssocConnection>;
        items?: Maybe<ItemsForJobTodoTemplateToJobTodoTemplateItemConnection>;
        maxTimeEstimate?: Maybe<Scalars['Int']>;
        minTimeEstimate?: Maybe<Scalars['Int']>;
        organisation?: Maybe<OrganisationType>;
        ownedByWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        recommendedPrice?: Maybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: Maybe<Scalars['Int']>;
        title?: Maybe<Scalars['String']>;
    };

    export type JobTodoTemplateIncludedInJobTodoTemplateAssocsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoTemplateAssocJobTodoTemplateIncludedInJobTodoTemplateAssocsSearch>;
        sort?: InputMaybe<JobTodoTemplateAssocJobTodoTemplateIncludedInJobTodoTemplateAssocsSort>;
    };

    export type JobTodoTemplateItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoTemplateItemJobTodoTemplateItemsSearch>;
        sort?: InputMaybe<JobTodoTemplateItemJobTodoTemplateItemsSort>;
    };

    export type JobTodoTemplateArchiveMutationInput = {
        /** Expects node: **JobTodoTemplate** */
        id: Scalars['ID'];
    };

    export type JobTodoTemplateAssoc = Node & {
        __typename?: 'JobTodoTemplateAssoc';
        checkMutations?: Maybe<JobTodoTemplateAssocMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        isSuggestion?: Maybe<Scalars['Boolean']>;
        jobTodoTemplate?: Maybe<JobTodoTemplate>;
        priority?: Maybe<Scalars['Int']>;
        updatedAt?: Maybe<Scalars['Date']>;
        workOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type JobTodoTemplateAssocJobTodoTemplateIncludedInJobTodoTemplateAssocsSearch = {
        workOrderTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
    };

    export type JobTodoTemplateAssocJobTodoTemplateIncludedInJobTodoTemplateAssocsSort = {
        /** Expects node: **JobTodoTemplateAssoc** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type JobTodoTemplateAssocMutationCheck = {
        __typename?: 'JobTodoTemplateAssocMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type JobTodoTemplateAssocMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateJobTodoTemplateAssocMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteJobTodoTemplateAssocMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoTemplateAssocMutationInput>>>;
    };

    export type JobTodoTemplateAssocMutationPayload = {
        __typename?: 'JobTodoTemplateAssocMutationPayload';
        JobTodoTemplateAssoc?: Maybe<JobTodoTemplateAssoc>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkJobTodoTemplateAssocMutationOutput>>>;
        updatedJobTodoTemplate?: Maybe<JobTodoTemplate>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSearch = {
        jobTodoTemplate?: InputMaybe<JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSearchJobTodoTemplate>;
    };

    export type JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSearchJobTodoTemplate = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **JobTodoTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **JobTodoTemplate** */
        not?: InputMaybe<Scalars['ID']>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        ownedByWorkOrderTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSort = {
        /** Expects node: **JobTodoTemplateAssoc** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocConnection = {
        __typename?: 'JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocMeta>;
    };

    /** An edge in a connection. */
    export type JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocEdge = {
        __typename?: 'JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoTemplateAssoc>;
    };

    export type JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocMeta = {
        __typename?: 'JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type JobTodoTemplateItem = Node & {
        __typename?: 'JobTodoTemplateItem';
        checkMutations?: Maybe<JobTodoTemplateItemMutationCheck>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        orderIndex?: Maybe<Scalars['Int']>;
        template?: Maybe<JobTodoTemplate>;
        title?: Maybe<Scalars['String']>;
    };

    export type JobTodoTemplateItemJobTodoTemplateItemsSearch = {
        description?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type JobTodoTemplateItemJobTodoTemplateItemsSort = {
        /** Expects node: **JobTodoTemplateItem** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        orderIndex?: InputMaybe<Scalars['Boolean']>;
    };

    export type JobTodoTemplateItemMutationCheck = {
        __typename?: 'JobTodoTemplateItemMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type JobTodoTemplateItemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateJobTodoTemplateItemMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteJobTodoTemplateItemMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoTemplateItemMutationInput>>>;
    };

    export type JobTodoTemplateItemMutationPayload = {
        __typename?: 'JobTodoTemplateItemMutationPayload';
        JobTodoTemplateItem?: Maybe<JobTodoTemplateItem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkJobTodoTemplateItemMutationOutput>>>;
        updatedTemplate?: Maybe<JobTodoTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type JobTodoTemplateMutationCheck = {
        __typename?: 'JobTodoTemplateMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type JobTodoTemplateMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<JobTodoTemplateArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateJobTodoTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteJobTodoTemplateMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoTemplateMutationInput>>>;
    };

    export type JobTodoTemplateMutationPayload = {
        __typename?: 'JobTodoTemplateMutationPayload';
        JobTodoTemplate?: Maybe<JobTodoTemplate>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkJobTodoTemplateMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedOwnedByWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type JobTodoTemplateViewerJobTodoTemplatesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        ownedByWorkOrderTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type JobTodoTemplateWorkOrderTemplateOwnerOfJobTodoTemplatesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type JobTodoTemplateWorkOrderTemplateOwnerOfJobTodoTemplatesSort = {
        /** Expects node: **JobTodoTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type JobTodoWorkOrderJobsSearch = {
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        status?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type JobTodoWorkOrderJobsSort = {
        /** Expects node: **JobTodo** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        index?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type JobsForWorkOrderToJobTodoConnection = {
        __typename?: 'JobsForWorkOrderToJobTodoConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<JobsForWorkOrderToJobTodoEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<JobsForWorkOrderToJobTodoMeta>;
    };

    /** An edge in a connection. */
    export type JobsForWorkOrderToJobTodoEdge = {
        __typename?: 'JobsForWorkOrderToJobTodoEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodo>;
    };

    export type JobsForWorkOrderToJobTodoMeta = {
        __typename?: 'JobsForWorkOrderToJobTodoMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type LeasesForPoolBottleToPoolBottleLeaseConnection = {
        __typename?: 'LeasesForPoolBottleToPoolBottleLeaseConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<LeasesForPoolBottleToPoolBottleLeaseEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<LeasesForPoolBottleToPoolBottleLeaseMeta>;
    };

    /** An edge in a connection. */
    export type LeasesForPoolBottleToPoolBottleLeaseEdge = {
        __typename?: 'LeasesForPoolBottleToPoolBottleLeaseEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolBottleLease>;
    };

    export type LeasesForPoolBottleToPoolBottleLeaseMeta = {
        __typename?: 'LeasesForPoolBottleToPoolBottleLeaseMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type Locales = Node & {
        __typename?: 'Locales';
        id: Scalars['ID'];
        locale?: Maybe<Scalars['String']>;
        localiseNumber?: Maybe<Scalars['String']>;
    };

    export type LocalesLocaliseNumberArgs = {
        number: Scalars['Float'];
    };

    export type LoginStaffInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        email: Scalars['String'];
        password: Scalars['String'];
    };

    export type LoginStaffPayload = {
        __typename?: 'LoginStaffPayload';
        clientMutationId?: Maybe<Scalars['String']>;
        viewer?: Maybe<Viewer>;
    };

    export type ManualProblem = Node & {
        __typename?: 'ManualProblem';
        checkMutations?: Maybe<ManualProblemMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        solution?: Maybe<Scalars['String']>;
    };

    export type ManualProblemMutationCheck = {
        __typename?: 'ManualProblemMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ManualProblemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateManualProblemMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteManualProblemMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateManualProblemMutationInput>>>;
    };

    export type ManualProblemMutationPayload = {
        __typename?: 'ManualProblemMutationPayload';
        ManualProblem?: Maybe<ManualProblem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkManualProblemMutationOutput>>>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type ManualProblemViewerManualProblemsSearch = {
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ManualProblemViewerManualProblemsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ManualProblem** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type ManualProblemsForTestReportToManualProblemConnection = {
        __typename?: 'ManualProblemsForTestReportToManualProblemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ManualProblemsForTestReportToManualProblemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ManualProblemsForTestReportToManualProblemEdge = {
        __typename?: 'ManualProblemsForTestReportToManualProblemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ManualProblem>;
    };

    /** A connection to a list of items. */
    export type ManualSolutionForTestReportToTestReportSolutionConnection = {
        __typename?: 'ManualSolutionForTestReportToTestReportSolutionConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ManualSolutionForTestReportToTestReportSolutionEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ManualSolutionForTestReportToTestReportSolutionEdge = {
        __typename?: 'ManualSolutionForTestReportToTestReportSolutionEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReportSolution>;
    };

    /** Container for viewer data */
    export type Me = {
        __typename?: 'Me';
        featureFlags?: Maybe<Scalars['JSON']>;
        franchise?: Maybe<Franchise>;
        organisation?: Maybe<OrganisationType>;
        pretending?: Maybe<Scalars['Boolean']>;
        staff?: Maybe<Staff>;
        trackId?: Maybe<Scalars['String']>;
    };

    export type Measurement = Node & {
        __typename?: 'Measurement';
        checkMutations?: Maybe<MeasurementMutationCheck>;
        conversionTemplates?: Maybe<ConversionTemplatesForMeasurementToSampleConverterTemplateConnection>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        linearConversion?: Maybe<UnitConversion>;
        linearForm?: Maybe<Measurement>;
        name?: Maybe<Scalars['String']>;
        priority?: Maybe<Scalars['Int']>;
        solutionCoefficient?: Maybe<Scalars['Float']>;
        tagIdentifier?: Maybe<Scalars['String']>;
        unit?: Maybe<Unit>;
    };

    export type MeasurementConversionTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type MeasurementMutationCheck = {
        __typename?: 'MeasurementMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type MeasurementMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateMeasurementMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteMeasurementMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateMeasurementMutationInput>>>;
    };

    export type MeasurementMutationPayload = {
        __typename?: 'MeasurementMutationPayload';
        Measurement?: Maybe<Measurement>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkMeasurementMutationOutput>>>;
        updatedLinearForm?: Maybe<Measurement>;
        updatedUnit?: Maybe<Unit>;
        viewer?: Maybe<Viewer>;
    };

    export type MeasurementNodeMatchSearchCommon = {
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type MeasurementViewerMeasurementTypesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type MeasurementViewerMeasurementTypesSort = {
        /** Expects node: **Measurement** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type MessagesForWarrantyClaimToWarrantyClaimMessageConnection = {
        __typename?: 'MessagesForWarrantyClaimToWarrantyClaimMessageConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<MessagesForWarrantyClaimToWarrantyClaimMessageEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<MessagesForWarrantyClaimToWarrantyClaimMessageMeta>;
    };

    /** An edge in a connection. */
    export type MessagesForWarrantyClaimToWarrantyClaimMessageEdge = {
        __typename?: 'MessagesForWarrantyClaimToWarrantyClaimMessageEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaimMessage>;
    };

    export type MessagesForWarrantyClaimToWarrantyClaimMessageMeta = {
        __typename?: 'MessagesForWarrantyClaimToWarrantyClaimMessageMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type MinMax = {
        __typename?: 'MinMax';
        max?: Maybe<Scalars['Float']>;
        min?: Maybe<Scalars['Float']>;
    };

    export enum MonthEnum {
        April = 'April',
        August = 'August',
        December = 'December',
        February = 'February',
        January = 'January',
        July = 'July',
        June = 'June',
        March = 'March',
        May = 'May',
        November = 'November',
        October = 'October',
        September = 'September',
    }

    export type Mutation = {
        __typename?: 'Mutation';
        AbstractDocument?: Maybe<AbstractDocumentMutationPayload>;
        Address?: Maybe<AddressMutationPayload>;
        Announcement?: Maybe<AnnouncementMutationPayload>;
        Appointment?: Maybe<AppointmentMutationPayload>;
        AppointmentGroup?: Maybe<AppointmentGroupMutationPayload>;
        AppointmentInstance?: Maybe<AppointmentInstanceMutationPayload>;
        AppointmentItem?: Maybe<AppointmentItemMutationPayload>;
        Brand?: Maybe<BrandMutationPayload>;
        ConsumableProduct?: Maybe<ConsumableProductMutationPayload>;
        Contact?: Maybe<ContactMutationPayload>;
        ContactType?: Maybe<ContactTypeMutationPayload>;
        CustomContract?: Maybe<CustomContractMutationPayload>;
        Customer?: Maybe<CustomerMutationPayload>;
        CustomerNote?: Maybe<CustomerNoteMutationPayload>;
        CustomerTag?: Maybe<CustomerTagMutationPayload>;
        EZCDeviceLink?: Maybe<EZCDeviceLinkMutationPayload>;
        EZCDeviceState?: Maybe<EZCDeviceStateMutationPayload>;
        EZCFranchiseLink?: Maybe<EZCFranchiseLinkMutationPayload>;
        EmailGlobalSelection?: Maybe<EmailGlobalSelectionMutationPayload>;
        EmailMessage?: Maybe<EmailMessageMutationPayload>;
        EmailTemplate?: Maybe<EmailTemplateMutationPayload>;
        EmailTemplateOrganisationSelection?: Maybe<EmailTemplateOrganisationSelectionMutationPayload>;
        EmailTemplateSelection?: Maybe<EmailTemplateSelectionMutationPayload>;
        Entity?: Maybe<EntityMutationPayload>;
        FeatureFlag?: Maybe<FeatureFlagMutationPayload>;
        FeatureFlagVariation?: Maybe<FeatureFlagVariationMutationPayload>;
        FileUpload?: Maybe<FileUploadMutationPayload>;
        Franchise?: Maybe<FranchiseMutationPayload>;
        FranchiseSequenceStore?: Maybe<FranchiseSequenceStoreMutationPayload>;
        FranchiseShop?: Maybe<FranchiseShopMutationPayload>;
        FranchiseTestTreatmentView?: Maybe<FranchiseTestTreatmentViewMutationPayload>;
        FranchiseValueStore?: Maybe<FranchiseValueStoreMutationPayload>;
        FranchiseWarranter?: Maybe<FranchiseWarranterMutationPayload>;
        HeaterRecommendation?: Maybe<HeaterRecommendationMutationPayload>;
        InstallableProduct?: Maybe<InstallableProductMutationPayload>;
        Item?: Maybe<ItemMutationPayload>;
        JobTodo?: Maybe<JobTodoMutationPayload>;
        JobTodoItem?: Maybe<JobTodoItemMutationPayload>;
        JobTodoTemplate?: Maybe<JobTodoTemplateMutationPayload>;
        JobTodoTemplateAssoc?: Maybe<JobTodoTemplateAssocMutationPayload>;
        JobTodoTemplateItem?: Maybe<JobTodoTemplateItemMutationPayload>;
        ManualProblem?: Maybe<ManualProblemMutationPayload>;
        Measurement?: Maybe<MeasurementMutationPayload>;
        Permission?: Maybe<PermissionMutationPayload>;
        Pool?: Maybe<PoolMutationPayload>;
        PoolAttachedFlagField?: Maybe<PoolAttachedFlagFieldMutationPayload>;
        PoolBottle?: Maybe<PoolBottleMutationPayload>;
        PoolBottleLease?: Maybe<PoolBottleLeaseMutationPayload>;
        PoolCalibration?: Maybe<PoolCalibrationMutationPayload>;
        PoolDeclarationFlagField?: Maybe<PoolDeclarationFlagFieldMutationPayload>;
        PoolNote?: Maybe<PoolNoteMutationPayload>;
        PoolProductDeclaration?: Maybe<PoolProductDeclarationMutationPayload>;
        PoolType?: Maybe<PoolTypeMutationPayload>;
        Product?: Maybe<ProductMutationPayload>;
        ProductCompany?: Maybe<ProductCompanyMutationPayload>;
        ProductDeclarationFlagField?: Maybe<ProductDeclarationFlagFieldMutationPayload>;
        ProductDeclarationFloatField?: Maybe<ProductDeclarationFloatFieldMutationPayload>;
        ProductDeclarationIntegerField?: Maybe<ProductDeclarationIntegerFieldMutationPayload>;
        ProductDeclarationMeasurementField?: Maybe<ProductDeclarationMeasurementFieldMutationPayload>;
        ProductDeclarationSelectionChoice?: Maybe<ProductDeclarationSelectionChoiceMutationPayload>;
        ProductDeclarationSelectionField?: Maybe<ProductDeclarationSelectionFieldMutationPayload>;
        ProductDeclarationStringField?: Maybe<ProductDeclarationStringFieldMutationPayload>;
        ProductDeclarationUnitField?: Maybe<ProductDeclarationUnitFieldMutationPayload>;
        ProductDocument?: Maybe<ProductDocumentMutationPayload>;
        ProductTrait?: Maybe<ProductTraitMutationPayload>;
        ProductTraitDeclaration?: Maybe<ProductTraitDeclarationMutationPayload>;
        ProductTraitFlagValue?: Maybe<ProductTraitFlagValueMutationPayload>;
        ProductTraitFloatValue?: Maybe<ProductTraitFloatValueMutationPayload>;
        ProductTraitIntegerValue?: Maybe<ProductTraitIntegerValueMutationPayload>;
        ProductTraitMeasurementValue?: Maybe<ProductTraitMeasurementValueMutationPayload>;
        ProductTraitSelectionValue?: Maybe<ProductTraitSelectionValueMutationPayload>;
        ProductTraitStringValue?: Maybe<ProductTraitStringValueMutationPayload>;
        ProductTraitUnitValue?: Maybe<ProductTraitUnitValueMutationPayload>;
        Project?: Maybe<ProjectMutationPayload>;
        ProjectVersion?: Maybe<ProjectVersionMutationPayload>;
        RecommendedTreatment?: Maybe<RecommendedTreatmentMutationPayload>;
        Role?: Maybe<RoleMutationPayload>;
        RoleFlag?: Maybe<RoleFlagMutationPayload>;
        Sample?: Maybe<SampleMutationPayload>;
        SampleConverter?: Maybe<SampleConverterMutationPayload>;
        SampleConverterTemplate?: Maybe<SampleConverterTemplateMutationPayload>;
        SampleSet?: Maybe<SampleSetMutationPayload>;
        SampleSource?: Maybe<SampleSourceMutationPayload>;
        Sanitiser?: Maybe<SanitiserMutationPayload>;
        ScheduleEvent?: Maybe<ScheduleEventMutationPayload>;
        ServiceJob?: Maybe<ServiceJobMutationPayload>;
        ServiceJobGroup?: Maybe<ServiceJobGroupMutationPayload>;
        ServiceJobNote?: Maybe<ServiceJobNoteMutationPayload>;
        ServiceJobStage?: Maybe<ServiceJobStageMutationPayload>;
        ServiceJobTemplate?: Maybe<ServiceJobTemplateMutationPayload>;
        SettingStore?: Maybe<SettingStoreMutationPayload>;
        SharedEntity?: Maybe<SharedEntityMutationPayload>;
        Site?: Maybe<SiteMutationPayload>;
        SiteNote?: Maybe<SiteNoteMutationPayload>;
        Staff?: Maybe<StaffMutationPayload>;
        StaffAccessToken?: Maybe<StaffAccessTokenMutationPayload>;
        StaffAccessTokenRegistration?: Maybe<StaffAccessTokenRegistrationMutationPayload>;
        StaffRegistration?: Maybe<StaffRegistrationMutationPayload>;
        StaffTeam?: Maybe<StaffTeamMutationPayload>;
        StaffTeamLink?: Maybe<StaffTeamLinkMutationPayload>;
        StaffValueStore?: Maybe<StaffValueStoreMutationPayload>;
        TestCriteria?: Maybe<TestCriteriaMutationPayload>;
        TestProblem?: Maybe<TestProblemMutationPayload>;
        TestRecommendation?: Maybe<TestRecommendationMutationPayload>;
        TestReport?: Maybe<TestReportMutationPayload>;
        TestReportAttachment?: Maybe<TestReportAttachmentMutationPayload>;
        TestReportSolution?: Maybe<TestReportSolutionMutationPayload>;
        TestTreatment?: Maybe<TestTreatmentMutationPayload>;
        TestTreatmentFranchiseGroup?: Maybe<TestTreatmentFranchiseGroupMutationPayload>;
        TestTreatmentFranchisePriority?: Maybe<TestTreatmentFranchisePriorityMutationPayload>;
        TestTreatmentGroup?: Maybe<TestTreatmentGroupMutationPayload>;
        TreatmentAffect?: Maybe<TreatmentAffectMutationPayload>;
        Unit?: Maybe<UnitMutationPayload>;
        UnitConversion?: Maybe<UnitConversionMutationPayload>;
        UniversalCriteria?: Maybe<UniversalCriteriaMutationPayload>;
        VendAPI?: Maybe<VendAPIMutationPayload>;
        VendCustomer?: Maybe<VendCustomerMutationPayload>;
        VendLink?: Maybe<VendLinkMutationPayload>;
        VendSale?: Maybe<VendSaleMutationPayload>;
        WarrantyClaim?: Maybe<WarrantyClaimMutationPayload>;
        WarrantyClaimCase?: Maybe<WarrantyClaimCaseMutationPayload>;
        WarrantyClaimFileAttach?: Maybe<WarrantyClaimFileAttachMutationPayload>;
        WarrantyClaimMessage?: Maybe<WarrantyClaimMessageMutationPayload>;
        WaterTestingRevision?: Maybe<WaterTestingRevisionMutationPayload>;
        WorkOrder?: Maybe<WorkOrderMutationPayload>;
        WorkOrderFeedItem?: Maybe<WorkOrderFeedItemMutationPayload>;
        WorkOrderNote?: Maybe<WorkOrderNoteMutationPayload>;
        WorkOrderTemplate?: Maybe<WorkOrderTemplateMutationPayload>;
        WorkOrderTemplateAssoc?: Maybe<WorkOrderTemplateAssocMutationPayload>;
        loginStaff?: Maybe<LoginStaffPayload>;
        pretend?: Maybe<PretendPayload>;
        unpretend?: Maybe<RemovePretendPayload>;
    };

    export type MutationAbstractDocumentArgs = {
        input: AbstractDocumentMutationInput;
    };

    export type MutationAddressArgs = {
        input: AddressMutationInput;
    };

    export type MutationAnnouncementArgs = {
        input: AnnouncementMutationInput;
    };

    export type MutationAppointmentArgs = {
        input: AppointmentMutationInput;
    };

    export type MutationAppointmentGroupArgs = {
        input: AppointmentGroupMutationInput;
    };

    export type MutationAppointmentInstanceArgs = {
        input: AppointmentInstanceMutationInput;
    };

    export type MutationAppointmentItemArgs = {
        input: AppointmentItemMutationInput;
    };

    export type MutationBrandArgs = {
        input: BrandMutationInput;
    };

    export type MutationConsumableProductArgs = {
        input: ConsumableProductMutationInput;
    };

    export type MutationContactArgs = {
        input: ContactMutationInput;
    };

    export type MutationContactTypeArgs = {
        input: ContactTypeMutationInput;
    };

    export type MutationCustomContractArgs = {
        input: CustomContractMutationInput;
    };

    export type MutationCustomerArgs = {
        input: CustomerMutationInput;
    };

    export type MutationCustomerNoteArgs = {
        input: CustomerNoteMutationInput;
    };

    export type MutationCustomerTagArgs = {
        input: CustomerTagMutationInput;
    };

    export type MutationEZCDeviceLinkArgs = {
        input: EZCDeviceLinkMutationInput;
    };

    export type MutationEZCDeviceStateArgs = {
        input: EZCDeviceStateMutationInput;
    };

    export type MutationEZCFranchiseLinkArgs = {
        input: EZCFranchiseLinkMutationInput;
    };

    export type MutationEmailGlobalSelectionArgs = {
        input: EmailGlobalSelectionMutationInput;
    };

    export type MutationEmailMessageArgs = {
        input: EmailMessageMutationInput;
    };

    export type MutationEmailTemplateArgs = {
        input: EmailTemplateMutationInput;
    };

    export type MutationEmailTemplateOrganisationSelectionArgs = {
        input: EmailTemplateOrganisationSelectionMutationInput;
    };

    export type MutationEmailTemplateSelectionArgs = {
        input: EmailTemplateSelectionMutationInput;
    };

    export type MutationEntityArgs = {
        input: EntityMutationInput;
    };

    export type MutationFeatureFlagArgs = {
        input: FeatureFlagMutationInput;
    };

    export type MutationFeatureFlagVariationArgs = {
        input: FeatureFlagVariationMutationInput;
    };

    export type MutationFileUploadArgs = {
        input: FileUploadMutationInput;
    };

    export type MutationFranchiseArgs = {
        input: FranchiseMutationInput;
    };

    export type MutationFranchiseSequenceStoreArgs = {
        input: FranchiseSequenceStoreMutationInput;
    };

    export type MutationFranchiseShopArgs = {
        input: FranchiseShopMutationInput;
    };

    export type MutationFranchiseTestTreatmentViewArgs = {
        input: FranchiseTestTreatmentViewMutationInput;
    };

    export type MutationFranchiseValueStoreArgs = {
        input: FranchiseValueStoreMutationInput;
    };

    export type MutationFranchiseWarranterArgs = {
        input: FranchiseWarranterMutationInput;
    };

    export type MutationHeaterRecommendationArgs = {
        input: HeaterRecommendationMutationInput;
    };

    export type MutationInstallableProductArgs = {
        input: InstallableProductMutationInput;
    };

    export type MutationItemArgs = {
        input: ItemMutationInput;
    };

    export type MutationJobTodoArgs = {
        input: JobTodoMutationInput;
    };

    export type MutationJobTodoItemArgs = {
        input: JobTodoItemMutationInput;
    };

    export type MutationJobTodoTemplateArgs = {
        input: JobTodoTemplateMutationInput;
    };

    export type MutationJobTodoTemplateAssocArgs = {
        input: JobTodoTemplateAssocMutationInput;
    };

    export type MutationJobTodoTemplateItemArgs = {
        input: JobTodoTemplateItemMutationInput;
    };

    export type MutationManualProblemArgs = {
        input: ManualProblemMutationInput;
    };

    export type MutationMeasurementArgs = {
        input: MeasurementMutationInput;
    };

    export type MutationPermissionArgs = {
        input: PermissionMutationInput;
    };

    export type MutationPoolArgs = {
        input: PoolMutationInput;
    };

    export type MutationPoolAttachedFlagFieldArgs = {
        input: PoolAttachedFlagFieldMutationInput;
    };

    export type MutationPoolBottleArgs = {
        input: PoolBottleMutationInput;
    };

    export type MutationPoolBottleLeaseArgs = {
        input: PoolBottleLeaseMutationInput;
    };

    export type MutationPoolCalibrationArgs = {
        input: PoolCalibrationMutationInput;
    };

    export type MutationPoolDeclarationFlagFieldArgs = {
        input: PoolDeclarationFlagFieldMutationInput;
    };

    export type MutationPoolNoteArgs = {
        input: PoolNoteMutationInput;
    };

    export type MutationPoolProductDeclarationArgs = {
        input: PoolProductDeclarationMutationInput;
    };

    export type MutationPoolTypeArgs = {
        input: PoolTypeMutationInput;
    };

    export type MutationProductArgs = {
        input: ProductMutationInput;
    };

    export type MutationProductCompanyArgs = {
        input: ProductCompanyMutationInput;
    };

    export type MutationProductDeclarationFlagFieldArgs = {
        input: ProductDeclarationFlagFieldMutationInput;
    };

    export type MutationProductDeclarationFloatFieldArgs = {
        input: ProductDeclarationFloatFieldMutationInput;
    };

    export type MutationProductDeclarationIntegerFieldArgs = {
        input: ProductDeclarationIntegerFieldMutationInput;
    };

    export type MutationProductDeclarationMeasurementFieldArgs = {
        input: ProductDeclarationMeasurementFieldMutationInput;
    };

    export type MutationProductDeclarationSelectionChoiceArgs = {
        input: ProductDeclarationSelectionChoiceMutationInput;
    };

    export type MutationProductDeclarationSelectionFieldArgs = {
        input: ProductDeclarationSelectionFieldMutationInput;
    };

    export type MutationProductDeclarationStringFieldArgs = {
        input: ProductDeclarationStringFieldMutationInput;
    };

    export type MutationProductDeclarationUnitFieldArgs = {
        input: ProductDeclarationUnitFieldMutationInput;
    };

    export type MutationProductDocumentArgs = {
        input: ProductDocumentMutationInput;
    };

    export type MutationProductTraitArgs = {
        input: ProductTraitMutationInput;
    };

    export type MutationProductTraitDeclarationArgs = {
        input: ProductTraitDeclarationMutationInput;
    };

    export type MutationProductTraitFlagValueArgs = {
        input: ProductTraitFlagValueMutationInput;
    };

    export type MutationProductTraitFloatValueArgs = {
        input: ProductTraitFloatValueMutationInput;
    };

    export type MutationProductTraitIntegerValueArgs = {
        input: ProductTraitIntegerValueMutationInput;
    };

    export type MutationProductTraitMeasurementValueArgs = {
        input: ProductTraitMeasurementValueMutationInput;
    };

    export type MutationProductTraitSelectionValueArgs = {
        input: ProductTraitSelectionValueMutationInput;
    };

    export type MutationProductTraitStringValueArgs = {
        input: ProductTraitStringValueMutationInput;
    };

    export type MutationProductTraitUnitValueArgs = {
        input: ProductTraitUnitValueMutationInput;
    };

    export type MutationProjectArgs = {
        input: ProjectMutationInput;
    };

    export type MutationProjectVersionArgs = {
        input: ProjectVersionMutationInput;
    };

    export type MutationRecommendedTreatmentArgs = {
        input: RecommendedTreatmentMutationInput;
    };

    export type MutationRoleArgs = {
        input: RoleMutationInput;
    };

    export type MutationRoleFlagArgs = {
        input: RoleFlagMutationInput;
    };

    export type MutationSampleArgs = {
        input: SampleMutationInput;
    };

    export type MutationSampleConverterArgs = {
        input: SampleConverterMutationInput;
    };

    export type MutationSampleConverterTemplateArgs = {
        input: SampleConverterTemplateMutationInput;
    };

    export type MutationSampleSetArgs = {
        input: SampleSetMutationInput;
    };

    export type MutationSampleSourceArgs = {
        input: SampleSourceMutationInput;
    };

    export type MutationSanitiserArgs = {
        input: SanitiserMutationInput;
    };

    export type MutationScheduleEventArgs = {
        input: ScheduleEventMutationInput;
    };

    export type MutationServiceJobArgs = {
        input: ServiceJobMutationInput;
    };

    export type MutationServiceJobGroupArgs = {
        input: ServiceJobGroupMutationInput;
    };

    export type MutationServiceJobNoteArgs = {
        input: ServiceJobNoteMutationInput;
    };

    export type MutationServiceJobStageArgs = {
        input: ServiceJobStageMutationInput;
    };

    export type MutationServiceJobTemplateArgs = {
        input: ServiceJobTemplateMutationInput;
    };

    export type MutationSettingStoreArgs = {
        input: SettingStoreMutationInput;
    };

    export type MutationSharedEntityArgs = {
        input: SharedEntityMutationInput;
    };

    export type MutationSiteArgs = {
        input: SiteMutationInput;
    };

    export type MutationSiteNoteArgs = {
        input: SiteNoteMutationInput;
    };

    export type MutationStaffArgs = {
        input: StaffMutationInput;
    };

    export type MutationStaffAccessTokenArgs = {
        input: StaffAccessTokenMutationInput;
    };

    export type MutationStaffAccessTokenRegistrationArgs = {
        input: StaffAccessTokenRegistrationMutationInput;
    };

    export type MutationStaffRegistrationArgs = {
        input: StaffRegistrationMutationInput;
    };

    export type MutationStaffTeamArgs = {
        input: StaffTeamMutationInput;
    };

    export type MutationStaffTeamLinkArgs = {
        input: StaffTeamLinkMutationInput;
    };

    export type MutationStaffValueStoreArgs = {
        input: StaffValueStoreMutationInput;
    };

    export type MutationTestCriteriaArgs = {
        input: TestCriteriaMutationInput;
    };

    export type MutationTestProblemArgs = {
        input: TestProblemMutationInput;
    };

    export type MutationTestRecommendationArgs = {
        input: TestRecommendationMutationInput;
    };

    export type MutationTestReportArgs = {
        input: TestReportMutationInput;
    };

    export type MutationTestReportAttachmentArgs = {
        input: TestReportAttachmentMutationInput;
    };

    export type MutationTestReportSolutionArgs = {
        input: TestReportSolutionMutationInput;
    };

    export type MutationTestTreatmentArgs = {
        input: TestTreatmentMutationInput;
    };

    export type MutationTestTreatmentFranchiseGroupArgs = {
        input: TestTreatmentFranchiseGroupMutationInput;
    };

    export type MutationTestTreatmentFranchisePriorityArgs = {
        input: TestTreatmentFranchisePriorityMutationInput;
    };

    export type MutationTestTreatmentGroupArgs = {
        input: TestTreatmentGroupMutationInput;
    };

    export type MutationTreatmentAffectArgs = {
        input: TreatmentAffectMutationInput;
    };

    export type MutationUnitArgs = {
        input: UnitMutationInput;
    };

    export type MutationUnitConversionArgs = {
        input: UnitConversionMutationInput;
    };

    export type MutationUniversalCriteriaArgs = {
        input: UniversalCriteriaMutationInput;
    };

    export type MutationVendAPIArgs = {
        input: VendAPIMutationInput;
    };

    export type MutationVendCustomerArgs = {
        input: VendCustomerMutationInput;
    };

    export type MutationVendLinkArgs = {
        input: VendLinkMutationInput;
    };

    export type MutationVendSaleArgs = {
        input: VendSaleMutationInput;
    };

    export type MutationWarrantyClaimArgs = {
        input: WarrantyClaimMutationInput;
    };

    export type MutationWarrantyClaimCaseArgs = {
        input: WarrantyClaimCaseMutationInput;
    };

    export type MutationWarrantyClaimFileAttachArgs = {
        input: WarrantyClaimFileAttachMutationInput;
    };

    export type MutationWarrantyClaimMessageArgs = {
        input: WarrantyClaimMessageMutationInput;
    };

    export type MutationWaterTestingRevisionArgs = {
        input: WaterTestingRevisionMutationInput;
    };

    export type MutationWorkOrderArgs = {
        input: WorkOrderMutationInput;
    };

    export type MutationWorkOrderFeedItemArgs = {
        input: WorkOrderFeedItemMutationInput;
    };

    export type MutationWorkOrderNoteArgs = {
        input: WorkOrderNoteMutationInput;
    };

    export type MutationWorkOrderTemplateArgs = {
        input: WorkOrderTemplateMutationInput;
    };

    export type MutationWorkOrderTemplateAssocArgs = {
        input: WorkOrderTemplateAssocMutationInput;
    };

    export type MutationLoginStaffArgs = {
        input: LoginStaffInput;
    };

    export type MutationPretendArgs = {
        input: PretendInput;
    };

    export type MutationUnpretendArgs = {
        input: RemovePretendInput;
    };

    /** A connection to a list of items. */
    export type NewAppointmentsForRecentActivityToActivitySampleConnection = {
        __typename?: 'NewAppointmentsForRecentActivityToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewAppointmentsForRecentActivityToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewAppointmentsForRecentActivityToActivitySampleEdge = {
        __typename?: 'NewAppointmentsForRecentActivityToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type NewCustomersForHQFranchisesStatsToActivitySampleConnection = {
        __typename?: 'NewCustomersForHQFranchisesStatsToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewCustomersForHQFranchisesStatsToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewCustomersForHQFranchisesStatsToActivitySampleEdge = {
        __typename?: 'NewCustomersForHQFranchisesStatsToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type NewCustomersForRecentActivityToActivitySampleConnection = {
        __typename?: 'NewCustomersForRecentActivityToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewCustomersForRecentActivityToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewCustomersForRecentActivityToActivitySampleEdge = {
        __typename?: 'NewCustomersForRecentActivityToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type NewPoolsForRecentActivityToActivitySampleConnection = {
        __typename?: 'NewPoolsForRecentActivityToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewPoolsForRecentActivityToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewPoolsForRecentActivityToActivitySampleEdge = {
        __typename?: 'NewPoolsForRecentActivityToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type NewReportsForRecentActivityToActivitySampleConnection = {
        __typename?: 'NewReportsForRecentActivityToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewReportsForRecentActivityToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewReportsForRecentActivityToActivitySampleEdge = {
        __typename?: 'NewReportsForRecentActivityToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type NewServiceJobsForRecentActivityToActivitySampleConnection = {
        __typename?: 'NewServiceJobsForRecentActivityToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NewServiceJobsForRecentActivityToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type NewServiceJobsForRecentActivityToActivitySampleEdge = {
        __typename?: 'NewServiceJobsForRecentActivityToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** An object with an ID */
    export type Node = {
        /** The id of the object. */
        id: Scalars['ID'];
    };

    export type NodeAbstractDocumentPermissions = {
        __typename?: 'NodeAbstractDocumentPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAddressPermissions = {
        __typename?: 'NodeAddressPermissions';
        cloneForEntity?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAnnouncementPermissions = {
        __typename?: 'NodeAnnouncementPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        publish?: Maybe<Scalars['Boolean']>;
        unpublish?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAppointmentGroupPermissions = {
        __typename?: 'NodeAppointmentGroupPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAppointmentInstancePermissions = {
        __typename?: 'NodeAppointmentInstancePermissions';
        addSale?: Maybe<Scalars['Boolean']>;
        confirmStandAlone?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        startWorkOrder?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAppointmentItemPermissions = {
        __typename?: 'NodeAppointmentItemPermissions';
        addSale?: Maybe<Scalars['Boolean']>;
        changeEndDate?: Maybe<Scalars['Boolean']>;
        changeRecurrence?: Maybe<Scalars['Boolean']>;
        changeRecurrenceFrom?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        moveDate?: Maybe<Scalars['Boolean']>;
        startWorkOrder?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateFuture?: Maybe<Scalars['Boolean']>;
        updateSingle?: Maybe<Scalars['Boolean']>;
    };

    export type NodeAppointmentPermissions = {
        __typename?: 'NodeAppointmentPermissions';
        addRecurrence?: Maybe<Scalars['Boolean']>;
        changeBlockEndDate?: Maybe<Scalars['Boolean']>;
        changeEndDate?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        deleteBlock?: Maybe<Scalars['Boolean']>;
        replaceTemplate?: Maybe<Scalars['Boolean']>;
        setWorkOrderTemplate?: Maybe<Scalars['Boolean']>;
        split?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateTime?: Maybe<Scalars['Boolean']>;
        updateTimeZone?: Maybe<Scalars['Boolean']>;
        updateTimeZoneFrom?: Maybe<Scalars['Boolean']>;
    };

    export type NodeBrandPermissions = {
        __typename?: 'NodeBrandPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeConsumableProductPermissions = {
        __typename?: 'NodeConsumableProductPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeContactPermissions = {
        __typename?: 'NodeContactPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        makePrimary?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeContactTypePermissions = {
        __typename?: 'NodeContactTypePermissions';
        create?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeCustomContractPermissions = {
        __typename?: 'NodeCustomContractPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeCustomerNotePermissions = {
        __typename?: 'NodeCustomerNotePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeCustomerPermissions = {
        __typename?: 'NodeCustomerPermissions';
        create?: Maybe<Scalars['Boolean']>;
        customCreate?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeCustomerTagPermissions = {
        __typename?: 'NodeCustomerTagPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEZCDeviceLinkPermissions = {
        __typename?: 'NodeEZCDeviceLinkPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEZCFranchiseLinkPermissions = {
        __typename?: 'NodeEZCFranchiseLinkPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        link?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEmailGlobalSelectionPermissions = {
        __typename?: 'NodeEmailGlobalSelectionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEmailMessagePermissions = {
        __typename?: 'NodeEmailMessagePermissions';
        create?: Maybe<Scalars['Boolean']>;
        createForReportAndSend?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEmailTemplateOrganisationSelectionPermissions = {
        __typename?: 'NodeEmailTemplateOrganisationSelectionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEmailTemplatePermissions = {
        __typename?: 'NodeEmailTemplatePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        selectForFranchise?: Maybe<Scalars['Boolean']>;
        selectForOrganisation?: Maybe<Scalars['Boolean']>;
        selectGlobally?: Maybe<Scalars['Boolean']>;
        unselectForFranchise?: Maybe<Scalars['Boolean']>;
        unselectForOrganisation?: Maybe<Scalars['Boolean']>;
        unselectGlobally?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEmailTemplateSelectionPermissions = {
        __typename?: 'NodeEmailTemplateSelectionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        select?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeEntityPermissions = {
        __typename?: 'NodeEntityPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        superDelete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFeatureFlagDescriptionPermissions = {
        __typename?: 'NodeFeatureFlagDescriptionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFeatureFlagPermissions = {
        __typename?: 'NodeFeatureFlagPermissions';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFeatureFlagVariationPermissions = {
        __typename?: 'NodeFeatureFlagVariationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFileUploadPermissions = {
        __typename?: 'NodeFileUploadPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchisePermissions = {
        __typename?: 'NodeFranchisePermissions';
        create?: Maybe<Scalars['Boolean']>;
        createTrialFranchiseWithManager?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setParentFranchise?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchiseSequenceStorePermissions = {
        __typename?: 'NodeFranchiseSequenceStorePermissions';
        increment?: Maybe<Scalars['Boolean']>;
        incrementOwn?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchiseShopPermissions = {
        __typename?: 'NodeFranchiseShopPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchiseTestTreatmentViewPermissions = {
        __typename?: 'NodeFranchiseTestTreatmentViewPermissions';
        setBlacklist?: Maybe<Scalars['Boolean']>;
        setLocalPriority?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchiseValueStorePermissions = {
        __typename?: 'NodeFranchiseValueStorePermissions';
        delete?: Maybe<Scalars['Boolean']>;
        set?: Maybe<Scalars['Boolean']>;
        setOwn?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeFranchiseWarranterPermissions = {
        __typename?: 'NodeFranchiseWarranterPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeHeaterRecommendationPermissions = {
        __typename?: 'NodeHeaterRecommendationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeInstallableProductPermissions = {
        __typename?: 'NodeInstallableProductPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeItemPermissions = {
        __typename?: 'NodeItemPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        transferTo?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeJobTodoItemPermissions = {
        __typename?: 'NodeJobTodoItemPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeJobTodoPermissions = {
        __typename?: 'NodeJobTodoPermissions';
        create?: Maybe<Scalars['Boolean']>;
        createFromTemplate?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeJobTodoTemplateAssocPermissions = {
        __typename?: 'NodeJobTodoTemplateAssocPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeJobTodoTemplateItemPermissions = {
        __typename?: 'NodeJobTodoTemplateItemPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeJobTodoTemplatePermissions = {
        __typename?: 'NodeJobTodoTemplatePermissions';
        archive?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeManualProblemPermissions = {
        __typename?: 'NodeManualProblemPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeMeasurementPermissions = {
        __typename?: 'NodeMeasurementPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePermissionPermissions = {
        __typename?: 'NodePermissionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolAttachedFlagFieldPermissions = {
        __typename?: 'NodePoolAttachedFlagFieldPermissions';
        setValue?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolBottleLeasePermissions = {
        __typename?: 'NodePoolBottleLeasePermissions';
        create?: Maybe<Scalars['Boolean']>;
        issueLease?: Maybe<Scalars['Boolean']>;
        processReturn?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolBottlePermissions = {
        __typename?: 'NodePoolBottlePermissions';
        create?: Maybe<Scalars['Boolean']>;
        processReturn?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolCalibrationPermissions = {
        __typename?: 'NodePoolCalibrationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolDeclarationFlagFieldPermissions = {
        __typename?: 'NodePoolDeclarationFlagFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setActive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolNotePermissions = {
        __typename?: 'NodePoolNotePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolPermissions = {
        __typename?: 'NodePoolPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        migrateToEntity?: Maybe<Scalars['Boolean']>;
        performTransferToOwnerSite?: Maybe<Scalars['Boolean']>;
        transferToSite?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolProductDeclarationPermissions = {
        __typename?: 'NodePoolProductDeclarationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodePoolTypePermissions = {
        __typename?: 'NodePoolTypePermissions';
        changePublishedStatus?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductCompanyPermissions = {
        __typename?: 'NodeProductCompanyPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationFlagFieldPermissions = {
        __typename?: 'NodeProductDeclarationFlagFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationFloatFieldPermissions = {
        __typename?: 'NodeProductDeclarationFloatFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationIntegerFieldPermissions = {
        __typename?: 'NodeProductDeclarationIntegerFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationMeasurementFieldPermissions = {
        __typename?: 'NodeProductDeclarationMeasurementFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationSelectionChoicePermissions = {
        __typename?: 'NodeProductDeclarationSelectionChoicePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationSelectionFieldPermissions = {
        __typename?: 'NodeProductDeclarationSelectionFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationStringFieldPermissions = {
        __typename?: 'NodeProductDeclarationStringFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDeclarationUnitFieldPermissions = {
        __typename?: 'NodeProductDeclarationUnitFieldPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductDocumentPermissions = {
        __typename?: 'NodeProductDocumentPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductPermissions = {
        __typename?: 'NodeProductPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        retire?: Maybe<Scalars['Boolean']>;
        unretire?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitDeclarationPermissions = {
        __typename?: 'NodeProductTraitDeclarationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitFlagValuePermissions = {
        __typename?: 'NodeProductTraitFlagValuePermissions';
        assign?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitFloatValuePermissions = {
        __typename?: 'NodeProductTraitFloatValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitIntegerValuePermissions = {
        __typename?: 'NodeProductTraitIntegerValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitMeasurementValuePermissions = {
        __typename?: 'NodeProductTraitMeasurementValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitPermissions = {
        __typename?: 'NodeProductTraitPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitSelectionValuePermissions = {
        __typename?: 'NodeProductTraitSelectionValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitStringValuePermissions = {
        __typename?: 'NodeProductTraitStringValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProductTraitUnitValuePermissions = {
        __typename?: 'NodeProductTraitUnitValuePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProjectPermissions = {
        __typename?: 'NodeProjectPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        incrementRelease?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeProjectVersionPermissions = {
        __typename?: 'NodeProjectVersionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeRecommendedTreatmentPermissions = {
        __typename?: 'NodeRecommendedTreatmentPermissions';
        override?: Maybe<Scalars['Boolean']>;
        reset?: Maybe<Scalars['Boolean']>;
    };

    export type NodeRoleFlagPermissions = {
        __typename?: 'NodeRoleFlagPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeRolePermissions = {
        __typename?: 'NodeRolePermissions';
        create?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSampleConverterPermissions = {
        __typename?: 'NodeSampleConverterPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSampleConverterTemplatePermissions = {
        __typename?: 'NodeSampleConverterTemplatePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSamplePermissions = {
        __typename?: 'NodeSamplePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSampleSetPermissions = {
        __typename?: 'NodeSampleSetPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSampleSourcePermissions = {
        __typename?: 'NodeSampleSourcePermissions';
        create?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSanitiserPermissions = {
        __typename?: 'NodeSanitiserPermissions';
        changePublishedStatus?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeScheduleEventPermissions = {
        __typename?: 'NodeScheduleEventPermissions';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobGroupPermissions = {
        __typename?: 'NodeServiceJobGroupPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobNotePermissions = {
        __typename?: 'NodeServiceJobNotePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobPermissions = {
        __typename?: 'NodeServiceJobPermissions';
        archive?: Maybe<Scalars['Boolean']>;
        clearImplicit?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        createForRecurrentAppointment?: Maybe<Scalars['Boolean']>;
        createForSingleAppointment?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        deleteIfImplicit?: Maybe<Scalars['Boolean']>;
        evaluateIfStillSimpleState?: Maybe<Scalars['Boolean']>;
        replaceTemplate?: Maybe<Scalars['Boolean']>;
        setStopDate?: Maybe<Scalars['Boolean']>;
        splitRecurrentJob?: Maybe<Scalars['Boolean']>;
        unArchive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateRecurringJob?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobStageHistoryPermissions = {
        __typename?: 'NodeServiceJobStageHistoryPermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobStagePermissions = {
        __typename?: 'NodeServiceJobStagePermissions';
        archive?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeServiceJobTemplatePermissions = {
        __typename?: 'NodeServiceJobTemplatePermissions';
        archive?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setAsDefault?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSettingStorePermissions = {
        __typename?: 'NodeSettingStorePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSharedEntityPermissions = {
        __typename?: 'NodeSharedEntityPermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSiteNotePermissions = {
        __typename?: 'NodeSiteNotePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeSitePermissions = {
        __typename?: 'NodeSitePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        transferTo?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffAccessTokenPermissions = {
        __typename?: 'NodeStaffAccessTokenPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        refresh?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffPermissions = {
        __typename?: 'NodeStaffPermissions';
        archive?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        unarchive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffRegistrationPermissions = {
        __typename?: 'NodeStaffRegistrationPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        disable?: Maybe<Scalars['Boolean']>;
        enable?: Maybe<Scalars['Boolean']>;
        forcePassword?: Maybe<Scalars['Boolean']>;
        invalidatePassword?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffTeamLinkPermissions = {
        __typename?: 'NodeStaffTeamLinkPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffTeamPermissions = {
        __typename?: 'NodeStaffTeamPermissions';
        addStaff?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeStaffValueStorePermissions = {
        __typename?: 'NodeStaffValueStorePermissions';
        delete?: Maybe<Scalars['Boolean']>;
        set?: Maybe<Scalars['Boolean']>;
        setOwn?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestCriteriaPermissions = {
        __typename?: 'NodeTestCriteriaPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestProblemPermissions = {
        __typename?: 'NodeTestProblemPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestRecommendationPermissions = {
        __typename?: 'NodeTestRecommendationPermissions';
        selectTreatment?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestReportAttachmentPermissions = {
        __typename?: 'NodeTestReportAttachmentPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestReportPermissions = {
        __typename?: 'NodeTestReportPermissions';
        archiveReport?: Maybe<Scalars['Boolean']>;
        assembleReportDocument?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        finaliseReport?: Maybe<Scalars['Boolean']>;
        refreshDocument?: Maybe<Scalars['Boolean']>;
        solveReport?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestReportSolutionPermissions = {
        __typename?: 'NodeTestReportSolutionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        override?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestTreatmentFranchiseBlacklistPermissions = {
        __typename?: 'NodeTestTreatmentFranchiseBlacklistPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestTreatmentFranchiseGroupPermissions = {
        __typename?: 'NodeTestTreatmentFranchiseGroupPermissions';
        activate?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        deactivate?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestTreatmentFranchisePriorityPermissions = {
        __typename?: 'NodeTestTreatmentFranchisePriorityPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestTreatmentGroupPermissions = {
        __typename?: 'NodeTestTreatmentGroupPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTestTreatmentPermissions = {
        __typename?: 'NodeTestTreatmentPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setBlacklistForFranchise?: Maybe<Scalars['Boolean']>;
        setPriorityForFranchise?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeTreatmentAffectPermissions = {
        __typename?: 'NodeTreatmentAffectPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeUnitConversionPermissions = {
        __typename?: 'NodeUnitConversionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeUnitPermissions = {
        __typename?: 'NodeUnitPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeUniversalCriteriaPermissions = {
        __typename?: 'NodeUniversalCriteriaPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeUserPermissions = {
        __typename?: 'NodeUserPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeVendAPIPermissions = {
        __typename?: 'NodeVendAPIPermissions';
        addCustomer?: Maybe<Scalars['Boolean']>;
        connectCustomerToVendCRN?: Maybe<Scalars['Boolean']>;
        connectCustomerToVendCustomerID?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        exportToVend?: Maybe<Scalars['Boolean']>;
        importFromVend?: Maybe<Scalars['Boolean']>;
        legacyReconnect?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeVendAppointmentSaleTypePermissions = {
        __typename?: 'NodeVendAppointmentSaleTypePermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeVendCustomerPermissions = {
        __typename?: 'NodeVendCustomerPermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeVendLinkPermissions = {
        __typename?: 'NodeVendLinkPermissions';
        sync?: Maybe<Scalars['Boolean']>;
    };

    export type NodeVendSalePermissions = {
        __typename?: 'NodeVendSalePermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWarrantyClaimCasePermissions = {
        __typename?: 'NodeWarrantyClaimCasePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        executeConfirmRequest?: Maybe<Scalars['Boolean']>;
        markForMessage?: Maybe<Scalars['Boolean']>;
        markMessagesAsRead?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateStatus?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWarrantyClaimFileAttachPermissions = {
        __typename?: 'NodeWarrantyClaimFileAttachPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWarrantyClaimMessagePermissions = {
        __typename?: 'NodeWarrantyClaimMessagePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        postMessage?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWarrantyClaimPermissions = {
        __typename?: 'NodeWarrantyClaimPermissions';
        confirmClaim?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        executeWarrantyClaimRequest?: Maybe<Scalars['Boolean']>;
        fixUnsharedClaims?: Maybe<Scalars['Boolean']>;
        forceClaimCase?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateFromClaim?: Maybe<Scalars['Boolean']>;
        updateStatus?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWarrantyClaimStatusHistoryPermissions = {
        __typename?: 'NodeWarrantyClaimStatusHistoryPermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWaterTestingRevisionPermissions = {
        __typename?: 'NodeWaterTestingRevisionPermissions';
        create?: Maybe<Scalars['Boolean']>;
        increment?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderFeedItemPermissions = {
        __typename?: 'NodeWorkOrderFeedItemPermissions';
        startWorkOrder?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderNotePermissions = {
        __typename?: 'NodeWorkOrderNotePermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderPermissions = {
        __typename?: 'NodeWorkOrderPermissions';
        addJobTodoFromTemplate?: Maybe<Scalars['Boolean']>;
        addVendSale?: Maybe<Scalars['Boolean']>;
        appendWorkOrderTemplate?: Maybe<Scalars['Boolean']>;
        assignAppointmentItem?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        deleteTodo?: Maybe<Scalars['Boolean']>;
        replaceWorkOrderTemplate?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderStageHistoryPermissions = {
        __typename?: 'NodeWorkOrderStageHistoryPermissions';
        create?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderTemplateAssocPermissions = {
        __typename?: 'NodeWorkOrderTemplateAssocPermissions';
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setDefault?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type NodeWorkOrderTemplatePermissions = {
        __typename?: 'NodeWorkOrderTemplatePermissions';
        archive?: Maybe<Scalars['Boolean']>;
        create?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type NotesForCustomerToCustomerNoteConnection = {
        __typename?: 'NotesForCustomerToCustomerNoteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NotesForCustomerToCustomerNoteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<NotesForCustomerToCustomerNoteMeta>;
    };

    /** An edge in a connection. */
    export type NotesForCustomerToCustomerNoteEdge = {
        __typename?: 'NotesForCustomerToCustomerNoteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<CustomerNote>;
    };

    export type NotesForCustomerToCustomerNoteMeta = {
        __typename?: 'NotesForCustomerToCustomerNoteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type NotesForPoolToPoolNoteConnection = {
        __typename?: 'NotesForPoolToPoolNoteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NotesForPoolToPoolNoteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<NotesForPoolToPoolNoteMeta>;
    };

    /** An edge in a connection. */
    export type NotesForPoolToPoolNoteEdge = {
        __typename?: 'NotesForPoolToPoolNoteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolNote>;
    };

    export type NotesForPoolToPoolNoteMeta = {
        __typename?: 'NotesForPoolToPoolNoteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type NotesForServiceJobToServiceJobNoteConnection = {
        __typename?: 'NotesForServiceJobToServiceJobNoteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NotesForServiceJobToServiceJobNoteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<NotesForServiceJobToServiceJobNoteMeta>;
    };

    /** An edge in a connection. */
    export type NotesForServiceJobToServiceJobNoteEdge = {
        __typename?: 'NotesForServiceJobToServiceJobNoteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobNote>;
    };

    export type NotesForServiceJobToServiceJobNoteMeta = {
        __typename?: 'NotesForServiceJobToServiceJobNoteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type NotesForSiteToSiteNoteConnection = {
        __typename?: 'NotesForSiteToSiteNoteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NotesForSiteToSiteNoteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<NotesForSiteToSiteNoteMeta>;
    };

    /** An edge in a connection. */
    export type NotesForSiteToSiteNoteEdge = {
        __typename?: 'NotesForSiteToSiteNoteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SiteNote>;
    };

    export type NotesForSiteToSiteNoteMeta = {
        __typename?: 'NotesForSiteToSiteNoteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type NotesForWorkOrderToWorkOrderNoteConnection = {
        __typename?: 'NotesForWorkOrderToWorkOrderNoteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<NotesForWorkOrderToWorkOrderNoteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<NotesForWorkOrderToWorkOrderNoteMeta>;
    };

    /** An edge in a connection. */
    export type NotesForWorkOrderToWorkOrderNoteEdge = {
        __typename?: 'NotesForWorkOrderToWorkOrderNoteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderNote>;
    };

    export type NotesForWorkOrderToWorkOrderNoteMeta = {
        __typename?: 'NotesForWorkOrderToWorkOrderNoteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export enum OrganisationBrandingEnum {
        Aquatest = 'Aquatest',
        Aquatight = 'Aquatight',
        Asiapools = 'Asiapools',
        Autopool = 'Autopool',
        Davey = 'Davey',
        PoolwareTrial = 'PoolwareTrial',
        Swimart = 'Swimart',
        Waterco = 'Waterco',
        Zane = 'Zane',
    }

    export type OrganisationType = Node & {
        __typename?: 'OrganisationType';
        id: Scalars['ID'];
        locales?: Maybe<Scalars['String']>;
        logo?: Maybe<Scalars['String']>;
        name?: Maybe<Scalars['String']>;
        timeZone?: Maybe<Scalars['String']>;
        token?: Maybe<Scalars['String']>;
    };

    export type OrganisationTypeNodeMatchSearchCommon = {
        /** Expects node: **OrganisationType** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **OrganisationType** */
        not?: InputMaybe<Scalars['ID']>;
    };

    /** A connection to a list of items. */
    export type OutcomeForTestReportToSolutionAffectConnection = {
        __typename?: 'OutcomeForTestReportToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<OutcomeForTestReportToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type OutcomeForTestReportToSolutionAffectEdge = {
        __typename?: 'OutcomeForTestReportToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    /** A connection to a list of items. */
    export type OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateConnection = {
        __typename?: 'OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateMeta>;
    };

    /** An edge in a connection. */
    export type OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateEdge = {
        __typename?: 'OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoTemplate>;
    };

    export type OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateMeta = {
        __typename?: 'OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateConnection = {
        __typename?: 'OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateMeta>;
    };

    /** An edge in a connection. */
    export type OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateEdge = {
        __typename?: 'OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderTemplate>;
    };

    export type OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateMeta = {
        __typename?: 'OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type OwnershipHistoryForPoolToPoolOwnershipHistoryConnection = {
        __typename?: 'OwnershipHistoryForPoolToPoolOwnershipHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<OwnershipHistoryForPoolToPoolOwnershipHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<OwnershipHistoryForPoolToPoolOwnershipHistoryMeta>;
    };

    /** An edge in a connection. */
    export type OwnershipHistoryForPoolToPoolOwnershipHistoryEdge = {
        __typename?: 'OwnershipHistoryForPoolToPoolOwnershipHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolOwnershipHistory>;
    };

    export type OwnershipHistoryForPoolToPoolOwnershipHistoryMeta = {
        __typename?: 'OwnershipHistoryForPoolToPoolOwnershipHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** Information about pagination in a connection. */
    export type PageInfo = {
        __typename?: 'PageInfo';
        /** When paginating forwards, the cursor to continue. */
        endCursor?: Maybe<Scalars['String']>;
        /** When paginating forwards, are there more items? */
        hasNextPage: Scalars['Boolean'];
        /** When paginating backwards, are there more items? */
        hasPreviousPage: Scalars['Boolean'];
        /** When paginating backwards, the cursor to continue. */
        startCursor?: Maybe<Scalars['String']>;
    };

    export enum PatternTypeEnum {
        AbsoluteMonthly = 'AbsoluteMonthly',
        Daily = 'Daily',
        Monthly = 'Monthly',
        RelativeMonthly = 'RelativeMonthly',
        Weekly = 'Weekly',
    }

    /** A connection to a list of items. */
    export type PendingProblemsForTestReportToTestProblemConnection = {
        __typename?: 'PendingProblemsForTestReportToTestProblemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PendingProblemsForTestReportToTestProblemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type PendingProblemsForTestReportToTestProblemEdge = {
        __typename?: 'PendingProblemsForTestReportToTestProblemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestProblem>;
    };

    export type Permission = Node & {
        __typename?: 'Permission';
        checkMutations?: Maybe<PermissionMutationCheck>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        tag?: Maybe<Scalars['String']>;
    };

    export type PermissionMutationCheck = {
        __typename?: 'PermissionMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PermissionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePermissionMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePermissionMutationInput>>>;
    };

    export type PermissionMutationPayload = {
        __typename?: 'PermissionMutationPayload';
        Permission?: Maybe<Permission>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPermissionMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type PermissionViewerPermissionsSearch = {
        description?: InputMaybe<StringMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type PermissionsForRoleToPermissionConnection = {
        __typename?: 'PermissionsForRoleToPermissionConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PermissionsForRoleToPermissionEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type PermissionsForRoleToPermissionEdge = {
        __typename?: 'PermissionsForRoleToPermissionEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Permission>;
    };

    export type Pool = Node & {
        __typename?: 'Pool';
        actualChemicalTargets?: Maybe<ActualChemicalTargetsForPoolToPoolChemicalTargetConnection>;
        address?: Maybe<Address>;
        appointments?: Maybe<AppointmentsForPoolToAppointmentItemConnection>;
        attachedFlagFields?: Maybe<Array<Maybe<PoolAttachedFlagField>>>;
        bottleNumber?: Maybe<Scalars['String']>;
        calibrations?: Maybe<CalibrationsForPoolToPoolCalibrationConnection>;
        checkMutations?: Maybe<PoolMutationCheck>;
        chemicalTargets?: Maybe<ChemicalTargetsForPoolToPoolChemicalTargetConnection>;
        createdAt?: Maybe<Scalars['Date']>;
        criteria?: Maybe<CriteriaForPoolToTestCriteriaConnection>;
        dataOwnership?: Maybe<EntityOwnershipType>;
        entity?: Maybe<Entity>;
        ezcDeviceLinks?: Maybe<EzcDeviceLinksForPoolToEZCDeviceLinkConnection>;
        files?: Maybe<FilesForPoolToFileUploadConnection>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        installedSanitisers?: Maybe<Array<Maybe<Item>>>;
        items?: Maybe<ItemsForPoolToItemConnection>;
        name?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        notePlain?: Maybe<Scalars['String']>;
        notes?: Maybe<NotesForPoolToPoolNoteConnection>;
        ownershipHistory?: Maybe<OwnershipHistoryForPoolToPoolOwnershipHistoryConnection>;
        poolBottleLeases?: Maybe<PoolBottleLeasesForPoolToPoolBottleLeaseConnection>;
        productCriteria?: Maybe<ProductCriteriaForPoolToTestCriteriaConnection>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        reports?: Maybe<ReportsForPoolToTestReportConnection>;
        revision?: Maybe<Scalars['Int']>;
        sampleSets?: Maybe<SampleSetsForPoolToSampleSetConnection>;
        samples?: Maybe<SamplesForPoolToSampleConnection>;
        samplesMap?: Maybe<SamplesMapForPoolToSampleMapConnection>;
        sanitiser?: Maybe<Sanitiser>;
        serviceJobs?: Maybe<ServiceJobsForPoolToServiceJobConnection>;
        site?: Maybe<Site>;
        type?: Maybe<PoolType>;
        uuid?: Maybe<Scalars['String']>;
        volume?: Maybe<Scalars['Float']>;
    };

    export type PoolActualChemicalTargetsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolAppointmentsArgs = {
        addedBy?: InputMaybe<Scalars['ID']>;
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type PoolCalibrationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolChemicalTargetsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        all?: InputMaybe<Scalars['Boolean']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolEzcDeviceLinksArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EZCDeviceLinkPoolEzcDeviceLinksSearch>;
        sort?: InputMaybe<EZCDeviceLinkPoolEzcDeviceLinksSort>;
    };

    export type PoolFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadPoolFilesSearch>;
        sort?: InputMaybe<FileUploadPoolFilesSort>;
    };

    export type PoolItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ItemPoolItemsSearch>;
        sort?: InputMaybe<ItemPoolItemsSort>;
    };

    export type PoolNotesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolNotePoolNotesSearch>;
        sort?: InputMaybe<PoolNotePoolNotesSort>;
    };

    export type PoolOwnershipHistoryArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolOwnershipHistoryPoolOwnershipHistorySearch>;
        sort?: InputMaybe<PoolOwnershipHistoryPoolOwnershipHistorySort>;
    };

    export type PoolPoolBottleLeasesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolBottleLeasePoolPoolBottleLeasesSearch>;
        sort?: InputMaybe<PoolBottleLeasePoolPoolBottleLeasesSort>;
    };

    export type PoolProductCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolReportsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestReportPoolReportsSearch>;
        sort?: InputMaybe<TestReportPoolReportsSort>;
    };

    export type PoolSampleSetsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SampleSetPoolSampleSetsSearch>;
        sort?: InputMaybe<SampleSetPoolSampleSetsSort>;
    };

    export type PoolSamplesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SamplePoolSamplesSearch>;
        sort?: InputMaybe<SamplePoolSamplesSort>;
    };

    export type PoolSamplesMapArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolServiceJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobPoolServiceJobsSearch>;
        sort?: InputMaybe<ServiceJobPoolServiceJobsSort>;
    };

    export type PoolAttachedFlagField = Node & {
        __typename?: 'PoolAttachedFlagField';
        checkMutations?: Maybe<PoolAttachedFlagFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        fieldDeclaration?: Maybe<PoolDeclarationFlagField>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        pool?: Maybe<Pool>;
        setBy?: Maybe<Staff>;
        updatedAt?: Maybe<Scalars['Date']>;
        value?: Maybe<Scalars['Boolean']>;
    };

    export type PoolAttachedFlagFieldMutationCheck = {
        __typename?: 'PoolAttachedFlagFieldMutationCheck';
        setValue?: Maybe<Scalars['Boolean']>;
    };

    export type PoolAttachedFlagFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        setValue?: InputMaybe<Array<InputMaybe<PoolAttachedFlagFieldSetValueMutationInput>>>;
    };

    export type PoolAttachedFlagFieldMutationPayload = {
        __typename?: 'PoolAttachedFlagFieldMutationPayload';
        PoolAttachedFlagField?: Maybe<PoolAttachedFlagField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolAttachedFlagFieldMutationOutput>>>;
        updatedFieldDeclaration?: Maybe<PoolDeclarationFlagField>;
        updatedFranchise?: Maybe<Franchise>;
        updatedPool?: Maybe<Pool>;
        updatedSetBy?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolAttachedFlagFieldSetValueMutationInput = {
        /** Expects node: **PoolAttachedFlagField** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type PoolBottle = Node & {
        __typename?: 'PoolBottle';
        checkMutations?: Maybe<PoolBottleMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        lastLease?: Maybe<PoolBottleLease>;
        leases?: Maybe<LeasesForPoolBottleToPoolBottleLeaseConnection>;
        scanCode?: Maybe<Scalars['String']>;
    };

    export type PoolBottleLeasesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolBottleLeasePoolBottleLeasesSearch>;
        sort?: InputMaybe<PoolBottleLeasePoolBottleLeasesSort>;
    };

    export type PoolBottleLease = Node & {
        __typename?: 'PoolBottleLease';
        bottle?: Maybe<PoolBottle>;
        checkMutations?: Maybe<PoolBottleLeaseMutationCheck>;
        id: Scalars['ID'];
        leaseTo?: Maybe<Entity>;
        leasedAt?: Maybe<Scalars['Date']>;
        leasedBy?: Maybe<Staff>;
        pool?: Maybe<Pool>;
        receivedBy?: Maybe<Staff>;
        returnedAt?: Maybe<Scalars['Date']>;
    };

    export type PoolBottleLeaseIssueLeaseMutationInput = {
        /** Expects node: **PoolBottle** */
        bottle?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool: Scalars['ID'];
        scanCode?: InputMaybe<Scalars['String']>;
    };

    export type PoolBottleLeaseMutationCheck = {
        __typename?: 'PoolBottleLeaseMutationCheck';
        processReturn?: Maybe<Scalars['Boolean']>;
    };

    export type PoolBottleLeaseMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        issueLease?: InputMaybe<Array<InputMaybe<PoolBottleLeaseIssueLeaseMutationInput>>>;
        processReturn?: InputMaybe<Array<InputMaybe<PoolBottleLeaseProcessReturnMutationInput>>>;
    };

    export type PoolBottleLeaseMutationPayload = {
        __typename?: 'PoolBottleLeaseMutationPayload';
        PoolBottleLease?: Maybe<PoolBottleLease>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolBottleLeaseMutationOutput>>>;
        updatedBottle?: Maybe<PoolBottle>;
        updatedLeaseTo?: Maybe<Entity>;
        updatedLeasedBy?: Maybe<Staff>;
        updatedPool?: Maybe<Pool>;
        updatedReceivedBy?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolBottleLeasePoolBottleLeasesSearch = {
        leaseTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        leasedAt?: InputMaybe<DateRangeSearchCommon>;
        leasedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        receivedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        returnedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolBottleLeasePoolBottleLeasesSort = {
        /** Expects node: **PoolBottleLease** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        leasedAt?: InputMaybe<Scalars['Boolean']>;
        returnedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolBottleLeasePoolPoolBottleLeasesSearch = {
        bottle?: InputMaybe<PoolBottleLeasePoolPoolBottleLeasesSearchBottle>;
        leaseTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        leasedAt?: InputMaybe<DateRangeSearchCommon>;
        leasedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        receivedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        returnedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolBottleLeasePoolPoolBottleLeasesSearchBottle = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **PoolBottle** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolBottle** */
        not?: InputMaybe<Scalars['ID']>;
        scanCode?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolBottleLeasePoolPoolBottleLeasesSort = {
        /** Expects node: **PoolBottleLease** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        leasedAt?: InputMaybe<Scalars['Boolean']>;
        returnedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolBottleLeaseProcessReturnMutationInput = {
        /** Expects node: **PoolBottleLease** */
        id: Scalars['ID'];
    };

    export type PoolBottleLeaseViewerPoolBottleLeasesSearch = {
        bottle?: InputMaybe<PoolBottleLeaseViewerPoolBottleLeasesSearchBottle>;
        leaseTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        leasedAt?: InputMaybe<DateRangeSearchCommon>;
        leasedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        receivedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        returnedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolBottleLeaseViewerPoolBottleLeasesSearchBottle = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **PoolBottle** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolBottle** */
        not?: InputMaybe<Scalars['ID']>;
        scanCode?: InputMaybe<StringMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type PoolBottleLeasesForPoolToPoolBottleLeaseConnection = {
        __typename?: 'PoolBottleLeasesForPoolToPoolBottleLeaseConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolBottleLeasesForPoolToPoolBottleLeaseEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolBottleLeasesForPoolToPoolBottleLeaseMeta>;
    };

    /** An edge in a connection. */
    export type PoolBottleLeasesForPoolToPoolBottleLeaseEdge = {
        __typename?: 'PoolBottleLeasesForPoolToPoolBottleLeaseEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolBottleLease>;
    };

    export type PoolBottleLeasesForPoolToPoolBottleLeaseMeta = {
        __typename?: 'PoolBottleLeasesForPoolToPoolBottleLeaseMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type PoolBottleMutationCheck = {
        __typename?: 'PoolBottleMutationCheck';
        processReturn?: Maybe<Scalars['Boolean']>;
    };

    export type PoolBottleMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolBottleMutationInput>>>;
        processReturn?: InputMaybe<Array<InputMaybe<PoolBottleProcessReturnMutationInput>>>;
    };

    export type PoolBottleMutationPayload = {
        __typename?: 'PoolBottleMutationPayload';
        PoolBottle?: Maybe<PoolBottle>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolBottleMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolBottleProcessReturnMutationInput = {
        /** Expects node: **PoolBottle** */
        id: Scalars['ID'];
    };

    export type PoolBottleViewerPoolBottlesSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        scanCode?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolCalibration = Node & {
        __typename?: 'PoolCalibration';
        checkMutations?: Maybe<PoolCalibrationMutationCheck>;
        higherThreshold?: Maybe<Scalars['Float']>;
        id: Scalars['ID'];
        lowerThreshold?: Maybe<Scalars['Float']>;
        measurementType?: Maybe<Measurement>;
        pool?: Maybe<Pool>;
        required?: Maybe<Scalars['Boolean']>;
        target?: Maybe<Scalars['Float']>;
    };

    export type PoolCalibrationMutationCheck = {
        __typename?: 'PoolCalibrationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolCalibrationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolCalibrationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolCalibrationMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolCalibrationMutationInput>>>;
    };

    export type PoolCalibrationMutationPayload = {
        __typename?: 'PoolCalibrationMutationPayload';
        PoolCalibration?: Maybe<PoolCalibration>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolCalibrationMutationOutput>>>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedPool?: Maybe<Pool>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolChemicalTarget = {
        __typename?: 'PoolChemicalTarget';
        calibration?: Maybe<PoolCalibration>;
        higherThreshold?: Maybe<Scalars['Float']>;
        isCalibrated?: Maybe<Scalars['Boolean']>;
        lowerThreshold?: Maybe<Scalars['Float']>;
        measurementType?: Maybe<Measurement>;
        pool?: Maybe<Pool>;
        target?: Maybe<Scalars['Float']>;
    };

    export type PoolDeclarationFlagField = Node & {
        __typename?: 'PoolDeclarationFlagField';
        activatedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<PoolDeclarationFlagFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        description?: Maybe<Scalars['String']>;
        exportName?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisation?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        useInExport?: Maybe<Scalars['Boolean']>;
    };

    export type PoolDeclarationFlagFieldMutationCheck = {
        __typename?: 'PoolDeclarationFlagFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        setActive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolDeclarationFlagFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolDeclarationFlagFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolDeclarationFlagFieldMutationInput>>>;
        setActive?: InputMaybe<Array<InputMaybe<PoolDeclarationFlagFieldSetActiveMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolDeclarationFlagFieldMutationInput>>>;
    };

    export type PoolDeclarationFlagFieldMutationPayload = {
        __typename?: 'PoolDeclarationFlagFieldMutationPayload';
        PoolDeclarationFlagField?: Maybe<PoolDeclarationFlagField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolDeclarationFlagFieldMutationOutput>>>;
        updatedOrganisation?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolDeclarationFlagFieldSetActiveMutationInput = {
        /** Expects node: **PoolDeclarationFlagField** */
        id: Scalars['ID'];
        state: Scalars['Boolean'];
    };

    export type PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSearch = {
        name?: InputMaybe<StringMatchSearchCommon>;
        organisation?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolDeclarationFlagField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolEntityPoolsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        ownershipHistory?: InputMaybe<PoolEntityPoolsSearchOwnershipHistory>;
        reports?: InputMaybe<PoolEntityPoolsSearchReports>;
        sanitiser?: InputMaybe<PoolEntityPoolsSearchSanitiser>;
        site?: InputMaybe<PoolEntityPoolsSearchSite>;
        type?: InputMaybe<PoolEntityPoolsSearchType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<PoolEntityPoolsSearchReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolEntityPoolsSearchSanitiserRelatedDeclarations>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolEntityPoolsSearchSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolEntityPoolsSearchSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolEntityPoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolEntityPoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolEntityPoolsSearchSite = {
        address?: InputMaybe<PoolEntityPoolsSearchSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolEntityPoolsSearchTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblem>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolEntityPoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolEntityPoolsSort = {
        bottleNumber?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Pool** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        volume?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolMutationCheck = {
        __typename?: 'PoolMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        transferToSite?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolMutationInput>>>;
        transferToSite?: InputMaybe<Array<InputMaybe<PoolTransferToSiteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolMutationInput>>>;
    };

    export type PoolMutationPayload = {
        __typename?: 'PoolMutationPayload';
        Pool?: Maybe<Pool>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolMutationOutput>>>;
        updatedEntity?: Maybe<Entity>;
        updatedSanitiser?: Maybe<Sanitiser>;
        updatedSite?: Maybe<Site>;
        updatedType?: Maybe<PoolType>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolNodeMatchSearchCommon = {
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolNote = Node & {
        __typename?: 'PoolNote';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<PoolNoteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        parent?: Maybe<Pool>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type PoolNoteMutationCheck = {
        __typename?: 'PoolNoteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolNoteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolNoteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolNoteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolNoteMutationInput>>>;
    };

    export type PoolNoteMutationPayload = {
        __typename?: 'PoolNoteMutationPayload';
        PoolNote?: Maybe<PoolNote>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolNoteMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Pool>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolNotePoolNotesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolNotePoolNotesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolNote** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolOwnershipHistory = Node & {
        __typename?: 'PoolOwnershipHistory';
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        pool?: Maybe<Pool>;
        transferredBy?: Maybe<Staff>;
        transferredFrom?: Maybe<Entity>;
        transferredTo?: Maybe<Entity>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearch = {
        pool?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPool>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        reports?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolReports>;
        sanitiser?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiser>;
        site?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSite>;
        type?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSite = {
        address?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityPreviouslyOwnedPoolsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolOwnershipHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearch = {
        pool?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPool>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        reports?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolReports>;
        sanitiser?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiser>;
        site?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSite>;
        type?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSite = {
        address?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryEntityTransferGainedPoolsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolOwnershipHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type PoolOwnershipHistoryPoolOwnershipHistorySearch = {
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryPoolOwnershipHistorySort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolOwnershipHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearch = {
        pool?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPool>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        reports?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReports>;
        sanitiser?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiser>;
        site?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSite>;
        type?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntity = {
        addresses?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJob>;
        sites?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntitySites>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntitySites = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportTo>;
        sampleSet?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportTo = {
        addresses?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJob>;
        sites?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToSites>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToSites = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerTags>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSite = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntity = {
        addresses?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJob>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryStaffPoolOwnershipTransfersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolOwnershipHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearch = {
        pool?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPool>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        reports?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReports>;
        sanitiser?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiser>;
        site?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSite>;
        type?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntity = {
        addresses?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJob>;
        sites?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntitySites>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntitySites = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedByTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportTo>;
        sampleSet?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportTo = {
        addresses?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJob>;
        sites?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToSites>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToSites = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerTags>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSite = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntity = {
        addresses?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJob>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJob = {
        address?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobWorkOrders>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaff>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomer = {
        addedBy?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerTags>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedByTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaffTeams>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolOwnershipHistoryViewerPoolTransfersSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolOwnershipHistoryViewerPoolTransfersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolOwnershipHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type PoolOwnershipTransfersForStaffToPoolOwnershipHistoryConnection = {
        __typename?: 'PoolOwnershipTransfersForStaffToPoolOwnershipHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolOwnershipTransfersForStaffToPoolOwnershipHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolOwnershipTransfersForStaffToPoolOwnershipHistoryMeta>;
    };

    /** An edge in a connection. */
    export type PoolOwnershipTransfersForStaffToPoolOwnershipHistoryEdge = {
        __typename?: 'PoolOwnershipTransfersForStaffToPoolOwnershipHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolOwnershipHistory>;
    };

    export type PoolOwnershipTransfersForStaffToPoolOwnershipHistoryMeta = {
        __typename?: 'PoolOwnershipTransfersForStaffToPoolOwnershipHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type PoolPoolTypePoolsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolPoolTypePoolsSearchEntity>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        ownershipHistory?: InputMaybe<PoolPoolTypePoolsSearchOwnershipHistory>;
        reports?: InputMaybe<PoolPoolTypePoolsSearchReports>;
        sanitiser?: InputMaybe<PoolPoolTypePoolsSearchSanitiser>;
        site?: InputMaybe<PoolPoolTypePoolsSearchSite>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntity = {
        addresses?: InputMaybe<PoolPoolTypePoolsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolPoolTypePoolsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJob>;
        sites?: InputMaybe<PoolPoolTypePoolsSearchEntitySites>;
        user?: InputMaybe<PoolPoolTypePoolsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJob = {
        address?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobWorkOrders>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerUser>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolPoolTypePoolsSearchEntitySites = {
        address?: InputMaybe<PoolPoolTypePoolsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchEntityUserStaff>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerTags>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchEntityUserCustomerAddedByTeams>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchEntityUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolPoolTypePoolsSearchReportsReportTo>;
        sampleSet?: InputMaybe<PoolPoolTypePoolsSearchReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportTo = {
        addresses?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJob>;
        sites?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToSites>;
        user?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJob = {
        address?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobWorkOrders>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUser>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToSites = {
        address?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserStaff>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerTags>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchReportsReportToUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolPoolTypePoolsSearchSanitiserRelatedDeclarations>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSite = {
        address?: InputMaybe<PoolPoolTypePoolsSearchSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolPoolTypePoolsSearchSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserStaff>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerTags>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteAddressEntityUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntity = {
        addresses?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJob>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJob = {
        address?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobWorkOrders>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUser>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaff>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserStaff>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomer = {
        addedBy?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerTags>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedByTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolPoolTypePoolsSearchSiteEntityUserStaffTeams>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolPoolTypePoolsSearchSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolPoolTypePoolsSort = {
        bottleNumber?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Pool** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        volume?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolProductDeclaration = Node & {
        __typename?: 'PoolProductDeclaration';
        checkMutations?: Maybe<PoolProductDeclarationMutationCheck>;
        declaration?: Maybe<ProductTraitDeclaration>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        organisationType?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
    };

    export type PoolProductDeclarationMutationCheck = {
        __typename?: 'PoolProductDeclarationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolProductDeclarationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolProductDeclarationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolProductDeclarationMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolProductDeclarationMutationInput>>>;
    };

    export type PoolProductDeclarationMutationPayload = {
        __typename?: 'PoolProductDeclarationMutationPayload';
        PoolProductDeclaration?: Maybe<PoolProductDeclaration>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolProductDeclarationMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearch = {
        declaration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclaration>;
        optional?: InputMaybe<BooleanMatchSearchCommon>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclaration = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProducts>;
        relatedSanitisers?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            pools?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPools>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPools =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
            /** Expects node: **Pool** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Pool** */
            not?: InputMaybe<Scalars['ID']>;
            ownershipHistory?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
            reports?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReports>;
            sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
            site?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSite>;
            type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
            volume?: InputMaybe<FloatRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntity =
        {
            addresses?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
            sites?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntitySites =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReports =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **TestReport** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestReport** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            refId?: InputMaybe<StringMatchSearchCommon>;
            reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            reportTo?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
            sampleSet?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo =
        {
            addresses?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
            sites?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSite =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity =
        {
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity =
        {
            addresses?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob =
        {
            address?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer =
        {
            addedBy?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
            teams?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSearchDeclarationRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type PoolProductDeclarationViewerPoolProductDeclarationsSort = {
        /** Expects node: **PoolProductDeclaration** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolSitePoolsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolSitePoolsSearchEntity>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        ownershipHistory?: InputMaybe<PoolSitePoolsSearchOwnershipHistory>;
        reports?: InputMaybe<PoolSitePoolsSearchReports>;
        sanitiser?: InputMaybe<PoolSitePoolsSearchSanitiser>;
        type?: InputMaybe<PoolSitePoolsSearchType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchEntity = {
        addresses?: InputMaybe<PoolSitePoolsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolSitePoolsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolSitePoolsSearchEntityServiceJob>;
        user?: InputMaybe<PoolSitePoolsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJob = {
        address?: InputMaybe<PoolSitePoolsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolSitePoolsSearchEntityServiceJobWorkOrders>;
    };

    export type PoolSitePoolsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerUser>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolSitePoolsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolSitePoolsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolSitePoolsSearchEntityUserStaff>;
    };

    export type PoolSitePoolsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerTags>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchEntityUserCustomerAddedByTeams>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchEntityUserStaffTeams>;
    };

    export type PoolSitePoolsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolSitePoolsSearchReportsReportTo>;
        sampleSet?: InputMaybe<PoolSitePoolsSearchReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportTo = {
        addresses?: InputMaybe<PoolSitePoolsSearchReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolSitePoolsSearchReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJob>;
        user?: InputMaybe<PoolSitePoolsSearchReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJob = {
        address?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobWorkOrders>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerUser>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolSitePoolsSearchReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolSitePoolsSearchReportsReportToUserStaff>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerTags>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolSitePoolsSearchReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolSitePoolsSearchReportsReportToUserStaffTeams>;
    };

    export type PoolSitePoolsSearchReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolSitePoolsSearchSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolSitePoolsSearchSanitiserRelatedDeclarations>;
    };

    export type PoolSitePoolsSearchSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolSitePoolsSearchSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolSitePoolsSearchSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolSitePoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolSitePoolsSearchTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolSitePoolsSearchTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolSitePoolsSearchTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblem>;
    };

    export type PoolSitePoolsSearchTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolSitePoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolSitePoolsSort = {
        bottleNumber?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Pool** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        volume?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolTransferToSiteMutationInput = {
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Expects node: **Site** */
        site: Scalars['ID'];
    };

    export type PoolType = Node & {
        __typename?: 'PoolType';
        checkMutations?: Maybe<PoolTypeMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        criteria?: Maybe<CriteriaForPoolTypeToTestCriteriaConnection>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        pools?: Maybe<PoolsForPoolTypeToPoolConnection>;
        priority?: Maybe<Scalars['Int']>;
        publishedAt?: Maybe<Scalars['Date']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type PoolTypeCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type PoolTypePoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolPoolTypePoolsSearch>;
        sort?: InputMaybe<PoolPoolTypePoolsSort>;
    };

    export type PoolTypeChangePublishedStatusMutationInput = {
        /** Expects node: **PoolType** */
        id: Scalars['ID'];
        status: Scalars['Boolean'];
    };

    export type PoolTypeMutationCheck = {
        __typename?: 'PoolTypeMutationCheck';
        changePublishedStatus?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type PoolTypeMutationInput = {
        changePublishedStatus?: InputMaybe<Array<InputMaybe<PoolTypeChangePublishedStatusMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreatePoolTypeMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeletePoolTypeMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolTypeMutationInput>>>;
    };

    export type PoolTypeMutationPayload = {
        __typename?: 'PoolTypeMutationPayload';
        PoolType?: Maybe<PoolType>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkPoolTypeMutationOutput>>>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type PoolTypeNodeMatchSearchCommon = {
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsOwnershipHistory>;
        reports?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReports>;
        sanitiser?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiser>;
        site?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntity = {
        addresses?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJob>;
        sites?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntitySites>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJob = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobWorkOrders>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntitySites = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerTags>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportTo>;
        sampleSet?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportTo = {
        addresses?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToSites>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJob = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobWorkOrders>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToSites = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerTags>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarations>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSite = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerTags>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntity = {
        addresses?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJob>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJob = {
        address?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaff>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerTags>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaffTeams>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeTestCriteriaPoolTypesSearchPoolsSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeTestCriteriaPoolTypesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolType** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        publishedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type PoolTypeViewerPoolTypesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteria>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<PoolTypeViewerPoolTypesSearchPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblem>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsOwnershipHistory>;
        reports?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReports>;
        sanitiser?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiser>;
        site?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntity = {
        addresses?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJob>;
        sites?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntitySites>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJob = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobWorkOrders>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntitySites = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerTags>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsEntityUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportTo>;
        sampleSet?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportTo = {
        addresses?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToSites>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJob = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobWorkOrders>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToSites = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerTags>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarations>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSite = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerTags>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntity = {
        addresses?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJob>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJob = {
        address?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaff>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerTags>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedByTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaffTeams>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolTypeViewerPoolTypesSearchPoolsSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolTypeViewerPoolTypesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **PoolType** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        publishedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type PoolTypesForTestCriteriaToPoolTypeConnection = {
        __typename?: 'PoolTypesForTestCriteriaToPoolTypeConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolTypesForTestCriteriaToPoolTypeEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolTypesForTestCriteriaToPoolTypeMeta>;
    };

    /** An edge in a connection. */
    export type PoolTypesForTestCriteriaToPoolTypeEdge = {
        __typename?: 'PoolTypesForTestCriteriaToPoolTypeEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolType>;
    };

    export type PoolTypesForTestCriteriaToPoolTypeMeta = {
        __typename?: 'PoolTypesForTestCriteriaToPoolTypeMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type PoolViewerPoolsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolViewerPoolsSearchEntity>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        ownershipHistory?: InputMaybe<PoolViewerPoolsSearchOwnershipHistory>;
        reports?: InputMaybe<PoolViewerPoolsSearchReports>;
        sanitiser?: InputMaybe<PoolViewerPoolsSearchSanitiser>;
        site?: InputMaybe<PoolViewerPoolsSearchSite>;
        type?: InputMaybe<PoolViewerPoolsSearchType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntity = {
        addresses?: InputMaybe<PoolViewerPoolsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolViewerPoolsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolViewerPoolsSearchEntityServiceJob>;
        sites?: InputMaybe<PoolViewerPoolsSearchEntitySites>;
        user?: InputMaybe<PoolViewerPoolsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJob = {
        address?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobWorkOrders>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerUser>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerUserStaff>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolViewerPoolsSearchEntitySites = {
        address?: InputMaybe<PoolViewerPoolsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchEntityUserStaff>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerTags>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchEntityUserCustomerAddedByTeams>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchEntityUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<PoolViewerPoolsSearchReportsReportTo>;
        sampleSet?: InputMaybe<PoolViewerPoolsSearchReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportTo = {
        addresses?: InputMaybe<PoolViewerPoolsSearchReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolViewerPoolsSearchReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJob>;
        sites?: InputMaybe<PoolViewerPoolsSearchReportsReportToSites>;
        user?: InputMaybe<PoolViewerPoolsSearchReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJob = {
        address?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobWorkOrders>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerUser>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaff>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolViewerPoolsSearchReportsReportToSites = {
        address?: InputMaybe<PoolViewerPoolsSearchReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserStaff>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerTags>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserCustomerAddedByTeams>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchReportsReportToUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolViewerPoolsSearchSanitiserRelatedDeclarations>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblem>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolViewerPoolsSearchSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolViewerPoolsSearchSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolViewerPoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSite = {
        address?: InputMaybe<PoolViewerPoolsSearchSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<PoolViewerPoolsSearchSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJob>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobWorkOrders>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUser>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserStaff>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserCustomerTags>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteAddressEntityUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntity = {
        addresses?: InputMaybe<PoolViewerPoolsSearchSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<PoolViewerPoolsSearchSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJob>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJob = {
        address?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobWorkOrders>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerUser>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaff>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type PoolViewerPoolsSearchSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserStaff>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomer = {
        addedBy?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerTags>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserCustomerAddedByTeams>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<PoolViewerPoolsSearchSiteEntityUserStaffTeams>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<PoolViewerPoolsSearchTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblem>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type PoolViewerPoolsSearchTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type PoolViewerPoolsSort = {
        bottleNumber?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Pool** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        volume?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type PoolsForCustomerToPoolConnection = {
        __typename?: 'PoolsForCustomerToPoolConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolsForCustomerToPoolEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolsForCustomerToPoolMeta>;
    };

    /** An edge in a connection. */
    export type PoolsForCustomerToPoolEdge = {
        __typename?: 'PoolsForCustomerToPoolEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Pool>;
    };

    export type PoolsForCustomerToPoolMeta = {
        __typename?: 'PoolsForCustomerToPoolMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type PoolsForEntityToPoolConnection = {
        __typename?: 'PoolsForEntityToPoolConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolsForEntityToPoolEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolsForEntityToPoolMeta>;
    };

    /** An edge in a connection. */
    export type PoolsForEntityToPoolEdge = {
        __typename?: 'PoolsForEntityToPoolEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Pool>;
    };

    export type PoolsForEntityToPoolMeta = {
        __typename?: 'PoolsForEntityToPoolMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type PoolsForPoolTypeToPoolConnection = {
        __typename?: 'PoolsForPoolTypeToPoolConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolsForPoolTypeToPoolEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolsForPoolTypeToPoolMeta>;
    };

    /** An edge in a connection. */
    export type PoolsForPoolTypeToPoolEdge = {
        __typename?: 'PoolsForPoolTypeToPoolEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Pool>;
    };

    export type PoolsForPoolTypeToPoolMeta = {
        __typename?: 'PoolsForPoolTypeToPoolMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type PoolsForSiteToPoolConnection = {
        __typename?: 'PoolsForSiteToPoolConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PoolsForSiteToPoolEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PoolsForSiteToPoolMeta>;
    };

    /** An edge in a connection. */
    export type PoolsForSiteToPoolEdge = {
        __typename?: 'PoolsForSiteToPoolEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Pool>;
    };

    export type PoolsForSiteToPoolMeta = {
        __typename?: 'PoolsForSiteToPoolMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type PretendInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        staffId: Scalars['ID'];
    };

    export type PretendPayload = {
        __typename?: 'PretendPayload';
        clientMutationId?: Maybe<Scalars['String']>;
        viewer?: Maybe<Viewer>;
    };

    /** A connection to a list of items. */
    export type PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryConnection = {
        __typename?: 'PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryMeta>;
    };

    /** An edge in a connection. */
    export type PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryEdge = {
        __typename?: 'PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolOwnershipHistory>;
    };

    export type PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryMeta = {
        __typename?: 'PreviouslyOwnedPoolsForEntityToPoolOwnershipHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ProblemsForTestTreatmentToTestProblemConnection = {
        __typename?: 'ProblemsForTestTreatmentToTestProblemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ProblemsForTestTreatmentToTestProblemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ProblemsForTestTreatmentToTestProblemEdge = {
        __typename?: 'ProblemsForTestTreatmentToTestProblemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestProblem>;
    };

    export type Product = Node & {
        __typename?: 'Product';
        addedBy?: Maybe<Staff>;
        brand?: Maybe<Brand>;
        brochure?: Maybe<ProductDocument>;
        checkMutations?: Maybe<ProductMutationCheck>;
        consumable?: Maybe<ConsumableProduct>;
        createdAt?: Maybe<Scalars['Date']>;
        customSku?: Maybe<Scalars['String']>;
        declarations?: Maybe<DeclarationsForProductToProductTraitDeclarationConnection>;
        description?: Maybe<Scalars['String']>;
        documents?: Maybe<DocumentsForProductToProductDocumentConnection>;
        familyCode?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        hasDeclaration?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        identification?: Maybe<Scalars['String']>;
        installable?: Maybe<InstallableProduct>;
        isGrouping?: Maybe<Scalars['Boolean']>;
        isOneOff?: Maybe<Scalars['Boolean']>;
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        retiredAt?: Maybe<Scalars['Date']>;
        retiredBy?: Maybe<Staff>;
        sku?: Maybe<Scalars['String']>;
        supplier?: Maybe<ProductCompany>;
        traits?: Maybe<Array<Maybe<ProductTrait>>>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationProductDeclarationsSort>;
    };

    export type ProductDocumentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductDocumentProductDocumentsSearch>;
        sort?: InputMaybe<ProductDocumentProductDocumentsSort>;
    };

    export type ProductHasDeclarationArgs = {
        declaration: Scalars['ID'];
    };

    export type ProductTraitsArgs = {
        declarations?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type ProductBrandProductsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        declarations?: InputMaybe<ProductBrandProductsSearchDeclarations>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        relatedSanitisers?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob = {
        address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites = {
        address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo = {
        addresses?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
        user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites = {
        address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob = {
        address?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductBrandProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ProductBrandProductsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        customSku?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        identification?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        sku?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductCompany = Node & {
        __typename?: 'ProductCompany';
        brands?: Maybe<BrandsForProductCompanyToBrandConnection>;
        checkMutations?: Maybe<ProductCompanyMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        description?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        parentCompany?: Maybe<ProductCompany>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductCompanyBrandsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<BrandProductCompanyBrandsSearch>;
        sort?: InputMaybe<BrandProductCompanyBrandsSort>;
    };

    export type ProductCompanyMutationCheck = {
        __typename?: 'ProductCompanyMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductCompanyMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductCompanyMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductCompanyMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductCompanyMutationInput>>>;
    };

    export type ProductCompanyMutationPayload = {
        __typename?: 'ProductCompanyMutationPayload';
        ProductCompany?: Maybe<ProductCompany>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductCompanyMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParentCompany?: Maybe<ProductCompany>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductCompanyNodeMatchSearchCommon = {
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductCompanyViewerProductCompanySearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductCompanyViewerProductCompanySearchTraits = {
        /** Expects node: **ProductTraitDeclaration** */
        declarations?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type ProductCompanyViewerProductCompanySort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductCompany** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type ProductCriteriaForPoolToTestCriteriaConnection = {
        __typename?: 'ProductCriteriaForPoolToTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ProductCriteriaForPoolToTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ProductCriteriaForPoolToTestCriteriaEdge = {
        __typename?: 'ProductCriteriaForPoolToTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    export type ProductDeclarationFieldGroup = Node & {
        __typename?: 'ProductDeclarationFieldGroup';
        declaration?: Maybe<ProductTraitDeclaration>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
    };

    export type ProductDeclarationFieldGroupNodeMatchSearchCommon = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductDeclarationFieldGroup** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductDeclarationFlagField = Node & {
        __typename?: 'ProductDeclarationFlagField';
        checkMutations?: Maybe<ProductDeclarationFlagFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationFlagFieldMutationCheck = {
        __typename?: 'ProductDeclarationFlagFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationFlagFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationFlagFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationFlagFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationFlagFieldMutationInput>>>;
    };

    export type ProductDeclarationFlagFieldMutationPayload = {
        __typename?: 'ProductDeclarationFlagFieldMutationPayload';
        ProductDeclarationFlagField?: Maybe<ProductDeclarationFlagField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationFlagFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationFloatField = Node & {
        __typename?: 'ProductDeclarationFloatField';
        checkMutations?: Maybe<ProductDeclarationFloatFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        distinctValues?: Maybe<Array<Maybe<Scalars['Float']>>>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        maxValue?: Maybe<Scalars['Float']>;
        minMaxValues?: Maybe<MinMax>;
        minValue?: Maybe<Scalars['Float']>;
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        unit?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationFloatFieldMutationCheck = {
        __typename?: 'ProductDeclarationFloatFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationFloatFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationFloatFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationFloatFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationFloatFieldMutationInput>>>;
    };

    export type ProductDeclarationFloatFieldMutationPayload = {
        __typename?: 'ProductDeclarationFloatFieldMutationPayload';
        ProductDeclarationFloatField?: Maybe<ProductDeclarationFloatField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationFloatFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationIntegerField = Node & {
        __typename?: 'ProductDeclarationIntegerField';
        checkMutations?: Maybe<ProductDeclarationIntegerFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        maxValue?: Maybe<Scalars['Int']>;
        minValue?: Maybe<Scalars['Int']>;
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        unit?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationIntegerFieldMutationCheck = {
        __typename?: 'ProductDeclarationIntegerFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationIntegerFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationIntegerFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationIntegerFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationIntegerFieldMutationInput>>>;
    };

    export type ProductDeclarationIntegerFieldMutationPayload = {
        __typename?: 'ProductDeclarationIntegerFieldMutationPayload';
        ProductDeclarationIntegerField?: Maybe<ProductDeclarationIntegerField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationIntegerFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationMeasurementField = Node & {
        __typename?: 'ProductDeclarationMeasurementField';
        checkMutations?: Maybe<ProductDeclarationMeasurementFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationMeasurementFieldMutationCheck = {
        __typename?: 'ProductDeclarationMeasurementFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationMeasurementFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationMeasurementFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationMeasurementFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationMeasurementFieldMutationInput>>>;
    };

    export type ProductDeclarationMeasurementFieldMutationPayload = {
        __typename?: 'ProductDeclarationMeasurementFieldMutationPayload';
        ProductDeclarationMeasurementField?: Maybe<ProductDeclarationMeasurementField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationMeasurementFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationSelectionChoice = Node & {
        __typename?: 'ProductDeclarationSelectionChoice';
        checkMutations?: Maybe<ProductDeclarationSelectionChoiceMutationCheck>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        priority?: Maybe<Scalars['Int']>;
        selection?: Maybe<ProductDeclarationSelectionField>;
        usageCount?: Maybe<Scalars['Int']>;
    };

    export type ProductDeclarationSelectionChoiceMutationCheck = {
        __typename?: 'ProductDeclarationSelectionChoiceMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationSelectionChoiceMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationSelectionChoiceMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationSelectionChoiceMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationSelectionChoiceMutationInput>>>;
    };

    export type ProductDeclarationSelectionChoiceMutationPayload = {
        __typename?: 'ProductDeclarationSelectionChoiceMutationPayload';
        ProductDeclarationSelectionChoice?: Maybe<ProductDeclarationSelectionChoice>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationSelectionChoiceMutationOutput>>>;
        updatedSelection?: Maybe<ProductDeclarationSelectionField>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationSelectionField = Node & {
        __typename?: 'ProductDeclarationSelectionField';
        checkMutations?: Maybe<ProductDeclarationSelectionFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        options?: Maybe<Array<Maybe<ProductDeclarationSelectionChoice>>>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationSelectionFieldOptionsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductDeclarationSelectionFieldOptionsSearch>;
        sort?: InputMaybe<ProductDeclarationSelectionFieldOptionsSort>;
    };

    export type ProductDeclarationSelectionFieldMutationCheck = {
        __typename?: 'ProductDeclarationSelectionFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationSelectionFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationSelectionFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationSelectionFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationSelectionFieldMutationInput>>>;
    };

    export type ProductDeclarationSelectionFieldMutationPayload = {
        __typename?: 'ProductDeclarationSelectionFieldMutationPayload';
        ProductDeclarationSelectionField?: Maybe<ProductDeclarationSelectionField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationSelectionFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationSelectionFieldOptionsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDeclarationSelectionFieldOptionsSort = {
        /** Expects node: **ProductDeclarationSelectionChoice** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationStringField = Node & {
        __typename?: 'ProductDeclarationStringField';
        checkMutations?: Maybe<ProductDeclarationStringFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        isHtml?: Maybe<Scalars['Boolean']>;
        maxLength?: Maybe<Scalars['Int']>;
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationStringFieldMutationCheck = {
        __typename?: 'ProductDeclarationStringFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationStringFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationStringFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationStringFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationStringFieldMutationInput>>>;
    };

    export type ProductDeclarationStringFieldMutationPayload = {
        __typename?: 'ProductDeclarationStringFieldMutationPayload';
        ProductDeclarationStringField?: Maybe<ProductDeclarationStringField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationStringFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDeclarationUnitField = Node & {
        __typename?: 'ProductDeclarationUnitField';
        checkMutations?: Maybe<ProductDeclarationUnitFieldMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        declaration?: Maybe<ProductTraitDeclaration>;
        group?: Maybe<ProductDeclarationFieldGroup>;
        hint?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        optional?: Maybe<Scalars['Boolean']>;
        searchAsDistinct?: Maybe<Scalars['Boolean']>;
        searchOrder?: Maybe<Scalars['Int']>;
        tag?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductDeclarationUnitFieldMutationCheck = {
        __typename?: 'ProductDeclarationUnitFieldMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDeclarationUnitFieldMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductDeclarationUnitFieldMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDeclarationUnitFieldMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDeclarationUnitFieldMutationInput>>>;
    };

    export type ProductDeclarationUnitFieldMutationPayload = {
        __typename?: 'ProductDeclarationUnitFieldMutationPayload';
        ProductDeclarationUnitField?: Maybe<ProductDeclarationUnitField>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDeclarationUnitFieldMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedGroup?: Maybe<ProductDeclarationFieldGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDocument = Node & {
        __typename?: 'ProductDocument';
        brand?: Maybe<Brand>;
        checkMutations?: Maybe<ProductDocumentMutationCheck>;
        company?: Maybe<ProductCompany>;
        createdAt?: Maybe<Scalars['Date']>;
        fileName?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        imageUrl?: Maybe<Scalars['String']>;
        isImage?: Maybe<Scalars['Boolean']>;
        isPublic?: Maybe<Scalars['Boolean']>;
        location?: Maybe<Scalars['String']>;
        mimeType?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        title?: Maybe<Scalars['String']>;
        type?: Maybe<ProductDocumentType>;
        updatedAt?: Maybe<Scalars['Date']>;
        uploadedBy?: Maybe<Staff>;
        url?: Maybe<Scalars['String']>;
    };

    export type ProductDocumentMutationCheck = {
        __typename?: 'ProductDocumentMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductDocumentMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductDocumentMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductDocumentMutationInput>>>;
    };

    export type ProductDocumentMutationPayload = {
        __typename?: 'ProductDocumentMutationPayload';
        ProductDocument?: Maybe<ProductDocument>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductDocumentMutationOutput>>>;
        updatedBrand?: Maybe<Brand>;
        updatedCompany?: Maybe<ProductCompany>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUploadedBy?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductDocumentProductDocumentsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<BrandNodeMatchSearchCommon>;
        company?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        type?: InputMaybe<ProductDocumentTypeEnumMatchSearchCommon>;
        uploadedBy?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedBy>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByRegistration>;
        teams?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByTeams>;
        user?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUser>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserCustomer>;
        entity?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserCustomerTags>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntity = {
        addresses?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPools>;
        serviceJob?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobWorkOrders>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntitySites = {
        address?: InputMaybe<ProductDocumentProductDocumentsSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentProductDocumentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductDocument** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        title?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export enum ProductDocumentType {
        Brochure = 'Brochure',
        Manual = 'Manual',
        Other = 'Other',
        Photo = 'Photo',
    }

    export type ProductDocumentTypeEnumMatchSearchCommon = {
        is?: InputMaybe<ProductDocumentType>;
        not?: InputMaybe<ProductDocumentType>;
    };

    export type ProductDocumentViewerProductDocumentsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<BrandNodeMatchSearchCommon>;
        company?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        fileName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        type?: InputMaybe<ProductDocumentTypeEnumMatchSearchCommon>;
        uploadedBy?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedBy>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByRegistration>;
        teams?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByTeams>;
        user?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUser>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomer>;
        entity?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerTags>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntity = {
        addresses?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPools>;
        serviceJob?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJob>;
        sites?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSite>;
        type?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSite = {
        address?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJob = {
        address?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobWorkOrders>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerTags>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntitySites = {
        address?: InputMaybe<ProductDocumentViewerProductDocumentsSearchUploadedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSearchUploadedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductDocumentViewerProductDocumentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductDocument** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        title?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductMutationCheck = {
        __typename?: 'ProductMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        retire?: Maybe<Scalars['Boolean']>;
        unretire?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductMutationInput>>>;
        retire?: InputMaybe<Array<InputMaybe<ProductRetireMutationInput>>>;
        unretire?: InputMaybe<Array<InputMaybe<ProductUnretireMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductMutationInput>>>;
    };

    export type ProductMutationPayload = {
        __typename?: 'ProductMutationPayload';
        Product?: Maybe<Product>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedBrand?: Maybe<Brand>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedRetiredBy?: Maybe<Staff>;
        updatedSupplier?: Maybe<ProductCompany>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductNodeMatchSearchCommon = {
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductProductTraitDeclarationProductsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ProductProductTraitDeclarationProductsSearchBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ProductProductTraitDeclarationProductsSearchBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ProductProductTraitDeclarationProductsSearchBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductProductTraitDeclarationProductsSearchBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductProductTraitDeclarationProductsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        customSku?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        identification?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        sku?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductRetireMutationInput = {
        /** Expects node: **Product** */
        id: Scalars['ID'];
    };

    export type ProductSearchHelper = {
        __typename?: 'ProductSearchHelper';
        brandCompanies?: Maybe<BrandCompaniesForProductSearchHelperToProductCompanyConnection>;
        brands?: Maybe<BrandsForProductSearchHelperToBrandConnection>;
        declarations?: Maybe<DeclarationsForProductSearchHelperToProductTraitDeclarationConnection>;
    };

    export type ProductSearchHelperBrandCompaniesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type ProductSearchHelperBrandsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type ProductSearchHelperDeclarationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type ProductTrait = Node & {
        __typename?: 'ProductTrait';
        checkMutations?: Maybe<ProductTraitMutationCheck>;
        declaration?: Maybe<ProductTraitDeclaration>;
        flagValues?: Maybe<Array<Maybe<ProductTraitFlagValue>>>;
        floatValues?: Maybe<Array<Maybe<ProductTraitFloatValue>>>;
        id: Scalars['ID'];
        integerValues?: Maybe<Array<Maybe<ProductTraitIntegerValue>>>;
        measurementValues?: Maybe<Array<Maybe<ProductTraitMeasurementValue>>>;
        product?: Maybe<Product>;
        selectionValues?: Maybe<Array<Maybe<ProductTraitSelectionValue>>>;
        stringValues?: Maybe<Array<Maybe<ProductTraitStringValue>>>;
        unitValues?: Maybe<Array<Maybe<ProductTraitUnitValue>>>;
    };

    export type ProductTraitDeclaration = Node & {
        __typename?: 'ProductTraitDeclaration';
        allChildren?: Maybe<Array<Maybe<ProductTraitDeclaration>>>;
        checkMutations?: Maybe<ProductTraitDeclarationMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        directChildren?: Maybe<Array<Maybe<ProductTraitDeclaration>>>;
        flagFields?: Maybe<Array<Maybe<ProductDeclarationFlagField>>>;
        floatFields?: Maybe<Array<Maybe<ProductDeclarationFloatField>>>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        integerFields?: Maybe<Array<Maybe<ProductDeclarationIntegerField>>>;
        isCategory?: Maybe<Scalars['Boolean']>;
        measurementFields?: Maybe<Array<Maybe<ProductDeclarationMeasurementField>>>;
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        parent?: Maybe<ProductTraitDeclaration>;
        parentDeclarations?: Maybe<Array<Maybe<ProductTraitDeclaration>>>;
        products?: Maybe<ProductsForProductTraitDeclarationToProductConnection>;
        relatedSanitisers?: Maybe<RelatedSanitisersForProductTraitDeclarationToSanitiserConnection>;
        selectionFields?: Maybe<Array<Maybe<ProductDeclarationSelectionField>>>;
        stringFields?: Maybe<Array<Maybe<ProductDeclarationStringField>>>;
        /** @deprecated Use "directChildren" instead. */
        subDeclarations?: Maybe<Array<Maybe<ProductTraitDeclaration>>>;
        tag?: Maybe<Scalars['String']>;
        unitFields?: Maybe<Array<Maybe<ProductDeclarationUnitField>>>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ProductTraitDeclarationAllChildrenArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ProductTraitDeclarationFlagFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationFlagFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationFlagFieldsSort>;
    };

    export type ProductTraitDeclarationFloatFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationFloatFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationFloatFieldsSort>;
    };

    export type ProductTraitDeclarationIntegerFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationIntegerFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationIntegerFieldsSort>;
    };

    export type ProductTraitDeclarationMeasurementFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationMeasurementFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationMeasurementFieldsSort>;
    };

    export type ProductTraitDeclarationParentDeclarationsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ProductTraitDeclarationProductsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductProductTraitDeclarationProductsSearch>;
        sort?: InputMaybe<ProductProductTraitDeclarationProductsSort>;
    };

    export type ProductTraitDeclarationRelatedSanitisersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearch>;
        sort?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSort>;
    };

    export type ProductTraitDeclarationSelectionFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationSelectionFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationSelectionFieldsSort>;
    };

    export type ProductTraitDeclarationStringFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationStringFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationStringFieldsSort>;
    };

    export type ProductTraitDeclarationUnitFieldsArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationUnitFieldsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationUnitFieldsSort>;
    };

    export type ProductTraitDeclarationFlagFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationFlagFieldsSort = {
        /** Expects node: **ProductDeclarationFlagField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationFloatFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationFloatFieldsSort = {
        /** Expects node: **ProductDeclarationFloatField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationIntegerFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationIntegerFieldsSort = {
        /** Expects node: **ProductDeclarationIntegerField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationMeasurementFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationMeasurementFieldsSort = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationMutationCheck = {
        __typename?: 'ProductTraitDeclarationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitDeclarationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitDeclarationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitDeclarationMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationMutationInput>>>;
    };

    export type ProductTraitDeclarationMutationPayload = {
        __typename?: 'ProductTraitDeclarationMutationPayload';
        ProductTraitDeclaration?: Maybe<ProductTraitDeclaration>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitDeclarationMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParent?: Maybe<ProductTraitDeclaration>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitDeclarationNodeMatchSearchCommon = {
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        relatedSanitisers?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySites = {
        address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo =
        {
            addresses?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
            sites?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites =
        {
            address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity =
        {
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductTraitDeclarationProductDeclarationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductTraitDeclaration** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductTraitDeclarationSanitiserRelatedDeclarationsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitDeclarationSanitiserRelatedDeclarationsSearchProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitDeclarationSanitiserRelatedDeclarationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductTraitDeclaration** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductTraitDeclarationSelectionFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationSelectionFieldsSort = {
        /** Expects node: **ProductDeclarationSelectionField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationStringFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationStringFieldsSort = {
        /** Expects node: **ProductDeclarationStringField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationUnitFieldsSearch = {
        group?: InputMaybe<ProductDeclarationFieldGroupNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationUnitFieldsSort = {
        /** Expects node: **ProductDeclarationUnitField** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchProducts>;
        relatedSanitisers?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySites =
        {
            address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo =
        {
            addresses?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
            sites?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites =
        {
            address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Site** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Site** */
            not?: InputMaybe<Scalars['ID']>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity =
        {
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity =
        {
            addresses?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
            chainUpdate?: InputMaybe<DateRangeSearchCommon>;
            contacts?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
            not?: InputMaybe<Scalars['ID']>;
            serviceJob?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
            uuid?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob =
        {
            address?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            customer?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ProductTraitDeclarationViewerProductDeclarationsSearchTraitOverlap = {
        /** Expects node: **ProductTraitDeclaration** */
        declarations?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type ProductTraitDeclarationViewerProductDeclarationsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ProductTraitDeclaration** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductTraitFlagValue = Node & {
        __typename?: 'ProductTraitFlagValue';
        checkMutations?: Maybe<ProductTraitFlagValueMutationCheck>;
        field?: Maybe<ProductDeclarationFlagField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitFlagValueAssignMutationInput = {
        declarationTag: Scalars['String'];
        fieldTag: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type ProductTraitFlagValueMutationCheck = {
        __typename?: 'ProductTraitFlagValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitFlagValueMutationInput = {
        assign?: InputMaybe<Array<InputMaybe<ProductTraitFlagValueAssignMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitFlagValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitFlagValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitFlagValueMutationInput>>>;
    };

    export type ProductTraitFlagValueMutationPayload = {
        __typename?: 'ProductTraitFlagValueMutationPayload';
        ProductTraitFlagValue?: Maybe<ProductTraitFlagValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitFlagValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationFlagField>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitFloatValue = Node & {
        __typename?: 'ProductTraitFloatValue';
        checkMutations?: Maybe<ProductTraitFloatValueMutationCheck>;
        field?: Maybe<ProductDeclarationFloatField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Scalars['Float']>;
    };

    export type ProductTraitFloatValueMutationCheck = {
        __typename?: 'ProductTraitFloatValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitFloatValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitFloatValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitFloatValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitFloatValueMutationInput>>>;
    };

    export type ProductTraitFloatValueMutationPayload = {
        __typename?: 'ProductTraitFloatValueMutationPayload';
        ProductTraitFloatValue?: Maybe<ProductTraitFloatValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitFloatValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationFloatField>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitIntegerValue = Node & {
        __typename?: 'ProductTraitIntegerValue';
        checkMutations?: Maybe<ProductTraitIntegerValueMutationCheck>;
        field?: Maybe<ProductDeclarationIntegerField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Scalars['Float']>;
    };

    export type ProductTraitIntegerValueMutationCheck = {
        __typename?: 'ProductTraitIntegerValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitIntegerValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitIntegerValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitIntegerValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitIntegerValueMutationInput>>>;
    };

    export type ProductTraitIntegerValueMutationPayload = {
        __typename?: 'ProductTraitIntegerValueMutationPayload';
        ProductTraitIntegerValue?: Maybe<ProductTraitIntegerValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitIntegerValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationIntegerField>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitMeasurementValue = Node & {
        __typename?: 'ProductTraitMeasurementValue';
        checkMutations?: Maybe<ProductTraitMeasurementValueMutationCheck>;
        field?: Maybe<ProductDeclarationMeasurementField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Measurement>;
    };

    export type ProductTraitMeasurementValueMutationCheck = {
        __typename?: 'ProductTraitMeasurementValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitMeasurementValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitMeasurementValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitMeasurementValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitMeasurementValueMutationInput>>>;
    };

    export type ProductTraitMeasurementValueMutationPayload = {
        __typename?: 'ProductTraitMeasurementValueMutationPayload';
        ProductTraitMeasurementValue?: Maybe<ProductTraitMeasurementValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitMeasurementValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationMeasurementField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<Measurement>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitMutationCheck = {
        __typename?: 'ProductTraitMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitMutationInput>>>;
    };

    export type ProductTraitMutationPayload = {
        __typename?: 'ProductTraitMutationPayload';
        ProductTrait?: Maybe<ProductTrait>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitMutationOutput>>>;
        updatedDeclaration?: Maybe<ProductTraitDeclaration>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitSelectionValue = Node & {
        __typename?: 'ProductTraitSelectionValue';
        checkMutations?: Maybe<ProductTraitSelectionValueMutationCheck>;
        field?: Maybe<ProductDeclarationSelectionField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<ProductDeclarationSelectionChoice>;
    };

    export type ProductTraitSelectionValueMutationCheck = {
        __typename?: 'ProductTraitSelectionValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitSelectionValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitSelectionValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitSelectionValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitSelectionValueMutationInput>>>;
    };

    export type ProductTraitSelectionValueMutationPayload = {
        __typename?: 'ProductTraitSelectionValueMutationPayload';
        ProductTraitSelectionValue?: Maybe<ProductTraitSelectionValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitSelectionValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationSelectionField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<ProductDeclarationSelectionChoice>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitStringValue = Node & {
        __typename?: 'ProductTraitStringValue';
        checkMutations?: Maybe<ProductTraitStringValueMutationCheck>;
        field?: Maybe<ProductDeclarationStringField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Scalars['String']>;
    };

    export type ProductTraitStringValueMutationCheck = {
        __typename?: 'ProductTraitStringValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitStringValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitStringValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitStringValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitStringValueMutationInput>>>;
    };

    export type ProductTraitStringValueMutationPayload = {
        __typename?: 'ProductTraitStringValueMutationPayload';
        ProductTraitStringValue?: Maybe<ProductTraitStringValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitStringValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationStringField>;
        updatedProduct?: Maybe<Product>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductTraitTagResolveCommon = {
        declarationTag: Scalars['String'];
        fieldTag: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
    };

    export type ProductTraitUnitValue = Node & {
        __typename?: 'ProductTraitUnitValue';
        checkMutations?: Maybe<ProductTraitUnitValueMutationCheck>;
        field?: Maybe<ProductDeclarationUnitField>;
        id: Scalars['ID'];
        product?: Maybe<Product>;
        value?: Maybe<Unit>;
    };

    export type ProductTraitUnitValueMutationCheck = {
        __typename?: 'ProductTraitUnitValueMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProductTraitUnitValueMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProductTraitUnitValueMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProductTraitUnitValueMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitUnitValueMutationInput>>>;
    };

    export type ProductTraitUnitValueMutationPayload = {
        __typename?: 'ProductTraitUnitValueMutationPayload';
        ProductTraitUnitValue?: Maybe<ProductTraitUnitValue>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProductTraitUnitValueMutationOutput>>>;
        updatedField?: Maybe<ProductDeclarationUnitField>;
        updatedProduct?: Maybe<Product>;
        updatedValue?: Maybe<Unit>;
        viewer?: Maybe<Viewer>;
    };

    export type ProductUnretireMutationInput = {
        /** Expects node: **Product** */
        id: Scalars['ID'];
    };

    export type ProductViewerProductsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ProductViewerProductsSearchBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        declarations?: InputMaybe<ProductViewerProductsSearchDeclarations>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        relatedNames?: InputMaybe<Scalars['String']>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ProductViewerProductsSearchBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductViewerProductsSearchBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ProductViewerProductsSearchDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        relatedSanitisers?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisers>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblem>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJob = {
        address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySites = {
        address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportTo = {
        addresses?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites>;
        user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSites = {
        address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJob = {
        address?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ProductViewerProductsSearchDeclarationsRelatedSanitisersCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ProductViewerProductsSearchTraits = {
        traits?: InputMaybe<ProductViewerProductsSearchTraitsTraits>;
        traitsValues?: InputMaybe<ProductViewerProductsSearchTraitsTraitsValues>;
    };

    export type ProductViewerProductsSearchTraitsTraits = {
        /** Expects node: **ProductTraitDeclaration** */
        and?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ProductTraitDeclaration** */
        or?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type ProductViewerProductsSearchTraitsTraitsValues = {
        and?: InputMaybe<Array<InputMaybe<CommonProductValuesTraitSearchCommon>>>;
    };

    export type ProductViewerProductsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        customSku?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        identification?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        sku?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProductWithDosage = {
        __typename?: 'ProductWithDosage';
        dosage?: Maybe<Scalars['Float']>;
        familyCode?: Maybe<Scalars['String']>;
        product?: Maybe<Product>;
        productName?: Maybe<Scalars['String']>;
        sku?: Maybe<Scalars['String']>;
        unit?: Maybe<Unit>;
        unitName?: Maybe<Scalars['String']>;
    };

    /** A connection to a list of items. */
    export type ProductsForBrandToProductConnection = {
        __typename?: 'ProductsForBrandToProductConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ProductsForBrandToProductEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ProductsForBrandToProductMeta>;
    };

    /** An edge in a connection. */
    export type ProductsForBrandToProductEdge = {
        __typename?: 'ProductsForBrandToProductEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Product>;
    };

    export type ProductsForBrandToProductMeta = {
        __typename?: 'ProductsForBrandToProductMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ProductsForProductTraitDeclarationToProductConnection = {
        __typename?: 'ProductsForProductTraitDeclarationToProductConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ProductsForProductTraitDeclarationToProductEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ProductsForProductTraitDeclarationToProductMeta>;
    };

    /** An edge in a connection. */
    export type ProductsForProductTraitDeclarationToProductEdge = {
        __typename?: 'ProductsForProductTraitDeclarationToProductEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Product>;
    };

    export type ProductsForProductTraitDeclarationToProductMeta = {
        __typename?: 'ProductsForProductTraitDeclarationToProductMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type Project = Node & {
        __typename?: 'Project';
        checkMutations?: Maybe<ProjectMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        description?: Maybe<Scalars['String']>;
        documentationProject?: Maybe<Project>;
        enabled?: Maybe<Scalars['Boolean']>;
        enabledFrom?: Maybe<Scalars['Date']>;
        fileURL?: Maybe<Scalars['String']>;
        franchiseRestricted?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        latestRelease?: Maybe<ProjectVersion>;
        name?: Maybe<Scalars['String']>;
        organisation?: Maybe<OrganisationType>;
        pinnedVersion?: Maybe<ProjectVersion>;
        platformKey?: Maybe<Scalars['String']>;
        priority?: Maybe<Scalars['Int']>;
        recommendedVersion?: Maybe<ProjectVersion>;
        releaseCount?: Maybe<Scalars['Int']>;
        remoteThumbnailURL?: Maybe<Scalars['String']>;
        thumbnailFile?: Maybe<FileUpload>;
        thumbnailURL?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
        versions?: Maybe<VersionsForProjectToProjectVersionConnection>;
    };

    export type ProjectVersionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProjectVersionProjectVersionsSearch>;
        sort?: InputMaybe<ProjectVersionProjectVersionsSort>;
    };

    export type ProjectMutationCheck = {
        __typename?: 'ProjectMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProjectMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProjectMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProjectMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProjectMutationInput>>>;
    };

    export type ProjectMutationPayload = {
        __typename?: 'ProjectMutationPayload';
        Project?: Maybe<Project>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProjectMutationOutput>>>;
        updatedDocumentationProject?: Maybe<Project>;
        updatedOrganisation?: Maybe<OrganisationType>;
        updatedPinnedVersion?: Maybe<ProjectVersion>;
        updatedThumbnailFile?: Maybe<FileUpload>;
        viewer?: Maybe<Viewer>;
    };

    export type ProjectVersion = Node & {
        __typename?: 'ProjectVersion';
        changelogSummary?: Maybe<Scalars['String']>;
        checkMutations?: Maybe<ProjectVersionMutationCheck>;
        description?: Maybe<Scalars['String']>;
        enabled?: Maybe<Scalars['Boolean']>;
        enabledFrom?: Maybe<Scalars['Date']>;
        fileURL?: Maybe<Scalars['String']>;
        fileUpload?: Maybe<FileUpload>;
        id: Scalars['ID'];
        isPinned?: Maybe<Scalars['Boolean']>;
        project?: Maybe<Project>;
        releaseNumber?: Maybe<Scalars['Int']>;
        remoteFileURL?: Maybe<Scalars['String']>;
        requirements?: Maybe<Scalars['String']>;
        versionNumber?: Maybe<Scalars['String']>;
    };

    export type ProjectVersionMutationCheck = {
        __typename?: 'ProjectVersionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ProjectVersionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateProjectVersionMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteProjectVersionMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateProjectVersionMutationInput>>>;
    };

    export type ProjectVersionMutationPayload = {
        __typename?: 'ProjectVersionMutationPayload';
        ProjectVersion?: Maybe<ProjectVersion>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkProjectVersionMutationOutput>>>;
        updatedFileUpload?: Maybe<FileUpload>;
        updatedProject?: Maybe<Project>;
        viewer?: Maybe<Viewer>;
    };

    export type ProjectVersionProjectVersionsSearch = {
        enabled?: InputMaybe<BooleanMatchSearchCommon>;
        enabledFrom?: InputMaybe<DateRangeSearchCommon>;
        releaseNumber?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ProjectVersionProjectVersionsSort = {
        /** Expects node: **ProjectVersion** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        releaseNumber?: InputMaybe<Scalars['Boolean']>;
        versionNumber?: InputMaybe<Scalars['Boolean']>;
    };

    export type ProjectViewerProjectHostingSearch = {
        enabled?: InputMaybe<BooleanMatchSearchCommon>;
        enabledFrom?: InputMaybe<DateRangeSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ProjectViewerProjectHostingSort = {
        /** Expects node: **Project** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    /** The query root of GraphQL interface. */
    export type Query = {
        __typename?: 'Query';
        /** Fetches an object given its ID */
        node?: Maybe<Node>;
        viewer: Viewer;
    };

    /** The query root of GraphQL interface. */
    export type QueryNodeArgs = {
        id: Scalars['ID'];
    };

    export type RecentActivity = {
        __typename?: 'RecentActivity';
        franchise?: Maybe<Franchise>;
        lastCustomerDate?: Maybe<Scalars['Date']>;
        lastReportDate?: Maybe<Scalars['Date']>;
        newAppointments?: Maybe<NewAppointmentsForRecentActivityToActivitySampleConnection>;
        newCustomers?: Maybe<NewCustomersForRecentActivityToActivitySampleConnection>;
        newPools?: Maybe<NewPoolsForRecentActivityToActivitySampleConnection>;
        newReports?: Maybe<NewReportsForRecentActivityToActivitySampleConnection>;
        newServiceJobs?: Maybe<NewServiceJobsForRecentActivityToActivitySampleConnection>;
    };

    export type RecentActivityNewAppointmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RecentActivityNewCustomersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RecentActivityNewPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RecentActivityNewReportsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        staff?: InputMaybe<Scalars['ID']>;
    };

    export type RecentActivityNewServiceJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type RecommendationsForTestReportToTestRecommendationConnection = {
        __typename?: 'RecommendationsForTestReportToTestRecommendationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RecommendationsForTestReportToTestRecommendationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type RecommendationsForTestReportToTestRecommendationEdge = {
        __typename?: 'RecommendationsForTestReportToTestRecommendationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestRecommendation>;
    };

    export type RecommendedTreatment = Node & {
        __typename?: 'RecommendedTreatment';
        affects?: Maybe<AffectsForRecommendedTreatmentToSolutionAffectConnection>;
        balancedDosage?: Maybe<Scalars['Float']>;
        checkMutations?: Maybe<RecommendedTreatmentMutationCheck>;
        comment?: Maybe<Scalars['String']>;
        dosage?: Maybe<Scalars['Float']>;
        id: Scalars['ID'];
        isCustomInstruction?: Maybe<Scalars['Boolean']>;
        isOverridden?: Maybe<Scalars['Boolean']>;
        problem?: Maybe<TestProblem>;
        report?: Maybe<TestReport>;
        sampleInstruction?: Maybe<Scalars['String']>;
        solution?: Maybe<TestReportSolution>;
        treatment?: Maybe<TestTreatment>;
    };

    export type RecommendedTreatmentAffectsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RecommendedTreatmentMutationCheck = {
        __typename?: 'RecommendedTreatmentMutationCheck';
        override?: Maybe<Scalars['Boolean']>;
        reset?: Maybe<Scalars['Boolean']>;
    };

    export type RecommendedTreatmentMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        override?: InputMaybe<Array<InputMaybe<RecommendedTreatmentOverrideMutationInput>>>;
        reset?: InputMaybe<Array<InputMaybe<RecommendedTreatmentResetMutationInput>>>;
    };

    export type RecommendedTreatmentMutationPayload = {
        __typename?: 'RecommendedTreatmentMutationPayload';
        RecommendedTreatment?: Maybe<RecommendedTreatment>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkRecommendedTreatmentMutationOutput>>>;
        updatedProblem?: Maybe<TestProblem>;
        updatedReport?: Maybe<TestReport>;
        updatedTreatment?: Maybe<TestTreatment>;
        viewer?: Maybe<Viewer>;
    };

    export type RecommendedTreatmentOverrideMutationInput = {
        comment?: InputMaybe<Scalars['String']>;
        dosage?: InputMaybe<Scalars['Float']>;
        /** Expects node: **RecommendedTreatment** */
        id: Scalars['ID'];
        instruction?: InputMaybe<Scalars['String']>;
    };

    export type RecommendedTreatmentResetMutationInput = {
        /** Expects node: **RecommendedTreatment** */
        id: Scalars['ID'];
    };

    /** A connection to a list of items. */
    export type RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentConnection = {
        __typename?: 'RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentEdge = {
        __typename?: 'RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<RecommendedTreatment>;
    };

    export type Recurrence = {
        __typename?: 'Recurrence';
        occurrencesPreview?: Maybe<Array<Maybe<Scalars['Date']>>>;
        pattern?: Maybe<RecurrencePattern>;
        range?: Maybe<RecurrenceRange>;
    };

    export type RecurrenceOccurrencesPreviewArgs = {
        reverse?: InputMaybe<Scalars['Boolean']>;
        startDate: Scalars['Date'];
    };

    export type RecurrenceNodeInitInput = {
        pattern: RecurrencePatternNodeInitInput;
        range: RecurrenceRangeNodeInitInput;
    };

    export type RecurrenceNodeUpdateInput = {
        pattern?: InputMaybe<RecurrencePatternNodeUpdateInput>;
        range?: InputMaybe<RecurrenceRangeNodeUpdateInput>;
    };

    export type RecurrencePattern = {
        __typename?: 'RecurrencePattern';
        dayOfMonth?: Maybe<Scalars['Int']>;
        /** Array size expected to be from 1 to 7 */
        daysOfWeek?: Maybe<Array<Maybe<WeekdayEnum>>>;
        index?: Maybe<WeekIndexEnum>;
        interval?: Maybe<Scalars['Int']>;
        month?: Maybe<MonthEnum>;
        type?: Maybe<PatternTypeEnum>;
    };

    export type RecurrencePatternNodeInitInput = {
        dayOfMonth?: InputMaybe<Scalars['Int']>;
        /** Array size expected to be from 1 to 7 */
        daysOfWeek?: InputMaybe<Array<InputMaybe<WeekdayEnum>>>;
        index?: InputMaybe<WeekIndexEnum>;
        interval?: InputMaybe<Scalars['Int']>;
        month?: InputMaybe<MonthEnum>;
        type: PatternTypeEnum;
    };

    export type RecurrencePatternNodeUpdateInput = {
        dayOfMonth?: InputMaybe<Scalars['Int']>;
        /** Array size expected to be from 1 to 7 */
        daysOfWeek?: InputMaybe<Array<InputMaybe<WeekdayEnum>>>;
        index?: InputMaybe<WeekIndexEnum>;
        interval?: InputMaybe<Scalars['Int']>;
        month?: InputMaybe<MonthEnum>;
        type?: InputMaybe<PatternTypeEnum>;
    };

    export type RecurrenceRange = {
        __typename?: 'RecurrenceRange';
        endDate?: Maybe<Scalars['Date']>;
        /** @deprecated Not used */
        interval?: Maybe<Scalars['Int']>;
        numberOfOccurrences?: Maybe<Scalars['Int']>;
        timeZone?: Maybe<Scalars['String']>;
        type?: Maybe<RecurrenceTypeEnum>;
    };

    export type RecurrenceRangeNodeInitInput = {
        endDate?: InputMaybe<Scalars['Date']>;
        interval?: InputMaybe<Scalars['Int']>;
        numberOfOccurrences?: InputMaybe<Scalars['Int']>;
        timeZone: Scalars['String'];
        type: RecurrenceTypeEnum;
    };

    export type RecurrenceRangeNodeUpdateInput = {
        endDate?: InputMaybe<Scalars['Date']>;
        interval?: InputMaybe<Scalars['Int']>;
        numberOfOccurrences?: InputMaybe<Scalars['Int']>;
        timeZone?: InputMaybe<Scalars['String']>;
        type?: InputMaybe<RecurrenceTypeEnum>;
    };

    export enum RecurrenceTypeEnum {
        EndDate = 'EndDate',
        NoEnd = 'NoEnd',
        Numbered = 'Numbered',
    }

    /** A connection to a list of items. */
    export type RecurrentAppointmentsForServiceJobToAppointmentConnection = {
        __typename?: 'RecurrentAppointmentsForServiceJobToAppointmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RecurrentAppointmentsForServiceJobToAppointmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type RecurrentAppointmentsForServiceJobToAppointmentEdge = {
        __typename?: 'RecurrentAppointmentsForServiceJobToAppointmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Appointment>;
    };

    /** A connection to a list of items. */
    export type RelatedDeclarationsForSanitiserToProductTraitDeclarationConnection = {
        __typename?: 'RelatedDeclarationsForSanitiserToProductTraitDeclarationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RelatedDeclarationsForSanitiserToProductTraitDeclarationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<RelatedDeclarationsForSanitiserToProductTraitDeclarationMeta>;
    };

    /** An edge in a connection. */
    export type RelatedDeclarationsForSanitiserToProductTraitDeclarationEdge = {
        __typename?: 'RelatedDeclarationsForSanitiserToProductTraitDeclarationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductTraitDeclaration>;
    };

    export type RelatedDeclarationsForSanitiserToProductTraitDeclarationMeta = {
        __typename?: 'RelatedDeclarationsForSanitiserToProductTraitDeclarationMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type RelatedEmailsForTestReportToEmailMessageConnection = {
        __typename?: 'RelatedEmailsForTestReportToEmailMessageConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RelatedEmailsForTestReportToEmailMessageEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<RelatedEmailsForTestReportToEmailMessageMeta>;
    };

    /** An edge in a connection. */
    export type RelatedEmailsForTestReportToEmailMessageEdge = {
        __typename?: 'RelatedEmailsForTestReportToEmailMessageEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailMessage>;
    };

    export type RelatedEmailsForTestReportToEmailMessageMeta = {
        __typename?: 'RelatedEmailsForTestReportToEmailMessageMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type RelatedProblemsForTestTreatmentToTestProblemConnection = {
        __typename?: 'RelatedProblemsForTestTreatmentToTestProblemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RelatedProblemsForTestTreatmentToTestProblemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<RelatedProblemsForTestTreatmentToTestProblemMeta>;
    };

    /** An edge in a connection. */
    export type RelatedProblemsForTestTreatmentToTestProblemEdge = {
        __typename?: 'RelatedProblemsForTestTreatmentToTestProblemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestProblem>;
    };

    export type RelatedProblemsForTestTreatmentToTestProblemMeta = {
        __typename?: 'RelatedProblemsForTestTreatmentToTestProblemMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type RelatedSanitisersForProductTraitDeclarationToSanitiserConnection = {
        __typename?: 'RelatedSanitisersForProductTraitDeclarationToSanitiserConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<RelatedSanitisersForProductTraitDeclarationToSanitiserEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<RelatedSanitisersForProductTraitDeclarationToSanitiserMeta>;
    };

    /** An edge in a connection. */
    export type RelatedSanitisersForProductTraitDeclarationToSanitiserEdge = {
        __typename?: 'RelatedSanitisersForProductTraitDeclarationToSanitiserEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sanitiser>;
    };

    export type RelatedSanitisersForProductTraitDeclarationToSanitiserMeta = {
        __typename?: 'RelatedSanitisersForProductTraitDeclarationToSanitiserMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type RemovePretendInput = {
        blank?: InputMaybe<Scalars['Boolean']>;
        clientMutationId?: InputMaybe<Scalars['String']>;
    };

    export type RemovePretendPayload = {
        __typename?: 'RemovePretendPayload';
        clientMutationId?: Maybe<Scalars['String']>;
        viewer?: Maybe<Viewer>;
    };

    /** A connection to a list of items. */
    export type ReportsForPoolToTestReportConnection = {
        __typename?: 'ReportsForPoolToTestReportConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ReportsForPoolToTestReportEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ReportsForPoolToTestReportMeta>;
    };

    /** An edge in a connection. */
    export type ReportsForPoolToTestReportEdge = {
        __typename?: 'ReportsForPoolToTestReportEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReport>;
    };

    export type ReportsForPoolToTestReportMeta = {
        __typename?: 'ReportsForPoolToTestReportMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ReportsForSampleSetToTestReportConnection = {
        __typename?: 'ReportsForSampleSetToTestReportConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ReportsForSampleSetToTestReportEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ReportsForSampleSetToTestReportEdge = {
        __typename?: 'ReportsForSampleSetToTestReportEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReport>;
    };

    /** A connection to a list of items. */
    export type ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleConnection = {
        __typename?: 'ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleEdge = {
        __typename?: 'ReportsVolumePerFranchiseForHQFranchisesStatsToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    /** A connection to a list of items. */
    export type ReportsVolumeTotalForHQFranchisesStatsToActivitySampleConnection = {
        __typename?: 'ReportsVolumeTotalForHQFranchisesStatsToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ReportsVolumeTotalForHQFranchisesStatsToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ReportsVolumeTotalForHQFranchisesStatsToActivitySampleEdge = {
        __typename?: 'ReportsVolumeTotalForHQFranchisesStatsToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    export type Role = Node & {
        __typename?: 'Role';
        checkMutations?: Maybe<RoleMutationCheck>;
        flags?: Maybe<FlagsForRoleToRoleFlagConnection>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        permissions?: Maybe<PermissionsForRoleToPermissionConnection>;
    };

    export type RoleFlagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RolePermissionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type RoleFlag = Node & {
        __typename?: 'RoleFlag';
        checkMutations?: Maybe<RoleFlagMutationCheck>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        tag?: Maybe<Scalars['String']>;
    };

    export type RoleFlagMutationCheck = {
        __typename?: 'RoleFlagMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type RoleFlagMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateRoleFlagMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteRoleFlagMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateRoleFlagMutationInput>>>;
    };

    export type RoleFlagMutationPayload = {
        __typename?: 'RoleFlagMutationPayload';
        RoleFlag?: Maybe<RoleFlag>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkRoleFlagMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type RoleFlagViewerRoleFlagsSearch = {
        description?: InputMaybe<StringMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
    };

    export type RoleMutationCheck = {
        __typename?: 'RoleMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type RoleMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateRoleMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateRoleMutationInput>>>;
    };

    export type RoleMutationPayload = {
        __typename?: 'RoleMutationPayload';
        Role?: Maybe<Role>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkRoleMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type RoleNodeMatchSearchCommon = {
        /** Expects node: **Role** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type RoleViewerRolesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type SaleItemsForVendSaleToVendSaleItemConnection = {
        __typename?: 'SaleItemsForVendSaleToVendSaleItemConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SaleItemsForVendSaleToVendSaleItemEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SaleItemsForVendSaleToVendSaleItemEdge = {
        __typename?: 'SaleItemsForVendSaleToVendSaleItemEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<VendSaleItem>;
    };

    /** A connection to a list of items. */
    export type SalesForVendCustomerToVendSaleConnection = {
        __typename?: 'SalesForVendCustomerToVendSaleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SalesForVendCustomerToVendSaleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SalesForVendCustomerToVendSaleEdge = {
        __typename?: 'SalesForVendCustomerToVendSaleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<VendSale>;
    };

    export type Sample = Node & {
        __typename?: 'Sample';
        checkMutations?: Maybe<SampleMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        measurementType?: Maybe<Measurement>;
        pool?: Maybe<Pool>;
        previous?: Maybe<Sample>;
        source?: Maybe<SampleSource>;
        value?: Maybe<Scalars['Float']>;
    };

    export type SampleConverter = Node & {
        __typename?: 'SampleConverter';
        checkMutations?: Maybe<SampleConverterMutationCheck>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        tag?: Maybe<Scalars['String']>;
        templates?: Maybe<TemplatesForSampleConverterToSampleConverterTemplateConnection>;
    };

    export type SampleConverterTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type SampleConverterMutationCheck = {
        __typename?: 'SampleConverterMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SampleConverterMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSampleConverterMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSampleConverterMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleConverterMutationInput>>>;
    };

    export type SampleConverterMutationPayload = {
        __typename?: 'SampleConverterMutationPayload';
        SampleConverter?: Maybe<SampleConverter>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSampleConverterMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type SampleConverterTemplate = Node & {
        __typename?: 'SampleConverterTemplate';
        checkMutations?: Maybe<SampleConverterTemplateMutationCheck>;
        converter?: Maybe<SampleConverter>;
        data?: Maybe<Scalars['JSON']>;
        id: Scalars['ID'];
        measurement?: Maybe<Measurement>;
        tag?: Maybe<Scalars['String']>;
    };

    export type SampleConverterTemplateMutationCheck = {
        __typename?: 'SampleConverterTemplateMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SampleConverterTemplateMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSampleConverterTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSampleConverterTemplateMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleConverterTemplateMutationInput>>>;
    };

    export type SampleConverterTemplateMutationPayload = {
        __typename?: 'SampleConverterTemplateMutationPayload';
        SampleConverterTemplate?: Maybe<SampleConverterTemplate>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSampleConverterTemplateMutationOutput>>>;
        updatedConverter?: Maybe<SampleConverter>;
        updatedMeasurement?: Maybe<Measurement>;
        viewer?: Maybe<Viewer>;
    };

    export type SampleConverterViewerConvertersSearch = {
        name?: InputMaybe<StringMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SampleMap = Node & {
        __typename?: 'SampleMap';
        entries?: Maybe<EntriesForSampleMapToSampleConnection>;
        id: Scalars['ID'];
        pool?: Maybe<Pool>;
        type?: Maybe<Measurement>;
    };

    export type SampleMapEntriesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type SampleMutationCheck = {
        __typename?: 'SampleMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SampleMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSampleMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSampleMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleMutationInput>>>;
    };

    export type SampleMutationPayload = {
        __typename?: 'SampleMutationPayload';
        Sample?: Maybe<Sample>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSampleMutationOutput>>>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedPool?: Maybe<Pool>;
        updatedSource?: Maybe<SampleSource>;
        viewer?: Maybe<Viewer>;
    };

    export type SamplePoolSamplesSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        source?: InputMaybe<SampleSourceNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SamplePoolSamplesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Sample** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        value?: InputMaybe<Scalars['Boolean']>;
    };

    export type SampleSet = Node & {
        __typename?: 'SampleSet';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<SampleSetMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        hasTestReports?: Maybe<Scalars['Int']>;
        id: Scalars['ID'];
        pool?: Maybe<Pool>;
        reports?: Maybe<ReportsForSampleSetToTestReportConnection>;
        samples?: Maybe<SamplesForSampleSetToSampleConnection>;
        toPlain?: Maybe<Scalars['JSON']>;
    };

    export type SampleSetReportsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type SampleSetSamplesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type SampleSetMutationCheck = {
        __typename?: 'SampleSetMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SampleSetMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSampleSetMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleSetMutationInput>>>;
    };

    export type SampleSetMutationPayload = {
        __typename?: 'SampleSetMutationPayload';
        SampleSet?: Maybe<SampleSet>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSampleSetMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedPool?: Maybe<Pool>;
        viewer?: Maybe<Viewer>;
    };

    export type SampleSetPoolSampleSetsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SampleSetPoolSampleSetsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **SampleSet** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type SampleSetStaffSamplesAddedSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SampleSetStaffSamplesAddedSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **SampleSet** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type SampleSetViewerSampleSetsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SampleSetViewerSampleSetsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **SampleSet** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type SampleSetsForPoolToSampleSetConnection = {
        __typename?: 'SampleSetsForPoolToSampleSetConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SampleSetsForPoolToSampleSetEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SampleSetsForPoolToSampleSetMeta>;
    };

    /** An edge in a connection. */
    export type SampleSetsForPoolToSampleSetEdge = {
        __typename?: 'SampleSetsForPoolToSampleSetEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleSet>;
    };

    export type SampleSetsForPoolToSampleSetMeta = {
        __typename?: 'SampleSetsForPoolToSampleSetMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type SampleSource = Node & {
        __typename?: 'SampleSource';
        checkMutations?: Maybe<SampleSourceMutationCheck>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
    };

    export type SampleSourceMutationCheck = {
        __typename?: 'SampleSourceMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SampleSourceMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSampleSourceMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleSourceMutationInput>>>;
    };

    export type SampleSourceMutationPayload = {
        __typename?: 'SampleSourceMutationPayload';
        SampleSource?: Maybe<SampleSource>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSampleSourceMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type SampleSourceNodeMatchSearchCommon = {
        /** Expects node: **SampleSource** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSource** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SampleSourceViewerSampleSourcesSearch = {
        name?: InputMaybe<StringMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type SamplesAddedForStaffToSampleSetConnection = {
        __typename?: 'SamplesAddedForStaffToSampleSetConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SamplesAddedForStaffToSampleSetEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SamplesAddedForStaffToSampleSetMeta>;
    };

    /** An edge in a connection. */
    export type SamplesAddedForStaffToSampleSetEdge = {
        __typename?: 'SamplesAddedForStaffToSampleSetEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleSet>;
    };

    export type SamplesAddedForStaffToSampleSetMeta = {
        __typename?: 'SamplesAddedForStaffToSampleSetMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type SamplesForPoolToSampleConnection = {
        __typename?: 'SamplesForPoolToSampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SamplesForPoolToSampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SamplesForPoolToSampleMeta>;
    };

    /** An edge in a connection. */
    export type SamplesForPoolToSampleEdge = {
        __typename?: 'SamplesForPoolToSampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sample>;
    };

    export type SamplesForPoolToSampleMeta = {
        __typename?: 'SamplesForPoolToSampleMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type SamplesForSampleSetToSampleConnection = {
        __typename?: 'SamplesForSampleSetToSampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SamplesForSampleSetToSampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SamplesForSampleSetToSampleEdge = {
        __typename?: 'SamplesForSampleSetToSampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sample>;
    };

    /** A connection to a list of items. */
    export type SamplesMapForPoolToSampleMapConnection = {
        __typename?: 'SamplesMapForPoolToSampleMapConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SamplesMapForPoolToSampleMapEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SamplesMapForPoolToSampleMapEdge = {
        __typename?: 'SamplesMapForPoolToSampleMapEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleMap>;
    };

    export type Sanitiser = Node & {
        __typename?: 'Sanitiser';
        checkMutations?: Maybe<SanitiserMutationCheck>;
        criteria?: Maybe<CriteriaForSanitiserToTestCriteriaConnection>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
        publishedAt?: Maybe<Scalars['Date']>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        relatedDeclarations?: Maybe<RelatedDeclarationsForSanitiserToProductTraitDeclarationConnection>;
        reportName?: Maybe<Scalars['String']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type SanitiserCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type SanitiserRelatedDeclarationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationSanitiserRelatedDeclarationsSearch>;
        sort?: InputMaybe<ProductTraitDeclarationSanitiserRelatedDeclarationsSort>;
    };

    export type SanitiserChangePublishedStatusMutationInput = {
        /** Expects node: **Sanitiser** */
        id: Scalars['ID'];
        status: Scalars['Boolean'];
    };

    export type SanitiserMutationCheck = {
        __typename?: 'SanitiserMutationCheck';
        changePublishedStatus?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SanitiserMutationInput = {
        changePublishedStatus?: InputMaybe<Array<InputMaybe<SanitiserChangePublishedStatusMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSanitiserMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSanitiserMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSanitiserMutationInput>>>;
    };

    export type SanitiserMutationPayload = {
        __typename?: 'SanitiserMutationPayload';
        Sanitiser?: Maybe<Sanitiser>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSanitiserMutationOutput>>>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type SanitiserNodeMatchSearchCommon = {
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteria>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblem>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJob = {
        address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntitySites = {
        address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaff>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerTags>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffTeams>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportTo = {
        addresses?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSites>;
        user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToAddresses =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJob =
        {
            address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSites = {
        address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSitesAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaff>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityContacts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            data?: InputMaybe<StringLikeOrMatchSearchCommon>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Contact** */
            id?: InputMaybe<Scalars['ID']>;
            isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
            label?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **Contact** */
            not?: InputMaybe<Scalars['ID']>;
            type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJob =
        {
            address?: InputMaybe<AddressNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            closedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
            description?: InputMaybe<StringMatchSearchCommon>;
            dueDate?: InputMaybe<DateRangeSearchCommon>;
            ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
            group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            id?: InputMaybe<Scalars['ID']>;
            implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
            isImplicit?: InputMaybe<Scalars['Boolean']>;
            isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects node: **ServiceJob** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJobNumber?: InputMaybe<Scalars['String']>;
            staff?: InputMaybe<StaffNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            stopsAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
            usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
            workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
            workOrders?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJob = {
        address?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer =
        {
            addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
            companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Customer** */
            id?: InputMaybe<Scalars['ID']>;
            inVend?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **Customer** */
            not?: InputMaybe<Scalars['ID']>;
            primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
            tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
            user?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
            staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
            hasAppointment?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrder** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **WorkOrder** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
            stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
            stageType?: InputMaybe<Array<ServiceJobStageType>>;
            statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
            title?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
            workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
            workOrderNumber?: InputMaybe<Scalars['String']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaff>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
            teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerTags =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **CustomerTag** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSearchCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SanitiserProductTraitDeclarationRelatedSanitisersSort = {
        /** Expects node: **Sanitiser** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        publishedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type SanitiserTestCriteriaSanitisersSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SanitiserTestCriteriaSanitisersSearchRelatedDeclarations>;
    };

    export type SanitiserTestCriteriaSanitisersSearchRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserTestCriteriaSanitisersSearchRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserTestCriteriaSanitisersSort = {
        /** Expects node: **Sanitiser** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        publishedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type SanitiserViewerPoolSanitisersSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteria>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SanitiserViewerPoolSanitisersSearchRelatedDeclarations>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblem>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<SanitiserNodeMatchSearchCommon>;
        site?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntity = {
        addresses?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntitySites>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJob = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobWorkOrders>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerTags>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntitySites = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerTags>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportTo = {
        addresses?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSites>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJob = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSites = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerTags>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSite = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            id?: InputMaybe<Scalars['ID']>;
            lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff =
        {
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            id?: InputMaybe<Scalars['ID']>;
            isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
            /** Expects nodes: **StaffRegistration**, **Staff** */
            not?: InputMaybe<Scalars['ID']>;
            registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
            teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **StaffTeam** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffTeam** */
            not?: InputMaybe<Scalars['ID']>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJob = {
        address?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaff>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerTags>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffTeams>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaPoolTypesPoolsSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSearchRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SanitiserViewerPoolSanitisersSort = {
        /** Expects node: **Sanitiser** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        publishedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type SanitisersForTestCriteriaToSanitiserConnection = {
        __typename?: 'SanitisersForTestCriteriaToSanitiserConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SanitisersForTestCriteriaToSanitiserEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SanitisersForTestCriteriaToSanitiserMeta>;
    };

    /** An edge in a connection. */
    export type SanitisersForTestCriteriaToSanitiserEdge = {
        __typename?: 'SanitisersForTestCriteriaToSanitiserEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sanitiser>;
    };

    export type SanitisersForTestCriteriaToSanitiserMeta = {
        __typename?: 'SanitisersForTestCriteriaToSanitiserMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type ScheduleEvent = Node & {
        __typename?: 'ScheduleEvent';
        checkMutations?: Maybe<ScheduleEventMutationCheck>;
        endDate?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        recurrence?: Maybe<Recurrence>;
        startDate?: Maybe<Scalars['Date']>;
    };

    export type ScheduleEventMutationCheck = {
        __typename?: 'ScheduleEventMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type ScheduleEventMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteScheduleEventMutationInput>>>;
    };

    export type ScheduleEventMutationPayload = {
        __typename?: 'ScheduleEventMutationPayload';
        ScheduleEvent?: Maybe<ScheduleEvent>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkScheduleEventMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJob = Node & {
        __typename?: 'ServiceJob';
        abstractDocuments?: Maybe<AbstractDocumentsForServiceJobToAbstractDocumentConnection>;
        address?: Maybe<Address>;
        appointmentItems?: Maybe<AppointmentItemsForServiceJobToAppointmentItemConnection>;
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<ServiceJobMutationCheck>;
        closedAt?: Maybe<Scalars['Date']>;
        createdAt?: Maybe<Scalars['Date']>;
        createdBy?: Maybe<Staff>;
        customer?: Maybe<Customer>;
        description?: Maybe<Scalars['String']>;
        dueDate?: Maybe<Scalars['Date']>;
        entity?: Maybe<Entity>;
        ezcDeviceLink?: Maybe<EZCDeviceLink>;
        files?: Maybe<FilesForServiceJobToFileUploadConnection>;
        followedFrom?: Maybe<ServiceJob>;
        group?: Maybe<ServiceJobGroup>;
        id: Scalars['ID'];
        implicitType?: Maybe<ServiceJobImplicitTypeEnum>;
        isImplicit?: Maybe<Scalars['Boolean']>;
        isRecurring?: Maybe<Scalars['Boolean']>;
        isSimpleMode?: Maybe<Scalars['Boolean']>;
        lastAppointment?: Maybe<Appointment>;
        lastAppointmentDate?: Maybe<Scalars['Date']>;
        lastRecurrentAppointment?: Maybe<Appointment>;
        notes?: Maybe<NotesForServiceJobToServiceJobNoteConnection>;
        pool?: Maybe<Pool>;
        preferredContact?: Maybe<Contact>;
        recurrentAppointments?: Maybe<RecurrentAppointmentsForServiceJobToAppointmentConnection>;
        serviceJobNumber?: Maybe<Scalars['String']>;
        staff?: Maybe<Staff>;
        stage?: Maybe<ServiceJobStage>;
        stageCandidates?: Maybe<Array<Maybe<ServiceJobStage>>>;
        stageChanges?: Maybe<StageChangesForServiceJobToServiceJobStageHistoryConnection>;
        stageHistory?: Maybe<StageHistoryForServiceJobToServiceJobStageDurationConnection>;
        stageStatus?: Maybe<ServiceJobStageType>;
        /** To be used with recurrent services */
        stopsAt?: Maybe<Scalars['Date']>;
        /** Required if no template provided */
        title?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        usedTemplate?: Maybe<ServiceJobTemplate>;
        workOrders?: Maybe<WorkOrdersForServiceJobToWorkOrderConnection>;
    };

    export type ServiceJobAbstractDocumentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AbstractDocumentServiceJobAbstractDocumentsSearch>;
        sort?: InputMaybe<AbstractDocumentServiceJobAbstractDocumentsSort>;
    };

    export type ServiceJobAppointmentItemsArgs = {
        addedBy?: InputMaybe<Scalars['ID']>;
        address?: InputMaybe<Scalars['ID']>;
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        customer?: InputMaybe<Scalars['ID']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type ServiceJobFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadServiceJobFilesSearch>;
        sort?: InputMaybe<FileUploadServiceJobFilesSort>;
    };

    export type ServiceJobNotesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobNoteServiceJobNotesSearch>;
        sort?: InputMaybe<ServiceJobNoteServiceJobNotesSort>;
    };

    export type ServiceJobRecurrentAppointmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ServiceJobStageChangesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobStageHistoryServiceJobStageChangesSearch>;
        sort?: InputMaybe<ServiceJobStageHistoryServiceJobStageChangesSort>;
    };

    export type ServiceJobStageHistoryArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobWorkOrdersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderServiceJobWorkOrdersSearch>;
        sort?: InputMaybe<WorkOrderServiceJobWorkOrdersSort>;
    };

    export type ServiceJobArchiveMutationInput = {
        /** Expects node: **ServiceJob** */
        id: Scalars['ID'];
    };

    export type ServiceJobBookingNodeInitInput = {
        /** In minutes */
        duration?: InputMaybe<Scalars['Int']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
        recurrence: RecurrenceNodeInitInput;
        selectedColor?: InputMaybe<Scalars['String']>;
        startDate: Scalars['Date'];
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        workOrderTemplate?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCreateForRecurrentAppointmentMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        appointmentItem: Scalars['ID'];
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobTemplate** */
        serviceTemplate?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        workOrderTemplate?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCreateForSingleAppointmentMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        appointmentItem: Scalars['ID'];
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobTemplate** */
        serviceTemplate?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        workOrderTemplate?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearch = {
        address?: InputMaybe<ServiceJobCustomerServiceJobsSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        pool?: InputMaybe<ServiceJobCustomerServiceJobsSearchPool>;
        /** Expects node: **Customer** */
        relatedCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        relatedPool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        relatedStaff?: InputMaybe<Scalars['ID']>;
        /** Checks stage type of ServiceJob Stage or WorkOrder Stage */
        relatedStageType?: InputMaybe<Array<ServiceJobStageType>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ServiceJobCustomerServiceJobsSearchWorkOrders>;
    };

    export type ServiceJobCustomerServiceJobsSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntity = {
        addresses?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPools>;
        sites?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntitySites>;
        user?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSite>;
        type?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSite = {
        address?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntitySites = {
        address?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityUserStaff>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobCustomerServiceJobsSearchEntityUserStaffTeams>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolOwnershipHistory>;
        reports?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolReports>;
        sanitiser?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiser>;
        site?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSite>;
        type?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSite = {
        address?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobCustomerServiceJobsSearchWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobCustomerServiceJobsSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        serviceJobNumber?: InputMaybe<Scalars['Boolean']>;
        stopsAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobGroup = Node & {
        __typename?: 'ServiceJobGroup';
        associatedStages?: Maybe<AssociatedStagesForServiceJobGroupToServiceJobStageConnection>;
        checkMutations?: Maybe<ServiceJobGroupMutationCheck>;
        children?: Maybe<ChildrenForServiceJobGroupToServiceJobGroupConnection>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        organisationType?: Maybe<OrganisationType>;
        parent?: Maybe<ServiceJobGroup>;
        priority?: Maybe<Scalars['Int']>;
        services?: Maybe<ServicesForServiceJobGroupToServiceJobConnection>;
        tag?: Maybe<Scalars['String']>;
        templates?: Maybe<TemplatesForServiceJobGroupToServiceJobTemplateConnection>;
        title?: Maybe<Scalars['String']>;
    };

    export type ServiceJobGroupAssociatedStagesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobStageServiceJobGroupAssociatedStagesSearch>;
        sort?: InputMaybe<ServiceJobStageServiceJobGroupAssociatedStagesSort>;
    };

    export type ServiceJobGroupChildrenArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobGroupServiceJobGroupChildrenSearch>;
        sort?: InputMaybe<ServiceJobGroupServiceJobGroupChildrenSort>;
    };

    export type ServiceJobGroupServicesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobServiceJobGroupServicesSearch>;
        sort?: InputMaybe<ServiceJobServiceJobGroupServicesSort>;
    };

    export type ServiceJobGroupTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobTemplateServiceJobGroupTemplatesSearch>;
        sort?: InputMaybe<ServiceJobTemplateServiceJobGroupTemplatesSort>;
    };

    export type ServiceJobGroupMutationCheck = {
        __typename?: 'ServiceJobGroupMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ServiceJobGroupMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobGroupMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteServiceJobGroupMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobGroupMutationInput>>>;
    };

    export type ServiceJobGroupMutationPayload = {
        __typename?: 'ServiceJobGroupMutationPayload';
        ServiceJobGroup?: Maybe<ServiceJobGroup>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkServiceJobGroupMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedParent?: Maybe<ServiceJobGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJobGroupNodeMatchSearchCommon = {
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobGroupServiceJobGroupChildrenSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobGroupServiceJobGroupChildrenSort = {
        /** Expects node: **ServiceJobGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobGroupServiceJobStageGroupsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobGroupServiceJobStageGroupsSort = {
        /** Expects node: **ServiceJobGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobGroupViewerServiceJobGroupsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobGroupViewerServiceJobGroupsSort = {
        /** Expects node: **ServiceJobGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export enum ServiceJobImplicitTypeEnum {
        Appointment = 'Appointment',
    }

    export type ServiceJobImplicitTypeEnumEnumMatchSearchCommon = {
        is?: InputMaybe<ServiceJobImplicitTypeEnum>;
        not?: InputMaybe<ServiceJobImplicitTypeEnum>;
    };

    export type ServiceJobMutationCheck = {
        __typename?: 'ServiceJobMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setStopDate?: Maybe<Scalars['Boolean']>;
        unArchive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateRecurringJob?: Maybe<Scalars['Boolean']>;
    };

    export type ServiceJobMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<ServiceJobArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobMutationInput>>>;
        createForRecurrentAppointment?: InputMaybe<
            Array<InputMaybe<ServiceJobCreateForRecurrentAppointmentMutationInput>>
        >;
        createForSingleAppointment?: InputMaybe<Array<InputMaybe<ServiceJobCreateForSingleAppointmentMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteServiceJobMutationInput>>>;
        setStopDate?: InputMaybe<Array<InputMaybe<ServiceJobSetStopDateMutationInput>>>;
        splitRecurrentJob?: InputMaybe<Array<InputMaybe<ServiceJobSplitRecurrentJobMutationInput>>>;
        unArchive?: InputMaybe<Array<InputMaybe<ServiceJobUnArchiveMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobMutationInput>>>;
        updateRecurringJob?: InputMaybe<Array<InputMaybe<ServiceJobUpdateRecurringJobMutationInput>>>;
    };

    export type ServiceJobMutationPayload = {
        __typename?: 'ServiceJobMutationPayload';
        ServiceJob?: Maybe<ServiceJob>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkServiceJobMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedCustomer?: Maybe<Customer>;
        updatedEzcDeviceLink?: Maybe<EZCDeviceLink>;
        updatedFollowedFrom?: Maybe<ServiceJob>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedPool?: Maybe<Pool>;
        updatedPreferredContact?: Maybe<Contact>;
        updatedStaff?: Maybe<Staff>;
        updatedStage?: Maybe<ServiceJobStage>;
        updatedUsedTemplate?: Maybe<ServiceJobTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJobNodeMatchSearchCommon = {
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobNote = Node & {
        __typename?: 'ServiceJobNote';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<ServiceJobNoteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        parent?: Maybe<ServiceJob>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ServiceJobNoteMutationCheck = {
        __typename?: 'ServiceJobNoteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ServiceJobNoteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobNoteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteServiceJobNoteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobNoteMutationInput>>>;
    };

    export type ServiceJobNoteMutationPayload = {
        __typename?: 'ServiceJobNoteMutationPayload';
        ServiceJobNote?: Maybe<ServiceJobNote>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkServiceJobNoteMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<ServiceJob>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJobNoteServiceJobNotesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobNoteServiceJobNotesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobNote** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobPoolServiceJobsSearch = {
        address?: InputMaybe<ServiceJobPoolServiceJobsSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ServiceJobPoolServiceJobsSearchEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **Customer** */
        relatedCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        relatedPool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        relatedStaff?: InputMaybe<Scalars['ID']>;
        /** Checks stage type of ServiceJob Stage or WorkOrder Stage */
        relatedStageType?: InputMaybe<Array<ServiceJobStageType>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ServiceJobPoolServiceJobsSearchWorkOrders>;
    };

    export type ServiceJobPoolServiceJobsSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomer = {
        addedBy?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerTags>;
        user?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUser>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByTeams>;
        user?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUser>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntity = {
        addresses?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        sites?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntitySites = {
        address?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserStaff>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserEntity = {
        addresses?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        sites?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserEntitySites = {
        address?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobPoolServiceJobsSearchCustomerUserStaffTeams>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobPoolServiceJobsSearchCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntity = {
        addresses?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        sites?: InputMaybe<ServiceJobPoolServiceJobsSearchEntitySites>;
        user?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntitySites = {
        address?: InputMaybe<ServiceJobPoolServiceJobsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserStaff>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerTags>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedByTeams>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobPoolServiceJobsSearchEntityUserStaffTeams>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobPoolServiceJobsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobPoolServiceJobsSearchWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobPoolServiceJobsSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        serviceJobNumber?: InputMaybe<Scalars['Boolean']>;
        stopsAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobServiceJobGroupServicesSearch = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        pool?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPool>;
        /** Expects node: **Customer** */
        relatedCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        relatedPool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        relatedStaff?: InputMaybe<Scalars['ID']>;
        /** Checks stage type of ServiceJob Stage or WorkOrder Stage */
        relatedStageType?: InputMaybe<Array<ServiceJobStageType>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ServiceJobServiceJobGroupServicesSearchWorkOrders>;
    };

    export type ServiceJobServiceJobGroupServicesSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomer = {
        addedBy?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerTags>;
        user?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUser>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByTeams>;
        user?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUser>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntity = {
        addresses?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPools>;
        sites?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntitySites = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserStaff>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntity = {
        addresses?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPools>;
        sites?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntitySites = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobServiceJobGroupServicesSearchCustomerUserStaffTeams>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntity = {
        addresses?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPools>;
        sites?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntitySites>;
        user?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSite>;
        type?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSite = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntitySites = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserStaff>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomer = {
        addedBy?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerTags>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedByTeams>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobServiceJobGroupServicesSearchEntityUserStaffTeams>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolOwnershipHistory>;
        reports?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolReports>;
        sanitiser?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiser>;
        site?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSite>;
        type?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSite = {
        address?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobServiceJobGroupServicesSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobServiceJobGroupServicesSearchWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobServiceJobGroupServicesSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        serviceJobNumber?: InputMaybe<Scalars['Boolean']>;
        stopsAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobSetStopDateMutationInput = {
        date?: InputMaybe<Scalars['Date']>;
        /** Expects node: **ServiceJob** */
        id: Scalars['ID'];
    };

    export type ServiceJobSplitRecurrentJobMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentItem** */
        fromAppointmentItem: Scalars['ID'];
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobTemplate** */
        serviceTemplate: Scalars['ID'];
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrderTemplate** */
        workOrderTemplate?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobStage = Node & {
        __typename?: 'ServiceJobStage';
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<ServiceJobStageMutationCheck>;
        color?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        groups?: Maybe<GroupsForServiceJobStageToServiceJobGroupConnection>;
        hidden?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        isDefault?: Maybe<Scalars['Boolean']>;
        isFallback?: Maybe<Scalars['Boolean']>;
        organisationType?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
        status?: Maybe<ServiceJobStageStatus>;
        tag?: Maybe<Scalars['String']>;
        title?: Maybe<Scalars['String']>;
        type?: Maybe<ServiceJobStageType>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type ServiceJobStageGroupsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobGroupServiceJobStageGroupsSearch>;
        sort?: InputMaybe<ServiceJobGroupServiceJobStageGroupsSort>;
    };

    export type ServiceJobStageAggregate = {
        __typename?: 'ServiceJobStageAggregate';
        count?: Maybe<Scalars['Int']>;
        stage?: Maybe<ServiceJobStage>;
    };

    export type ServiceJobStageArchiveMutationInput = {
        /** Expects node: **ServiceJobStage** */
        id: Scalars['ID'];
    };

    export type ServiceJobStageDuration = {
        __typename?: 'ServiceJobStageDuration';
        endedAt?: Maybe<Scalars['Date']>;
        fromStage?: Maybe<ServiceJobStage>;
        serviceJobId?: Maybe<Scalars['Date']>;
        startedAt?: Maybe<Scalars['Date']>;
        startedBy?: Maybe<Staff>;
        toStage?: Maybe<ServiceJobStage>;
    };

    export type ServiceJobStageHistory = Node & {
        __typename?: 'ServiceJobStageHistory';
        changedAt?: Maybe<Scalars['Date']>;
        changedBy?: Maybe<Staff>;
        fromStage?: Maybe<ServiceJobStage>;
        id: Scalars['ID'];
        serviceJob?: Maybe<ServiceJob>;
    };

    export type ServiceJobStageHistoryServiceJobStageChangesSearch = {
        fromStage?: InputMaybe<ServiceJobStageHistoryServiceJobStageChangesSearchFromStage>;
    };

    export type ServiceJobStageHistoryServiceJobStageChangesSearchFromStage = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        groups?: InputMaybe<ServiceJobStageHistoryServiceJobStageChangesSearchFromStageGroups>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJobStage** */
        id?: InputMaybe<Scalars['ID']>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJobStage** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobStageHistoryServiceJobStageChangesSearchFromStageGroups = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobStageHistoryServiceJobStageChangesSort = {
        changedAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStageHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type ServiceJobStageMutationCheck = {
        __typename?: 'ServiceJobStageMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ServiceJobStageMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<ServiceJobStageArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobStageMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteServiceJobStageMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobStageMutationInput>>>;
    };

    export type ServiceJobStageMutationPayload = {
        __typename?: 'ServiceJobStageMutationPayload';
        ServiceJobStage?: Maybe<ServiceJobStage>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkServiceJobStageMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJobStageNodeMatchSearchCommon = {
        /** Expects node: **ServiceJobStage** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobStage** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobStageServiceJobGroupAssociatedStagesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobStageServiceJobGroupAssociatedStagesSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        title?: InputMaybe<Scalars['Boolean']>;
        type?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export enum ServiceJobStageStatus {
        Active = 'Active',
        Closed = 'Closed',
        Suspended = 'Suspended',
    }

    export type ServiceJobStageTemplateOwnership = {
        __typename?: 'ServiceJobStageTemplateOwnership';
        isActive?: Maybe<Scalars['Boolean']>;
        ownershipType?: Maybe<ServiceJobStageTemplateOwnershipType>;
        stage?: Maybe<ServiceJobStage>;
    };

    export enum ServiceJobStageTemplateOwnershipType {
        Fallback = 'Fallback',
        Own = 'Own',
    }

    export enum ServiceJobStageType {
        ActionRequired = 'ActionRequired',
        Canceled = 'Canceled',
        Finished = 'Finished',
        InProgress = 'InProgress',
        Opened = 'Opened',
        Suspended = 'Suspended',
    }

    export type ServiceJobStageTypeEnumMatchSearchCommon = {
        is?: InputMaybe<ServiceJobStageType>;
        not?: InputMaybe<ServiceJobStageType>;
    };

    export type ServiceJobStageViewerServiceJobStagesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        groups?: InputMaybe<ServiceJobStageViewerServiceJobStagesSearchGroups>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobStageViewerServiceJobStagesSearchGroups = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTemplate = Node & {
        __typename?: 'ServiceJobTemplate';
        addedBy?: Maybe<Staff>;
        archivedAt?: Maybe<Scalars['Date']>;
        assignedStages?: Maybe<Array<Maybe<ServiceJobStage>>>;
        canBeRecurring?: Maybe<Scalars['Boolean']>;
        checkMutations?: Maybe<ServiceJobTemplateMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        defaultStage?: Maybe<ServiceJobStage>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<ServiceJobGroup>;
        hideStageActionRequired?: Maybe<Scalars['Boolean']>;
        hideStageInProgress?: Maybe<Scalars['Boolean']>;
        hideStageSuspended?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        isDefault?: Maybe<Scalars['Boolean']>;
        isPublished?: Maybe<Scalars['Boolean']>;
        organisationType?: Maybe<OrganisationType>;
        ownerOfWorkOrderTemplates?: Maybe<OwnerOfWorkOrderTemplatesForServiceJobTemplateToWorkOrderTemplateConnection>;
        priority?: Maybe<Scalars['Int']>;
        providedDescription?: Maybe<Scalars['String']>;
        providedTitle?: Maybe<Scalars['String']>;
        requiresAddress?: Maybe<Scalars['Boolean']>;
        requiresContact?: Maybe<Scalars['Boolean']>;
        requiresCustomer?: Maybe<Scalars['Boolean']>;
        requiresPool?: Maybe<Scalars['Boolean']>;
        requiresStaff?: Maybe<Scalars['Boolean']>;
        stageReport?: Maybe<Array<Maybe<ServiceJobStageTemplateOwnership>>>;
        tag?: Maybe<Scalars['String']>;
        templateDescription?: Maybe<Scalars['String']>;
        templateTitle?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        workOrderTemplateAssocs?: Maybe<WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocConnection>;
    };

    export type ServiceJobTemplateAssignedStagesArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobTemplateAssignedStagesSearch>;
        sort?: InputMaybe<ServiceJobTemplateAssignedStagesSort>;
    };

    export type ServiceJobTemplateOwnerOfWorkOrderTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderTemplateServiceJobTemplateOwnerOfWorkOrderTemplatesSearch>;
        sort?: InputMaybe<WorkOrderTemplateServiceJobTemplateOwnerOfWorkOrderTemplatesSort>;
    };

    export type ServiceJobTemplateWorkOrderTemplateAssocsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSearch>;
        sort?: InputMaybe<WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSort>;
    };

    export type ServiceJobTemplateArchiveMutationInput = {
        /** Expects node: **ServiceJobTemplate** */
        id: Scalars['ID'];
    };

    export type ServiceJobTemplateAssignedStagesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        groups?: InputMaybe<ServiceJobTemplateAssignedStagesSearchGroups>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTemplateAssignedStagesSearchGroups = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTemplateAssignedStagesSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        title?: InputMaybe<Scalars['Boolean']>;
        type?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobTemplateMutationCheck = {
        __typename?: 'ServiceJobTemplateMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        setAsDefault?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type ServiceJobTemplateMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<ServiceJobTemplateArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateServiceJobTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteServiceJobTemplateMutationInput>>>;
        setAsDefault?: InputMaybe<Array<InputMaybe<ServiceJobTemplateSetAsDefaultMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobTemplateMutationInput>>>;
    };

    export type ServiceJobTemplateMutationPayload = {
        __typename?: 'ServiceJobTemplateMutationPayload';
        ServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkServiceJobTemplateMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedDefaultStage?: Maybe<ServiceJobStage>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type ServiceJobTemplateNodeMatchSearchCommon = {
        /** Expects node: **ServiceJobTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobTemplate** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTemplateServiceJobGroupTemplatesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        canBeRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        isPublished?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        templateTitle?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTemplateServiceJobGroupTemplatesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        templateTitle?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobTemplateSetAsDefaultMutationInput = {
        /** Expects node: **ServiceJobTemplate** */
        id: Scalars['ID'];
    };

    export type ServiceJobTemplateViewerServiceJobTemplatesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        canBeRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        isPublished?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        templateTitle?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTemplateViewerServiceJobTemplatesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        templateTitle?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobTestReportServiceJobsSearch = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        pool?: InputMaybe<ServiceJobTestReportServiceJobsSearchPool>;
        /** Expects node: **Customer** */
        relatedCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        relatedPool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        relatedStaff?: InputMaybe<Scalars['ID']>;
        /** Checks stage type of ServiceJob Stage or WorkOrder Stage */
        relatedStageType?: InputMaybe<Array<ServiceJobStageType>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ServiceJobTestReportServiceJobsSearchWorkOrders>;
    };

    export type ServiceJobTestReportServiceJobsSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomer = {
        addedBy?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerTags>;
        user?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUser>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByTeams>;
        user?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUser>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntity = {
        addresses?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPools>;
        sites?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntitySites = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserStaff>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntity = {
        addresses?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPools>;
        sites?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntitySites = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobTestReportServiceJobsSearchCustomerUserStaffTeams>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntity = {
        addresses?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPools>;
        sites?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntitySites>;
        user?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSite>;
        type?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSite = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntitySites = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserStaff>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerTags>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedByTeams>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobTestReportServiceJobsSearchEntityUserStaffTeams>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolOwnershipHistory>;
        sanitiser?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiser>;
        site?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSite>;
        type?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSite = {
        address?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobTestReportServiceJobsSearchWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobTestReportServiceJobsSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        serviceJobNumber?: InputMaybe<Scalars['Boolean']>;
        stopsAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type ServiceJobUnArchiveMutationInput = {
        /** Expects node: **ServiceJob** */
        id: Scalars['ID'];
    };

    export type ServiceJobUpdateRecurringJobMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        duration?: InputMaybe<Scalars['Int']>;
        endsAt?: InputMaybe<Scalars['Date']>;
        fromDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **ServiceJob** */
        id: Scalars['ID'];
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobViewerServiceJobsSearch = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<ServiceJobViewerServiceJobsSearchEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        pool?: InputMaybe<ServiceJobViewerServiceJobsSearchPool>;
        /** Expects node: **Customer** */
        relatedCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        relatedPool?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        relatedStaff?: InputMaybe<Scalars['ID']>;
        /** Checks stage type of ServiceJob Stage or WorkOrder Stage */
        relatedStageType?: InputMaybe<Array<ServiceJobStageType>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<ServiceJobViewerServiceJobsSearchWorkOrders>;
    };

    export type ServiceJobViewerServiceJobsSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomer = {
        addedBy?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerTags>;
        user?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUser>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByTeams>;
        user?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUser>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntity = {
        addresses?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPools>;
        sites?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntitySites = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserStaff>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntity = {
        addresses?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPools>;
        sites?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSite>;
        type?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSite = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntitySites = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobViewerServiceJobsSearchCustomerUserStaffTeams>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntity = {
        addresses?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPools>;
        sites?: InputMaybe<ServiceJobViewerServiceJobsSearchEntitySites>;
        user?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiser>;
        site?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSite>;
        type?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSite = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type ServiceJobViewerServiceJobsSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntitySites = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserStaff>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomer = {
        addedBy?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerTags>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedByTeams>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<ServiceJobViewerServiceJobsSearchEntityUserStaffTeams>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolOwnershipHistory>;
        reports?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolReports>;
        sanitiser?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiser>;
        site?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSite>;
        type?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSite = {
        address?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type ServiceJobViewerServiceJobsSearchWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type ServiceJobViewerServiceJobsSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueDate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        serviceJobNumber?: InputMaybe<Scalars['Boolean']>;
        stopsAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type ServiceJobsForCustomerToServiceJobConnection = {
        __typename?: 'ServiceJobsForCustomerToServiceJobConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ServiceJobsForCustomerToServiceJobEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ServiceJobsForCustomerToServiceJobMeta>;
    };

    /** An edge in a connection. */
    export type ServiceJobsForCustomerToServiceJobEdge = {
        __typename?: 'ServiceJobsForCustomerToServiceJobEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJob>;
    };

    export type ServiceJobsForCustomerToServiceJobMeta = {
        __typename?: 'ServiceJobsForCustomerToServiceJobMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ServiceJobsForPoolToServiceJobConnection = {
        __typename?: 'ServiceJobsForPoolToServiceJobConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ServiceJobsForPoolToServiceJobEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ServiceJobsForPoolToServiceJobMeta>;
    };

    /** An edge in a connection. */
    export type ServiceJobsForPoolToServiceJobEdge = {
        __typename?: 'ServiceJobsForPoolToServiceJobEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJob>;
    };

    export type ServiceJobsForPoolToServiceJobMeta = {
        __typename?: 'ServiceJobsForPoolToServiceJobMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ServiceJobsForTestReportToServiceJobConnection = {
        __typename?: 'ServiceJobsForTestReportToServiceJobConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ServiceJobsForTestReportToServiceJobEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ServiceJobsForTestReportToServiceJobMeta>;
    };

    /** An edge in a connection. */
    export type ServiceJobsForTestReportToServiceJobEdge = {
        __typename?: 'ServiceJobsForTestReportToServiceJobEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJob>;
    };

    export type ServiceJobsForTestReportToServiceJobMeta = {
        __typename?: 'ServiceJobsForTestReportToServiceJobMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ServicesForServiceJobGroupToServiceJobConnection = {
        __typename?: 'ServicesForServiceJobGroupToServiceJobConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ServicesForServiceJobGroupToServiceJobEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ServicesForServiceJobGroupToServiceJobMeta>;
    };

    /** An edge in a connection. */
    export type ServicesForServiceJobGroupToServiceJobEdge = {
        __typename?: 'ServicesForServiceJobGroupToServiceJobEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJob>;
    };

    export type ServicesForServiceJobGroupToServiceJobMeta = {
        __typename?: 'ServicesForServiceJobGroupToServiceJobMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type SettingStore = Node & {
        __typename?: 'SettingStore';
        checkMutations?: Maybe<SettingStoreMutationCheck>;
        group?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        value?: Maybe<Scalars['String']>;
    };

    export type SettingStoreMutationCheck = {
        __typename?: 'SettingStoreMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SettingStoreMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSettingStoreMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSettingStoreMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSettingStoreMutationInput>>>;
    };

    export type SettingStoreMutationPayload = {
        __typename?: 'SettingStoreMutationPayload';
        SettingStore?: Maybe<SettingStore>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSettingStoreMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type SettingStoreViewerSettingStoreSearch = {
        group?: InputMaybe<StringMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SettingStoreViewerSettingStoreSort = {
        /** Expects node: **SettingStore** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        group?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
    };

    export type SharedEntity = Node & {
        __typename?: 'SharedEntity';
        createdAt?: Maybe<Scalars['Date']>;
        entity?: Maybe<Entity>;
        id: Scalars['ID'];
        originFranchise?: Maybe<Franchise>;
        sharedBy?: Maybe<Staff>;
        sharedToFranchise?: Maybe<Franchise>;
    };

    export type SharedEntityMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSharedEntityMutationInput>>>;
    };

    export type SharedEntityMutationPayload = {
        __typename?: 'SharedEntityMutationPayload';
        SharedEntity?: Maybe<SharedEntity>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSharedEntityMutationOutput>>>;
        updatedEntity?: Maybe<Entity>;
        updatedOriginFranchise?: Maybe<Franchise>;
        updatedSharedBy?: Maybe<Staff>;
        updatedSharedToFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    /** A connection to a list of items. */
    export type ShopsForFranchiseToFranchiseShopConnection = {
        __typename?: 'ShopsForFranchiseToFranchiseShopConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ShopsForFranchiseToFranchiseShopEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ShopsForFranchiseToFranchiseShopEdge = {
        __typename?: 'ShopsForFranchiseToFranchiseShopEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseShop>;
    };

    export type Site = Node & {
        __typename?: 'Site';
        accessKeyLocation?: Maybe<Scalars['String']>;
        address?: Maybe<Address>;
        checkMutations?: Maybe<SiteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        dataOwnership?: Maybe<EntityOwnershipType>;
        entity?: Maybe<Entity>;
        ezcDeviceLinks?: Maybe<EzcDeviceLinksForSiteToEZCDeviceLinkConnection>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        notes?: Maybe<NotesForSiteToSiteNoteConnection>;
        pools?: Maybe<PoolsForSiteToPoolConnection>;
        /** Can be used as primary id in all ID inputs */
        refId?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type SiteEzcDeviceLinksArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EZCDeviceLinkSiteEzcDeviceLinksSearch>;
        sort?: InputMaybe<EZCDeviceLinkSiteEzcDeviceLinksSort>;
    };

    export type SiteNotesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SiteNoteSiteNotesSearch>;
        sort?: InputMaybe<SiteNoteSiteNotesSort>;
    };

    export type SitePoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolSitePoolsSearch>;
        sort?: InputMaybe<PoolSitePoolsSort>;
    };

    export type SiteAddressSitesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SiteAddressSitesSearchEntity>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SiteAddressSitesSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<SiteAddressSitesSearchEntityPools>;
        serviceJob?: InputMaybe<SiteAddressSitesSearchEntityServiceJob>;
        user?: InputMaybe<SiteAddressSitesSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteAddressSitesSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<SiteAddressSitesSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteAddressSitesSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteAddressSitesSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SiteAddressSitesSearchEntityServiceJobWorkOrders>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerUser>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerUserStaff>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SiteAddressSitesSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SiteAddressSitesSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SiteAddressSitesSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteAddressSitesSearchEntityUserStaff>;
    };

    export type SiteAddressSitesSearchEntityUserCustomer = {
        addedBy?: InputMaybe<SiteAddressSitesSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SiteAddressSitesSearchEntityUserCustomerTags>;
    };

    export type SiteAddressSitesSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteAddressSitesSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteAddressSitesSearchEntityUserCustomerAddedByTeams>;
    };

    export type SiteAddressSitesSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteAddressSitesSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteAddressSitesSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<SiteAddressSitesSearchEntityUserStaffTeams>;
    };

    export type SiteAddressSitesSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteAddressSitesSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteAddressSitesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Site** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type SiteEntitySitesSearch = {
        address?: InputMaybe<SiteEntitySitesSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteEntitySitesSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteEntitySitesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Site** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type SiteMutationCheck = {
        __typename?: 'SiteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        transferTo?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SiteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSiteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSiteMutationInput>>>;
        transferTo?: InputMaybe<Array<InputMaybe<SiteTransferToMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSiteMutationInput>>>;
    };

    export type SiteMutationPayload = {
        __typename?: 'SiteMutationPayload';
        Site?: Maybe<Site>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSiteMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedEntity?: Maybe<Entity>;
        viewer?: Maybe<Viewer>;
    };

    export type SiteNodeMatchSearchCommon = {
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteNote = Node & {
        __typename?: 'SiteNote';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<SiteNoteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        parent?: Maybe<Site>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type SiteNoteMutationCheck = {
        __typename?: 'SiteNoteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type SiteNoteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateSiteNoteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteSiteNoteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateSiteNoteMutationInput>>>;
    };

    export type SiteNoteMutationPayload = {
        __typename?: 'SiteNoteMutationPayload';
        SiteNote?: Maybe<SiteNote>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkSiteNoteMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<Site>;
        viewer?: Maybe<Viewer>;
    };

    export type SiteNoteSiteNotesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteNoteSiteNotesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **SiteNote** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type SiteTransferToMutationInput = {
        /** Expects node: **Site** */
        id: Scalars['ID'];
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        to: Scalars['ID'];
    };

    export type SiteViewerSitesSearch = {
        address?: InputMaybe<SiteViewerSitesSearchAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<SiteViewerSitesSearchEntity>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<SiteViewerSitesSearchAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SiteViewerSitesSearchAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<SiteViewerSitesSearchAddressEntityPools>;
        serviceJob?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJob>;
        user?: InputMaybe<SiteViewerSitesSearchAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsReports>;
        sanitiser?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobWorkOrders>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerUser>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaff>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchAddressEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SiteViewerSitesSearchAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SiteViewerSitesSearchAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteViewerSitesSearchAddressEntityUserStaff>;
    };

    export type SiteViewerSitesSearchAddressEntityUserCustomer = {
        addedBy?: InputMaybe<SiteViewerSitesSearchAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<SiteViewerSitesSearchAddressEntityUserCustomerTags>;
    };

    export type SiteViewerSitesSearchAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchAddressEntityUserCustomerAddedByTeams>;
    };

    export type SiteViewerSitesSearchAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchAddressEntityUserStaffTeams>;
    };

    export type SiteViewerSitesSearchAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntity = {
        addresses?: InputMaybe<SiteViewerSitesSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<SiteViewerSitesSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<SiteViewerSitesSearchEntityPools>;
        serviceJob?: InputMaybe<SiteViewerSitesSearchEntityServiceJob>;
        user?: InputMaybe<SiteViewerSitesSearchEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteViewerSitesSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<SiteViewerSitesSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteViewerSitesSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteViewerSitesSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJob = {
        address?: InputMaybe<SiteViewerSitesSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<SiteViewerSitesSearchEntityServiceJobWorkOrders>;
    };

    export type SiteViewerSitesSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerTags>;
        user?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerUser>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerAddedByUser>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerUserStaff>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchEntityServiceJobCustomerUserStaffTeams>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<SiteNodeMatchSearchCommon>;
        type?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type SiteViewerSitesSearchEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<SiteViewerSitesSearchEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<SiteViewerSitesSearchEntityUserStaff>;
    };

    export type SiteViewerSitesSearchEntityUserCustomer = {
        addedBy?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerTags>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchEntityUserCustomerAddedByTeams>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type SiteViewerSitesSearchEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<SiteViewerSitesSearchEntityUserStaffRegistration>;
        teams?: InputMaybe<SiteViewerSitesSearchEntityUserStaffTeams>;
    };

    export type SiteViewerSitesSearchEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type SiteViewerSitesSearchEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    /** A connection to a list of items. */
    export type SitesForAddressToSiteConnection = {
        __typename?: 'SitesForAddressToSiteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SitesForAddressToSiteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SitesForAddressToSiteMeta>;
    };

    /** An edge in a connection. */
    export type SitesForAddressToSiteEdge = {
        __typename?: 'SitesForAddressToSiteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Site>;
    };

    export type SitesForAddressToSiteMeta = {
        __typename?: 'SitesForAddressToSiteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type SitesForCustomerToSiteConnection = {
        __typename?: 'SitesForCustomerToSiteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SitesForCustomerToSiteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SitesForCustomerToSiteMeta>;
    };

    /** An edge in a connection. */
    export type SitesForCustomerToSiteEdge = {
        __typename?: 'SitesForCustomerToSiteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Site>;
    };

    export type SitesForCustomerToSiteMeta = {
        __typename?: 'SitesForCustomerToSiteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type SitesForEntityToSiteConnection = {
        __typename?: 'SitesForEntityToSiteConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SitesForEntityToSiteEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<SitesForEntityToSiteMeta>;
    };

    /** An edge in a connection. */
    export type SitesForEntityToSiteEdge = {
        __typename?: 'SitesForEntityToSiteEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Site>;
    };

    export type SitesForEntityToSiteMeta = {
        __typename?: 'SitesForEntityToSiteMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type SolutionAffect = {
        __typename?: 'SolutionAffect';
        measurementType?: Maybe<Measurement>;
        value?: Maybe<Scalars['Float']>;
    };

    /** A connection to a list of items. */
    export type SolutionAffectForTestReportToSolutionAffectConnection = {
        __typename?: 'SolutionAffectForTestReportToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SolutionAffectForTestReportToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SolutionAffectForTestReportToSolutionAffectEdge = {
        __typename?: 'SolutionAffectForTestReportToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    /** A connection to a list of items. */
    export type SolutionAffectForTestTreatmentToSolutionAffectConnection = {
        __typename?: 'SolutionAffectForTestTreatmentToSolutionAffectConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SolutionAffectForTestTreatmentToSolutionAffectEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SolutionAffectForTestTreatmentToSolutionAffectEdge = {
        __typename?: 'SolutionAffectForTestTreatmentToSolutionAffectEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SolutionAffect>;
    };

    /** A connection to a list of items. */
    export type SolutionForTestReportToTestReportSolutionConnection = {
        __typename?: 'SolutionForTestReportToTestReportSolutionConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<SolutionForTestReportToTestReportSolutionEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type SolutionForTestReportToTestReportSolutionEdge = {
        __typename?: 'SolutionForTestReportToTestReportSolutionEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReportSolution>;
    };

    export type SolverPreview = {
        __typename?: 'SolverPreview';
        chemicalTargets?: Maybe<Array<Maybe<AnonymousTarget>>>;
        criteria?: Maybe<Array<Maybe<TestCriteria>>>;
        discoveredProblems?: Maybe<Array<Maybe<TestProblem>>>;
        discoveredTreatments?: Maybe<Array<Maybe<TestTreatment>>>;
        forcedTreatments?: Maybe<Array<Maybe<TestTreatment>>>;
        franchise?: Maybe<Franchise>;
        outcome?: Maybe<Array<Maybe<SolutionAffect>>>;
        recommendations?: Maybe<Array<Maybe<AnonymousRecommendation>>>;
        samples?: Maybe<Array<Maybe<AnonymousSample>>>;
        sanitiser?: Maybe<Sanitiser>;
        solutions?: Maybe<Array<Maybe<SolverPreviewSolution>>>;
        treatmentPreview?: Maybe<Scalars['Float']>;
        type?: Maybe<Pool>;
        volume?: Maybe<Scalars['Float']>;
    };

    export type SolverPreviewCriteriaArgs = {
        includeUniversalCriteria?: InputMaybe<Scalars['Boolean']>;
    };

    export type SolverPreviewDiscoveredTreatmentsArgs = {
        includeUniversalCriteria?: InputMaybe<Scalars['Boolean']>;
    };

    export type SolverPreviewTreatmentPreviewArgs = {
        treatment: Scalars['ID'];
    };

    export type SolverPreviewForcedEntryNodeInitInput = {
        amount?: InputMaybe<Scalars['Float']>;
        /** Expects node: **TestTreatment** */
        treatment: Scalars['ID'];
    };

    export type SolverPreviewSolution = {
        __typename?: 'SolverPreviewSolution';
        affects?: Maybe<Array<Maybe<SolutionAffect>>>;
        amount?: Maybe<Scalars['Float']>;
        anonymousTreatment?: Maybe<AnonymousTreatment>;
        instruction?: Maybe<Scalars['String']>;
        manual?: Maybe<Scalars['Boolean']>;
        treatment?: Maybe<TestTreatment>;
    };

    export type Staff = Node & {
        __typename?: 'Staff';
        accessTokens?: Maybe<AccessTokensForStaffToStaffAccessTokenConnection>;
        addedAppointments?: Maybe<AddedAppointmentsForStaffToAppointmentItemConnection>;
        archivedAt?: Maybe<Scalars['Date']>;
        archivedBy?: Maybe<Staff>;
        assignedAppointments?: Maybe<AssignedAppointmentsForStaffToAppointmentItemConnection>;
        assignedWorkOrders?: Maybe<AssignedWorkOrdersForStaffToWorkOrderConnection>;
        checkMutations?: Maybe<StaffMutationCheck>;
        color?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        createdWorkOrders?: Maybe<CreatedWorkOrdersForStaffToWorkOrderConnection>;
        customersAdded?: Maybe<CustomersAddedForStaffToCustomerConnection>;
        filesUploaded?: Maybe<FilesUploadedForStaffToFileUploadConnection>;
        flags?: Maybe<FlagsForStaffToRoleFlagConnection>;
        id: Scalars['ID'];
        isSystemHidden?: Maybe<Scalars['Boolean']>;
        managedBy?: Maybe<Staff>;
        poolOwnershipTransfers?: Maybe<PoolOwnershipTransfersForStaffToPoolOwnershipHistoryConnection>;
        registration?: Maybe<StaffRegistration>;
        role?: Maybe<Role>;
        root?: Maybe<Entity>;
        samplesAdded?: Maybe<SamplesAddedForStaffToSampleSetConnection>;
        teams?: Maybe<TeamsForStaffToStaffTeamConnection>;
        updatedAt?: Maybe<Scalars['Date']>;
        user?: Maybe<User>;
    };

    export type StaffAccessTokensArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffAccessTokenStaffAccessTokensSearch>;
        sort?: InputMaybe<StaffAccessTokenStaffAccessTokensSort>;
    };

    export type StaffAddedAppointmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        customer?: InputMaybe<Scalars['ID']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        pool?: InputMaybe<Scalars['ID']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        serviceJob?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type StaffAssignedAppointmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        customer?: InputMaybe<Scalars['ID']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        pool?: InputMaybe<Scalars['ID']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        serviceJob?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type StaffAssignedWorkOrdersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearch>;
        sort?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSort>;
    };

    export type StaffCreatedWorkOrdersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearch>;
        sort?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSort>;
    };

    export type StaffCustomersAddedArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomerStaffCustomersAddedSearch>;
        sort?: InputMaybe<CustomerStaffCustomersAddedSort>;
    };

    export type StaffFilesUploadedArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadStaffFilesUploadedSearch>;
        sort?: InputMaybe<FileUploadStaffFilesUploadedSort>;
    };

    export type StaffFlagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type StaffPoolOwnershipTransfersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSearch>;
        sort?: InputMaybe<PoolOwnershipHistoryStaffPoolOwnershipTransfersSort>;
    };

    export type StaffSamplesAddedArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SampleSetStaffSamplesAddedSearch>;
        sort?: InputMaybe<SampleSetStaffSamplesAddedSort>;
    };

    export type StaffTeamsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffTeamStaffTeamsSearch>;
        sort?: InputMaybe<StaffTeamStaffTeamsSort>;
    };

    export type StaffAccessToken = Node & {
        __typename?: 'StaffAccessToken';
        checkMutations?: Maybe<StaffAccessTokenMutationCheck>;
        disabledAt?: Maybe<Scalars['Date']>;
        disabledBy?: Maybe<Staff>;
        expiresAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        staff?: Maybe<Staff>;
        token?: Maybe<Scalars['String']>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearch = {
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        disabledBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        expiresAt?: InputMaybe<DateRangeSearchCommon>;
        staff?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaff>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffRegistration>;
        teams?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffTeams>;
        user?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUser>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomer>;
        entity?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerTags>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntity = {
        addresses?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityContacts>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPools>;
        serviceJob?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJob>;
        sites?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsReports>;
        sanitiser?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiser>;
        site?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSite>;
        type?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSite = {
        address?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJob = {
        address?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobWorkOrders>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerTags>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSite>;
        type?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSite = {
        address?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntitySites = {
        address?: InputMaybe<StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSearchStaffUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffAccessTokenFranchiseStaffAccessTokensSort = {
        disabledAt?: InputMaybe<Scalars['Boolean']>;
        expiresAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **StaffAccessToken** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type StaffAccessTokenMutationCheck = {
        __typename?: 'StaffAccessTokenMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type StaffAccessTokenMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteStaffAccessTokenMutationInput>>>;
    };

    export type StaffAccessTokenMutationPayload = {
        __typename?: 'StaffAccessTokenMutationPayload';
        StaffAccessToken?: Maybe<StaffAccessToken>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffAccessTokenMutationOutput>>>;
        updatedDisabledBy?: Maybe<Staff>;
        updatedStaff?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffAccessTokenRegistration = {
        __typename?: 'StaffAccessTokenRegistration';
        tokenKey?: Maybe<Scalars['String']>;
        tokenRef?: Maybe<StaffAccessToken>;
    };

    export type StaffAccessTokenRegistrationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        registerTokenForStaff?: InputMaybe<
            Array<InputMaybe<StaffAccessTokenRegistrationRegisterTokenForStaffMutationInput>>
        >;
        resolveAndRefreshManagerToken?: InputMaybe<
            Array<InputMaybe<StaffAccessTokenRegistrationResolveAndRefreshManagerTokenMutationInput>>
        >;
    };

    export type StaffAccessTokenRegistrationMutationPayload = {
        __typename?: 'StaffAccessTokenRegistrationMutationPayload';
        StaffAccessTokenRegistration?: Maybe<StaffAccessTokenRegistration>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffAccessTokenRegistrationMutationOutput>>>;
        updatedTokenRef?: Maybe<StaffAccessToken>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffAccessTokenRegistrationRegisterTokenForStaffMutationInput = {
        expiresAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff: Scalars['ID'];
    };

    export type StaffAccessTokenRegistrationResolveAndRefreshManagerTokenMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
    };

    export type StaffAccessTokenStaffAccessTokensSearch = {
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        disabledBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        expiresAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffAccessTokenStaffAccessTokensSort = {
        disabledAt?: InputMaybe<Scalars['Boolean']>;
        expiresAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **StaffAccessToken** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    /** A connection to a list of items. */
    export type StaffAccessTokensForFranchiseToStaffAccessTokenConnection = {
        __typename?: 'StaffAccessTokensForFranchiseToStaffAccessTokenConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StaffAccessTokensForFranchiseToStaffAccessTokenEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<StaffAccessTokensForFranchiseToStaffAccessTokenMeta>;
    };

    /** An edge in a connection. */
    export type StaffAccessTokensForFranchiseToStaffAccessTokenEdge = {
        __typename?: 'StaffAccessTokensForFranchiseToStaffAccessTokenEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<StaffAccessToken>;
    };

    export type StaffAccessTokensForFranchiseToStaffAccessTokenMeta = {
        __typename?: 'StaffAccessTokensForFranchiseToStaffAccessTokenMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type StaffArchiveMutationInput = {
        deleteRegistration?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Staff** */
        id: Scalars['ID'];
    };

    /** A connection to a list of items. */
    export type StaffForFranchiseToStaffConnection = {
        __typename?: 'StaffForFranchiseToStaffConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StaffForFranchiseToStaffEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type StaffForFranchiseToStaffEdge = {
        __typename?: 'StaffForFranchiseToStaffEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Staff>;
    };

    /** A connection to a list of items. */
    export type StaffForStaffTeamToStaffConnection = {
        __typename?: 'StaffForStaffTeamToStaffConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StaffForStaffTeamToStaffEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<StaffForStaffTeamToStaffMeta>;
    };

    /** An edge in a connection. */
    export type StaffForStaffTeamToStaffEdge = {
        __typename?: 'StaffForStaffTeamToStaffEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Staff>;
    };

    export type StaffForStaffTeamToStaffMeta = {
        __typename?: 'StaffForStaffTeamToStaffMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type StaffMutationCheck = {
        __typename?: 'StaffMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        unarchive?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type StaffMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<StaffArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateStaffMutationInput>>>;
        unarchive?: InputMaybe<Array<InputMaybe<StaffUnarchiveMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffMutationInput>>>;
    };

    export type StaffMutationPayload = {
        __typename?: 'StaffMutationPayload';
        Staff?: Maybe<Staff>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffMutationOutput>>>;
        updatedArchivedBy?: Maybe<Staff>;
        updatedManagedBy?: Maybe<Staff>;
        updatedRole?: Maybe<Role>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffNodeMatchSearchCommon = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffRegistration = Node & {
        __typename?: 'StaffRegistration';
        checkMutations?: Maybe<StaffRegistrationMutationCheck>;
        disabledAt?: Maybe<Scalars['Date']>;
        disabledBy?: Maybe<Staff>;
        email?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        root?: Maybe<Entity>;
        staff?: Maybe<Staff>;
    };

    export type StaffRegistrationDisableMutationInput = {
        /** Expects node: **StaffRegistration** */
        id: Scalars['ID'];
    };

    export type StaffRegistrationEnableMutationInput = {
        /** Expects node: **StaffRegistration** */
        id: Scalars['ID'];
    };

    export type StaffRegistrationForcePasswordMutationInput = {
        /** Expects node: **StaffRegistration** */
        id: Scalars['ID'];
        password?: InputMaybe<Scalars['String']>;
    };

    export type StaffRegistrationInvalidatePasswordMutationInput = {
        /** Expects node: **StaffRegistration** */
        id: Scalars['ID'];
    };

    export type StaffRegistrationMutationCheck = {
        __typename?: 'StaffRegistrationMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        disable?: Maybe<Scalars['Boolean']>;
        enable?: Maybe<Scalars['Boolean']>;
        forcePassword?: Maybe<Scalars['Boolean']>;
        invalidatePassword?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type StaffRegistrationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateStaffRegistrationMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteStaffRegistrationMutationInput>>>;
        disable?: InputMaybe<Array<InputMaybe<StaffRegistrationDisableMutationInput>>>;
        enable?: InputMaybe<Array<InputMaybe<StaffRegistrationEnableMutationInput>>>;
        forcePassword?: InputMaybe<Array<InputMaybe<StaffRegistrationForcePasswordMutationInput>>>;
        invalidatePassword?: InputMaybe<Array<InputMaybe<StaffRegistrationInvalidatePasswordMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationMutationInput>>>;
    };

    export type StaffRegistrationMutationPayload = {
        __typename?: 'StaffRegistrationMutationPayload';
        StaffRegistration?: Maybe<StaffRegistration>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffRegistrationMutationOutput>>>;
        updatedDisabledBy?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffStaffTeamStaffSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        registration?: InputMaybe<StaffStaffTeamStaffSearchRegistration>;
        user?: InputMaybe<StaffStaffTeamStaffSearchUser>;
    };

    export type StaffStaffTeamStaffSearchRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<StaffStaffTeamStaffSearchUserCustomer>;
        entity?: InputMaybe<StaffStaffTeamStaffSearchUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffStaffTeamStaffSearchUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffStaffTeamStaffSearchUserCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffStaffTeamStaffSearchUserCustomerTags>;
    };

    export type StaffStaffTeamStaffSearchUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntity = {
        addresses?: InputMaybe<StaffStaffTeamStaffSearchUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<StaffStaffTeamStaffSearchUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPools>;
        serviceJob?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJob>;
        sites?: InputMaybe<StaffStaffTeamStaffSearchUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsReports>;
        sanitiser?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiser>;
        site?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSite>;
        type?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSite = {
        address?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJob = {
        address?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobWorkOrders>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobCustomerTags>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSite>;
        type?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSite = {
        address?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type StaffStaffTeamStaffSearchUserEntitySites = {
        address?: InputMaybe<StaffStaffTeamStaffSearchUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffStaffTeamStaffSearchUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffStaffTeamStaffSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        firstName?: InputMaybe<Scalars['Boolean']>;
        lastName?: InputMaybe<Scalars['Boolean']>;
    };

    export type StaffTeam = Node & {
        __typename?: 'StaffTeam';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<StaffTeamMutationCheck>;
        color?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        staff?: Maybe<StaffForStaffTeamToStaffConnection>;
        tag?: Maybe<Scalars['String']>;
        title?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type StaffTeamStaffArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffStaffTeamStaffSearch>;
        sort?: InputMaybe<StaffStaffTeamStaffSort>;
    };

    export type StaffTeamAddStaffMutationInput = {
        /** Expects node: **StaffTeam** */
        id: Scalars['ID'];
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff: Scalars['ID'];
    };

    export type StaffTeamLink = Node & {
        __typename?: 'StaffTeamLink';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<StaffTeamLinkMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        staff?: Maybe<Staff>;
        team?: Maybe<StaffTeamLink>;
    };

    export type StaffTeamLinkMutationCheck = {
        __typename?: 'StaffTeamLinkMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type StaffTeamLinkMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteStaffTeamLinkMutationInput>>>;
    };

    export type StaffTeamLinkMutationPayload = {
        __typename?: 'StaffTeamLinkMutationPayload';
        StaffTeamLink?: Maybe<StaffTeamLink>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffTeamLinkMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedStaff?: Maybe<Staff>;
        updatedTeam?: Maybe<StaffTeamLink>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffTeamMutationCheck = {
        __typename?: 'StaffTeamMutationCheck';
        addStaff?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type StaffTeamMutationInput = {
        addStaff?: InputMaybe<Array<InputMaybe<StaffTeamAddStaffMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateStaffTeamMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteStaffTeamMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffTeamMutationInput>>>;
    };

    export type StaffTeamMutationPayload = {
        __typename?: 'StaffTeamMutationPayload';
        StaffTeam?: Maybe<StaffTeam>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffTeamMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffTeamStaffTeamsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffTeamStaffTeamsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **StaffTeam** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        title?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type StaffTeamViewerStaffTeamsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffTeamViewerStaffTeamsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **StaffTeam** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        title?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type StaffUnarchiveMutationInput = {
        /** Expects node: **Staff** */
        id: Scalars['ID'];
    };

    export type StaffValueStore = Node & {
        __typename?: 'StaffValueStore';
        checkMutations?: Maybe<StaffValueStoreMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        group?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        key?: Maybe<Scalars['String']>;
        staff?: Maybe<Staff>;
        updatedAt?: Maybe<Scalars['Date']>;
        value?: Maybe<Scalars['String']>;
    };

    export type StaffValueStoreMutationCheck = {
        __typename?: 'StaffValueStoreMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type StaffValueStoreMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteStaffValueStoreMutationInput>>>;
        set?: InputMaybe<Array<InputMaybe<StaffValueStoreSetMutationInput>>>;
        setOwn?: InputMaybe<Array<InputMaybe<StaffValueStoreSetOwnMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffValueStoreMutationInput>>>;
    };

    export type StaffValueStoreMutationPayload = {
        __typename?: 'StaffValueStoreMutationPayload';
        StaffValueStore?: Maybe<StaffValueStore>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkStaffValueStoreMutationOutput>>>;
        updatedStaff?: Maybe<Staff>;
        viewer?: Maybe<Viewer>;
    };

    export type StaffValueStoreSetMutationInput = {
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type StaffValueStoreSetOwnMutationInput = {
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type StaffValueStoreViewerStaffValuesStoreSearch = {
        group?: InputMaybe<StringMatchSearchCommon>;
        key?: InputMaybe<StringMatchSearchCommon>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
    };

    export type StaffValueStoreViewerStaffValuesStoreSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **StaffValueStore** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        key?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type StaffViewerStaffSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        registration?: InputMaybe<StaffViewerStaffSearchRegistration>;
        teams?: InputMaybe<StaffViewerStaffSearchTeams>;
        user?: InputMaybe<StaffViewerStaffSearchUser>;
    };

    export type StaffViewerStaffSearchRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<StaffViewerStaffSearchUserCustomer>;
        entity?: InputMaybe<StaffViewerStaffSearchUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffViewerStaffSearchUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffViewerStaffSearchUserCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffViewerStaffSearchUserCustomerTags>;
    };

    export type StaffViewerStaffSearchUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntity = {
        addresses?: InputMaybe<StaffViewerStaffSearchUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<StaffViewerStaffSearchUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<StaffViewerStaffSearchUserEntityPools>;
        serviceJob?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJob>;
        sites?: InputMaybe<StaffViewerStaffSearchUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsReports>;
        sanitiser?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiser>;
        site?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSite>;
        type?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSite = {
        address?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJob = {
        address?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobWorkOrders>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobCustomerTags>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSite>;
        type?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSite = {
        address?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type StaffViewerStaffSearchUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type StaffViewerStaffSearchUserEntitySites = {
        address?: InputMaybe<StaffViewerStaffSearchUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type StaffViewerStaffSearchUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type StaffViewerStaffSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        firstName?: InputMaybe<Scalars['Boolean']>;
        lastName?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type StageChangesForServiceJobToServiceJobStageHistoryConnection = {
        __typename?: 'StageChangesForServiceJobToServiceJobStageHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StageChangesForServiceJobToServiceJobStageHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<StageChangesForServiceJobToServiceJobStageHistoryMeta>;
    };

    /** An edge in a connection. */
    export type StageChangesForServiceJobToServiceJobStageHistoryEdge = {
        __typename?: 'StageChangesForServiceJobToServiceJobStageHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobStageHistory>;
    };

    export type StageChangesForServiceJobToServiceJobStageHistoryMeta = {
        __typename?: 'StageChangesForServiceJobToServiceJobStageHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type StageChangesForWorkOrderToWorkOrderStageHistoryConnection = {
        __typename?: 'StageChangesForWorkOrderToWorkOrderStageHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StageChangesForWorkOrderToWorkOrderStageHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<StageChangesForWorkOrderToWorkOrderStageHistoryMeta>;
    };

    /** An edge in a connection. */
    export type StageChangesForWorkOrderToWorkOrderStageHistoryEdge = {
        __typename?: 'StageChangesForWorkOrderToWorkOrderStageHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderStageHistory>;
    };

    export type StageChangesForWorkOrderToWorkOrderStageHistoryMeta = {
        __typename?: 'StageChangesForWorkOrderToWorkOrderStageHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type StageHistoryForServiceJobToServiceJobStageDurationConnection = {
        __typename?: 'StageHistoryForServiceJobToServiceJobStageDurationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StageHistoryForServiceJobToServiceJobStageDurationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type StageHistoryForServiceJobToServiceJobStageDurationEdge = {
        __typename?: 'StageHistoryForServiceJobToServiceJobStageDurationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobStageDuration>;
    };

    /** A connection to a list of items. */
    export type StageHistoryForWorkOrderToWorkOrderStageDurationConnection = {
        __typename?: 'StageHistoryForWorkOrderToWorkOrderStageDurationConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StageHistoryForWorkOrderToWorkOrderStageDurationEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type StageHistoryForWorkOrderToWorkOrderStageDurationEdge = {
        __typename?: 'StageHistoryForWorkOrderToWorkOrderStageDurationEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderStageDuration>;
    };

    export enum StatsGroupByEnum {
        Franchise = 'Franchise',
        Staff = 'Staff',
    }

    /** A connection to a list of items. */
    export type StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryConnection = {
        __typename?: 'StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryMeta>;
    };

    /** An edge in a connection. */
    export type StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryEdge = {
        __typename?: 'StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaimStatusHistory>;
    };

    export type StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryMeta = {
        __typename?: 'StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type StringLikeOrMatchSearchCommon = {
        is?: InputMaybe<Scalars['String']>;
        like?: InputMaybe<Scalars['String']>;
    };

    export type StringMatchSearchCommon = {
        is?: InputMaybe<Scalars['String']>;
        not?: InputMaybe<Scalars['String']>;
    };

    /** A connection to a list of items. */
    export type TagsForCustomerToCustomerTagConnection = {
        __typename?: 'TagsForCustomerToCustomerTagConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TagsForCustomerToCustomerTagEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TagsForCustomerToCustomerTagMeta>;
    };

    /** An edge in a connection. */
    export type TagsForCustomerToCustomerTagEdge = {
        __typename?: 'TagsForCustomerToCustomerTagEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<CustomerTag>;
    };

    export type TagsForCustomerToCustomerTagMeta = {
        __typename?: 'TagsForCustomerToCustomerTagMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type TeamsForStaffToStaffTeamConnection = {
        __typename?: 'TeamsForStaffToStaffTeamConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TeamsForStaffToStaffTeamEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TeamsForStaffToStaffTeamMeta>;
    };

    /** An edge in a connection. */
    export type TeamsForStaffToStaffTeamEdge = {
        __typename?: 'TeamsForStaffToStaffTeamEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<StaffTeam>;
    };

    export type TeamsForStaffToStaffTeamMeta = {
        __typename?: 'TeamsForStaffToStaffTeamMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type TemplatesForSampleConverterToSampleConverterTemplateConnection = {
        __typename?: 'TemplatesForSampleConverterToSampleConverterTemplateConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TemplatesForSampleConverterToSampleConverterTemplateEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type TemplatesForSampleConverterToSampleConverterTemplateEdge = {
        __typename?: 'TemplatesForSampleConverterToSampleConverterTemplateEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleConverterTemplate>;
    };

    /** A connection to a list of items. */
    export type TemplatesForServiceJobGroupToServiceJobTemplateConnection = {
        __typename?: 'TemplatesForServiceJobGroupToServiceJobTemplateConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TemplatesForServiceJobGroupToServiceJobTemplateEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TemplatesForServiceJobGroupToServiceJobTemplateMeta>;
    };

    /** An edge in a connection. */
    export type TemplatesForServiceJobGroupToServiceJobTemplateEdge = {
        __typename?: 'TemplatesForServiceJobGroupToServiceJobTemplateEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobTemplate>;
    };

    export type TemplatesForServiceJobGroupToServiceJobTemplateMeta = {
        __typename?: 'TemplatesForServiceJobGroupToServiceJobTemplateMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type TestCriteria = Node & {
        __typename?: 'TestCriteria';
        actualHigherThreshold?: Maybe<Scalars['Float']>;
        actualLowerThreshold?: Maybe<Scalars['Float']>;
        actualTarget?: Maybe<Scalars['Float']>;
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<TestCriteriaMutationCheck>;
        countReportsAffected?: Maybe<Scalars['Int']>;
        description?: Maybe<Scalars['String']>;
        higherThreshold?: Maybe<Scalars['Float']>;
        id: Scalars['ID'];
        isUniversal?: Maybe<Scalars['Boolean']>;
        lowerThreshold?: Maybe<Scalars['Float']>;
        measurementType?: Maybe<Measurement>;
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        poolTypes?: Maybe<PoolTypesForTestCriteriaToPoolTypeConnection>;
        sanitisers?: Maybe<SanitisersForTestCriteriaToSanitiserConnection>;
        targetValue?: Maybe<Scalars['Float']>;
        toHighValueProblem?: Maybe<TestProblem>;
        toLowValueProblem?: Maybe<TestProblem>;
        universal?: Maybe<UniversalCriteria>;
    };

    export type TestCriteriaPoolTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolTypeTestCriteriaPoolTypesSearch>;
        sort?: InputMaybe<PoolTypeTestCriteriaPoolTypesSort>;
    };

    export type TestCriteriaSanitisersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SanitiserTestCriteriaSanitisersSearch>;
        sort?: InputMaybe<SanitiserTestCriteriaSanitisersSort>;
    };

    export type TestCriteriaMutationCheck = {
        __typename?: 'TestCriteriaMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestCriteriaMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestCriteriaMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestCriteriaMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestCriteriaMutationInput>>>;
    };

    export type TestCriteriaMutationPayload = {
        __typename?: 'TestCriteriaMutationPayload';
        TestCriteria?: Maybe<TestCriteria>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestCriteriaMutationOutput>>>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedToHighValueProblem?: Maybe<TestProblem>;
        updatedToLowValueProblem?: Maybe<TestProblem>;
        viewer?: Maybe<Viewer>;
    };

    export type TestCriteriaNodeMatchSearchCommon = {
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearch = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestCriteriaViewerTestCriteriaSearchMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypes>;
        sanitisers?: InputMaybe<TestCriteriaViewerTestCriteriaSearchSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblem>;
    };

    export type TestCriteriaViewerTestCriteriaSearchMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        pools?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPools>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsOwnershipHistory>;
        reports?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReports>;
        sanitiser?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiser>;
        site?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSite>;
        type?: InputMaybe<PoolTypeNodeMatchSearchCommon>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntity = {
        addresses?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJob>;
        sites?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntitySites>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJob = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobWorkOrders>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntitySites = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerTags>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedByTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportTo>;
        sampleSet?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportTo = {
        addresses?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJob>;
        sites?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToSites>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJob = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobWorkOrders>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerTags>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToSites = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerTags>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedByTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarations>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSite = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJob>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffRegistration =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            disabledAt?: InputMaybe<DateRangeSearchCommon>;
            email?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **StaffRegistration** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **StaffRegistration** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerTags>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntity = {
        addresses?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJob>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJob = {
        address?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobWorkOrders>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaff>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomer = {
        addedBy?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerTags>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedByTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaffTeams>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchPoolTypesPoolsSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarations>;
    };

    export type TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatments = {
        affects?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSearchToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestCriteriaViewerTestCriteriaSort = {
        actualHigherThreshold?: InputMaybe<Scalars['Boolean']>;
        actualLowerThreshold?: InputMaybe<Scalars['Boolean']>;
        actualTarget?: InputMaybe<Scalars['Boolean']>;
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestCriteria** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        higherThreshold?: InputMaybe<Scalars['Boolean']>;
        lowerThreshold?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['Boolean']>;
        targetValue?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestProblem = Node & {
        __typename?: 'TestProblem';
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<TestProblemMutationCheck>;
        countReportsAffected?: Maybe<Scalars['Int']>;
        createdAt?: Maybe<Scalars['Date']>;
        criteria?: Maybe<CriteriaForTestProblemToTestCriteriaConnection>;
        description?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        treatments?: Maybe<TreatmentsForTestProblemToTestTreatmentConnection>;
        treatmentsForFranchise?: Maybe<TreatmentsForFranchiseForTestProblemToTestTreatmentConnection>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type TestProblemCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestProblemTreatmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestProblemTreatmentsForFranchiseArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise: Scalars['ID'];
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestProblemMutationCheck = {
        __typename?: 'TestProblemMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestProblemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestProblemMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestProblemMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestProblemMutationInput>>>;
    };

    export type TestProblemMutationPayload = {
        __typename?: 'TestProblemMutationPayload';
        TestProblem?: Maybe<TestProblem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestProblemMutationOutput>>>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type TestProblemTestTreatmentRelatedProblemsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestProblemTestTreatmentRelatedProblemsSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestProblem** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestProblemViewerTestProblemsSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestProblemViewerTestProblemsSearchTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSearchTreatments = {
        affects?: InputMaybe<TestProblemViewerTestProblemsSearchTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestProblemViewerTestProblemsSearchTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestProblemViewerTestProblemsSearchTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestProblemViewerTestProblemsSearchTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSearchTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSearchTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSearchTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSearchTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestProblemViewerTestProblemsSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestProblem** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestRecommendation = Node & {
        __typename?: 'TestRecommendation';
        checkMutations?: Maybe<TestRecommendationMutationCheck>;
        id: Scalars['ID'];
        problem?: Maybe<TestProblem>;
        recommendedTreatments?: Maybe<RecommendedTreatmentsForTestRecommendationToRecommendedTreatmentConnection>;
        report?: Maybe<TestReport>;
        selectedTreatment?: Maybe<RecommendedTreatment>;
    };

    export type TestRecommendationRecommendedTreatmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestRecommendationMutationCheck = {
        __typename?: 'TestRecommendationMutationCheck';
        selectTreatment?: Maybe<Scalars['Boolean']>;
    };

    export type TestRecommendationMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        selectTreatment?: InputMaybe<Array<InputMaybe<TestRecommendationSelectTreatmentMutationInput>>>;
    };

    export type TestRecommendationMutationPayload = {
        __typename?: 'TestRecommendationMutationPayload';
        TestRecommendation?: Maybe<TestRecommendation>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestRecommendationMutationOutput>>>;
        updatedProblem?: Maybe<TestProblem>;
        updatedReport?: Maybe<TestReport>;
        viewer?: Maybe<Viewer>;
    };

    export type TestRecommendationSelectTreatmentMutationInput = {
        /** Expects node: **TestRecommendation** */
        id: Scalars['ID'];
        /** Expects node: **TestTreatment** */
        treatmentId?: InputMaybe<Scalars['ID']>;
    };

    export type TestReport = Node & {
        __typename?: 'TestReport';
        assignedTreatments?: Maybe<AssignedTreatmentsForTestReportToTestTreatmentConnection>;
        attachments?: Maybe<AttachmentsForTestReportToTestReportAttachmentConnection>;
        checkMutations?: Maybe<TestReportMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        dataOwnership?: Maybe<EntityOwnershipType>;
        discoveredProblems?: Maybe<DiscoveredProblemsForTestReportToTestProblemConnection>;
        /** @deprecated Document Version is no longer available as API */
        docVersion?: Maybe<Scalars['Int']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        isArchived?: Maybe<Scalars['Boolean']>;
        isFinalised?: Maybe<Scalars['Boolean']>;
        manualProblems?: Maybe<ManualProblemsForTestReportToManualProblemConnection>;
        manualSolution?: Maybe<ManualSolutionForTestReportToTestReportSolutionConnection>;
        note?: Maybe<Scalars['String']>;
        outcome?: Maybe<OutcomeForTestReportToSolutionAffectConnection>;
        pdfUrl?: Maybe<Scalars['String']>;
        pendingProblems?: Maybe<PendingProblemsForTestReportToTestProblemConnection>;
        pool?: Maybe<Pool>;
        poolRevision?: Maybe<Scalars['Int']>;
        previewPdfUrl?: Maybe<Scalars['String']>;
        previousReport?: Maybe<TestReport>;
        recommendations?: Maybe<RecommendationsForTestReportToTestRecommendationConnection>;
        refId?: Maybe<Scalars['String']>;
        relatedEmails?: Maybe<RelatedEmailsForTestReportToEmailMessageConnection>;
        reportBy?: Maybe<Staff>;
        reportTo?: Maybe<Entity>;
        sampleSet?: Maybe<SampleSet>;
        serviceJobs?: Maybe<ServiceJobsForTestReportToServiceJobConnection>;
        solution?: Maybe<SolutionForTestReportToTestReportSolutionConnection>;
        solutionAffect?: Maybe<SolutionAffectForTestReportToSolutionAffectConnection>;
        solutionForTreatment?: Maybe<TreatmentResultType>;
        submissionMetadata?: Maybe<TestReportSubmission>;
        testingRevision?: Maybe<Scalars['Int']>;
        testingVersion?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type TestReportAssignedTreatmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportAttachmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestReportAttachmentTestReportAttachmentsSearch>;
        sort?: InputMaybe<TestReportAttachmentTestReportAttachmentsSort>;
    };

    export type TestReportDiscoveredProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportManualProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportManualSolutionArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportOutcomeArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportPendingProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportRecommendationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportRelatedEmailsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailMessageTestReportRelatedEmailsSearch>;
        sort?: InputMaybe<EmailMessageTestReportRelatedEmailsSort>;
    };

    export type TestReportServiceJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobTestReportServiceJobsSearch>;
        sort?: InputMaybe<ServiceJobTestReportServiceJobsSort>;
    };

    export type TestReportSolutionArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportSolutionAffectArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportSolutionForTreatmentArgs = {
        treatmentId: Scalars['ID'];
    };

    export type TestReportArchiveReportMutationInput = {
        /** Expects node: **TestReport** */
        id: Scalars['ID'];
    };

    export type TestReportAttachment = Node & {
        __typename?: 'TestReportAttachment';
        checkMutations?: Maybe<TestReportAttachmentMutationCheck>;
        comment?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        file?: Maybe<FileUpload>;
        hidden?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        smallView?: Maybe<Scalars['Boolean']>;
        testReport?: Maybe<TestReport>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type TestReportAttachmentMutationCheck = {
        __typename?: 'TestReportAttachmentMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestReportAttachmentMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestReportAttachmentMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestReportAttachmentMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestReportAttachmentMutationInput>>>;
    };

    export type TestReportAttachmentMutationPayload = {
        __typename?: 'TestReportAttachmentMutationPayload';
        TestReportAttachment?: Maybe<TestReportAttachment>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestReportAttachmentMutationOutput>>>;
        updatedFile?: Maybe<FileUpload>;
        updatedTestReport?: Maybe<TestReport>;
        viewer?: Maybe<Viewer>;
    };

    export type TestReportAttachmentTestReportAttachmentsSearch = {
        file?: InputMaybe<FileUploadNodeMatchSearchCommon>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        smallView?: InputMaybe<BooleanMatchSearchCommon>;
    };

    export type TestReportAttachmentTestReportAttachmentsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReportAttachment** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        smallView?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestReportFinaliseReportMutationInput = {
        /** Expects node: **TestReport** */
        id: Scalars['ID'];
    };

    export type TestReportMutationCheck = {
        __typename?: 'TestReportMutationCheck';
        archiveReport?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        finaliseReport?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestReportMutationInput = {
        archiveReport?: InputMaybe<Array<InputMaybe<TestReportArchiveReportMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestReportMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestReportMutationInput>>>;
        finaliseReport?: InputMaybe<Array<InputMaybe<TestReportFinaliseReportMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestReportMutationInput>>>;
    };

    export type TestReportMutationPayload = {
        __typename?: 'TestReportMutationPayload';
        TestReport?: Maybe<TestReport>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestReportMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedPool?: Maybe<Pool>;
        updatedReportBy?: Maybe<Staff>;
        updatedReportTo?: Maybe<Entity>;
        updatedSampleSet?: Maybe<SampleSet>;
        updatedWorkOrder?: Maybe<WorkOrder>;
        viewer?: Maybe<Viewer>;
    };

    export type TestReportNodeMatchSearchCommon = {
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<TestReportPoolReportsSearchReportTo>;
        sampleSet?: InputMaybe<TestReportPoolReportsSearchSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportTo = {
        addresses?: InputMaybe<TestReportPoolReportsSearchReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportPoolReportsSearchReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportPoolReportsSearchReportToServiceJob>;
        sites?: InputMaybe<TestReportPoolReportsSearchReportToSites>;
        user?: InputMaybe<TestReportPoolReportsSearchReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJob = {
        address?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobWorkOrders>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerTags>;
        user?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerUser>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerAddedByUser>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerUserStaff>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportPoolReportsSearchReportToServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestReportPoolReportsSearchReportToSites = {
        address?: InputMaybe<TestReportPoolReportsSearchReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportPoolReportsSearchReportToUserStaff>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomer = {
        addedBy?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerTags>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportPoolReportsSearchReportToUserCustomerAddedByTeams>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportPoolReportsSearchReportToUserStaffRegistration>;
        teams?: InputMaybe<TestReportPoolReportsSearchReportToUserStaffTeams>;
    };

    export type TestReportPoolReportsSearchReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSearchReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportPoolReportsSearchSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportPoolReportsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReport** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestReportSolution = Node & {
        __typename?: 'TestReportSolution';
        affects?: Maybe<AffectsForTestReportSolutionToSolutionAffectConnection>;
        amount?: Maybe<Scalars['Float']>;
        checkMutations?: Maybe<TestReportSolutionMutationCheck>;
        comment?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        instruction?: Maybe<Scalars['String']>;
        instructionOverride?: Maybe<Scalars['String']>;
        manual?: Maybe<Scalars['Boolean']>;
        report?: Maybe<TestReport>;
        treatment?: Maybe<TestTreatment>;
    };

    export type TestReportSolutionAffectsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestReportSolutionMutationCheck = {
        __typename?: 'TestReportSolutionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        override?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestReportSolutionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestReportSolutionMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestReportSolutionMutationInput>>>;
        override?: InputMaybe<Array<InputMaybe<TestReportSolutionOverrideMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestReportSolutionMutationInput>>>;
    };

    export type TestReportSolutionMutationPayload = {
        __typename?: 'TestReportSolutionMutationPayload';
        TestReportSolution?: Maybe<TestReportSolution>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestReportSolutionMutationOutput>>>;
        updatedReport?: Maybe<TestReport>;
        updatedTreatment?: Maybe<TestTreatment>;
        viewer?: Maybe<Viewer>;
    };

    export type TestReportSolutionOverrideMutationInput = {
        amount?: InputMaybe<Scalars['Float']>;
        comment?: InputMaybe<Scalars['String']>;
        /** Expects node: **TestReportSolution** */
        id: Scalars['ID'];
        instruction?: InputMaybe<Scalars['String']>;
    };

    export type TestReportSubmission = {
        __typename?: 'TestReportSubmission';
        deviceVersion?: Maybe<Scalars['String']>;
        method?: Maybe<Scalars['String']>;
        methodVersion?: Maybe<Scalars['String']>;
        reagentsContext?: Maybe<Array<Maybe<Scalars['String']>>>;
        submittedAt?: Maybe<Scalars['Date']>;
        toolContext?: Maybe<Scalars['String']>;
        typeContext?: Maybe<Scalars['String']>;
    };

    export type TestReportSubmissionNodeInitInput = {
        deviceVersion?: InputMaybe<Scalars['String']>;
        method?: InputMaybe<Scalars['String']>;
        methodVersion?: InputMaybe<Scalars['String']>;
        reagentsContext?: InputMaybe<Scalars['String']>;
        submittedAt?: InputMaybe<Scalars['Date']>;
        toolContext?: InputMaybe<Scalars['String']>;
        typeContext?: InputMaybe<Scalars['String']>;
    };

    export type TestReportSubmissionNodeUpdateInput = {
        deviceVersion?: InputMaybe<Scalars['String']>;
        method?: InputMaybe<Scalars['String']>;
        methodVersion?: InputMaybe<Scalars['String']>;
        reagentsContext?: InputMaybe<Scalars['String']>;
        submittedAt?: InputMaybe<Scalars['Date']>;
        toolContext?: InputMaybe<Scalars['String']>;
        typeContext?: InputMaybe<Scalars['String']>;
    };

    export type TestReportViewerReportsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        pool?: InputMaybe<TestReportViewerReportsSearchPool>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<TestReportViewerReportsSearchReportTo>;
        sampleSet?: InputMaybe<TestReportViewerReportsSearchSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestReportViewerReportsSearchPoolEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportViewerReportsSearchPoolOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportViewerReportsSearchPoolSanitiser>;
        site?: InputMaybe<TestReportViewerReportsSearchPoolSite>;
        type?: InputMaybe<TestReportViewerReportsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntity = {
        addresses?: InputMaybe<TestReportViewerReportsSearchPoolEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportViewerReportsSearchPoolEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJob>;
        sites?: InputMaybe<TestReportViewerReportsSearchPoolEntitySites>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJob = {
        address?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobWorkOrders>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerUser>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestReportViewerReportsSearchPoolEntitySites = {
        address?: InputMaybe<TestReportViewerReportsSearchPoolEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerTags>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserCustomerAddedByTeams>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolEntityUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSite = {
        address?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJob>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobWorkOrders>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUser>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerTags>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteAddressEntityUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntity = {
        addresses?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJob>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJob = {
        address?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobWorkOrders>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUser>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserStaff>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerTags>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedByTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchPoolSiteEntityUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportTo = {
        addresses?: InputMaybe<TestReportViewerReportsSearchReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportViewerReportsSearchReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<TestReportViewerReportsSearchReportToPools>;
        serviceJob?: InputMaybe<TestReportViewerReportsSearchReportToServiceJob>;
        sites?: InputMaybe<TestReportViewerReportsSearchReportToSites>;
        user?: InputMaybe<TestReportViewerReportsSearchReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportViewerReportsSearchReportToPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiser>;
        site?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSite>;
        type?: InputMaybe<TestReportViewerReportsSearchReportToPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSite = {
        address?: InputMaybe<TestReportViewerReportsSearchReportToPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJob = {
        address?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobWorkOrders>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerTags>;
        user?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerUser>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerAddedByUser>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerUserStaff>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiser>;
        site?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSite>;
        type?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSite = {
        address?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportViewerReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type TestReportViewerReportsSearchReportToSites = {
        address?: InputMaybe<TestReportViewerReportsSearchReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportViewerReportsSearchReportToUserStaff>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomer = {
        addedBy?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerTags>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchReportToUserCustomerAddedByTeams>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportViewerReportsSearchReportToUserStaffRegistration>;
        teams?: InputMaybe<TestReportViewerReportsSearchReportToUserStaffTeams>;
    };

    export type TestReportViewerReportsSearchReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportViewerReportsSearchReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportViewerReportsSearchSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type TestReportViewerReportsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReport** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestReportWorkOrderTestReportsSearch = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        pool?: InputMaybe<TestReportWorkOrderTestReportsSearchPool>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<TestReportWorkOrderTestReportsSearchReportTo>;
        sampleSet?: InputMaybe<TestReportWorkOrderTestReportsSearchSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntity>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiser>;
        site?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSite>;
        type?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntity = {
        addresses?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJob>;
        sites?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntitySites>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJob = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntitySites = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerTags>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedByTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolEntityUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSite = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntity>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntity>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntity = {
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJob>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJob = {
        address?: InputMaybe<AddressNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<AddressNodeMatchSearchCommon>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerTags>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedByTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteAddressEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntity = {
        addresses?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        serviceJob?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJob>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJob = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerTags>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByUser>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerTags>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedByTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolSiteEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportTo = {
        addresses?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPools>;
        serviceJob?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJob>;
        sites?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToSites>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiser>;
        site?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSite>;
        type?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSite = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJob = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerTags>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUser>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByUser>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolOwnershipHistory>;
        sanitiser?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiser>;
        site?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSite>;
        type?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSite = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type TestReportWorkOrderTestReportsSearchReportToSites = {
        address?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToSitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToSitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserStaff>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomer = {
        addedBy?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerPrimaryAddress>;
        tags?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerTags>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedByRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedByTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserStaffRegistration>;
        teams?: InputMaybe<TestReportWorkOrderTestReportsSearchReportToUserStaffTeams>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestReportWorkOrderTestReportsSearchReportToUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSearchSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type TestReportWorkOrderTestReportsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReport** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type TestReportsForWorkOrderToTestReportConnection = {
        __typename?: 'TestReportsForWorkOrderToTestReportConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TestReportsForWorkOrderToTestReportEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TestReportsForWorkOrderToTestReportMeta>;
    };

    /** An edge in a connection. */
    export type TestReportsForWorkOrderToTestReportEdge = {
        __typename?: 'TestReportsForWorkOrderToTestReportEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReport>;
    };

    export type TestReportsForWorkOrderToTestReportMeta = {
        __typename?: 'TestReportsForWorkOrderToTestReportMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type TestTreatment = Node & {
        __typename?: 'TestTreatment';
        affects?: Maybe<AffectsForTestTreatmentToTreatmentAffectConnection>;
        archivedAt?: Maybe<Scalars['Date']>;
        checkMutations?: Maybe<TestTreatmentMutationCheck>;
        completeInstruction?: Maybe<Scalars['String']>;
        consumableProduct?: Maybe<ConsumableProduct>;
        countReportsAffected?: Maybe<Scalars['Int']>;
        createdAt?: Maybe<Scalars['Date']>;
        decimalPlaces?: Maybe<Scalars['Int']>;
        dosageForState?: Maybe<Scalars['Float']>;
        franchiseBlacklists?: Maybe<FranchiseBlacklistsForTestTreatmentToTestTreatmentFranchiseBlacklistConnection>;
        franchisePriorities?: Maybe<FranchisePrioritiesForTestTreatmentToTestTreatmentFranchisePriorityConnection>;
        group?: Maybe<TestTreatmentGroup>;
        id: Scalars['ID'];
        instruction?: Maybe<Scalars['String']>;
        instructionPlain?: Maybe<Scalars['String']>;
        isBlacklistForFranchise?: Maybe<Scalars['Boolean']>;
        isRadical?: Maybe<Scalars['Boolean']>;
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
        priorityForFranchise?: Maybe<Scalars['Int']>;
        /** @deprecated Private method, to be removed from API */
        problems?: Maybe<ProblemsForTestTreatmentToTestProblemConnection>;
        relatedProblems?: Maybe<RelatedProblemsForTestTreatmentToTestProblemConnection>;
        simpleSolution?: Maybe<Scalars['Float']>;
        solutionAffect?: Maybe<SolutionAffectForTestTreatmentToSolutionAffectConnection>;
        unit?: Maybe<Unit>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type TestTreatmentAffectsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestTreatmentCompleteInstructionArgs = {
        dosage: Scalars['Float'];
    };

    export type TestTreatmentDosageForStateArgs = {
        state?: InputMaybe<Array<Scalars['Float']>>;
        targets?: InputMaybe<Array<Scalars['Float']>>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type TestTreatmentFranchiseBlacklistsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentFranchiseBlacklistTestTreatmentFranchiseBlacklistsSearch>;
    };

    export type TestTreatmentFranchisePrioritiesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentFranchisePriorityTestTreatmentFranchisePrioritiesSearch>;
    };

    export type TestTreatmentIsBlacklistForFranchiseArgs = {
        franchise: Scalars['ID'];
    };

    export type TestTreatmentPriorityForFranchiseArgs = {
        franchise: Scalars['ID'];
    };

    export type TestTreatmentProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestTreatmentRelatedProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestProblemTestTreatmentRelatedProblemsSearch>;
        sort?: InputMaybe<TestProblemTestTreatmentRelatedProblemsSort>;
    };

    export type TestTreatmentSimpleSolutionArgs = {
        sampleSetId: Scalars['ID'];
    };

    export type TestTreatmentSolutionAffectArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        dosage: Scalars['Float'];
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type TestTreatmentFranchiseBlacklist = Node & {
        __typename?: 'TestTreatmentFranchiseBlacklist';
        checkMutations?: Maybe<TestTreatmentFranchiseBlacklistMutationCheck>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        testTreatment?: Maybe<TestTreatment>;
    };

    export type TestTreatmentFranchiseBlacklistMutationCheck = {
        __typename?: 'TestTreatmentFranchiseBlacklistMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type TestTreatmentFranchiseBlacklistTestTreatmentFranchiseBlacklistsSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
    };

    export type TestTreatmentFranchiseGroup = Node & {
        __typename?: 'TestTreatmentFranchiseGroup';
        active?: Maybe<Scalars['Boolean']>;
        checkMutations?: Maybe<TestTreatmentFranchiseGroupMutationCheck>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        priority?: Maybe<Scalars['Int']>;
        treatmentGroup?: Maybe<TestTreatmentGroup>;
    };

    export type TestTreatmentFranchiseGroupActivateMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
    };

    export type TestTreatmentFranchiseGroupDeactivateMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
    };

    export type TestTreatmentFranchiseGroupMutationCheck = {
        __typename?: 'TestTreatmentFranchiseGroupMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type TestTreatmentFranchiseGroupMutationInput = {
        activate?: InputMaybe<Array<InputMaybe<TestTreatmentFranchiseGroupActivateMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        deactivate?: InputMaybe<Array<InputMaybe<TestTreatmentFranchiseGroupDeactivateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestTreatmentFranchiseGroupMutationInput>>>;
    };

    export type TestTreatmentFranchiseGroupMutationPayload = {
        __typename?: 'TestTreatmentFranchiseGroupMutationPayload';
        TestTreatmentFranchiseGroup?: Maybe<TestTreatmentFranchiseGroup>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestTreatmentFranchiseGroupMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTreatmentGroup?: Maybe<TestTreatmentGroup>;
        viewer?: Maybe<Viewer>;
    };

    export type TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        treatmentGroup?: InputMaybe<TestTreatmentGroupNodeMatchSearchCommon>;
    };

    export type TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSort = {
        /** Expects node: **TestTreatmentFranchiseGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestTreatmentFranchisePriority = Node & {
        __typename?: 'TestTreatmentFranchisePriority';
        checkMutations?: Maybe<TestTreatmentFranchisePriorityMutationCheck>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        priority?: Maybe<Scalars['Int']>;
        testTreatment?: Maybe<TestTreatment>;
    };

    export type TestTreatmentFranchisePriorityMutationCheck = {
        __typename?: 'TestTreatmentFranchisePriorityMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestTreatmentFranchisePriorityMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestTreatmentFranchisePriorityMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestTreatmentFranchisePriorityMutationInput>>>;
    };

    export type TestTreatmentFranchisePriorityMutationPayload = {
        __typename?: 'TestTreatmentFranchisePriorityMutationPayload';
        TestTreatmentFranchisePriority?: Maybe<TestTreatmentFranchisePriority>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestTreatmentFranchisePriorityMutationOutput>>>;
        updatedFranchise?: Maybe<Franchise>;
        updatedTestTreatment?: Maybe<TestTreatment>;
        viewer?: Maybe<Viewer>;
    };

    export type TestTreatmentFranchisePriorityTestTreatmentFranchisePrioritiesSearch = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestTreatmentGroup = Node & {
        __typename?: 'TestTreatmentGroup';
        active?: Maybe<Scalars['Boolean']>;
        checkMutations?: Maybe<TestTreatmentGroupMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        organisationType?: Maybe<OrganisationType>;
        priority?: Maybe<Scalars['Int']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type TestTreatmentGroupFranchiseTreatmentGroupsSearch = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestTreatmentGroupFranchiseTreatmentGroupsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestTreatmentGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestTreatmentGroupMutationCheck = {
        __typename?: 'TestTreatmentGroupMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestTreatmentGroupMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestTreatmentGroupMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestTreatmentGroupMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestTreatmentGroupMutationInput>>>;
    };

    export type TestTreatmentGroupMutationPayload = {
        __typename?: 'TestTreatmentGroupMutationPayload';
        TestTreatmentGroup?: Maybe<TestTreatmentGroup>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestTreatmentGroupMutationOutput>>>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    export type TestTreatmentGroupNodeMatchSearchCommon = {
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestTreatmentGroupViewerTestTreatmentGroupsSearch = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestTreatmentGroupViewerTestTreatmentGroupsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestTreatmentGroup** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
    };

    export type TestTreatmentMutationCheck = {
        __typename?: 'TestTreatmentMutationCheck';
        erase?: Maybe<Scalars['Boolean']>;
        setBlacklistForFranchise?: Maybe<Scalars['Boolean']>;
        setPriorityForFranchise?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TestTreatmentMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTestTreatmentMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTestTreatmentMutationInput>>>;
        setBlacklistForFranchise?: InputMaybe<Array<InputMaybe<TestTreatmentSetBlacklistForFranchiseMutationInput>>>;
        setPriorityForFranchise?: InputMaybe<Array<InputMaybe<TestTreatmentSetPriorityForFranchiseMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTestTreatmentMutationInput>>>;
    };

    export type TestTreatmentMutationPayload = {
        __typename?: 'TestTreatmentMutationPayload';
        TestTreatment?: Maybe<TestTreatment>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTestTreatmentMutationOutput>>>;
        updatedConsumableProduct?: Maybe<ConsumableProduct>;
        updatedGroup?: Maybe<TestTreatmentGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedUnit?: Maybe<Unit>;
        viewer?: Maybe<Viewer>;
    };

    export type TestTreatmentNodeMatchSearchCommon = {
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type TestTreatmentSetBlacklistForFranchiseMutationInput = {
        blacklisted: Scalars['Boolean'];
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        /** Expects node: **TestTreatment** */
        id: Scalars['ID'];
    };

    export type TestTreatmentSetPriorityForFranchiseMutationInput = {
        /** Expects node: **Franchise** */
        franchise: Scalars['ID'];
        /** Expects node: **TestTreatment** */
        id: Scalars['ID'];
        priority: Scalars['Int'];
    };

    export type TestTreatmentViewerTestTreatmentsSearch = {
        affects?: InputMaybe<TestTreatmentViewerTestTreatmentsSearchAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<TestTreatmentViewerTestTreatmentsSearchFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<TestTreatmentViewerTestTreatmentsSearchFranchisePriorities>;
        group?: InputMaybe<TestTreatmentViewerTestTreatmentsSearchGroup>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        relatedProblems?: InputMaybe<TestTreatmentViewerTestTreatmentsSearchRelatedProblems>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSearchAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSearchFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSearchFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSearchGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSearchRelatedProblems = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type TestTreatmentViewerTestTreatmentsSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestTreatment** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type Timezone = Node & {
        __typename?: 'Timezone';
        dateFor?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        localiseDate?: Maybe<Scalars['String']>;
        localiseDateTime?: Maybe<Scalars['String']>;
        localiseTime?: Maybe<Scalars['String']>;
        shortLocaliseDate?: Maybe<Scalars['String']>;
        timeZone?: Maybe<Scalars['String']>;
        verboseLocaliseDateTime?: Maybe<Scalars['String']>;
    };

    export type TimezoneDateForArgs = {
        day?: InputMaybe<Scalars['Int']>;
        month?: InputMaybe<Scalars['Int']>;
        year?: InputMaybe<Scalars['Int']>;
    };

    export type TimezoneLocaliseDateArgs = {
        date: Scalars['Date'];
    };

    export type TimezoneLocaliseDateTimeArgs = {
        date: Scalars['Date'];
    };

    export type TimezoneShortLocaliseDateArgs = {
        date: Scalars['Date'];
    };

    export type TimezoneVerboseLocaliseDateTimeArgs = {
        date: Scalars['Date'];
    };

    /** A connection to a list of items. */
    export type TransferGainedPoolsForEntityToPoolOwnershipHistoryConnection = {
        __typename?: 'TransferGainedPoolsForEntityToPoolOwnershipHistoryConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TransferGainedPoolsForEntityToPoolOwnershipHistoryEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TransferGainedPoolsForEntityToPoolOwnershipHistoryMeta>;
    };

    /** An edge in a connection. */
    export type TransferGainedPoolsForEntityToPoolOwnershipHistoryEdge = {
        __typename?: 'TransferGainedPoolsForEntityToPoolOwnershipHistoryEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolOwnershipHistory>;
    };

    export type TransferGainedPoolsForEntityToPoolOwnershipHistoryMeta = {
        __typename?: 'TransferGainedPoolsForEntityToPoolOwnershipHistoryMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type TreatmentAffect = Node & {
        __typename?: 'TreatmentAffect';
        checkMutations?: Maybe<TreatmentAffectMutationCheck>;
        id: Scalars['ID'];
        measurementType?: Maybe<Measurement>;
        primary?: Maybe<Scalars['Boolean']>;
        treatment?: Maybe<TestTreatment>;
        value?: Maybe<Scalars['Float']>;
    };

    export type TreatmentAffectMutationCheck = {
        __typename?: 'TreatmentAffectMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type TreatmentAffectMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateTreatmentAffectMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteTreatmentAffectMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateTreatmentAffectMutationInput>>>;
    };

    export type TreatmentAffectMutationPayload = {
        __typename?: 'TreatmentAffectMutationPayload';
        TreatmentAffect?: Maybe<TreatmentAffect>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkTreatmentAffectMutationOutput>>>;
        updatedMeasurementType?: Maybe<Measurement>;
        updatedTreatment?: Maybe<TestTreatment>;
        viewer?: Maybe<Viewer>;
    };

    /** A connection to a list of items. */
    export type TreatmentGroupsForFranchiseToTestTreatmentGroupConnection = {
        __typename?: 'TreatmentGroupsForFranchiseToTestTreatmentGroupConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TreatmentGroupsForFranchiseToTestTreatmentGroupEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TreatmentGroupsForFranchiseToTestTreatmentGroupMeta>;
    };

    /** An edge in a connection. */
    export type TreatmentGroupsForFranchiseToTestTreatmentGroupEdge = {
        __typename?: 'TreatmentGroupsForFranchiseToTestTreatmentGroupEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatmentGroup>;
    };

    export type TreatmentGroupsForFranchiseToTestTreatmentGroupMeta = {
        __typename?: 'TreatmentGroupsForFranchiseToTestTreatmentGroupMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type TreatmentResultType = {
        __typename?: 'TreatmentResultType';
        affects?: Maybe<AffectsForTreatmentResultTypeToSolutionAffectConnection>;
        dosage?: Maybe<Scalars['Float']>;
        instruction?: Maybe<Scalars['String']>;
        treatment?: Maybe<TestTreatment>;
    };

    export type TreatmentResultTypeAffectsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewConnection = {
        __typename?: 'TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewMeta>;
    };

    /** An edge in a connection. */
    export type TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewEdge = {
        __typename?: 'TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseTestTreatmentView>;
    };

    export type TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewMeta = {
        __typename?: 'TreatmentViewsForFranchiseTestProblemViewToFranchiseTestTreatmentViewMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type TreatmentsForFranchiseForTestProblemToTestTreatmentConnection = {
        __typename?: 'TreatmentsForFranchiseForTestProblemToTestTreatmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TreatmentsForFranchiseForTestProblemToTestTreatmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type TreatmentsForFranchiseForTestProblemToTestTreatmentEdge = {
        __typename?: 'TreatmentsForFranchiseForTestProblemToTestTreatmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatment>;
    };

    /** A connection to a list of items. */
    export type TreatmentsForTestProblemToTestTreatmentConnection = {
        __typename?: 'TreatmentsForTestProblemToTestTreatmentConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<TreatmentsForTestProblemToTestTreatmentEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type TreatmentsForTestProblemToTestTreatmentEdge = {
        __typename?: 'TreatmentsForTestProblemToTestTreatmentEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatment>;
    };

    export type Unit = Node & {
        __typename?: 'Unit';
        checkMutations?: Maybe<UnitMutationCheck>;
        id: Scalars['ID'];
        linearConversion?: Maybe<UnitConversion>;
        linearForm?: Maybe<Unit>;
        name?: Maybe<Scalars['String']>;
    };

    export type UnitConversion = Node & {
        __typename?: 'UnitConversion';
        checkMutations?: Maybe<UnitConversionMutationCheck>;
        convert?: Maybe<Scalars['Float']>;
        destination?: Maybe<Unit>;
        factors?: Maybe<Array<Maybe<Scalars['Float']>>>;
        id: Scalars['ID'];
        method?: Maybe<UnitConverter>;
        reverseConvert?: Maybe<Scalars['Float']>;
        source?: Maybe<Unit>;
    };

    export type UnitConversionConvertArgs = {
        value: Scalars['Float'];
    };

    export type UnitConversionReverseConvertArgs = {
        value: Scalars['Float'];
    };

    export type UnitConversionMutationCheck = {
        __typename?: 'UnitConversionMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type UnitConversionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateUnitConversionMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteUnitConversionMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateUnitConversionMutationInput>>>;
    };

    export type UnitConversionMutationPayload = {
        __typename?: 'UnitConversionMutationPayload';
        UnitConversion?: Maybe<UnitConversion>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkUnitConversionMutationOutput>>>;
        updatedDestination?: Maybe<Unit>;
        updatedMethod?: Maybe<UnitConverter>;
        updatedSource?: Maybe<Unit>;
        viewer?: Maybe<Viewer>;
    };

    export type UnitConversionViewerUnitConversionsSearch = {
        destination?: InputMaybe<UnitNodeMatchSearchCommon>;
        factors?: InputMaybe<FloatMatchSearchCommon>;
        method?: InputMaybe<UnitConverterNodeMatchSearchCommon>;
        source?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type UnitConverter = Node & {
        __typename?: 'UnitConverter';
        convert?: Maybe<Scalars['Float']>;
        id: Scalars['ID'];
        name?: Maybe<Scalars['String']>;
        requiresFactors?: Maybe<Scalars['Int']>;
        reverseConvert?: Maybe<Scalars['Float']>;
        tag?: Maybe<Scalars['String']>;
    };

    export type UnitConverterConvertArgs = {
        factors?: InputMaybe<Array<Scalars['Float']>>;
        value: Scalars['Float'];
    };

    export type UnitConverterReverseConvertArgs = {
        factors?: InputMaybe<Array<Scalars['Float']>>;
        value: Scalars['Float'];
    };

    export type UnitConverterNodeMatchSearchCommon = {
        /** Expects node: **UnitConverter** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **UnitConverter** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UnitMutationCheck = {
        __typename?: 'UnitMutationCheck';
        deleteUnit?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type UnitMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateUnitMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteUnitMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateUnitMutationInput>>>;
    };

    export type UnitMutationPayload = {
        __typename?: 'UnitMutationPayload';
        Unit?: Maybe<Unit>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkUnitMutationOutput>>>;
        updatedLinearForm?: Maybe<Unit>;
        viewer?: Maybe<Viewer>;
    };

    export type UnitNodeMatchSearchCommon = {
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Unit** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UnitViewerMeasurementUnitsSearch = {
        linearForm?: InputMaybe<UnitNodeMatchSearchCommon>;
        name?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UnitViewerMeasurementUnitsSort = {
        /** Expects node: **Unit** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        name?: InputMaybe<Scalars['Boolean']>;
    };

    export type UniversalCriteria = Node & {
        __typename?: 'UniversalCriteria';
        checkMutations?: Maybe<UniversalCriteriaMutationCheck>;
        criterion?: Maybe<TestCriteria>;
        id: Scalars['ID'];
    };

    export type UniversalCriteriaMutationCheck = {
        __typename?: 'UniversalCriteriaMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
    };

    export type UniversalCriteriaMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateUniversalCriteriaMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteUniversalCriteriaMutationInput>>>;
    };

    export type UniversalCriteriaMutationPayload = {
        __typename?: 'UniversalCriteriaMutationPayload';
        UniversalCriteria?: Maybe<UniversalCriteria>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkUniversalCriteriaMutationOutput>>>;
        updatedCriterion?: Maybe<TestCriteria>;
        viewer?: Maybe<Viewer>;
    };

    export type UniversalCriteriaViewerUniversalCriteriaSearch = {
        criterion?: InputMaybe<TestCriteriaNodeMatchSearchCommon>;
    };

    /** Updates an instance of: AbstractDocument */
    export type UpdateAbstractDocumentMutationInput = {
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        dateDue?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AbstractDocument** */
        id: Scalars['ID'];
        issuedAt?: InputMaybe<Scalars['Date']>;
        jsonBody?: InputMaybe<Scalars['JSON']>;
        refNumber?: InputMaybe<Scalars['String']>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Scalars['ID']>;
        statusTag?: InputMaybe<Scalars['String']>;
        typeTag?: InputMaybe<Scalars['String']>;
        /** Expects node: **WorkOrder** */
        workOrder?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Address */
    export type UpdateAddressMutationInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        id: Scalars['ID'];
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: Announcement */
    export type UpdateAnnouncementMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        authorName?: InputMaybe<Scalars['String']>;
        body?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Announcement** */
        id: Scalars['ID'];
        link?: InputMaybe<Scalars['String']>;
        preview?: InputMaybe<Scalars['String']>;
        subject?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: AppointmentGroup */
    export type UpdateAppointmentGroupMutationInput = {
        colorPalette?: InputMaybe<Scalars['JSON']>;
        defaultColor?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentGroup** */
        id: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        statusTitleCustom?: InputMaybe<Scalars['String']>;
        statusTitleFailed?: InputMaybe<Scalars['String']>;
        statusTitleFinished?: InputMaybe<Scalars['String']>;
        statusTitlePending?: InputMaybe<Scalars['String']>;
        statusTitleStarted?: InputMaybe<Scalars['String']>;
        statusVisibilityCustom?: InputMaybe<Scalars['Boolean']>;
        statusVisibilityStarted?: InputMaybe<Scalars['Boolean']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: AppointmentInstance */
    export type UpdateAppointmentInstanceMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        completed?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        duration?: InputMaybe<Scalars['Int']>;
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **AppointmentInstance** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        selectedColor?: InputMaybe<Scalars['String']>;
        /** Expects node: **Site** */
        site?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        status?: InputMaybe<AppointmentStatusEnum>;
    };

    /** Updates an instance of: Appointment */
    export type UpdateAppointmentMutationInput = {
        /** Expects node: **Appointment** */
        id: Scalars['ID'];
    };

    /** Updates an instance of: Brand */
    export type UpdateBrandMutationInput = {
        company?: InputMaybe<UpdateBrandProductCompanySwitchInput>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Brand** */
        id: Scalars['ID'];
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateBrandSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateBrandSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ConsumableProduct */
    export type UpdateConsumableProductMutationInput = {
        /** Expects node: **ConsumableProduct** */
        id: Scalars['ID'];
        product?: InputMaybe<UpdateConsumableProductProductInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateConsumableProductProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateConsumableProductProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateConsumableProductProductSwitchUpdateBrandInput>;
    };

    export type UpdateConsumableProductProductInput = {
        brand?: InputMaybe<UpdateConsumableProductProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateConsumableProductProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateConsumableProductProductProductCompanySwitchInput>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        traits?: InputMaybe<UpdateConsumableProductProductListProductTrait>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateConsumableProductProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateConsumableProductProductListProductTrait = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateConsumableProductProductListProductTraitCreateInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitFloatValueInput>>
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitIntegerValueInput>>
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitMeasurementValueInput>>
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitSelectionValueInput>>
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitStringValueInput>>
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitCreateListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateConsumableProductProductListProductTraitCreateListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flagValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        floatValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValue>;
        /** Expects node: **ProductTrait** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        integerValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        measurementValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        selectionValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        stringValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        unitValues?: InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValue>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValueCreateInput>>
        >;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValueCreateInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFlagValueUpdateInput = {
        /** Expects node: **ProductTraitFlagValue** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValueCreateInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitFloatValueUpdateInput = {
        /** Expects node: **ProductTraitFloatValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValueCreateInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput = {
        /** Expects node: **ProductTraitIntegerValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput = {
        /** Expects node: **ProductTraitMeasurementValue** */
        id: Scalars['ID'];
        /** Expects node: **Measurement** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValueCreateInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput = {
        /** Expects node: **ProductTraitSelectionValue** */
        id: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValueCreateInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitStringValueUpdateInput = {
        /** Expects node: **ProductTraitStringValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValueCreateInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateConsumableProductProductListProductTraitUpdateListProductTraitUnitValueUpdateInput = {
        /** Expects node: **ProductTraitUnitValue** */
        id: Scalars['ID'];
        /** Expects node: **Unit** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateConsumableProductProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateConsumableProductProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateConsumableProductProductSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateConsumableProductProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateConsumableProductProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateConsumableProductProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateConsumableProductProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateConsumableProductProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateConsumableProductProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateConsumableProductProductSwitchUpdateBrandInput = {
        company?: InputMaybe<UpdateConsumableProductProductSwitchUpdateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateConsumableProductProductSwitchUpdateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateConsumableProductProductSwitchUpdateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateConsumableProductProductSwitchUpdateBrandSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateConsumableProductProductSwitchUpdateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateConsumableProductProductSwitchUpdateBrandSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateConsumableProductProductSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Contact */
    export type UpdateContactMutationInput = {
        data?: InputMaybe<Scalars['String']>;
        /** Expects node: **Contact** */
        id: Scalars['ID'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ContactType */
    export type UpdateContactTypeMutationInput = {
        iconPath?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        validatorTag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Updates an instance of: CustomContract */
    export type UpdateCustomContractMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        /** Expects node: **CustomContract** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate?: InputMaybe<Scalars['Date']>;
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateCustomerAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateCustomerSwitchUpdateAddressInput>;
    };

    export type UpdateCustomerListCustomerNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerListCustomerNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerListCustomerNoteUpdateInput>>>;
    };

    /** Creates a new instances of: CustomerNote */
    export type UpdateCustomerListCustomerNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerListCustomerNoteUpdateInput = {
        /** Expects node: **CustomerNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerListCustomerTag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerListCustomerTagCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: CustomerTag */
    export type UpdateCustomerListCustomerTagCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Customer */
    export type UpdateCustomerMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        billAddress?: InputMaybe<UpdateCustomerAddressSwitchInput>;
        companyName?: InputMaybe<Scalars['String']>;
        contactName?: InputMaybe<Scalars['String']>;
        /** Expects node: **Customer** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateCustomerListCustomerNote>;
        primaryAddress?: InputMaybe<UpdateCustomerAddressSwitchInput>;
        registeredAt?: InputMaybe<Scalars['Date']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        tags?: InputMaybe<UpdateCustomerListCustomerTag>;
        user?: InputMaybe<UpdateCustomerUserInput>;
    };

    /** Updates an instance of: CustomerNote */
    export type UpdateCustomerNoteMutationInput = {
        /** Expects node: **CustomerNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Customer** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateCustomerSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: CustomerTag */
    export type UpdateCustomerTagMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **CustomerTag** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateCustomerUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateCustomerUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateCustomerUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateCustomerUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateCustomerUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateCustomerUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateCustomerUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateCustomerUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateCustomerUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateCustomerUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateCustomerUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateCustomerUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateCustomerUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateCustomerUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateCustomerUserEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateCustomerUserEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateCustomerUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateCustomerUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateCustomerUserEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateCustomerUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateCustomerUserEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateCustomerUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateCustomerUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolSwitchCreateSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateCustomerUserEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateCustomerUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateCustomerUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateCustomerUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateCustomerUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateCustomerUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateCustomerUserEntityListSiteListPool>;
    };

    export type UpdateCustomerUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateCustomerUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateCustomerUserEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateCustomerUserEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateCustomerUserEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateCustomerUserEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateCustomerUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateCustomerUserInput = {
        entity?: InputMaybe<UpdateCustomerUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: EZCDeviceLink */
    export type UpdateEZCDeviceLinkMutationInput = {
        /** Expects node: **EZCDeviceLink** */
        id: Scalars['ID'];
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **Customer** */
        owner?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Site** */
        site?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: EmailTemplate */
    export type UpdateEmailTemplateMutationInput = {
        /** Expects node: **EmailTemplate** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        provider?: InputMaybe<EmailTemplateProvider>;
        templateBody?: InputMaybe<Scalars['String']>;
        templateMetadata?: InputMaybe<Scalars['JSON']>;
    };

    /** Updates an instance of: FeatureFlagVariation */
    export type UpdateFeatureFlagVariationMutationInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **FeatureFlagVariation** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: FileUpload */
    export type UpdateFileUploadMutationInput = {
        beforeAfterStatus?: InputMaybe<FileUploadBeforeAfterEnum>;
        /** Expects node: **FileUpload** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: FranchiseShop
     */
    export type UpdateFranchiseFranchiseShopSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopInput>;
        /** Expects node: **FranchiseShop** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopInput>;
    };

    export type UpdateFranchiseListRoleFlag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseListRoleFlagCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: RoleFlag */
    export type UpdateFranchiseListRoleFlagCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        tag: Scalars['String'];
    };

    /** Updates an instance of: Franchise */
    export type UpdateFranchiseMutationInput = {
        contactExport?: InputMaybe<Scalars['Boolean']>;
        email?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flags?: InputMaybe<UpdateFranchiseListRoleFlag>;
        headOffice?: InputMaybe<UpdateFranchiseFranchiseShopSwitchInput>;
        /** Expects node: **Franchise** */
        id: Scalars['ID'];
        /** Expects node: **Locales** */
        locales?: InputMaybe<Scalars['ID']>;
        manager?: InputMaybe<UpdateFranchiseStaffSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        phoneNumber?: InputMaybe<Scalars['String']>;
        /** Expects node: **Timezone** */
        timeZone?: InputMaybe<Scalars['ID']>;
        /**
         * Array size minimum: 1
         * Array size minimum: 1
         */
        types?: InputMaybe<Array<InputMaybe<FranchiseType>>>;
        waterTestBranding?: InputMaybe<OrganisationBrandingEnum>;
    };

    /** Updates an instance of: FranchiseSequenceStore */
    export type UpdateFranchiseSequenceStoreMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FranchiseSequenceStore** */
        id: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseShopAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseShopEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseShopEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseShopEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseShopEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseShopEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateFranchiseShopEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseShopEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateFranchiseShopEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListPoolSwitchCreateSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseShopEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateFranchiseShopEntityListSiteListPool>;
    };

    export type UpdateFranchiseShopEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: FranchiseShop */
    export type UpdateFranchiseShopMutationInput = {
        address?: InputMaybe<UpdateFranchiseShopAddressSwitchInput>;
        entity?: InputMaybe<UpdateFranchiseShopEntityInput>;
        /** Expects node: **FranchiseShop** */
        id: Scalars['ID'];
        manager?: InputMaybe<UpdateFranchiseShopStaffSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type UpdateFranchiseShopStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Staff */
    export type UpdateFranchiseShopSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffListStaffTeam>;
        user: UpdateFranchiseShopSwitchCreateStaffUserSwitchInput;
    };

    export type UpdateFranchiseShopSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserInput = {
        entity: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
            >
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandSwitchInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            /** Expects node: **Franchise** */
            franchise?: InputMaybe<Scalars['ID']>;
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type UpdateFranchiseShopSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseShopSwitchCreateStaffSwitchCreateUserInput;
    };

    export type UpdateFranchiseShopSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffListStaffTeam>;
        user?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserInput>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffListStaffTeamCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseShopSwitchUpdateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractInput>>
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPool>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateInput>>
        >;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseShopSwitchUpdateStaffUserInput = {
        entity?: InputMaybe<UpdateFranchiseShopSwitchUpdateStaffUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type UpdateFranchiseStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateStaffInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type UpdateFranchiseSwitchCreateFranchiseShopEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityInput;
    };

    /** Creates a new instances of: FranchiseShop */
    export type UpdateFranchiseSwitchCreateFranchiseShopInput = {
        address?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopAddressInput>;
        entity: UpdateFranchiseSwitchCreateFranchiseShopEntitySwitchInput;
        manager?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopStaffSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type UpdateFranchiseSwitchCreateFranchiseShopStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Entity */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateEntityListSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Staff */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeam>;
        user: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffUserSwitchInput;
    };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput>>
        >;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput = {
        entity: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>
            >
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>
            >
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>
            >
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>
            >
        >;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput =
        {
            data: Scalars['String'];
            isPrimary?: InputMaybe<Scalars['Boolean']>;
            label?: InputMaybe<Scalars['String']>;
            /** Expects node: **ContactType** */
            type: Scalars['ID'];
        };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput =
        {
            /** Expects nodes: **StaffRegistration**, **Staff** */
            assignedTo?: InputMaybe<Scalars['ID']>;
            contractNumber?: InputMaybe<Scalars['String']>;
            endDate: Scalars['Date'];
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
            frequency?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            rate?: InputMaybe<Scalars['Int']>;
            startDate: Scalars['Date'];
            status?: InputMaybe<CustomContractStatusEnum>;
            term?: InputMaybe<Scalars['String']>;
            terminationDate?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput =
        {
            expiryDate?: InputMaybe<Scalars['Date']>;
            newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
            note?: InputMaybe<Scalars['String']>;
            payload?: InputMaybe<Scalars['JSON']>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            product?: InputMaybe<Scalars['ID']>;
            recNumber?: InputMaybe<Scalars['String']>;
            status?: InputMaybe<HeaterRecommendationStatusEnum>;
        };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            site: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
                >
            >;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
                >
            >;
            /** Creates new instances of Pool. New instances will be appended to the edge */
            pools?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
                >
            >;
        };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchCreateFranchiseShopSwitchCreateStaffSwitchCreateUserInput;
    };

    /** Creates a new instances of: Staff */
    export type UpdateFranchiseSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseSwitchCreateStaffListStaffTeam>;
        user: UpdateFranchiseSwitchCreateStaffUserSwitchInput;
    };

    export type UpdateFranchiseSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchCreateStaffListStaffTeamCreateInput>>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserInput = {
        entity: UpdateFranchiseSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type UpdateFranchiseSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchCreateStaffSwitchCreateUserInput;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductBrandProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListItemCreateProductProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPool>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateInput>>
        >;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateInput>>
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolCreateListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopInput = {
        address?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopAddressSwitchInput>;
        entity?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopEntityInput>;
        manager?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopStaffSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Staff
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopStaffSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffInput>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Staff */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        isSystemHidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffListStaffTeam>;
        user: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffUserSwitchInput;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput>>
        >;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Entity
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput;
    };

    /** Creates a new instances of: User */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserInput = {
        entity: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserEntitySwitchInput;
        firstName: Scalars['String'];
        lastName: Scalars['String'];
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Entity */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput>
            >
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput>
            >
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput>
            >
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput>
            >
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput>
            >
        >;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListContactInput =
        {
            data: Scalars['String'];
            isPrimary?: InputMaybe<Scalars['Boolean']>;
            label?: InputMaybe<Scalars['String']>;
            /** Expects node: **ContactType** */
            type: Scalars['ID'];
        };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractInput =
        {
            /** Expects nodes: **StaffRegistration**, **Staff** */
            assignedTo?: InputMaybe<Scalars['ID']>;
            contractNumber?: InputMaybe<Scalars['String']>;
            endDate: Scalars['Date'];
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload>;
            frequency?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            rate?: InputMaybe<Scalars['Int']>;
            startDate: Scalars['Date'];
            status?: InputMaybe<CustomContractStatusEnum>;
            term?: InputMaybe<Scalars['String']>;
            terminationDate?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListCustomContractListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListHeaterRecommendationInput =
        {
            expiryDate?: InputMaybe<Scalars['Date']>;
            newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
            note?: InputMaybe<Scalars['String']>;
            payload?: InputMaybe<Scalars['JSON']>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            product?: InputMaybe<Scalars['ID']>;
            recNumber?: InputMaybe<Scalars['String']>;
            status?: InputMaybe<HeaterRecommendationStatusEnum>;
        };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            site: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput;
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput>
                >
            >;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListPoolSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteInput =
        {
            accessKeyLocation?: InputMaybe<Scalars['String']>;
            address: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteAddressInput;
            name: Scalars['String'];
            /** Creates new instances of SiteNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput>
                >
            >;
            /** Creates new instances of Pool. New instances will be appended to the edge */
            pools?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput>
                >
            >;
        };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolInput =
        {
            /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
            calibrations?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput>
                >
            >;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem>;
            name: Scalars['String'];
            note?: InputMaybe<Scalars['String']>;
            /** Creates new instances of PoolNote. New instances will be appended to the edge */
            notes?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput>
                >
            >;
            /** Expects node: **Sanitiser** */
            sanitiser: Scalars['ID'];
            /** Expects node: **PoolType** */
            type: Scalars['ID'];
            volume: Scalars['Float'];
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItem =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput>
                >
            >;
        };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListPoolListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserSwitchCreateEntityListSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: User
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffUserSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create: UpdateFranchiseSwitchUpdateFranchiseShopSwitchCreateStaffSwitchCreateUserInput;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffListStaffTeam>;
        user?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserInput>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffListStaffTeamCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListAddressInput>>
        >;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListContactInput>>
        >;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractInput>
            >
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListHeaterRecommendationInput>
            >
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolInput>>
        >;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteInput>>
        >;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Updates instance, which is referred by current field state */
            update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote =
        {
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>
                >
            >;
            /** Deletes selected instances from list */
            delete?: InputMaybe<Array<Scalars['ID']>>;
            /** Updates instances in list */
            update?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>
                >
            >;
        };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput =
        {
            /** Expects node: **SiteNote** */
            id: Scalars['ID'];
            note?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeInitInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput =
        {
            buildingName?: InputMaybe<Scalars['String']>;
            city?: InputMaybe<Scalars['String']>;
            coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
            country?: InputMaybe<Scalars['String']>;
            destinationName?: InputMaybe<Scalars['String']>;
            poBoxNumber?: InputMaybe<Scalars['String']>;
            postCode?: InputMaybe<Scalars['String']>;
            state?: InputMaybe<Scalars['String']>;
            streetName?: InputMaybe<Scalars['String']>;
            streetNumber?: InputMaybe<Scalars['String']>;
            suburb?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPool>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateInput>
            >
        >;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput>
            >
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput>
            >
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput>
            >
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Deletes selected instances from list */
            delete?: InputMaybe<Array<Scalars['ID']>>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput =
        {
            installedAt?: InputMaybe<Scalars['Date']>;
            label?: InputMaybe<Scalars['String']>;
            note?: InputMaybe<Scalars['String']>;
            product: UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput;
            serial?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandInput =
        {
            company?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput =
        {
            brand?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandInput>;
            createdAt?: InputMaybe<Scalars['Date']>;
            customSku?: InputMaybe<Scalars['String']>;
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
            documents?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
            familyCode?: InputMaybe<Scalars['String']>;
            identification?: InputMaybe<Scalars['String']>;
            isGrouping?: InputMaybe<Scalars['Boolean']>;
            isOneOff?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType?: InputMaybe<Scalars['ID']>;
            sku?: InputMaybe<Scalars['String']>;
            supplier?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput>;
            /** Creates new instances of ProductTrait. New instances will be appended to the edge */
            traits?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>
                >
            >;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput =
        {
            higherThreshold: Scalars['Float'];
            lowerThreshold: Scalars['Float'];
            /** Expects node: **Measurement** */
            measurementType?: InputMaybe<Scalars['ID']>;
            required?: InputMaybe<Scalars['Boolean']>;
            target: Scalars['Float'];
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote =
        {
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>
                >
            >;
            /** Deletes selected instances from list */
            delete?: InputMaybe<Array<Scalars['ID']>>;
            /** Updates instances in list */
            update?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>
                >
            >;
        };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput =
        {
            note?: InputMaybe<Scalars['String']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput =
        {
            /** Expects node: **PoolNote** */
            id: Scalars['ID'];
            note?: InputMaybe<Scalars['String']>;
        };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserInput = {
        entity?: InputMaybe<UpdateFranchiseSwitchUpdateFranchiseShopSwitchUpdateStaffUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateFranchiseSwitchUpdateStaffListStaffTeam>;
        user?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserInput>;
    };

    export type UpdateFranchiseSwitchUpdateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffListStaffTeamCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateFranchiseSwitchUpdateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListCustomContractInput>>
        >;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListItemCreateProductProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateFranchiseSwitchUpdateStaffUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPool>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandInput = {
        company?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Product */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
        };

    /** Creates a new instances of: ProductTrait */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNoteCreateInput = {
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateFranchiseSwitchUpdateStaffUserInput = {
        entity?: InputMaybe<UpdateFranchiseSwitchUpdateStaffUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: FranchiseValueStore */
    export type UpdateFranchiseValueStoreMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FranchiseValueStore** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: FranchiseWarranter */
    export type UpdateFranchiseWarranterMutationInput = {
        /** Expects node: **Franchise** */
        actingFranchise?: InputMaybe<Scalars['ID']>;
        disabled?: InputMaybe<Scalars['Boolean']>;
        email?: InputMaybe<Scalars['String']>;
        /** Expects node: **FranchiseWarranter** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: HeaterRecommendation */
    export type UpdateHeaterRecommendationMutationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **HeaterRecommendation** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    export type UpdateInstallableProductListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateInstallableProductListTestCriteriaCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type UpdateInstallableProductListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: UpdateInstallableProductListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateInstallableProductListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>
            >
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type UpdateInstallableProductListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: InstallableProduct */
    export type UpdateInstallableProductMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<UpdateInstallableProductListTestCriteria>;
        /** Expects node: **InstallableProduct** */
        id: Scalars['ID'];
        product?: InputMaybe<UpdateInstallableProductProductInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateInstallableProductProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateInstallableProductProductSwitchUpdateBrandInput>;
    };

    export type UpdateInstallableProductProductInput = {
        brand?: InputMaybe<UpdateInstallableProductProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateInstallableProductProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateInstallableProductProductProductCompanySwitchInput>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        traits?: InputMaybe<UpdateInstallableProductProductListProductTrait>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateInstallableProductProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateInstallableProductProductListProductTrait = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateInstallableProductProductListProductTraitUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateInstallableProductProductListProductTraitCreateInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitFloatValueInput>>
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitIntegerValueInput>>
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitSelectionValueInput>>
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitStringValueInput>>
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitCreateListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateInstallableProductProductListProductTraitCreateListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flagValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        floatValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValue>;
        /** Expects node: **ProductTrait** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        integerValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        measurementValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        selectionValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        stringValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        unitValues?: InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValue>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValueCreateInput>>
        >;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValueCreateInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFlagValueUpdateInput = {
        /** Expects node: **ProductTraitFlagValue** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValueCreateInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitFloatValueUpdateInput = {
        /** Expects node: **ProductTraitFloatValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValueCreateInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput = {
        /** Expects node: **ProductTraitIntegerValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput = {
        /** Expects node: **ProductTraitMeasurementValue** */
        id: Scalars['ID'];
        /** Expects node: **Measurement** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValueCreateInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput = {
        /** Expects node: **ProductTraitSelectionValue** */
        id: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValueCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValueUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValueCreateInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitStringValueUpdateInput = {
        /** Expects node: **ProductTraitStringValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValueCreateInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateInstallableProductProductListProductTraitUpdateListProductTraitUnitValueUpdateInput = {
        /** Expects node: **ProductTraitUnitValue** */
        id: Scalars['ID'];
        /** Expects node: **Unit** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateInstallableProductProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateInstallableProductProductSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateInstallableProductProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateInstallableProductProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateInstallableProductProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateInstallableProductProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateInstallableProductProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateInstallableProductProductSwitchUpdateBrandInput = {
        company?: InputMaybe<UpdateInstallableProductProductSwitchUpdateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateInstallableProductProductSwitchUpdateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateInstallableProductProductSwitchUpdateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateInstallableProductProductSwitchUpdateBrandSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateInstallableProductProductSwitchUpdateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateInstallableProductProductSwitchUpdateBrandSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateInstallableProductProductSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Item */
    export type UpdateItemMutationInput = {
        /** Expects node: **Item** */
        id: Scalars['ID'];
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        serial?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: JobTodoItem */
    export type UpdateJobTodoItemMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateJobTodoListJobTodoItem = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateJobTodoListJobTodoItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoListJobTodoItemUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateJobTodoListJobTodoItemCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateJobTodoListJobTodoItemUpdateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: JobTodo */
    export type UpdateJobTodoMutationInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodo** */
        id: Scalars['ID'];
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateJobTodoListJobTodoItem>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: JobTodoTemplateAssoc */
    export type UpdateJobTodoTemplateAssocMutationInput = {
        /** Expects node: **JobTodoTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /** Updates an instance of: JobTodoTemplateItem */
    export type UpdateJobTodoTemplateItemMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoTemplateItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateJobTodoTemplateListJobTodoTemplateItem = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateJobTodoTemplateListJobTodoTemplateItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateJobTodoTemplateListJobTodoTemplateItemUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type UpdateJobTodoTemplateListJobTodoTemplateItemCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateJobTodoTemplateListJobTodoTemplateItemUpdateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoTemplateItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: JobTodoTemplate */
    export type UpdateJobTodoTemplateMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **JobTodoTemplate** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateJobTodoTemplateListJobTodoTemplateItem>;
        maxTimeEstimate?: InputMaybe<Scalars['Int']>;
        minTimeEstimate?: InputMaybe<Scalars['Int']>;
        ownedByWorkOrderTemplate?: InputMaybe<UpdateJobTodoTemplateWorkOrderTemplateSwitchInput>;
        recommendedPrice?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WorkOrderTemplate */
    export type UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<
            Array<InputMaybe<UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput>>
        >;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /**
         * Expects node: **ServiceJobTemplate**
         *
         * If null then meant for sharing with many ServiceJobTemplate
         */
        ownedByServiceJobTemplate?: InputMaybe<Scalars['ID']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput>>
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListServiceJobStage>;
        defaultStage?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateServiceJobStageSwitchInput>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        jobTodoTemplateAssocs?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssoc>;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** If null then meant for sharing with many ServiceJobTemplate */
        ownedByServiceJobTemplate?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateServiceJobTemplateSwitchInput>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssoc = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssocUpdateInput>>
        >;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListJobTodoTemplateAssocUpdateInput = {
        /** Expects node: **JobTodoTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListServiceJobStageCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ServiceJobStage
     */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateServiceJobStageSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobStageInput>;
        /** Expects node: **ServiceJobStage** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobStageInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ServiceJobTemplate
     */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateServiceJobTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateInput>;
        /** Expects node: **ServiceJobTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateInput>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobStageInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instances of: ServiceJobTemplate */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        isPublished?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances of WorkOrderTemplateAssoc. New instances will be appended to the edge */
        workOrderTemplateAssocs?: InputMaybe<
            Array<
                InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListWorkOrderTemplateAssocInput>
            >
        >;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStageCreateInput>
            >
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStageCreateInput =
        {
            color?: InputMaybe<Scalars['String']>;
            hidden?: InputMaybe<Scalars['Boolean']>;
            isDefault?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
            title: Scalars['String'];
            type?: InputMaybe<ServiceJobStageType>;
        };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListWorkOrderTemplateAssocInput =
        {
            /** Expects nodes: **StaffRegistration**, **Staff** */
            addedBy?: InputMaybe<Scalars['ID']>;
            forwardAddress?: InputMaybe<Scalars['Boolean']>;
            forwardContact?: InputMaybe<Scalars['Boolean']>;
            forwardPool?: InputMaybe<Scalars['Boolean']>;
            forwardStaff?: InputMaybe<Scalars['Boolean']>;
            forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
            isSuggestion?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
        };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobStageInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        isPublished?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        workOrderTemplateAssocs?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssoc>;
    };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStageCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStageCreateInput =
        {
            color?: InputMaybe<Scalars['String']>;
            hidden?: InputMaybe<Scalars['Boolean']>;
            isDefault?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
            title: Scalars['String'];
            type?: InputMaybe<ServiceJobStageType>;
        };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssoc =
        {
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput>
                >
            >;
            /** Deletes selected instances from list */
            delete?: InputMaybe<Array<Scalars['ID']>>;
            /** Updates instances in list */
            update?: InputMaybe<
                Array<
                    InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput>
                >
            >;
        };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput =
        {
            /** Expects nodes: **StaffRegistration**, **Staff** */
            addedBy?: InputMaybe<Scalars['ID']>;
            forwardAddress?: InputMaybe<Scalars['Boolean']>;
            forwardContact?: InputMaybe<Scalars['Boolean']>;
            forwardPool?: InputMaybe<Scalars['Boolean']>;
            forwardStaff?: InputMaybe<Scalars['Boolean']>;
            forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
            isSuggestion?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
        };

    export type UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput =
        {
            forwardAddress?: InputMaybe<Scalars['Boolean']>;
            forwardContact?: InputMaybe<Scalars['Boolean']>;
            forwardPool?: InputMaybe<Scalars['Boolean']>;
            forwardStaff?: InputMaybe<Scalars['Boolean']>;
            forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
            /** Expects node: **WorkOrderTemplateAssoc** */
            id: Scalars['ID'];
            isSuggestion?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: WorkOrderTemplate
     */
    export type UpdateJobTodoTemplateWorkOrderTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateJobTodoTemplateSwitchCreateWorkOrderTemplateInput>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateJobTodoTemplateSwitchUpdateWorkOrderTemplateInput>;
    };

    /** Updates an instance of: ManualProblem */
    export type UpdateManualProblemMutationInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **ManualProblem** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        solution?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: Measurement */
    export type UpdateMeasurementMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        id: Scalars['ID'];
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateMeasurementSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateMeasurementSwitchUpdateUnitInput>;
    };

    /** Updates an instance of: Permission */
    export type UpdatePermissionMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Permission** */
        id: Scalars['ID'];
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: PoolCalibration */
    export type UpdatePoolCalibrationMutationInput = {
        higherThreshold?: InputMaybe<Scalars['Float']>;
        /** Expects node: **PoolCalibration** */
        id: Scalars['ID'];
        lowerThreshold?: InputMaybe<Scalars['Float']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: PoolDeclarationFlagField */
    export type UpdatePoolDeclarationFlagFieldMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        exportName?: InputMaybe<Scalars['String']>;
        /** Expects node: **PoolDeclarationFlagField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        useInExport?: InputMaybe<Scalars['Boolean']>;
    };

    export type UpdatePoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdatePoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdatePoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdatePoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdatePoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdatePoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdatePoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdatePoolListItemCreateSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdatePoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>>
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<InputMaybe<UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdatePoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdatePoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdatePoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdatePoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdatePoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdatePoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdatePoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdatePoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdatePoolListPoolNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdatePoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: Pool */
    export type UpdatePoolMutationInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdatePoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdatePoolListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdatePoolListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdatePoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: PoolNote */
    export type UpdatePoolNoteMutationInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Pool** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: PoolProductDeclaration */
    export type UpdatePoolProductDeclarationMutationInput = {
        /** Expects node: **PoolProductDeclaration** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdatePoolTypeListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdatePoolTypeListTestCriteriaCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type UpdatePoolTypeListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: UpdatePoolTypeListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdatePoolTypeListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>>
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type UpdatePoolTypeListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdatePoolTypeListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: PoolType */
    export type UpdatePoolTypeMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<UpdatePoolTypeListTestCriteria>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **PoolType** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateProductSwitchUpdateBrandInput>;
    };

    /** Updates an instance of: ProductCompany */
    export type UpdateProductCompanyMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductCompany** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ProductDeclarationFlagField */
    export type UpdateProductDeclarationFlagFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationFlagField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationFloatField */
    export type UpdateProductDeclarationFloatFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationFloatField** */
        id: Scalars['ID'];
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationIntegerField */
    export type UpdateProductDeclarationIntegerFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationIntegerField** */
        id: Scalars['ID'];
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationMeasurementField */
    export type UpdateProductDeclarationMeasurementFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationMeasurementField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationSelectionChoice */
    export type UpdateProductDeclarationSelectionChoiceMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationSelectionChoice** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        /** Expects node: **ProductDeclarationSelectionField** */
        selection?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoice = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceUpdateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationSelectionChoice** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /** Updates an instance of: ProductDeclarationSelectionField */
    export type UpdateProductDeclarationSelectionFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationSelectionField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        options?: InputMaybe<UpdateProductDeclarationSelectionFieldListProductDeclarationSelectionChoice>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationStringField */
    export type UpdateProductDeclarationStringFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationStringField** */
        id: Scalars['ID'];
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDeclarationUnitField */
    export type UpdateProductDeclarationUnitFieldMutationInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationUnitField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductDocument */
    export type UpdateProductDocumentMutationInput = {
        /** Expects node: **Brand** */
        brand?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductCompany** */
        company?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductDocument** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
        type?: InputMaybe<ProductDocumentType>;
    };

    export type UpdateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateProductListProductTrait = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateProductListProductTraitCreateInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitFlagValueInput>>>;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitFloatValueInput>>>;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitIntegerValueInput>>
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitMeasurementValueInput>>
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitSelectionValueInput>>
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitStringValueInput>>
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitCreateListProductTraitUnitValueInput>>>;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateProductListProductTraitCreateListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateProductListProductTraitCreateListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateProductListProductTraitCreateListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateProductListProductTraitCreateListProductTraitMeasurementValueInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateProductListProductTraitCreateListProductTraitSelectionValueInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateProductListProductTraitCreateListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateProductListProductTraitCreateListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateProductListProductTraitUpdateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flagValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFlagValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        floatValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFloatValue>;
        /** Expects node: **ProductTrait** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        integerValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitIntegerValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        measurementValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitMeasurementValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        selectionValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitSelectionValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        stringValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitStringValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        unitValues?: InputMaybe<UpdateProductListProductTraitUpdateListProductTraitUnitValue>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitFlagValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFlagValueCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFlagValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateProductListProductTraitUpdateListProductTraitFlagValueCreateInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitFlagValueUpdateInput = {
        /** Expects node: **ProductTraitFlagValue** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitFloatValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFloatValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitFloatValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateProductListProductTraitUpdateListProductTraitFloatValueCreateInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitFloatValueUpdateInput = {
        /** Expects node: **ProductTraitFloatValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitIntegerValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitIntegerValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateProductListProductTraitUpdateListProductTraitIntegerValueCreateInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitIntegerValueUpdateInput = {
        /** Expects node: **ProductTraitIntegerValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitMeasurementValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateProductListProductTraitUpdateListProductTraitMeasurementValueCreateInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitMeasurementValueUpdateInput = {
        /** Expects node: **ProductTraitMeasurementValue** */
        id: Scalars['ID'];
        /** Expects node: **Measurement** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitSelectionValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitSelectionValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateProductListProductTraitUpdateListProductTraitSelectionValueCreateInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitSelectionValueUpdateInput = {
        /** Expects node: **ProductTraitSelectionValue** */
        id: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitStringValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitStringValueCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitStringValueUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateProductListProductTraitUpdateListProductTraitStringValueCreateInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitStringValueUpdateInput = {
        /** Expects node: **ProductTraitStringValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductListProductTraitUpdateListProductTraitUnitValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitUnitValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductListProductTraitUpdateListProductTraitUnitValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateProductListProductTraitUpdateListProductTraitUnitValueCreateInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateProductListProductTraitUpdateListProductTraitUnitValueUpdateInput = {
        /** Expects node: **ProductTraitUnitValue** */
        id: Scalars['ID'];
        /** Expects node: **Unit** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Product */
    export type UpdateProductMutationInput = {
        brand?: InputMaybe<UpdateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Product** */
        id: Scalars['ID'];
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateProductProductCompanySwitchInput>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        traits?: InputMaybe<UpdateProductListProductTrait>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateProductSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductSwitchUpdateBrandInput = {
        company?: InputMaybe<UpdateProductSwitchUpdateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateProductSwitchUpdateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductSwitchUpdateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateProductSwitchUpdateBrandSwitchUpdateProductCompanyInput>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateProductSwitchUpdateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductSwitchUpdateBrandSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductSwitchUpdateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationFlagField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFlagFieldCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFlagFieldUpdateInput>>>;
    };

    /** Creates a new instances of: ProductDeclarationFlagField */
    export type UpdateProductTraitDeclarationListProductDeclarationFlagFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationFlagFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationFlagField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationFloatField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFloatFieldCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFloatFieldUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationFloatField */
    export type UpdateProductTraitDeclarationListProductDeclarationFloatFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationFloatFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationFloatField** */
        id: Scalars['ID'];
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationIntegerField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationIntegerFieldCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationIntegerFieldUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationIntegerField */
    export type UpdateProductTraitDeclarationListProductDeclarationIntegerFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationIntegerFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationIntegerField** */
        id: Scalars['ID'];
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationMeasurementField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationMeasurementFieldCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationMeasurementFieldUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationMeasurementField */
    export type UpdateProductTraitDeclarationListProductDeclarationMeasurementFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationMeasurementFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationMeasurementField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationSelectionField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationSelectionField */
    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationSelectionChoice. New instances will be appended to the edge */
        options?: InputMaybe<
            Array<
                InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldCreateListProductDeclarationSelectionChoiceInput>
            >
        >;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldCreateListProductDeclarationSelectionChoiceInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name: Scalars['String'];
            priority?: InputMaybe<Scalars['Int']>;
        };

    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationSelectionField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        options?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoice>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoice =
        {
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoiceCreateInput>
                >
            >;
            /** Deletes selected instances from list */
            delete?: InputMaybe<Array<Scalars['ID']>>;
            /** Updates instances in list */
            update?: InputMaybe<
                Array<
                    InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoiceUpdateInput>
                >
            >;
        };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoiceCreateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name: Scalars['String'];
            priority?: InputMaybe<Scalars['Int']>;
        };

    export type UpdateProductTraitDeclarationListProductDeclarationSelectionFieldUpdateListProductDeclarationSelectionChoiceUpdateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductDeclarationSelectionChoice** */
            id: Scalars['ID'];
            name?: InputMaybe<Scalars['String']>;
            priority?: InputMaybe<Scalars['Int']>;
        };

    export type UpdateProductTraitDeclarationListProductDeclarationStringField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationStringFieldCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationStringFieldUpdateInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationStringField */
    export type UpdateProductTraitDeclarationListProductDeclarationStringFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationStringFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationStringField** */
        id: Scalars['ID'];
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationUnitField = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationUnitFieldCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationListProductDeclarationUnitFieldUpdateInput>>>;
    };

    /** Creates a new instances of: ProductDeclarationUnitField */
    export type UpdateProductTraitDeclarationListProductDeclarationUnitFieldCreateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListProductDeclarationUnitFieldUpdateInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductDeclarationUnitField** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListSanitiser = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateInput>>>;
    };

    /** Creates a new instances of: Sanitiser */
    export type UpdateProductTraitDeclarationListSanitiserCreateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteria>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        reportName?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateInput>>
        >;
    };

    /** Creates a new instances of: TestCriteria */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: TestProblem */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>
                >
            >;
        };

    /** Creates a new instances of: TestTreatment */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput =
        {
            /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
            affects?: InputMaybe<
                Array<
                    InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
                >
            >;
            /** Expects node: **ConsumableProduct** */
            consumableProduct?: InputMaybe<Scalars['ID']>;
            createdAt?: InputMaybe<Scalars['Date']>;
            decimalPlaces?: InputMaybe<Scalars['Int']>;
            /** Expects node: **TestTreatmentGroup** */
            group?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<Scalars['String']>;
            isRadical?: InputMaybe<Scalars['Boolean']>;
            name: Scalars['String'];
            /** Expects node: **OrganisationType** */
            organisationType: Scalars['ID'];
            priority?: InputMaybe<Scalars['Int']>;
            unit?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
            updatedAt?: InputMaybe<Scalars['Date']>;
        };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
            /** Expects node: **Unit** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProductTraitDeclarationListSanitiserCreateListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ProductTraitDeclaration */
    export type UpdateProductTraitDeclarationMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flagFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFlagField>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        floatFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationFloatField>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductTraitDeclaration** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        integerFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationIntegerField>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        measurementFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationMeasurementField>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductTraitDeclaration** */
        parent?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        relatedSanitisers?: InputMaybe<UpdateProductTraitDeclarationListSanitiser>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        selectionFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationSelectionField>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        stringFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationStringField>;
        tag?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        unitFields?: InputMaybe<UpdateProductTraitDeclarationListProductDeclarationUnitField>;
    };

    /** Updates an instance of: ProductTraitFlagValue */
    export type UpdateProductTraitFlagValueMutationInput = {
        /** Expects node: **ProductTraitFlagValue** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Updates an instance of: ProductTraitFloatValue */
    export type UpdateProductTraitFloatValueMutationInput = {
        /** Expects node: **ProductTraitFloatValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: ProductTraitIntegerValue */
    export type UpdateProductTraitIntegerValueMutationInput = {
        /** Expects node: **ProductTraitIntegerValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateProductTraitListProductTraitFlagValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitFlagValueCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitFlagValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateProductTraitListProductTraitFlagValueCreateInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateProductTraitListProductTraitFlagValueUpdateInput = {
        /** Expects node: **ProductTraitFlagValue** */
        id: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    export type UpdateProductTraitListProductTraitFloatValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitFloatValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitFloatValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateProductTraitListProductTraitFloatValueCreateInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateProductTraitListProductTraitFloatValueUpdateInput = {
        /** Expects node: **ProductTraitFloatValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateProductTraitListProductTraitIntegerValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitIntegerValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitIntegerValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateProductTraitListProductTraitIntegerValueCreateInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    export type UpdateProductTraitListProductTraitIntegerValueUpdateInput = {
        /** Expects node: **ProductTraitIntegerValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateProductTraitListProductTraitMeasurementValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitMeasurementValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitMeasurementValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateProductTraitListProductTraitMeasurementValueCreateInput = {
        /** Expects node: **ProductDeclarationMeasurementField** */
        field: Scalars['ID'];
        /** Expects node: **Measurement** */
        value: Scalars['ID'];
    };

    export type UpdateProductTraitListProductTraitMeasurementValueUpdateInput = {
        /** Expects node: **ProductTraitMeasurementValue** */
        id: Scalars['ID'];
        /** Expects node: **Measurement** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductTraitListProductTraitSelectionValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitSelectionValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitSelectionValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateProductTraitListProductTraitSelectionValueCreateInput = {
        /** Expects node: **ProductDeclarationSelectionField** */
        field: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value: Scalars['ID'];
    };

    export type UpdateProductTraitListProductTraitSelectionValueUpdateInput = {
        /** Expects node: **ProductTraitSelectionValue** */
        id: Scalars['ID'];
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProductTraitListProductTraitStringValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitStringValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitStringValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateProductTraitListProductTraitStringValueCreateInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    export type UpdateProductTraitListProductTraitStringValueUpdateInput = {
        /** Expects node: **ProductTraitStringValue** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProductTraitListProductTraitUnitValue = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitUnitValueCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProductTraitListProductTraitUnitValueUpdateInput>>>;
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateProductTraitListProductTraitUnitValueCreateInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    export type UpdateProductTraitListProductTraitUnitValueUpdateInput = {
        /** Expects node: **ProductTraitUnitValue** */
        id: Scalars['ID'];
        /** Expects node: **Unit** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ProductTraitMeasurementValue */
    export type UpdateProductTraitMeasurementValueMutationInput = {
        /** Expects node: **ProductTraitMeasurementValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Measurement** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ProductTrait */
    export type UpdateProductTraitMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flagValues?: InputMaybe<UpdateProductTraitListProductTraitFlagValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        floatValues?: InputMaybe<UpdateProductTraitListProductTraitFloatValue>;
        /** Expects node: **ProductTrait** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        integerValues?: InputMaybe<UpdateProductTraitListProductTraitIntegerValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        measurementValues?: InputMaybe<UpdateProductTraitListProductTraitMeasurementValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        selectionValues?: InputMaybe<UpdateProductTraitListProductTraitSelectionValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        stringValues?: InputMaybe<UpdateProductTraitListProductTraitStringValue>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        unitValues?: InputMaybe<UpdateProductTraitListProductTraitUnitValue>;
    };

    /** Updates an instance of: ProductTraitSelectionValue */
    export type UpdateProductTraitSelectionValueMutationInput = {
        /** Expects node: **ProductTraitSelectionValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductDeclarationSelectionChoice** */
        value?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ProductTraitStringValue */
    export type UpdateProductTraitStringValueMutationInput = {
        /** Expects node: **ProductTraitStringValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        value?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProductTraitUnitValue */
    export type UpdateProductTraitUnitValueMutationInput = {
        /** Expects node: **ProductTraitUnitValue** */
        id: Scalars['ID'];
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Unit** */
        value?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateProjectListProjectVersion = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateProjectListProjectVersionCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateProjectListProjectVersionUpdateInput>>>;
    };

    /** Creates a new instances of: ProjectVersion */
    export type UpdateProjectListProjectVersionCreateInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProjectListProjectVersionUpdateInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProjectVersion** */
        id: Scalars['ID'];
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: Project */
    export type UpdateProjectMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Project** */
        documentationProject?: InputMaybe<Scalars['ID']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        franchiseRestricted?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Project** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisation?: InputMaybe<Scalars['ID']>;
        pinnedVersion?: InputMaybe<UpdateProjectProjectVersionSwitchInput>;
        platformKey?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        remoteThumbnailURL?: InputMaybe<Scalars['String']>;
        /** Expects node: **FileUpload** */
        thumbnailFile?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        versions?: InputMaybe<UpdateProjectListProjectVersion>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProjectVersion
     */
    export type UpdateProjectProjectVersionSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateProjectSwitchCreateProjectVersionInput>;
        /** Expects node: **ProjectVersion** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateProjectSwitchUpdateProjectVersionInput>;
    };

    /** Creates a new instances of: ProjectVersion */
    export type UpdateProjectSwitchCreateProjectVersionInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    export type UpdateProjectSwitchUpdateProjectVersionInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ProjectVersion */
    export type UpdateProjectVersionMutationInput = {
        changelogSummary?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        enabled?: InputMaybe<Scalars['Boolean']>;
        enabledFrom?: InputMaybe<Scalars['Date']>;
        /** Expects node: **FileUpload** */
        fileUpload?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProjectVersion** */
        id: Scalars['ID'];
        /** Expects node: **Project** */
        project?: InputMaybe<Scalars['ID']>;
        remoteFileURL?: InputMaybe<Scalars['String']>;
        requirements?: InputMaybe<Scalars['String']>;
        versionNumber?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: RoleFlag */
    export type UpdateRoleFlagMutationInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **RoleFlag** */
        id: Scalars['ID'];
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateRoleListRoleFlag = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateRoleListRoleFlagCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: RoleFlag */
    export type UpdateRoleListRoleFlagCreateInput = {
        description?: InputMaybe<Scalars['String']>;
        tag: Scalars['String'];
    };

    /** Updates an instance of: Role */
    export type UpdateRoleMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        flags?: InputMaybe<UpdateRoleListRoleFlag>;
        /** Expects node: **Role** */
        id: Scalars['ID'];
    };

    /** Creates a new instances of: SampleConverterTemplate */
    export type UpdateSampleConverterListSampleConverterTemplateInput = {
        data?: InputMaybe<Scalars['JSON']>;
        measurement: UpdateSampleConverterListSampleConverterTemplateMeasurementSwitchInput;
        tag: Scalars['String'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateSampleConverterListSampleConverterTemplateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementInput>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateSampleConverterListSampleConverterTemplateSwitchUpdateMeasurementSwitchUpdateUnitInput>;
    };

    /** Updates an instance of: SampleConverter */
    export type UpdateSampleConverterMutationInput = {
        /** Expects node: **SampleConverter** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances of SampleConverterTemplate. New instances will be appended to the edge */
        templates?: InputMaybe<Array<InputMaybe<UpdateSampleConverterListSampleConverterTemplateInput>>>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateSampleConverterTemplateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterTemplateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateSampleConverterTemplateSwitchUpdateMeasurementInput>;
    };

    /** Updates an instance of: SampleConverterTemplate */
    export type UpdateSampleConverterTemplateMutationInput = {
        data?: InputMaybe<Scalars['JSON']>;
        /** Expects node: **SampleConverterTemplate** */
        id: Scalars['ID'];
        measurement?: InputMaybe<UpdateSampleConverterTemplateMeasurementSwitchInput>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateSampleConverterTemplateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSampleConverterTemplateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterTemplateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateSampleConverterTemplateSwitchUpdateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateSampleConverterTemplateSwitchUpdateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateSampleConverterTemplateSwitchUpdateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSampleConverterTemplateSwitchUpdateMeasurementSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSampleConverterTemplateSwitchUpdateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSampleConverterTemplateSwitchUpdateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateSampleConverterTemplateSwitchUpdateMeasurementSwitchUpdateUnitInput>;
    };

    /** Updates an instance of: Sample */
    export type UpdateSampleMutationInput = {
        /** Expects node: **Sample** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateSampleSetListSample = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSampleSetListSampleCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateSampleSetListSampleUpdateInput>>>;
    };

    /** Creates a new instances of: Sample */
    export type UpdateSampleSetListSampleCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    export type UpdateSampleSetListSampleUpdateInput = {
        /** Expects node: **Sample** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: SampleSet */
    export type UpdateSampleSetMutationInput = {
        /** Expects node: **SampleSet** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        samples?: InputMaybe<UpdateSampleSetListSample>;
    };

    /** Updates an instance of: SampleSource */
    export type UpdateSampleSourceMutationInput = {
        /** Expects node: **SampleSource** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSanitiserListProductTraitDeclaration = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTraitDeclaration */
    export type UpdateSanitiserListProductTraitDeclarationCreateInput = {
        /** Creates new instances of ProductDeclarationFlagField. New instances will be appended to the edge */
        flagFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationFlagFieldInput>>
        >;
        /** Creates new instances of ProductDeclarationFloatField. New instances will be appended to the edge */
        floatFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationFloatFieldInput>>
        >;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProductDeclarationIntegerField. New instances will be appended to the edge */
        integerFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationIntegerFieldInput>>
        >;
        isCategory?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationMeasurementField. New instances will be appended to the edge */
        measurementFields?: InputMaybe<
            Array<
                InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationMeasurementFieldInput>
            >
        >;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ProductTraitDeclaration** */
        parent?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of ProductDeclarationSelectionField. New instances will be appended to the edge */
        selectionFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldInput>>
        >;
        /** Creates new instances of ProductDeclarationStringField. New instances will be appended to the edge */
        stringFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationStringFieldInput>>
        >;
        tag?: InputMaybe<Scalars['String']>;
        /** Creates new instances of ProductDeclarationUnitField. New instances will be appended to the edge */
        unitFields?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationUnitFieldInput>>
        >;
    };

    /** Creates a new instances of: ProductDeclarationFlagField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationFlagFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationFloatField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationFloatFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Float']>;
        minValue?: InputMaybe<Scalars['Float']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationIntegerField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationIntegerFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        maxValue?: InputMaybe<Scalars['Int']>;
        minValue?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationMeasurementField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationMeasurementFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of ProductDeclarationSelectionChoice. New instances will be appended to the edge */
        options?: InputMaybe<
            Array<
                InputMaybe<UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput>
            >
        >;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationSelectionChoice */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationSelectionFieldListProductDeclarationSelectionChoiceInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name: Scalars['String'];
            priority?: InputMaybe<Scalars['Int']>;
        };

    /** Creates a new instances of: ProductDeclarationStringField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationStringFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        isHtml?: InputMaybe<Scalars['Boolean']>;
        maxLength?: InputMaybe<Scalars['Int']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: ProductDeclarationUnitField */
    export type UpdateSanitiserListProductTraitDeclarationCreateListProductDeclarationUnitFieldInput = {
        /** Expects node: **ProductDeclarationFieldGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hint?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        optional?: InputMaybe<Scalars['Boolean']>;
        searchAsDistinct?: InputMaybe<Scalars['Boolean']>;
        searchOrder?: InputMaybe<Scalars['Int']>;
        tag?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSanitiserListTestCriteria = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSanitiserListTestCriteriaCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: TestCriteria */
    export type UpdateSanitiserListTestCriteriaCreateInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        measurementType: UpdateSanitiserListTestCriteriaCreateMeasurementSwitchInput;
        name: Scalars['String'];
        targetValue: Scalars['Float'];
        toHighValueProblem?: InputMaybe<UpdateSanitiserListTestCriteriaCreateTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<UpdateSanitiserListTestCriteriaCreateTestProblemSwitchInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateSanitiserListTestCriteriaCreateMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput>>
        >;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<
                InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>
            >
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput =
        {
            /** Expects node: **Measurement** */
            measurementType: Scalars['ID'];
            primary?: InputMaybe<Scalars['Boolean']>;
            value: Scalars['Float'];
        };

    /** Creates a new instances of: Unit */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput =
        {
            /** Expects node: **Unit** */
            linearForm?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type UpdateSanitiserListTestCriteriaCreateTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSanitiserListTestCriteriaCreateSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Sanitiser */
    export type UpdateSanitiserMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        criteria?: InputMaybe<UpdateSanitiserListTestCriteria>;
        /** Expects node: **Sanitiser** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        relatedDeclarations?: InputMaybe<UpdateSanitiserListProductTraitDeclaration>;
        reportName?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateServiceJobEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateServiceJobEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateServiceJobEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateServiceJobEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateServiceJobEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateServiceJobEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateServiceJobEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateServiceJobEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateServiceJobEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateServiceJobEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateServiceJobEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateServiceJobEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateServiceJobEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateServiceJobEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateServiceJobEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateServiceJobEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateServiceJobEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateServiceJobEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateServiceJobEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        address: Scalars['ID'];
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolSwitchCreateSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateServiceJobEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateServiceJobEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        /** Expects node: **Address** */
        address: Scalars['ID'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateServiceJobEntityListSiteListPool>;
    };

    export type UpdateServiceJobEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateServiceJobEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateServiceJobEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateServiceJobEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateServiceJobEntityListSiteListPoolCreateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateServiceJobEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateServiceJobEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateServiceJobEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateServiceJobEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ServiceJobGroup */
    export type UpdateServiceJobGroupMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        id: Scalars['ID'];
        /** Expects node: **ServiceJobGroup** */
        parent?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateServiceJobListServiceJobNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobListServiceJobNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobListServiceJobNoteUpdateInput>>>;
    };

    /** Creates a new instances of: ServiceJobNote */
    export type UpdateServiceJobListServiceJobNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListServiceJobNoteUpdateInput = {
        /** Expects node: **ServiceJobNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrder = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateInput>>>;
    };

    /** Creates a new instances of: WorkOrder */
    export type UpdateServiceJobListWorkOrderCreateInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        appointment?: InputMaybe<WorkOrderBookingCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        autoInflateTemplate?: InputMaybe<Scalars['Boolean']>;
        checkedInAt?: InputMaybe<Scalars['Date']>;
        checkedOutAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        dueRangeEnd?: InputMaybe<Scalars['Date']>;
        dueRangeStart?: InputMaybe<Scalars['Date']>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobListWorkOrderCreateListFileUpload>;
        /** Expects node: **WorkOrder** */
        followUpFrom?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of JobTodo. New instances will be appended to the edge */
        jobs?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderCreateListJobTodoInput>>>;
        /** Creates new instances of WorkOrderNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderCreateListWorkOrderNoteInput>>>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresAppointment?: InputMaybe<Scalars['Boolean']>;
        requiresAssignedTo?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresPreferredContact?: InputMaybe<Scalars['Boolean']>;
        requiresTestReport?: InputMaybe<Scalars['Boolean']>;
        requiresWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        stageType?: InputMaybe<ServiceJobStageType>;
        /** Expects node: **WorkOrderTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        todoTemplates?: InputMaybe<Array<Scalars['ID']>>;
        useDefaultTemplate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        workFor?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateServiceJobListWorkOrderCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: JobTodo */
    export type UpdateServiceJobListWorkOrderCreateListJobTodoInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderCreateListJobTodoListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateServiceJobListWorkOrderCreateListJobTodoListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: WorkOrderNote */
    export type UpdateServiceJobListWorkOrderCreateListWorkOrderNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        checkedInAt?: InputMaybe<Scalars['Date']>;
        checkedOutAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        dueRangeEnd?: InputMaybe<Scalars['Date']>;
        dueRangeStart?: InputMaybe<Scalars['Date']>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobListWorkOrderUpdateListFileUpload>;
        /** Expects node: **WorkOrder** */
        followUpFrom?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        jobs?: InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodo>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobListWorkOrderUpdateListWorkOrderNote>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresAppointment?: InputMaybe<Scalars['Boolean']>;
        requiresAssignedTo?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresPreferredContact?: InputMaybe<Scalars['Boolean']>;
        requiresTestReport?: InputMaybe<Scalars['Boolean']>;
        requiresWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        stageType?: InputMaybe<ServiceJobStageType>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListJobTodo = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodo */
    export type UpdateServiceJobListWorkOrderUpdateListJobTodoCreateInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoCreateListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateServiceJobListWorkOrderUpdateListJobTodoCreateListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodo** */
        id: Scalars['ID'];
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItem>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItem = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItemUpdateInput>>
        >;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItemCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListJobTodoUpdateListJobTodoItemUpdateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListWorkOrderNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListWorkOrderNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobListWorkOrderUpdateListWorkOrderNoteUpdateInput>>>;
    };

    /** Creates a new instances of: WorkOrderNote */
    export type UpdateServiceJobListWorkOrderUpdateListWorkOrderNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateServiceJobListWorkOrderUpdateListWorkOrderNoteUpdateInput = {
        /** Expects node: **WorkOrderNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: ServiceJob */
    export type UpdateServiceJobMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        customer?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        dueDate?: InputMaybe<Scalars['Date']>;
        entity?: InputMaybe<UpdateServiceJobEntityInput>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateServiceJobListFileUpload>;
        /** Expects node: **ServiceJob** */
        followedFrom?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJob** */
        id: Scalars['ID'];
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnum>;
        isSimpleMode?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateServiceJobListServiceJobNote>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        workOrders?: InputMaybe<UpdateServiceJobListWorkOrder>;
    };

    /** Updates an instance of: ServiceJobNote */
    export type UpdateServiceJobNoteMutationInput = {
        /** Expects node: **ServiceJobNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **ServiceJob** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: ServiceJobStage */
    export type UpdateServiceJobStageMutationInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        id: Scalars['ID'];
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobTemplateListServiceJobStageCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateServiceJobTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateServiceJobTemplateListWorkOrderTemplateAssoc = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput>>>;
    };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        workOrderTemplate: UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateWorkOrderTemplateSwitchInput;
    };

    /** Creates a new instances of: WorkOrderTemplate */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListServiceJobStage>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of JobTodoTemplateAssoc. New instances will be appended to the edge */
        jobTodoTemplateAssocs?: InputMaybe<
            Array<
                InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput>
            >
        >;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocInput =
        {
            isSuggestion?: InputMaybe<Scalars['Boolean']>;
            jobTodoTemplate: UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput;
            priority?: InputMaybe<Scalars['Int']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocJobTodoTemplateSwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput>;
            /** Expects node: **JobTodoTemplate** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: JobTodoTemplate */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateInput =
        {
            description?: InputMaybe<Scalars['String']>;
            /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
            items?: InputMaybe<
                Array<
                    InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
                >
            >;
            maxTimeEstimate?: InputMaybe<Scalars['Int']>;
            minTimeEstimate?: InputMaybe<Scalars['Int']>;
            recommendedPrice?: InputMaybe<Scalars['Int']>;
            /** Time Estimate in minutes */
            timeEstimate?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListJobTodoTemplateAssocSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListServiceJobStage =
        {
            /** Assigns selected instances to list */
            assign?: InputMaybe<Array<Scalars['ID']>>;
            /** Creates and assigns new instances to list */
            create?: InputMaybe<
                Array<
                    InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput>
                >
            >;
        };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateListServiceJobStageCreateInput =
        {
            color?: InputMaybe<Scalars['String']>;
            hidden?: InputMaybe<Scalars['Boolean']>;
            isDefault?: InputMaybe<Scalars['Boolean']>;
            priority?: InputMaybe<Scalars['Int']>;
            title: Scalars['String'];
            type?: InputMaybe<ServiceJobStageType>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: WorkOrderTemplate
     */
    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateWorkOrderTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateSwitchCreateWorkOrderTemplateInput>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput = {
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /** Updates an instance of: ServiceJobTemplate */
    export type UpdateServiceJobTemplateMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobTemplate** */
        id: Scalars['ID'];
        isPublished?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        workOrderTemplateAssocs?: InputMaybe<UpdateServiceJobTemplateListWorkOrderTemplateAssoc>;
    };

    /** Updates an instance of: SettingStore */
    export type UpdateSettingStoreMutationInput = {
        /** Expects node: **SettingStore** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolCreateListPoolCalibrationInput>>>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateSiteListPoolCreateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateSiteListPoolCreateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateSiteListPoolCreateListPoolNoteInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolUpdateListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        installedByStaff?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateSiteListPoolUpdateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateSiteListPoolUpdateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        brand?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateSiteListSiteNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: Site */
    export type UpdateSiteMutationInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateSiteAddressSwitchInput>;
        /** Expects node: **Site** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateSiteListPool>;
    };

    /** Updates an instance of: SiteNote */
    export type UpdateSiteNoteMutationInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **Site** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        entity: Scalars['ID'];
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffListStaffTeamCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    /** Updates an instance of: Staff */
    export type UpdateStaffMutationInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Staff** */
        id: Scalars['ID'];
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateStaffListStaffTeam>;
        user?: InputMaybe<UpdateStaffUserInput>;
    };

    /** Updates an instance of: StaffRegistration */
    export type UpdateStaffRegistrationMutationInput = {
        email?: InputMaybe<Scalars['String']>;
        /** Expects node: **StaffRegistration** */
        id: Scalars['ID'];
        oldPassword?: InputMaybe<Scalars['String']>;
        password?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<UpdateStaffRegistrationStaffInput>;
    };

    export type UpdateStaffRegistrationStaffInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        managedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Role** */
        role?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        teams?: InputMaybe<UpdateStaffRegistrationStaffListStaffTeam>;
        user?: InputMaybe<UpdateStaffRegistrationStaffUserInput>;
    };

    export type UpdateStaffRegistrationStaffListStaffTeam = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffListStaffTeamCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: StaffTeam */
    export type UpdateStaffRegistrationStaffListStaffTeamCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<Scalars['String']>;
        title: Scalars['String'];
    };

    export type UpdateStaffRegistrationStaffUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListHeaterRecommendationInput>>
        >;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffRegistrationStaffUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateStaffRegistrationStaffUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateStaffRegistrationStaffUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateStaffRegistrationStaffUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateStaffRegistrationStaffUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateStaffRegistrationStaffUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffRegistrationStaffUserEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateStaffRegistrationStaffUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateStaffRegistrationStaffUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPool>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListPoolNoteInput>>
        >;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateInput>>
        >;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>
        >;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffRegistrationStaffUserEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffRegistrationStaffUserEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffRegistrationStaffUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffRegistrationStaffUserInput = {
        entity?: InputMaybe<UpdateStaffRegistrationStaffUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffTeamListStaff = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Updates an instance of: StaffTeam */
    export type UpdateStaffTeamMutationInput = {
        color?: InputMaybe<Scalars['String']>;
        /** Expects node: **StaffTeam** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        staff?: InputMaybe<UpdateStaffTeamListStaff>;
        tag?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffUserEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateStaffUserEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateStaffUserEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffUserEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffUserEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateStaffUserEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateStaffUserEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffUserEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffUserEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffUserEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        site: UpdateStaffUserEntityListPoolSiteSwitchInput;
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateStaffUserEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffUserEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffUserEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffUserEntityListPoolListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffUserEntityListPoolListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffUserEntityListPoolListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput>>>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFlagValueInput = {
        /** Expects node: **ProductDeclarationFlagField** */
        field: Scalars['ID'];
        value: Scalars['Boolean'];
    };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitFloatValueInput = {
        /** Expects node: **ProductDeclarationFloatField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitIntegerValueInput = {
        /** Expects node: **ProductDeclarationIntegerField** */
        field: Scalars['ID'];
        value: Scalars['Float'];
    };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitStringValueInput = {
        /** Expects node: **ProductDeclarationStringField** */
        field: Scalars['ID'];
        value: Scalars['String'];
    };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffUserEntityListPoolListItemCreateProductListProductTraitListProductTraitUnitValueInput = {
        /** Expects node: **ProductDeclarationUnitField** */
        field: Scalars['ID'];
        /** Expects node: **Unit** */
        value: Scalars['ID'];
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListPoolListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListPoolListItemCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffUserEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateStaffUserEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffUserEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Site
     */
    export type UpdateStaffUserEntityListPoolSiteSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListPoolSwitchCreateSiteInput>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteInput>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Site */
    export type UpdateStaffUserEntityListPoolSwitchCreateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateStaffUserEntityListPoolSwitchCreateSiteAddressInput;
        name: Scalars['String'];
        /** Creates new instances of SiteNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffUserEntityListPoolSwitchCreateSiteListSiteNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput>;
    };

    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address?: InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteAddressSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote>;
    };

    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListPoolSwitchUpdateSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateStaffUserEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateStaffUserEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateStaffUserEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateStaffUserEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffUserEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateStaffUserEntityListSiteListPool>;
    };

    export type UpdateStaffUserEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateStaffUserEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateStaffUserEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffUserEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListSiteListPoolCreateListItemCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffUserEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffUserEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductInput = {
        brand?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListItemCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateStaffUserEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateStaffUserEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateStaffUserEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateStaffUserInput = {
        entity?: InputMaybe<UpdateStaffUserEntityInput>;
        firstName?: InputMaybe<Scalars['String']>;
        lastName?: InputMaybe<Scalars['String']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: StaffValueStore */
    export type UpdateStaffValueStoreMutationInput = {
        /** Expects node: **StaffValueStore** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Measurement
     */
    export type UpdateTestCriteriaMeasurementSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchCreateMeasurementInput>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestCriteriaSwitchUpdateMeasurementInput>;
    };

    /** Updates an instance of: TestCriteria */
    export type UpdateTestCriteriaMutationInput = {
        actualHigherThreshold?: InputMaybe<Scalars['Float']>;
        actualLowerThreshold?: InputMaybe<Scalars['Float']>;
        actualTarget?: InputMaybe<Scalars['Float']>;
        description?: InputMaybe<Scalars['String']>;
        higherThreshold?: InputMaybe<Scalars['Float']>;
        /** Expects node: **TestCriteria** */
        id: Scalars['ID'];
        lowerThreshold?: InputMaybe<Scalars['Float']>;
        measurementType?: InputMaybe<UpdateTestCriteriaMeasurementSwitchInput>;
        name?: InputMaybe<Scalars['String']>;
        targetValue?: InputMaybe<Scalars['Float']>;
        toHighValueProblem?: InputMaybe<UpdateTestCriteriaTestProblemSwitchInput>;
        toLowValueProblem?: InputMaybe<UpdateTestCriteriaTestProblemSwitchInput>;
    };

    /** Creates a new instances of: Measurement */
    export type UpdateTestCriteriaSwitchCreateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateTestCriteriaSwitchCreateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestCriteriaSwitchCreateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestCriteriaSwitchCreateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchCreateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: TestProblem */
    export type UpdateTestCriteriaSwitchCreateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description: Scalars['String'];
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateTestCriteriaSwitchCreateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateInput>>>;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput>>
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateTestCriteriaSwitchUpdateMeasurementInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **Measurement** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        solutionCoefficient?: InputMaybe<Scalars['Float']>;
        tagIdentifier?: InputMaybe<Scalars['String']>;
        unit?: InputMaybe<UpdateTestCriteriaSwitchUpdateMeasurementUnitSwitchInput>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestCriteriaSwitchUpdateMeasurementSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateTestCriteriaSwitchUpdateMeasurementSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestCriteriaSwitchUpdateMeasurementUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchUpdateMeasurementSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestCriteriaSwitchUpdateMeasurementSwitchUpdateUnitInput>;
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateInput>>>;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<
            Array<InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateListTreatmentAffectInput>>
        >;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        affects?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffect>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatment** */
        id: Scalars['ID'];
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffect = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffectCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffectUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffectCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateListTreatmentAffectUpdateInput = {
        /** Expects node: **TreatmentAffect** */
        id: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemListTestTreatmentUpdateSwitchUpdateUnitInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: TestProblem
     */
    export type UpdateTestCriteriaTestProblemSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestCriteriaSwitchCreateTestProblemInput>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestCriteriaSwitchUpdateTestProblemInput>;
    };

    export type UpdateTestProblemListTestTreatment = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestProblemListTestTreatmentCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestProblemListTestTreatmentUpdateInput>>>;
    };

    /** Creates a new instances of: TestTreatment */
    export type UpdateTestProblemListTestTreatmentCreateInput = {
        /** Creates new instances of TreatmentAffect. New instances will be appended to the edge */
        affects?: InputMaybe<Array<InputMaybe<UpdateTestProblemListTestTreatmentCreateListTreatmentAffectInput>>>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestProblemListTestTreatmentCreateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestProblemListTestTreatmentCreateListTreatmentAffectInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestProblemListTestTreatmentCreateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestProblemListTestTreatmentCreateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestProblemListTestTreatmentCreateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type UpdateTestProblemListTestTreatmentUpdateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        affects?: InputMaybe<UpdateTestProblemListTestTreatmentUpdateListTreatmentAffect>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatment** */
        id: Scalars['ID'];
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestProblemListTestTreatmentUpdateUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateTestProblemListTestTreatmentUpdateListTreatmentAffect = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestProblemListTestTreatmentUpdateListTreatmentAffectCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestProblemListTestTreatmentUpdateListTreatmentAffectUpdateInput>>>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestProblemListTestTreatmentUpdateListTreatmentAffectCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    export type UpdateTestProblemListTestTreatmentUpdateListTreatmentAffectUpdateInput = {
        /** Expects node: **TreatmentAffect** */
        id: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestProblemListTestTreatmentUpdateSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateTestProblemListTestTreatmentUpdateSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestProblemListTestTreatmentUpdateUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestProblemListTestTreatmentUpdateSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestProblemListTestTreatmentUpdateSwitchUpdateUnitInput>;
    };

    /** Updates an instance of: TestProblem */
    export type UpdateTestProblemMutationInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **TestProblem** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        treatments?: InputMaybe<UpdateTestProblemListTestTreatment>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Updates an instance of: TestReportAttachment */
    export type UpdateTestReportAttachmentMutationInput = {
        comment?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReportAttachment** */
        id: Scalars['ID'];
        smallView?: InputMaybe<Scalars['Boolean']>;
    };

    export type UpdateTestReportListManualProblem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestReportListManualProblemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ManualProblem */
    export type UpdateTestReportListManualProblemCreateInput = {
        createdAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType: Scalars['ID'];
        solution?: InputMaybe<Scalars['String']>;
    };

    export type UpdateTestReportListTestReportAttachment = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestReportListTestReportAttachmentCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestReportListTestReportAttachmentUpdateInput>>>;
    };

    /** Creates a new instances of: TestReportAttachment */
    export type UpdateTestReportListTestReportAttachmentCreateInput = {
        comment?: InputMaybe<Scalars['String']>;
        /** Expects node: **FileUpload** */
        file: Scalars['ID'];
        hidden?: InputMaybe<Scalars['Boolean']>;
        smallView?: InputMaybe<Scalars['Boolean']>;
    };

    export type UpdateTestReportListTestReportAttachmentUpdateInput = {
        comment?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestReportAttachment** */
        id: Scalars['ID'];
        smallView?: InputMaybe<Scalars['Boolean']>;
    };

    /** Updates an instance of: TestReport */
    export type UpdateTestReportMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        attachments?: InputMaybe<UpdateTestReportListTestReportAttachment>;
        /** Expects node: **TestReport** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        manualProblems?: InputMaybe<UpdateTestReportListManualProblem>;
        note?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        reportBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        reportTo?: InputMaybe<Scalars['ID']>;
        sampleSet?: InputMaybe<UpdateTestReportSampleSetSwitchInput>;
        submissionMetadata?: InputMaybe<TestReportSubmissionNodeUpdateInput>;
        /** Expects node: **WorkOrder** */
        workOrder?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: SampleSet
     */
    export type UpdateTestReportSampleSetSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestReportSwitchCreateSampleSetInput>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Deletes previous instance and replaces it with new created instance */
        replace?: InputMaybe<UpdateTestReportSwitchCreateSampleSetInput>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestReportSwitchUpdateSampleSetInput>;
    };

    /** Updates an instance of: TestReportSolution */
    export type UpdateTestReportSolutionMutationInput = {
        amount?: InputMaybe<Scalars['Float']>;
        comment?: InputMaybe<Scalars['String']>;
        /** Expects node: **TestReportSolution** */
        id: Scalars['ID'];
        instructionOverride?: InputMaybe<Scalars['String']>;
        manual?: InputMaybe<Scalars['Boolean']>;
    };

    /** Creates a new instances of: SampleSet */
    export type UpdateTestReportSwitchCreateSampleSetInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        samples?: InputMaybe<UpdateTestReportSwitchCreateSampleSetListSample>;
    };

    export type UpdateTestReportSwitchCreateSampleSetListSample = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestReportSwitchCreateSampleSetListSampleCreateInput>>>;
    };

    /** Creates a new instances of: Sample */
    export type UpdateTestReportSwitchCreateSampleSetListSampleCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    export type UpdateTestReportSwitchUpdateSampleSetInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        samples?: InputMaybe<UpdateTestReportSwitchUpdateSampleSetListSample>;
    };

    export type UpdateTestReportSwitchUpdateSampleSetListSample = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestReportSwitchUpdateSampleSetListSampleCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestReportSwitchUpdateSampleSetListSampleUpdateInput>>>;
    };

    /** Creates a new instances of: Sample */
    export type UpdateTestReportSwitchUpdateSampleSetListSampleCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        /** Expects node: **SampleSource** */
        source?: InputMaybe<Scalars['ID']>;
        value: Scalars['Float'];
    };

    export type UpdateTestReportSwitchUpdateSampleSetListSampleUpdateInput = {
        /** Expects node: **Sample** */
        id: Scalars['ID'];
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: TestTreatmentFranchisePriority */
    export type UpdateTestTreatmentFranchisePriorityMutationInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentFranchisePriority** */
        id: Scalars['ID'];
        priority?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatment** */
        testTreatment?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: TestTreatmentGroup */
    export type UpdateTestTreatmentGroupMutationInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **TestTreatmentGroup** */
        id: Scalars['ID'];
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateTestTreatmentListTreatmentAffect = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateTestTreatmentListTreatmentAffectCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateTestTreatmentListTreatmentAffectUpdateInput>>>;
    };

    /** Creates a new instances of: TreatmentAffect */
    export type UpdateTestTreatmentListTreatmentAffectCreateInput = {
        /** Expects node: **Measurement** */
        measurementType: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value: Scalars['Float'];
    };

    export type UpdateTestTreatmentListTreatmentAffectUpdateInput = {
        /** Expects node: **TreatmentAffect** */
        id: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: TestTreatment */
    export type UpdateTestTreatmentMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        affects?: InputMaybe<UpdateTestTreatmentListTreatmentAffect>;
        /** Expects node: **ConsumableProduct** */
        consumableProduct?: InputMaybe<Scalars['ID']>;
        createdAt?: InputMaybe<Scalars['Date']>;
        decimalPlaces?: InputMaybe<Scalars['Int']>;
        /** Expects node: **TestTreatmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatment** */
        id: Scalars['ID'];
        instruction?: InputMaybe<Scalars['String']>;
        isRadical?: InputMaybe<Scalars['Boolean']>;
        name?: InputMaybe<Scalars['String']>;
        priority?: InputMaybe<Scalars['Int']>;
        unit?: InputMaybe<UpdateTestTreatmentUnitSwitchInput>;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    /** Creates a new instances of: Unit */
    export type UpdateTestTreatmentSwitchCreateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    export type UpdateTestTreatmentSwitchUpdateUnitInput = {
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Unit
     */
    export type UpdateTestTreatmentUnitSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateTestTreatmentSwitchCreateUnitInput>;
        /** Expects node: **Unit** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateTestTreatmentSwitchUpdateUnitInput>;
    };

    /** Updates an instance of: TreatmentAffect */
    export type UpdateTreatmentAffectMutationInput = {
        /** Expects node: **TreatmentAffect** */
        id: Scalars['ID'];
        primary?: InputMaybe<Scalars['Boolean']>;
        value?: InputMaybe<Scalars['Float']>;
    };

    /** Updates an instance of: UnitConversion */
    export type UpdateUnitConversionMutationInput = {
        /** Expects node: **Unit** */
        destination?: InputMaybe<Scalars['ID']>;
        factors?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
        /** Expects node: **UnitConversion** */
        id: Scalars['ID'];
        /** Expects node: **UnitConverter** */
        method?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Unit** */
        source?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: Unit */
    export type UpdateUnitMutationInput = {
        /** Expects node: **Unit** */
        id: Scalars['ID'];
        /** Expects node: **Unit** */
        linearForm?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: VendAPI */
    export type UpdateVendAPIMutationInput = {
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
        key?: InputMaybe<Scalars['String']>;
        shopUri?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateWarrantyClaimAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateWarrantyClaimSwitchUpdateAddressInput>;
    };

    /** Updates an instance of: WarrantyClaimCase */
    export type UpdateWarrantyClaimCaseMutationInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        externalRMA?: InputMaybe<Scalars['String']>;
        externalSO?: InputMaybe<Scalars['String']>;
        externalSRN?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WarrantyClaimCase** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityInput = {
        active?: InputMaybe<Scalars['Boolean']>;
        /** Creates new instances of Address. New instances will be appended to the edge */
        addresses?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListAddressInput>>>;
        /** Creates new instances of Contact. New instances will be appended to the edge */
        contacts?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListContactInput>>>;
        /** Creates new instances of CustomContract. New instances will be appended to the edge */
        customContracts?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListCustomContractInput>>>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Creates new instances of HeaterRecommendation. New instances will be appended to the edge */
        heaterRecommendations?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListHeaterRecommendationInput>>>;
        /** Creates new instances of Pool. New instances will be appended to the edge */
        pools?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListPoolInput>>>;
        /** Creates new instances of Site. New instances will be appended to the edge */
        sites?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteInput>>>;
    };

    /** Creates a new instances of: Address */
    export type UpdateWarrantyClaimEntityListAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Contact */
    export type UpdateWarrantyClaimEntityListContactInput = {
        data: Scalars['String'];
        isPrimary?: InputMaybe<Scalars['Boolean']>;
        label?: InputMaybe<Scalars['String']>;
        /** Expects node: **ContactType** */
        type: Scalars['ID'];
    };

    /** Creates a new instances of: CustomContract */
    export type UpdateWarrantyClaimEntityListCustomContractInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        contractNumber?: InputMaybe<Scalars['String']>;
        endDate: Scalars['Date'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWarrantyClaimEntityListCustomContractListFileUpload>;
        frequency?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        rate?: InputMaybe<Scalars['Int']>;
        startDate: Scalars['Date'];
        status?: InputMaybe<CustomContractStatusEnum>;
        term?: InputMaybe<Scalars['String']>;
        terminationDate?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateWarrantyClaimEntityListCustomContractListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: HeaterRecommendation */
    export type UpdateWarrantyClaimEntityListHeaterRecommendationInput = {
        expiryDate?: InputMaybe<Scalars['Date']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        newCustomer?: InputMaybe<HeaterRecommendationCustomerCreateCommon>;
        note?: InputMaybe<Scalars['String']>;
        payload?: InputMaybe<Scalars['JSON']>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        product?: InputMaybe<Scalars['ID']>;
        recNumber?: InputMaybe<Scalars['String']>;
        status?: InputMaybe<HeaterRecommendationStatusEnum>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateWarrantyClaimEntityListPoolInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListPoolListPoolCalibrationInput>>>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWarrantyClaimEntityListPoolListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateWarrantyClaimEntityListPoolListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **Site** */
        site: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateWarrantyClaimEntityListPoolListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateWarrantyClaimEntityListPoolListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateWarrantyClaimEntityListPoolListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput>>
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitInput = {
        /** Expects node: **ProductTraitDeclaration** */
        declaration: Scalars['ID'];
        /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
        flagValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
            >
        >;
        /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
        floatValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
            >
        >;
        /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
        integerValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
            >
        >;
        /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
        measurementValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
            >
        >;
        /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
        selectionValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
            >
        >;
        /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
        stringValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
            >
        >;
        /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
        unitValues?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
            >
        >;
    };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductProductCompanySwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Brand */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandInput = {
        company?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
        identification?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListPoolListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput = {
        description?: InputMaybe<Scalars['String']>;
        name?: InputMaybe<Scalars['String']>;
        /** Expects node: **ProductCompany** */
        parentCompany?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateWarrantyClaimEntityListPoolListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateWarrantyClaimEntityListPoolListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListPoolListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListPoolListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateWarrantyClaimEntityListPoolListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListPoolListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Address
     */
    export type UpdateWarrantyClaimEntityListSiteAddressSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListSiteSwitchCreateAddressInput>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateWarrantyClaimEntityListSiteSwitchUpdateAddressInput>;
    };

    /** Creates a new instances of: Site */
    export type UpdateWarrantyClaimEntityListSiteInput = {
        accessKeyLocation?: InputMaybe<Scalars['String']>;
        address: UpdateWarrantyClaimEntityListSiteAddressSwitchInput;
        name: Scalars['String'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateWarrantyClaimEntityListSiteListSiteNote>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        pools?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPool>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPool = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateInput>>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateInput>>>;
    };

    /** Creates a new instances of: Pool */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListFileUpload>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItem>;
        name: Scalars['String'];
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances of PoolNote. New instances will be appended to the edge */
        notes?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListPoolNoteInput>>>;
        /** Expects node: **Sanitiser** */
        sanitiser: Scalars['ID'];
        /** Expects node: **PoolType** */
        type: Scalars['ID'];
        volume: Scalars['Float'];
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateInput>>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateWarrantyClaimEntityListSiteListPoolCreateListPoolNoteInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateInput = {
        /** Creates new instances of PoolCalibration. New instances will be appended to the edge */
        calibrations?: InputMaybe<
            Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolCalibrationInput>>
        >;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListFileUpload>;
        /** Expects node: **Pool** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItem>;
        name?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNote>;
        /** Expects node: **Sanitiser** */
        sanitiser?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolType** */
        type?: InputMaybe<Scalars['ID']>;
        volume?: InputMaybe<Scalars['Float']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItem = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: Item */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateInput = {
        installedAt?: InputMaybe<Scalars['Date']>;
        label?: InputMaybe<Scalars['String']>;
        note?: InputMaybe<Scalars['String']>;
        product: UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateProductSwitchInput;
        serial?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Product
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateProductSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: Brand
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: Product */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductInput = {
        brand?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductBrandSwitchInput>;
        createdAt?: InputMaybe<Scalars['Date']>;
        customSku?: InputMaybe<Scalars['String']>;
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        documents?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument>;
        familyCode?: InputMaybe<Scalars['String']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<Scalars['String']>;
        isGrouping?: InputMaybe<Scalars['Boolean']>;
        isOneOff?: InputMaybe<Scalars['Boolean']>;
        name: Scalars['String'];
        /** Expects node: **OrganisationType** */
        organisationType?: InputMaybe<Scalars['ID']>;
        sku?: InputMaybe<Scalars['String']>;
        supplier?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput>;
        /** Creates new instances of ProductTrait. New instances will be appended to the edge */
        traits?: InputMaybe<
            Array<
                InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput>
            >
        >;
        updatedAt?: InputMaybe<Scalars['Date']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductDocument = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ProductTrait */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitInput =
        {
            /** Expects node: **ProductTraitDeclaration** */
            declaration: Scalars['ID'];
            /** Creates new instances of ProductTraitFlagValue. New instances will be appended to the edge */
            flagValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput>
                >
            >;
            /** Creates new instances of ProductTraitFloatValue. New instances will be appended to the edge */
            floatValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput>
                >
            >;
            /** Creates new instances of ProductTraitIntegerValue. New instances will be appended to the edge */
            integerValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput>
                >
            >;
            /** Creates new instances of ProductTraitMeasurementValue. New instances will be appended to the edge */
            measurementValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput>
                >
            >;
            /** Creates new instances of ProductTraitSelectionValue. New instances will be appended to the edge */
            selectionValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput>
                >
            >;
            /** Creates new instances of ProductTraitStringValue. New instances will be appended to the edge */
            stringValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput>
                >
            >;
            /** Creates new instances of ProductTraitUnitValue. New instances will be appended to the edge */
            unitValues?: InputMaybe<
                Array<
                    InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput>
                >
            >;
        };

    /** Creates a new instances of: ProductTraitFlagValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFlagValueInput =
        {
            /** Expects node: **ProductDeclarationFlagField** */
            field: Scalars['ID'];
            value: Scalars['Boolean'];
        };

    /** Creates a new instances of: ProductTraitFloatValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitFloatValueInput =
        {
            /** Expects node: **ProductDeclarationFloatField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitIntegerValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitIntegerValueInput =
        {
            /** Expects node: **ProductDeclarationIntegerField** */
            field: Scalars['ID'];
            value: Scalars['Float'];
        };

    /** Creates a new instances of: ProductTraitMeasurementValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitMeasurementValueInput =
        {
            /** Expects node: **ProductDeclarationMeasurementField** */
            field: Scalars['ID'];
            /** Expects node: **Measurement** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitSelectionValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitSelectionValueInput =
        {
            /** Expects node: **ProductDeclarationSelectionField** */
            field: Scalars['ID'];
            /** Expects node: **ProductDeclarationSelectionChoice** */
            value: Scalars['ID'];
        };

    /** Creates a new instances of: ProductTraitStringValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitStringValueInput =
        {
            /** Expects node: **ProductDeclarationStringField** */
            field: Scalars['ID'];
            value: Scalars['String'];
        };

    /** Creates a new instances of: ProductTraitUnitValue */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductListProductTraitListProductTraitUnitValueInput =
        {
            /** Expects node: **ProductDeclarationUnitField** */
            field: Scalars['ID'];
            /** Expects node: **Unit** */
            value: Scalars['ID'];
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: Brand */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandInput =
        {
            company?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput>;
            identification?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
        };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ProductCompany
     */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandProductCompanySwitchInput =
        {
            /** Creates and new instance and assigns it to field */
            create?: InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateBrandSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: ProductCompany */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListItemCreateSwitchCreateProductSwitchCreateProductCompanyInput =
        {
            description?: InputMaybe<Scalars['String']>;
            name?: InputMaybe<Scalars['String']>;
            /** Expects node: **ProductCompany** */
            parentCompany?: InputMaybe<Scalars['ID']>;
        };

    /** Creates a new instances of: PoolCalibration */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolCalibrationInput = {
        higherThreshold: Scalars['Float'];
        lowerThreshold: Scalars['Float'];
        /** Expects node: **Measurement** */
        measurementType?: InputMaybe<Scalars['ID']>;
        required?: InputMaybe<Scalars['Boolean']>;
        target: Scalars['Float'];
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNoteUpdateInput>>>;
    };

    /** Creates a new instances of: PoolNote */
    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListPoolUpdateListPoolNoteUpdateInput = {
        /** Expects node: **PoolNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListSiteNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListSiteNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimEntityListSiteListSiteNoteUpdateInput>>>;
    };

    /** Creates a new instances of: SiteNote */
    export type UpdateWarrantyClaimEntityListSiteListSiteNoteCreateInput = {
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListSiteListSiteNoteUpdateInput = {
        /** Expects node: **SiteNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateWarrantyClaimEntityListSiteSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimEntityListSiteSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: WarrantyClaimFileAttach */
    export type UpdateWarrantyClaimFileAttachMutationInput = {
        /** Expects node: **WarrantyClaimFileAttach** */
        id: Scalars['ID'];
        label?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimListWarrantyClaimFileAttach = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimListWarrantyClaimFileAttachCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimListWarrantyClaimFileAttachUpdateInput>>>;
    };

    /** Creates a new instances of: WarrantyClaimFileAttach */
    export type UpdateWarrantyClaimListWarrantyClaimFileAttachCreateInput = {
        /** Expects node: **FileUpload** */
        file?: InputMaybe<Scalars['ID']>;
        label?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimListWarrantyClaimFileAttachUpdateInput = {
        /** Expects node: **WarrantyClaimFileAttach** */
        id: Scalars['ID'];
        label?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: WarrantyClaimMessage */
    export type UpdateWarrantyClaimMessageMutationInput = {
        /** Expects node: **WarrantyClaimMessage** */
        id: Scalars['ID'];
        message?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: WarrantyClaim */
    export type UpdateWarrantyClaimMutationInput = {
        /** Address for a location of claimed item */
        address?: InputMaybe<UpdateWarrantyClaimAddressSwitchInput>;
        assetInstallDate?: InputMaybe<Scalars['Date']>;
        assetLocation?: InputMaybe<Scalars['String']>;
        assetName?: InputMaybe<Scalars['String']>;
        assetSerial?: InputMaybe<Scalars['String']>;
        claimedAt?: InputMaybe<Scalars['Date']>;
        comment?: InputMaybe<Scalars['String']>;
        customerCompanyName?: InputMaybe<Scalars['String']>;
        customerEmail?: InputMaybe<Scalars['String']>;
        customerFirstName?: InputMaybe<Scalars['String']>;
        customerLastName?: InputMaybe<Scalars['String']>;
        customerPhone?: InputMaybe<Scalars['String']>;
        emailCC?: InputMaybe<Scalars['String']>;
        emailReplyTo?: InputMaybe<Scalars['String']>;
        entity?: InputMaybe<UpdateWarrantyClaimEntityInput>;
        faultDescription?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWarrantyClaimListWarrantyClaimFileAttach>;
        /** Expects node: **WarrantyClaim** */
        id: Scalars['ID'];
        installerName?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        receiverStaff?: InputMaybe<Scalars['ID']>;
        /** Expects node: **FranchiseWarranter** */
        receiverWarranter?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Item** */
        refAsset?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Customer** */
        refCustomer?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Site** */
        refSite?: InputMaybe<Scalars['ID']>;
        siteAccessKey?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: Address */
    export type UpdateWarrantyClaimSwitchCreateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeInitInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWarrantyClaimSwitchUpdateAddressInput = {
        buildingName?: InputMaybe<Scalars['String']>;
        city?: InputMaybe<Scalars['String']>;
        coordinates?: InputMaybe<CoordinateNodeUpdateInput>;
        country?: InputMaybe<Scalars['String']>;
        destinationName?: InputMaybe<Scalars['String']>;
        poBoxNumber?: InputMaybe<Scalars['String']>;
        postCode?: InputMaybe<Scalars['String']>;
        state?: InputMaybe<Scalars['String']>;
        streetName?: InputMaybe<Scalars['String']>;
        streetNumber?: InputMaybe<Scalars['String']>;
        suburb?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListFileUpload = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type UpdateWorkOrderListJobTodo = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListJobTodoCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListJobTodoUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodo */
    export type UpdateWorkOrderListJobTodoCreateInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        createdBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances of JobTodoItem. New instances will be appended to the edge */
        items?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListJobTodoCreateListJobTodoItemInput>>>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Expects node: **JobTodoTemplate** */
        template?: InputMaybe<Scalars['ID']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateWorkOrderListJobTodoCreateListJobTodoItemInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListJobTodoUpdateInput = {
        completedAt?: InputMaybe<Scalars['Date']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodo** */
        id: Scalars['ID'];
        index?: InputMaybe<Scalars['Int']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        items?: InputMaybe<UpdateWorkOrderListJobTodoUpdateListJobTodoItem>;
        price?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListJobTodoUpdateListJobTodoItem = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListJobTodoUpdateListJobTodoItemCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListJobTodoUpdateListJobTodoItemUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodoItem */
    export type UpdateWorkOrderListJobTodoUpdateListJobTodoItemCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        completedBy?: InputMaybe<Scalars['ID']>;
        description?: InputMaybe<Scalars['String']>;
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListJobTodoUpdateListJobTodoItemUpdateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Expects node: **JobTodoItem** */
        id: Scalars['ID'];
        orderIndex?: InputMaybe<Scalars['Int']>;
        status?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListWorkOrderNote = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListWorkOrderNoteCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderListWorkOrderNoteUpdateInput>>>;
    };

    /** Creates a new instances of: WorkOrderNote */
    export type UpdateWorkOrderListWorkOrderNoteCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        note?: InputMaybe<Scalars['String']>;
    };

    export type UpdateWorkOrderListWorkOrderNoteUpdateInput = {
        /** Expects node: **WorkOrderNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
    };

    /** Updates an instance of: WorkOrder */
    export type UpdateWorkOrderMutationInput = {
        /** Expects node: **Address** */
        address?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        assignedTo?: InputMaybe<Scalars['ID']>;
        checkedInAt?: InputMaybe<Scalars['Date']>;
        checkedOutAt?: InputMaybe<Scalars['Date']>;
        description?: InputMaybe<Scalars['String']>;
        dueRangeEnd?: InputMaybe<Scalars['Date']>;
        dueRangeStart?: InputMaybe<Scalars['Date']>;
        /** Expects node: **EZCDeviceLink** */
        ezcDeviceLink?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        files?: InputMaybe<UpdateWorkOrderListFileUpload>;
        /** Expects node: **WorkOrder** */
        followUpFrom?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        jobs?: InputMaybe<UpdateWorkOrderListJobTodo>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        notes?: InputMaybe<UpdateWorkOrderListWorkOrderNote>;
        /** Expects node: **Pool** */
        pool?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Contact** */
        preferredContact?: InputMaybe<Scalars['ID']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresAppointment?: InputMaybe<Scalars['Boolean']>;
        requiresAssignedTo?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresPreferredContact?: InputMaybe<Scalars['Boolean']>;
        requiresTestReport?: InputMaybe<Scalars['Boolean']>;
        requiresWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        stage?: InputMaybe<Scalars['ID']>;
        stageType?: InputMaybe<ServiceJobStageType>;
        /** Required if no template provided */
        title?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        workFor?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: WorkOrderNote */
    export type UpdateWorkOrderNoteMutationInput = {
        /** Expects node: **WorkOrderNote** */
        id: Scalars['ID'];
        note?: InputMaybe<Scalars['String']>;
        /** Expects node: **WorkOrder** */
        parent?: InputMaybe<Scalars['ID']>;
    };

    /** Updates an instance of: WorkOrderTemplateAssoc */
    export type UpdateWorkOrderTemplateAssocMutationInput = {
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateWorkOrderTemplateListJobTodoTemplateAssoc = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderTemplateListJobTodoTemplateAssocUpdateInput>>>;
    };

    /** Creates a new instances of: JobTodoTemplateAssoc */
    export type UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateInput = {
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        jobTodoTemplate: UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateJobTodoTemplateSwitchInput;
        priority?: InputMaybe<Scalars['Int']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: JobTodoTemplate
     */
    export type UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateJobTodoTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateSwitchCreateJobTodoTemplateInput>;
        /** Expects node: **JobTodoTemplate** */
        id?: InputMaybe<Scalars['ID']>;
    };

    /** Creates a new instances of: JobTodoTemplate */
    export type UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateSwitchCreateJobTodoTemplateInput = {
        description?: InputMaybe<Scalars['String']>;
        /** Creates new instances of JobTodoTemplateItem. New instances will be appended to the edge */
        items?: InputMaybe<
            Array<
                InputMaybe<UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput>
            >
        >;
        maxTimeEstimate?: InputMaybe<Scalars['Int']>;
        minTimeEstimate?: InputMaybe<Scalars['Int']>;
        recommendedPrice?: InputMaybe<Scalars['Int']>;
        /** Time Estimate in minutes */
        timeEstimate?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
    };

    /** Creates a new instances of: JobTodoTemplateItem */
    export type UpdateWorkOrderTemplateListJobTodoTemplateAssocCreateSwitchCreateJobTodoTemplateListJobTodoTemplateItemInput =
        {
            description?: InputMaybe<Scalars['String']>;
            orderIndex?: InputMaybe<Scalars['Int']>;
            title?: InputMaybe<Scalars['String']>;
        };

    export type UpdateWorkOrderTemplateListJobTodoTemplateAssocUpdateInput = {
        /** Expects node: **JobTodoTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateWorkOrderTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<Array<InputMaybe<UpdateWorkOrderTemplateListServiceJobStageCreateInput>>>;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateWorkOrderTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Updates an instance of: WorkOrderTemplate */
    export type UpdateWorkOrderTemplateMutationInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateWorkOrderTemplateListServiceJobStage>;
        defaultStage?: InputMaybe<UpdateWorkOrderTemplateServiceJobStageSwitchInput>;
        /** Expects node: **Franchise** */
        franchise?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        group?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        id: Scalars['ID'];
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        jobTodoTemplateAssocs?: InputMaybe<UpdateWorkOrderTemplateListJobTodoTemplateAssoc>;
        onlyOwnedStages?: InputMaybe<Scalars['Boolean']>;
        /** If null then meant for sharing with many ServiceJobTemplate */
        ownedByServiceJobTemplate?: InputMaybe<UpdateWorkOrderTemplateServiceJobTemplateSwitchInput>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ServiceJobStage
     */
    export type UpdateWorkOrderTemplateServiceJobStageSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWorkOrderTemplateSwitchCreateServiceJobStageInput>;
        /** Expects node: **ServiceJobStage** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobStageInput>;
    };

    /**
     * Selects existing instances, or creates new one and assigns to parent field.
     *  Create or select: ServiceJobTemplate
     */
    export type UpdateWorkOrderTemplateServiceJobTemplateSwitchInput = {
        /** Creates and new instance and assigns it to field */
        create?: InputMaybe<UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateInput>;
        /** Expects node: **ServiceJobTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Updates instance, which is referred by current field state */
        update?: InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateInput>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateWorkOrderTemplateSwitchCreateServiceJobStageInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instances of: ServiceJobTemplate */
    export type UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        isPublished?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances of WorkOrderTemplateAssoc. New instances will be appended to the edge */
        workOrderTemplateAssocs?: InputMaybe<
            Array<InputMaybe<UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListWorkOrderTemplateAssocInput>>
        >;
    };

    export type UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStageCreateInput>>
        >;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type UpdateWorkOrderTemplateSwitchCreateServiceJobTemplateListWorkOrderTemplateAssocInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobStageInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title?: InputMaybe<Scalars['String']>;
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateInput = {
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        assignedStages?: InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStage>;
        canBeRecurring?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        defaultStage?: InputMaybe<Scalars['ID']>;
        hideStageActionRequired?: InputMaybe<Scalars['Boolean']>;
        hideStageInProgress?: InputMaybe<Scalars['Boolean']>;
        hideStageSuspended?: InputMaybe<Scalars['Boolean']>;
        isPublished?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        providedDescription?: InputMaybe<Scalars['String']>;
        providedTitle?: InputMaybe<Scalars['String']>;
        requiresAddress?: InputMaybe<Scalars['Boolean']>;
        requiresContact?: InputMaybe<Scalars['Boolean']>;
        requiresCustomer?: InputMaybe<Scalars['Boolean']>;
        requiresPool?: InputMaybe<Scalars['Boolean']>;
        requiresStaff?: InputMaybe<Scalars['Boolean']>;
        templateDescription?: InputMaybe<Scalars['String']>;
        templateTitle?: InputMaybe<Scalars['String']>;
        /** Creates new instances, deletes or adds existing ones to the edge. Existing instances aren't deleted, they just removed from the edge */
        workOrderTemplateAssocs?: InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssoc>;
    };

    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStage = {
        /** Assigns selected instances to list */
        assign?: InputMaybe<Array<Scalars['ID']>>;
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStageCreateInput>>
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
    };

    /** Creates a new instances of: ServiceJobStage */
    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListServiceJobStageCreateInput = {
        color?: InputMaybe<Scalars['String']>;
        hidden?: InputMaybe<Scalars['Boolean']>;
        isDefault?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
        title: Scalars['String'];
        type?: InputMaybe<ServiceJobStageType>;
    };

    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssoc = {
        /** Creates and assigns new instances to list */
        create?: InputMaybe<
            Array<
                InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput>
            >
        >;
        /** Deletes selected instances from list */
        delete?: InputMaybe<Array<Scalars['ID']>>;
        /** Updates instances in list */
        update?: InputMaybe<
            Array<
                InputMaybe<UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput>
            >
        >;
    };

    /** Creates a new instances of: WorkOrderTemplateAssoc */
    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput = {
        /** Expects nodes: **StaffRegistration**, **Staff** */
        addedBy?: InputMaybe<Scalars['ID']>;
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type UpdateWorkOrderTemplateSwitchUpdateServiceJobTemplateListWorkOrderTemplateAssocUpdateInput = {
        forwardAddress?: InputMaybe<Scalars['Boolean']>;
        forwardContact?: InputMaybe<Scalars['Boolean']>;
        forwardPool?: InputMaybe<Scalars['Boolean']>;
        forwardStaff?: InputMaybe<Scalars['Boolean']>;
        forwardWorkFor?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplateAssoc** */
        id: Scalars['ID'];
        isSuggestion?: InputMaybe<Scalars['Boolean']>;
        priority?: InputMaybe<Scalars['Int']>;
    };

    export type User = Node & {
        __typename?: 'User';
        checkMutations?: Maybe<UserMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        customer?: Maybe<Customer>;
        entity?: Maybe<Entity>;
        firstName?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        lastName?: Maybe<Scalars['String']>;
        staff?: Maybe<Staff>;
        title?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type UserMutationCheck = {
        __typename?: 'UserMutationCheck';
        update?: Maybe<Scalars['Boolean']>;
    };

    export type UserViewerUsersSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<UserViewerUsersSearchCustomer>;
        entity?: InputMaybe<UserViewerUsersSearchEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        staff?: InputMaybe<UserViewerUsersSearchStaff>;
    };

    export type UserViewerUsersSearchCustomer = {
        addedBy?: InputMaybe<UserViewerUsersSearchCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<UserViewerUsersSearchCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<UserViewerUsersSearchCustomerPrimaryAddress>;
        tags?: InputMaybe<UserViewerUsersSearchCustomerTags>;
    };

    export type UserViewerUsersSearchCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<UserViewerUsersSearchCustomerAddedByRegistration>;
        teams?: InputMaybe<UserViewerUsersSearchCustomerAddedByTeams>;
    };

    export type UserViewerUsersSearchCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntity = {
        addresses?: InputMaybe<UserViewerUsersSearchEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<UserViewerUsersSearchEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<UserViewerUsersSearchEntityPools>;
        serviceJob?: InputMaybe<UserViewerUsersSearchEntityServiceJob>;
        sites?: InputMaybe<UserViewerUsersSearchEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<UserViewerUsersSearchEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<UserViewerUsersSearchEntityPoolsReports>;
        sanitiser?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiser>;
        site?: InputMaybe<UserViewerUsersSearchEntityPoolsSite>;
        type?: InputMaybe<UserViewerUsersSearchEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<UserViewerUsersSearchEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityPoolsSite = {
        address?: InputMaybe<UserViewerUsersSearchEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJob = {
        address?: InputMaybe<UserViewerUsersSearchEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<UserViewerUsersSearchEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<UserViewerUsersSearchEntityServiceJobWorkOrders>;
    };

    export type UserViewerUsersSearchEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomer = {
        addedBy?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerTags>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<UserViewerUsersSearchEntityServiceJobCustomerAddedByTeams>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSite>;
        type?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSite = {
        address?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type UserViewerUsersSearchEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type UserViewerUsersSearchEntitySites = {
        address?: InputMaybe<UserViewerUsersSearchEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type UserViewerUsersSearchEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSearchStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<UserViewerUsersSearchStaffRegistration>;
        teams?: InputMaybe<UserViewerUsersSearchStaffTeams>;
    };

    export type UserViewerUsersSearchStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type UserViewerUsersSearchStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type UserViewerUsersSort = {
        chainUpdate?: InputMaybe<Scalars['Boolean']>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        firstName?: InputMaybe<Scalars['Boolean']>;
        lastName?: InputMaybe<Scalars['Boolean']>;
    };

    export type VendAPI = Node & {
        __typename?: 'VendAPI';
        addedCustomerId?: Maybe<Customer>;
        checkMutations?: Maybe<VendAPIMutationCheck>;
        customers?: Maybe<CustomersForVendAPIToVendCustomerConnection>;
        disabledAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        key?: Maybe<Scalars['String']>;
        resolveCustomerByCRN?: Maybe<VendCustomer>;
        resolveCustomerByUUID?: Maybe<VendCustomer>;
        resolveCustomerLinkByUUID?: Maybe<VendLink>;
        shopUri?: Maybe<Scalars['String']>;
        vendTest?: Maybe<Scalars['Boolean']>;
    };

    export type VendAPICustomersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        sort?: InputMaybe<VendCustomerOrderingNodeInitInput>;
    };

    export type VendAPIResolveCustomerByCRNArgs = {
        crn: Scalars['String'];
    };

    export type VendAPIResolveCustomerByUUIDArgs = {
        uuid: Scalars['String'];
    };

    export type VendAPIResolveCustomerLinkByUUIDArgs = {
        uuid: Scalars['String'];
    };

    export type VendAPIAddCustomerMutationInput = {
        /** Expects node: **Customer** */
        customerId: Scalars['ID'];
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
    };

    export type VendAPIConnectCustomerToVendCRNMutationInput = {
        /** Expects node: **Customer** */
        customer: Scalars['ID'];
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
        vendCRN: Scalars['String'];
    };

    export type VendAPIConnectCustomerToVendCustomerIDMutationInput = {
        /** Expects node: **Customer** */
        customer: Scalars['ID'];
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
        vendCustomerId: Scalars['String'];
    };

    export type VendAPIExportToVendMutationInput = {
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
    };

    export type VendAPIImportFromVendMutationInput = {
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
    };

    export type VendAPILegacyReconnectMutationInput = {
        /** Expects node: **Customer** */
        customerId: Scalars['ID'];
        deletePrevious?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **VendAPI** */
        id: Scalars['ID'];
    };

    export type VendAPIMutationCheck = {
        __typename?: 'VendAPIMutationCheck';
        addCustomer?: Maybe<Scalars['Boolean']>;
        connectCustomerToVendCRN?: Maybe<Scalars['Boolean']>;
        connectCustomerToVendCustomerID?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        exportToVend?: Maybe<Scalars['Boolean']>;
        importFromVend?: Maybe<Scalars['Boolean']>;
        legacyReconnect?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type VendAPIMutationInput = {
        addCustomer?: InputMaybe<Array<InputMaybe<VendAPIAddCustomerMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        connectCustomerToVendCRN?: InputMaybe<Array<InputMaybe<VendAPIConnectCustomerToVendCRNMutationInput>>>;
        connectCustomerToVendCustomerID?: InputMaybe<
            Array<InputMaybe<VendAPIConnectCustomerToVendCustomerIDMutationInput>>
        >;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateVendAPIMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteVendAPIMutationInput>>>;
        exportToVend?: InputMaybe<Array<InputMaybe<VendAPIExportToVendMutationInput>>>;
        importFromVend?: InputMaybe<Array<InputMaybe<VendAPIImportFromVendMutationInput>>>;
        legacyReconnect?: InputMaybe<Array<InputMaybe<VendAPILegacyReconnectMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateVendAPIMutationInput>>>;
    };

    export type VendAPIMutationPayload = {
        __typename?: 'VendAPIMutationPayload';
        VendAPI?: Maybe<VendAPI>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkVendAPIMutationOutput>>>;
        updatedAddedCustomerId?: Maybe<Customer>;
        updatedFranchise?: Maybe<Franchise>;
        viewer?: Maybe<Viewer>;
    };

    export type VendAppointmentSaleType = Node & {
        __typename?: 'VendAppointmentSaleType';
        appointmentInstance?: Maybe<AppointmentInstance>;
        appointmentItem?: Maybe<AppointmentItem>;
        historyUrl?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        vend?: Maybe<VendAPI>;
        vendSale?: Maybe<VendSale>;
        webRegistryUrl?: Maybe<Scalars['String']>;
    };

    export type VendCustomer = Node & {
        __typename?: 'VendCustomer';
        balance?: Maybe<Scalars['Float']>;
        companyName?: Maybe<Scalars['String']>;
        createdAt?: Maybe<Scalars['Date']>;
        cursor?: Maybe<Scalars['String']>;
        customField1?: Maybe<Scalars['String']>;
        customField2?: Maybe<Scalars['String']>;
        customField3?: Maybe<Scalars['String']>;
        customField4?: Maybe<Scalars['String']>;
        customerCode?: Maybe<Scalars['String']>;
        deletedAt?: Maybe<Scalars['Date']>;
        doNotEmail?: Maybe<Scalars['Boolean']>;
        email?: Maybe<Scalars['String']>;
        fax?: Maybe<Scalars['String']>;
        firstName?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        lastName?: Maybe<Scalars['String']>;
        localCustomer?: Maybe<Customer>;
        loyaltyBalance?: Maybe<Scalars['Float']>;
        loyaltyEmailSent?: Maybe<Scalars['String']>;
        mobile?: Maybe<Scalars['String']>;
        note?: Maybe<Scalars['String']>;
        phone?: Maybe<Scalars['String']>;
        physicalAddress?: Maybe<VendPhysicalAddress>;
        postalAddress?: Maybe<VendPostalAddress>;
        sales?: Maybe<SalesForVendCustomerToVendSaleConnection>;
        updatedAt?: Maybe<Scalars['Date']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type VendCustomerSalesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type VendCustomerCreateMutationInput = {
        /** Expects node: **Customer** */
        customer: Scalars['ID'];
    };

    export type VendCustomerMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        create?: InputMaybe<Array<InputMaybe<VendCustomerCreateMutationInput>>>;
    };

    export type VendCustomerMutationPayload = {
        __typename?: 'VendCustomerMutationPayload';
        VendCustomer?: Maybe<VendCustomer>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkVendCustomerMutationOutput>>>;
        updatedCustomer?: Maybe<Customer>;
        viewer?: Maybe<Viewer>;
    };

    export type VendCustomerOrderingNodeInitInput = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        deletedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type VendLink = Node & {
        __typename?: 'VendLink';
        checkMutations?: Maybe<VendLinkMutationCheck>;
        id: Scalars['ID'];
        isDeleted?: Maybe<Scalars['Boolean']>;
        localCustomer?: Maybe<Customer>;
        vendCustomer?: Maybe<VendCustomer>;
        vendURL?: Maybe<Scalars['String']>;
        vendUUID?: Maybe<Scalars['String']>;
    };

    export type VendLinkMutationCheck = {
        __typename?: 'VendLinkMutationCheck';
        sync?: Maybe<Scalars['Boolean']>;
    };

    export type VendLinkMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        sync?: InputMaybe<Array<InputMaybe<VendLinkSyncMutationInput>>>;
    };

    export type VendLinkMutationPayload = {
        __typename?: 'VendLinkMutationPayload';
        VendLink?: Maybe<VendLink>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkVendLinkMutationOutput>>>;
        updatedLocalCustomer?: Maybe<Customer>;
        viewer?: Maybe<Viewer>;
    };

    export type VendLinkSyncMutationInput = {
        /** Expects node: **VendLink** */
        id: Scalars['ID'];
    };

    export type VendPhysicalAddress = {
        __typename?: 'VendPhysicalAddress';
        city?: Maybe<Scalars['String']>;
        countryId?: Maybe<Scalars['String']>;
        postCode?: Maybe<Scalars['String']>;
        state?: Maybe<Scalars['String']>;
        streetLine1?: Maybe<Scalars['String']>;
        streetLine2?: Maybe<Scalars['String']>;
        suburb?: Maybe<Scalars['String']>;
    };

    export type VendPostalAddress = {
        __typename?: 'VendPostalAddress';
        city?: Maybe<Scalars['String']>;
        countryId?: Maybe<Scalars['String']>;
        postCode?: Maybe<Scalars['String']>;
        state?: Maybe<Scalars['String']>;
        streetLine1?: Maybe<Scalars['String']>;
        streetLine2?: Maybe<Scalars['String']>;
        suburb?: Maybe<Scalars['String']>;
    };

    export type VendProduct = Node & {
        __typename?: 'VendProduct';
        id: Scalars['ID'];
        imageUrl?: Maybe<Scalars['String']>;
        name?: Maybe<Scalars['String']>;
        url?: Maybe<Scalars['String']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type VendSale = Node & {
        __typename?: 'VendSale';
        appointmentInstance?: Maybe<AppointmentInstance>;
        appointmentItem?: Maybe<AppointmentItem>;
        date?: Maybe<Scalars['Date']>;
        historyUrl?: Maybe<Scalars['String']>;
        id: Scalars['ID'];
        localCustomer?: Maybe<Customer>;
        note?: Maybe<Scalars['String']>;
        saleItems?: Maybe<SaleItemsForVendSaleToVendSaleItemConnection>;
        status?: Maybe<Scalars['String']>;
        totalPrice?: Maybe<Scalars['Float']>;
        totalPriceInclTax?: Maybe<Scalars['Float']>;
        totalTax?: Maybe<Scalars['Float']>;
        uuid?: Maybe<Scalars['String']>;
        vendCustomer?: Maybe<VendCustomer>;
        vendUser?: Maybe<VendUser>;
        webRegistryUrl?: Maybe<Scalars['String']>;
    };

    export type VendSaleSaleItemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type VendSaleItem = {
        __typename?: 'VendSaleItem';
        cost?: Maybe<Scalars['Float']>;
        discount?: Maybe<Scalars['Float']>;
        isReturn?: Maybe<Scalars['Boolean']>;
        note?: Maybe<Scalars['String']>;
        product?: Maybe<VendProduct>;
        quantity?: Maybe<Scalars['Int']>;
        sequence?: Maybe<Scalars['Int']>;
        status?: Maybe<Scalars['String']>;
        tax?: Maybe<Scalars['Float']>;
        unitPrice?: Maybe<Scalars['Float']>;
        unitTax?: Maybe<Scalars['Float']>;
        uuid?: Maybe<Scalars['String']>;
    };

    export type VendSaleMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateVendSaleMutationInput>>>;
    };

    export type VendSaleMutationPayload = {
        __typename?: 'VendSaleMutationPayload';
        VendSale?: Maybe<VendSale>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkVendSaleMutationOutput>>>;
        viewer?: Maybe<Viewer>;
    };

    export type VendUser = {
        __typename?: 'VendUser';
        displayName?: Maybe<Scalars['String']>;
        url?: Maybe<Scalars['String']>;
        uuid?: Maybe<Scalars['String']>;
    };

    /** A connection to a list of items. */
    export type VersionsForProjectToProjectVersionConnection = {
        __typename?: 'VersionsForProjectToProjectVersionConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<VersionsForProjectToProjectVersionEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<VersionsForProjectToProjectVersionMeta>;
    };

    /** An edge in a connection. */
    export type VersionsForProjectToProjectVersionEdge = {
        __typename?: 'VersionsForProjectToProjectVersionEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProjectVersion>;
    };

    export type VersionsForProjectToProjectVersionMeta = {
        __typename?: 'VersionsForProjectToProjectVersionMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type Viewer = {
        __typename?: 'Viewer';
        RecurrenceView?: Maybe<Recurrence>;
        abstractDocuments?: Maybe<ViewerAbstractDocumentsConnection>;
        announcements?: Maybe<ViewerAnnouncementsConnection>;
        appointmentGroups?: Maybe<ViewerAppointmentGroupsConnection>;
        appointmentItems?: Maybe<ViewerAppointmentItemsConnection>;
        appointmentPatternForPatternBlockId?: Maybe<RecurrencePattern>;
        appointments?: Maybe<ViewerAppointmentsConnection>;
        brands?: Maybe<ViewerBrandsConnection>;
        checkPermissions?: Maybe<ViewerPermissions>;
        consumableProducts?: Maybe<ViewerConsumableProductsConnection>;
        contactTypeByTag?: Maybe<ContactType>;
        contactTypes?: Maybe<ViewerContactTypesConnection>;
        converters?: Maybe<ViewerConvertersConnection>;
        customContracts?: Maybe<ViewerCustomContractsConnection>;
        customerTags?: Maybe<ViewerCustomerTagsConnection>;
        customers?: Maybe<ViewerCustomersConnection>;
        /** Decode ID */
        decodeId?: Maybe<Scalars['JSON']>;
        defaultServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        emailGlobalTemplateSelections?: Maybe<ViewerEmailGlobalTemplateSelectionsConnection>;
        emailOrganisationTemplateSelections?: Maybe<ViewerEmailOrganisationTemplateSelectionsConnection>;
        emailTemplateSelections?: Maybe<ViewerEmailTemplateSelectionsConnection>;
        emailTemplates?: Maybe<ViewerEmailTemplatesConnection>;
        emailTypes?: Maybe<ViewerEmailTypesConnection>;
        entities?: Maybe<ViewerEntitiesConnection>;
        ezcDeviceDefinitions?: Maybe<ViewerEzcDeviceDefinitionsConnection>;
        ezcDeviceLinks?: Maybe<ViewerEzcDeviceLinksConnection>;
        ezcDevices?: Maybe<ViewerEzcDevicesConnection>;
        ezcServiceProviderLinks?: Maybe<ViewerEzcServiceProviderLinksConnection>;
        ezcServiceProviders?: Maybe<ViewerEzcServiceProvidersConnection>;
        featureFlagVariations?: Maybe<ViewerFeatureFlagVariationsConnection>;
        featureFlags?: Maybe<ViewerFeatureFlagsConnection>;
        files?: Maybe<ViewerFilesConnection>;
        franchiseSequenceStore?: Maybe<ViewerFranchiseSequenceStoreConnection>;
        franchiseValuesStore?: Maybe<ViewerFranchiseValuesStoreConnection>;
        franchiseWarranters?: Maybe<ViewerFranchiseWarrantersConnection>;
        franchises?: Maybe<ViewerFranchisesConnection>;
        heaterRecommendations?: Maybe<ViewerHeaterRecommendationsConnection>;
        installableProducts?: Maybe<ViewerInstallableProductsConnection>;
        jobTodoTemplates?: Maybe<ViewerJobTodoTemplatesConnection>;
        locales?: Maybe<ViewerLocalesConnection>;
        manualProblems?: Maybe<ViewerManualProblemsConnection>;
        me?: Maybe<Me>;
        measurementTypes?: Maybe<ViewerMeasurementTypesConnection>;
        measurementUnits?: Maybe<ViewerMeasurementUnitsConnection>;
        organisationTypes?: Maybe<ViewerOrganisationTypesConnection>;
        permissions?: Maybe<ViewerPermissionsConnection>;
        poolBottleLeases?: Maybe<ViewerPoolBottleLeasesConnection>;
        poolBottles?: Maybe<ViewerPoolBottlesConnection>;
        poolDeclarationFlagFields?: Maybe<ViewerPoolDeclarationFlagFieldsConnection>;
        poolProductDeclarations?: Maybe<ViewerPoolProductDeclarationsConnection>;
        poolSanitisers?: Maybe<ViewerPoolSanitisersConnection>;
        poolTransfers?: Maybe<ViewerPoolTransfersConnection>;
        poolTypes?: Maybe<ViewerPoolTypesConnection>;
        pools?: Maybe<ViewerPoolsConnection>;
        productCompany?: Maybe<ViewerProductCompanyConnection>;
        productDeclarationByTag?: Maybe<ProductTraitDeclaration>;
        productDeclarations?: Maybe<ViewerProductDeclarationsConnection>;
        productDocuments?: Maybe<ViewerProductDocumentsConnection>;
        productSearchHelper?: Maybe<ProductSearchHelper>;
        products?: Maybe<ViewerProductsConnection>;
        projectHosting?: Maybe<ViewerProjectHostingConnection>;
        recentActivities?: Maybe<RecentActivity>;
        reports?: Maybe<ViewerReportsConnection>;
        resolveCustomerByCRN?: Maybe<Customer>;
        resolveEZCDeviceMetrics?: Maybe<EZCDeviceMetricsBundle>;
        resolveEZCDeviceSchema?: Maybe<EZCDeviceSchema>;
        resolveEZCDeviceState?: Maybe<EZCDeviceState>;
        resolveEZCDeviceStates?: Maybe<Array<Maybe<EZCDeviceState>>>;
        resolveFranchiseSequence?: Maybe<FranchiseSequenceStore>;
        resolveFranchiseValue?: Maybe<FranchiseValueStore>;
        resolveNode?: Maybe<Node>;
        resolveNodes?: Maybe<Array<Maybe<Node>>>;
        resolvePoolByBottleNumber?: Maybe<Pool>;
        resolveProjectByUUID?: Maybe<Project>;
        resolveStaffValue?: Maybe<StaffValueStore>;
        resolveWaterTestByRefId?: Maybe<TestReport>;
        resolveWaterTestSummaryByRefId?: Maybe<WaterTestReportSummary>;
        resolveWaterTestSummaryFromReportId?: Maybe<WaterTestReportSummary>;
        retrieveProductBrochure?: Maybe<ProductDocument>;
        roleFlags?: Maybe<ViewerRoleFlagsConnection>;
        roles?: Maybe<ViewerRolesConnection>;
        sampleSets?: Maybe<ViewerSampleSetsConnection>;
        sampleSources?: Maybe<ViewerSampleSourcesConnection>;
        sentEmails?: Maybe<ViewerSentEmailsConnection>;
        serviceJobGroups?: Maybe<ViewerServiceJobGroupsConnection>;
        serviceJobStages?: Maybe<ViewerServiceJobStagesConnection>;
        serviceJobStagesActiveReport?: Maybe<Array<Maybe<ServiceJobStageAggregate>>>;
        serviceJobTemplates?: Maybe<ViewerServiceJobTemplatesConnection>;
        serviceJobs?: Maybe<ViewerServiceJobsConnection>;
        settingStore?: Maybe<ViewerSettingStoreConnection>;
        shops?: Maybe<ViewerShopsConnection>;
        sites?: Maybe<ViewerSitesConnection>;
        solverPreview?: Maybe<SolverPreview>;
        staff?: Maybe<ViewerStaffConnection>;
        staffTeams?: Maybe<ViewerStaffTeamsConnection>;
        staffValuesStore?: Maybe<ViewerStaffValuesStoreConnection>;
        stats?: Maybe<HQFranchisesStats>;
        testCriteria?: Maybe<ViewerTestCriteriaConnection>;
        testProblems?: Maybe<ViewerTestProblemsConnection>;
        testTreatmentGroupForFranchises?: Maybe<ViewerTestTreatmentGroupForFranchisesConnection>;
        testTreatmentGroups?: Maybe<ViewerTestTreatmentGroupsConnection>;
        testTreatments?: Maybe<ViewerTestTreatmentsConnection>;
        timezones?: Maybe<ViewerTimezonesConnection>;
        unitConversions?: Maybe<ViewerUnitConversionsConnection>;
        unitConverters?: Maybe<ViewerUnitConvertersConnection>;
        universalCriteria?: Maybe<ViewerUniversalCriteriaConnection>;
        users?: Maybe<ViewerUsersConnection>;
        warranterClaimMessages?: Maybe<ViewerWarranterClaimMessagesConnection>;
        warrantyClaimCases?: Maybe<ViewerWarrantyClaimCasesConnection>;
        warrantyClaims?: Maybe<ViewerWarrantyClaimsConnection>;
        workOrderFeed?: Maybe<ViewerWorkOrderFeedConnection>;
        workOrderTemplates?: Maybe<ViewerWorkOrderTemplatesConnection>;
        workOrders?: Maybe<ViewerWorkOrdersConnection>;
    };

    export type ViewerRecurrenceViewArgs = {
        pattern: RecurrencePatternNodeInitInput;
        range: RecurrenceRangeNodeInitInput;
    };

    export type ViewerAbstractDocumentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AbstractDocumentViewerAbstractDocumentsSearch>;
        sort?: InputMaybe<AbstractDocumentViewerAbstractDocumentsSort>;
    };

    export type ViewerAnnouncementsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AnnouncementViewerAnnouncementsSearch>;
    };

    export type ViewerAppointmentGroupsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AppointmentGroupViewerAppointmentGroupsSearch>;
        sort?: InputMaybe<AppointmentGroupViewerAppointmentGroupsSort>;
    };

    export type ViewerAppointmentItemsArgs = {
        addedBy?: InputMaybe<Scalars['ID']>;
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        customer?: InputMaybe<Scalars['ID']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        group?: InputMaybe<Scalars['ID']>;
        isRecurrent?: InputMaybe<Scalars['Boolean']>;
        last?: InputMaybe<Scalars['Int']>;
        patternBlockId?: InputMaybe<Scalars['String']>;
        pivotDate?: InputMaybe<Scalars['Date']>;
        pool?: InputMaybe<Scalars['ID']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        search?: InputMaybe<AppointmentItemSearchCommon>;
        serviceJob?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<Scalars['ID']>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type ViewerAppointmentPatternForPatternBlockIdArgs = {
        id: Scalars['String'];
    };

    export type ViewerAppointmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        endDate?: InputMaybe<Scalars['Date']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AppointmentViewerAppointmentsSearch>;
        startDate?: InputMaybe<Scalars['Date']>;
    };

    export type ViewerBrandsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<BrandViewerBrandsSearch>;
        searchTraits?: InputMaybe<BrandViewerBrandsSearchTraits>;
        sort?: InputMaybe<BrandViewerBrandsSort>;
    };

    export type ViewerConsumableProductsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ConsumableProductViewerConsumableProductsSearch>;
        sort?: InputMaybe<ConsumableProductViewerConsumableProductsSort>;
    };

    export type ViewerContactTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ContactTypeViewerContactTypesSearch>;
        sort?: InputMaybe<ContactTypeViewerContactTypesSort>;
    };

    export type ViewerConvertersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SampleConverterViewerConvertersSearch>;
    };

    export type ViewerCustomContractsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomContractViewerCustomContractsSearch>;
        sort?: InputMaybe<CustomContractViewerCustomContractsSort>;
    };

    export type ViewerCustomerTagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomerTagViewerCustomerTagsSearch>;
        sort?: InputMaybe<CustomerTagViewerCustomerTagsSort>;
    };

    export type ViewerCustomersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<CustomerViewerCustomersSearch>;
        sort?: InputMaybe<CustomerViewerCustomersSort>;
    };

    export type ViewerDecodeIdArgs = {
        id: Scalars['ID'];
    };

    export type ViewerEmailGlobalTemplateSelectionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailGlobalSelectionViewerEmailGlobalTemplateSelectionsSearch>;
    };

    export type ViewerEmailOrganisationTemplateSelectionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSearch>;
        sort?: InputMaybe<EmailTemplateOrganisationSelectionViewerEmailOrganisationTemplateSelectionsSort>;
    };

    export type ViewerEmailTemplateSelectionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailTemplateSelectionViewerEmailTemplateSelectionsSearch>;
        sort?: InputMaybe<EmailTemplateSelectionViewerEmailTemplateSelectionsSort>;
    };

    export type ViewerEmailTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailTemplateViewerEmailTemplatesSearch>;
        sort?: InputMaybe<EmailTemplateViewerEmailTemplatesSort>;
    };

    export type ViewerEmailTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerEntitiesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EntityViewerEntitiesSearch>;
    };

    export type ViewerEzcDeviceDefinitionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerEzcDeviceLinksArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EZCDeviceLinkViewerEzcDeviceLinksSearch>;
        sort?: InputMaybe<EZCDeviceLinkViewerEzcDeviceLinksSort>;
    };

    export type ViewerEzcDevicesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        franchise?: InputMaybe<Scalars['ID']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerEzcServiceProviderLinksArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EZCFranchiseLinkViewerEzcServiceProviderLinksSearch>;
        sort?: InputMaybe<EZCFranchiseLinkViewerEzcServiceProviderLinksSort>;
    };

    export type ViewerEzcServiceProvidersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerFeatureFlagVariationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FeatureFlagVariationViewerFeatureFlagVariationsSearch>;
        sort?: InputMaybe<FeatureFlagVariationViewerFeatureFlagVariationsSort>;
    };

    export type ViewerFeatureFlagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadViewerFilesSearch>;
        sort?: InputMaybe<FileUploadViewerFilesSort>;
    };

    export type ViewerFranchiseSequenceStoreArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseSequenceStoreViewerFranchiseSequenceStoreSearch>;
        sort?: InputMaybe<FranchiseSequenceStoreViewerFranchiseSequenceStoreSort>;
    };

    export type ViewerFranchiseValuesStoreArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseValueStoreViewerFranchiseValuesStoreSearch>;
        sort?: InputMaybe<FranchiseValueStoreViewerFranchiseValuesStoreSort>;
    };

    export type ViewerFranchiseWarrantersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseWarranterViewerFranchiseWarrantersSearch>;
        sort?: InputMaybe<FranchiseWarranterViewerFranchiseWarrantersSort>;
    };

    export type ViewerFranchisesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseViewerFranchisesSearch>;
        sort?: InputMaybe<FranchiseViewerFranchisesSort>;
    };

    export type ViewerHeaterRecommendationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSearch>;
        sort?: InputMaybe<HeaterRecommendationViewerHeaterRecommendationsSort>;
    };

    export type ViewerInstallableProductsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<InstallableProductViewerInstallableProductsSearch>;
        sort?: InputMaybe<InstallableProductViewerInstallableProductsSort>;
    };

    export type ViewerJobTodoTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoTemplateViewerJobTodoTemplatesSearch>;
    };

    export type ViewerLocalesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerManualProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ManualProblemViewerManualProblemsSearch>;
        sort?: InputMaybe<ManualProblemViewerManualProblemsSort>;
    };

    export type ViewerMeasurementTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<MeasurementViewerMeasurementTypesSearch>;
        sort?: InputMaybe<MeasurementViewerMeasurementTypesSort>;
    };

    export type ViewerMeasurementUnitsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<UnitViewerMeasurementUnitsSearch>;
        sort?: InputMaybe<UnitViewerMeasurementUnitsSort>;
    };

    export type ViewerOrganisationTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerPermissionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PermissionViewerPermissionsSearch>;
    };

    export type ViewerPoolBottleLeasesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolBottleLeaseViewerPoolBottleLeasesSearch>;
    };

    export type ViewerPoolBottlesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolBottleViewerPoolBottlesSearch>;
    };

    export type ViewerPoolDeclarationFlagFieldsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSearch>;
        sort?: InputMaybe<PoolDeclarationFlagFieldViewerPoolDeclarationFlagFieldsSort>;
    };

    export type ViewerPoolProductDeclarationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSearch>;
        sort?: InputMaybe<PoolProductDeclarationViewerPoolProductDeclarationsSort>;
    };

    export type ViewerPoolSanitisersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SanitiserViewerPoolSanitisersSearch>;
        sort?: InputMaybe<SanitiserViewerPoolSanitisersSort>;
    };

    export type ViewerPoolTransfersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSearch>;
        sort?: InputMaybe<PoolOwnershipHistoryViewerPoolTransfersSort>;
    };

    export type ViewerPoolTypesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolTypeViewerPoolTypesSearch>;
        sort?: InputMaybe<PoolTypeViewerPoolTypesSort>;
    };

    export type ViewerPoolsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<PoolViewerPoolsSearch>;
        sort?: InputMaybe<PoolViewerPoolsSort>;
    };

    export type ViewerProductCompanyArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductCompanyViewerProductCompanySearch>;
        searchTraits?: InputMaybe<ProductCompanyViewerProductCompanySearchTraits>;
        sort?: InputMaybe<ProductCompanyViewerProductCompanySort>;
    };

    export type ViewerProductDeclarationByTagArgs = {
        organisation?: InputMaybe<Scalars['ID']>;
        tag: Scalars['String'];
    };

    export type ViewerProductDeclarationsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearch>;
        searchTraitOverlap?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSearchTraitOverlap>;
        sort?: InputMaybe<ProductTraitDeclarationViewerProductDeclarationsSort>;
    };

    export type ViewerProductDocumentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductDocumentViewerProductDocumentsSearch>;
        sort?: InputMaybe<ProductDocumentViewerProductDocumentsSort>;
    };

    export type ViewerProductSearchHelperArgs = {
        brand?: InputMaybe<Scalars['ID']>;
        brandCompany?: InputMaybe<Scalars['ID']>;
        declarations?: InputMaybe<Array<Scalars['ID']>>;
    };

    export type ViewerProductsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProductViewerProductsSearch>;
        searchTraits?: InputMaybe<ProductViewerProductsSearchTraits>;
        sort?: InputMaybe<ProductViewerProductsSort>;
    };

    export type ViewerProjectHostingArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ProjectViewerProjectHostingSearch>;
        sort?: InputMaybe<ProjectViewerProjectHostingSort>;
    };

    export type ViewerReportsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestReportViewerReportsSearch>;
        sort?: InputMaybe<TestReportViewerReportsSort>;
    };

    export type ViewerResolveCustomerByCRNArgs = {
        crn: Scalars['String'];
    };

    export type ViewerResolveEZCDeviceMetricsArgs = {
        device: Scalars['ID'];
        from?: InputMaybe<Scalars['Float']>;
        fromDate?: InputMaybe<Scalars['Date']>;
        scope?: InputMaybe<Scalars['String']>;
        to?: InputMaybe<Scalars['Float']>;
        toDate?: InputMaybe<Scalars['Date']>;
    };

    export type ViewerResolveEZCDeviceSchemaArgs = {
        device: Scalars['ID'];
    };

    export type ViewerResolveEZCDeviceStateArgs = {
        device: Scalars['ID'];
    };

    export type ViewerResolveEZCDeviceStatesArgs = {
        devices: Array<Scalars['ID']>;
    };

    export type ViewerResolveFranchiseSequenceArgs = {
        franchise?: InputMaybe<Scalars['ID']>;
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
    };

    export type ViewerResolveFranchiseValueArgs = {
        franchise?: InputMaybe<Scalars['ID']>;
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
    };

    export type ViewerResolveNodeArgs = {
        id: Scalars['ID'];
    };

    export type ViewerResolveNodesArgs = {
        ids: Array<Scalars['ID']>;
    };

    export type ViewerResolvePoolByBottleNumberArgs = {
        bottleNumber: Scalars['String'];
    };

    export type ViewerResolveProjectByUUIDArgs = {
        uuid: Scalars['String'];
    };

    export type ViewerResolveStaffValueArgs = {
        group?: InputMaybe<Scalars['String']>;
        key: Scalars['String'];
        staff?: InputMaybe<Scalars['ID']>;
    };

    export type ViewerResolveWaterTestByRefIdArgs = {
        refId: Scalars['String'];
    };

    export type ViewerResolveWaterTestSummaryByRefIdArgs = {
        refId: Scalars['String'];
    };

    export type ViewerResolveWaterTestSummaryFromReportIdArgs = {
        testReport: Scalars['ID'];
    };

    export type ViewerRetrieveProductBrochureArgs = {
        product: Scalars['ID'];
    };

    export type ViewerRoleFlagsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<RoleFlagViewerRoleFlagsSearch>;
    };

    export type ViewerRolesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<RoleViewerRolesSearch>;
    };

    export type ViewerSampleSetsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SampleSetViewerSampleSetsSearch>;
        sort?: InputMaybe<SampleSetViewerSampleSetsSort>;
    };

    export type ViewerSampleSourcesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SampleSourceViewerSampleSourcesSearch>;
    };

    export type ViewerSentEmailsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<EmailMessageViewerSentEmailsSearch>;
        sort?: InputMaybe<EmailMessageViewerSentEmailsSort>;
    };

    export type ViewerServiceJobGroupsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobGroupViewerServiceJobGroupsSearch>;
        sort?: InputMaybe<ServiceJobGroupViewerServiceJobGroupsSort>;
    };

    export type ViewerServiceJobStagesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobStageViewerServiceJobStagesSearch>;
    };

    export type ViewerServiceJobTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobTemplateViewerServiceJobTemplatesSearch>;
        sort?: InputMaybe<ServiceJobTemplateViewerServiceJobTemplatesSort>;
    };

    export type ViewerServiceJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<ServiceJobViewerServiceJobsSearch>;
        sort?: InputMaybe<ServiceJobViewerServiceJobsSort>;
    };

    export type ViewerSettingStoreArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SettingStoreViewerSettingStoreSearch>;
        sort?: InputMaybe<SettingStoreViewerSettingStoreSort>;
    };

    export type ViewerShopsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FranchiseShopViewerShopsSearch>;
        sort?: InputMaybe<FranchiseShopViewerShopsSort>;
    };

    export type ViewerSitesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<SiteViewerSitesSearch>;
    };

    export type ViewerSolverPreviewArgs = {
        customCoefficients?: InputMaybe<Array<AnonymousCoefficientNodeInitInput>>;
        customTargets?: InputMaybe<Array<AnonymousTargetNodeInitInput>>;
        customTreatments?: InputMaybe<Array<AnonymousTreatmentNodeInitInput>>;
        forcedCriteria?: InputMaybe<Array<Scalars['ID']>>;
        forcedMeasurementProblems?: InputMaybe<Array<Scalars['ID']>>;
        forcedTreatments?: InputMaybe<Array<SolverPreviewForcedEntryNodeInitInput>>;
        franchise?: InputMaybe<Scalars['ID']>;
        performAutoSolution?: InputMaybe<Scalars['Boolean']>;
        poolType?: InputMaybe<Scalars['ID']>;
        samples?: InputMaybe<Array<AnonymousSampleNodeInitInput>>;
        sanitiser?: InputMaybe<Scalars['ID']>;
        volume: Scalars['Float'];
    };

    export type ViewerStaffArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffViewerStaffSearch>;
        sort?: InputMaybe<StaffViewerStaffSort>;
    };

    export type ViewerStaffTeamsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffTeamViewerStaffTeamsSearch>;
        sort?: InputMaybe<StaffTeamViewerStaffTeamsSort>;
    };

    export type ViewerStaffValuesStoreArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<StaffValueStoreViewerStaffValuesStoreSearch>;
        sort?: InputMaybe<StaffValueStoreViewerStaffValuesStoreSort>;
    };

    export type ViewerTestCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestCriteriaViewerTestCriteriaSearch>;
        sort?: InputMaybe<TestCriteriaViewerTestCriteriaSort>;
    };

    export type ViewerTestProblemsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestProblemViewerTestProblemsSearch>;
        sort?: InputMaybe<TestProblemViewerTestProblemsSort>;
    };

    export type ViewerTestTreatmentGroupForFranchisesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSearch>;
        sort?: InputMaybe<TestTreatmentFranchiseGroupViewerTestTreatmentGroupForFranchisesSort>;
    };

    export type ViewerTestTreatmentGroupsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentGroupViewerTestTreatmentGroupsSearch>;
        sort?: InputMaybe<TestTreatmentGroupViewerTestTreatmentGroupsSort>;
    };

    export type ViewerTestTreatmentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestTreatmentViewerTestTreatmentsSearch>;
        sort?: InputMaybe<TestTreatmentViewerTestTreatmentsSort>;
    };

    export type ViewerTimezonesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerUnitConversionsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<UnitConversionViewerUnitConversionsSearch>;
    };

    export type ViewerUnitConvertersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
    };

    export type ViewerUniversalCriteriaArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<UniversalCriteriaViewerUniversalCriteriaSearch>;
    };

    export type ViewerUsersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<UserViewerUsersSearch>;
        sort?: InputMaybe<UserViewerUsersSort>;
    };

    export type ViewerWarranterClaimMessagesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimMessageViewerWarranterClaimMessagesSearch>;
        sort?: InputMaybe<WarrantyClaimMessageViewerWarranterClaimMessagesSort>;
    };

    export type ViewerWarrantyClaimCasesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearch>;
        sort?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSort>;
    };

    export type ViewerWarrantyClaimsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearch>;
        sort?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSort>;
    };

    export type ViewerWorkOrderFeedArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
        search?: InputMaybe<WorkOrderFeedItemViewerWorkOrderFeedSearch>;
    };

    export type ViewerWorkOrderTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderTemplateViewerWorkOrderTemplatesSearch>;
        sort?: InputMaybe<WorkOrderTemplateViewerWorkOrderTemplatesSort>;
    };

    export type ViewerWorkOrdersArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderViewerWorkOrdersSearch>;
        sort?: InputMaybe<WorkOrderViewerWorkOrdersSort>;
    };

    /** A connection to a list of items. */
    export type ViewerAbstractDocumentsConnection = {
        __typename?: 'ViewerAbstractDocumentsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerAbstractDocumentsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerAbstractDocumentsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerAbstractDocumentsEdge = {
        __typename?: 'ViewerAbstractDocumentsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AbstractDocument>;
    };

    export type ViewerAbstractDocumentsMeta = {
        __typename?: 'ViewerAbstractDocumentsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerAnnouncementsConnection = {
        __typename?: 'ViewerAnnouncementsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerAnnouncementsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerAnnouncementsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerAnnouncementsEdge = {
        __typename?: 'ViewerAnnouncementsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Announcement>;
    };

    export type ViewerAnnouncementsMeta = {
        __typename?: 'ViewerAnnouncementsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerAppointmentGroupsConnection = {
        __typename?: 'ViewerAppointmentGroupsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerAppointmentGroupsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerAppointmentGroupsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerAppointmentGroupsEdge = {
        __typename?: 'ViewerAppointmentGroupsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentGroup>;
    };

    export type ViewerAppointmentGroupsMeta = {
        __typename?: 'ViewerAppointmentGroupsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerAppointmentItemsConnection = {
        __typename?: 'ViewerAppointmentItemsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerAppointmentItemsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerAppointmentItemsEdge = {
        __typename?: 'ViewerAppointmentItemsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<AppointmentItem>;
    };

    /** A connection to a list of items. */
    export type ViewerAppointmentsConnection = {
        __typename?: 'ViewerAppointmentsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerAppointmentsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerAppointmentsEdge = {
        __typename?: 'ViewerAppointmentsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Appointment>;
    };

    /** A connection to a list of items. */
    export type ViewerBrandsConnection = {
        __typename?: 'ViewerBrandsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerBrandsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerBrandsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerBrandsEdge = {
        __typename?: 'ViewerBrandsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Brand>;
    };

    export type ViewerBrandsMeta = {
        __typename?: 'ViewerBrandsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerConsumableProductsConnection = {
        __typename?: 'ViewerConsumableProductsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerConsumableProductsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerConsumableProductsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerConsumableProductsEdge = {
        __typename?: 'ViewerConsumableProductsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ConsumableProduct>;
    };

    export type ViewerConsumableProductsMeta = {
        __typename?: 'ViewerConsumableProductsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerContactTypesConnection = {
        __typename?: 'ViewerContactTypesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerContactTypesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerContactTypesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerContactTypesEdge = {
        __typename?: 'ViewerContactTypesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ContactType>;
    };

    export type ViewerContactTypesMeta = {
        __typename?: 'ViewerContactTypesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerConvertersConnection = {
        __typename?: 'ViewerConvertersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerConvertersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerConvertersEdge = {
        __typename?: 'ViewerConvertersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleConverter>;
    };

    /** A connection to a list of items. */
    export type ViewerCustomContractsConnection = {
        __typename?: 'ViewerCustomContractsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerCustomContractsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerCustomContractsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerCustomContractsEdge = {
        __typename?: 'ViewerCustomContractsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<CustomContract>;
    };

    export type ViewerCustomContractsMeta = {
        __typename?: 'ViewerCustomContractsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerCustomerTagsConnection = {
        __typename?: 'ViewerCustomerTagsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerCustomerTagsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerCustomerTagsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerCustomerTagsEdge = {
        __typename?: 'ViewerCustomerTagsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<CustomerTag>;
    };

    export type ViewerCustomerTagsMeta = {
        __typename?: 'ViewerCustomerTagsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerCustomersConnection = {
        __typename?: 'ViewerCustomersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerCustomersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerCustomersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerCustomersEdge = {
        __typename?: 'ViewerCustomersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Customer>;
    };

    export type ViewerCustomersMeta = {
        __typename?: 'ViewerCustomersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEmailGlobalTemplateSelectionsConnection = {
        __typename?: 'ViewerEmailGlobalTemplateSelectionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEmailGlobalTemplateSelectionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEmailGlobalTemplateSelectionsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEmailGlobalTemplateSelectionsEdge = {
        __typename?: 'ViewerEmailGlobalTemplateSelectionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailGlobalSelection>;
    };

    export type ViewerEmailGlobalTemplateSelectionsMeta = {
        __typename?: 'ViewerEmailGlobalTemplateSelectionsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEmailOrganisationTemplateSelectionsConnection = {
        __typename?: 'ViewerEmailOrganisationTemplateSelectionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEmailOrganisationTemplateSelectionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEmailOrganisationTemplateSelectionsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEmailOrganisationTemplateSelectionsEdge = {
        __typename?: 'ViewerEmailOrganisationTemplateSelectionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailTemplateOrganisationSelection>;
    };

    export type ViewerEmailOrganisationTemplateSelectionsMeta = {
        __typename?: 'ViewerEmailOrganisationTemplateSelectionsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEmailTemplateSelectionsConnection = {
        __typename?: 'ViewerEmailTemplateSelectionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEmailTemplateSelectionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEmailTemplateSelectionsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEmailTemplateSelectionsEdge = {
        __typename?: 'ViewerEmailTemplateSelectionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailTemplateSelection>;
    };

    export type ViewerEmailTemplateSelectionsMeta = {
        __typename?: 'ViewerEmailTemplateSelectionsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEmailTemplatesConnection = {
        __typename?: 'ViewerEmailTemplatesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEmailTemplatesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEmailTemplatesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEmailTemplatesEdge = {
        __typename?: 'ViewerEmailTemplatesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailTemplate>;
    };

    export type ViewerEmailTemplatesMeta = {
        __typename?: 'ViewerEmailTemplatesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEmailTypesConnection = {
        __typename?: 'ViewerEmailTypesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEmailTypesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerEmailTypesEdge = {
        __typename?: 'ViewerEmailTypesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailType>;
    };

    /** A connection to a list of items. */
    export type ViewerEntitiesConnection = {
        __typename?: 'ViewerEntitiesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEntitiesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEntitiesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEntitiesEdge = {
        __typename?: 'ViewerEntitiesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Entity>;
    };

    export type ViewerEntitiesMeta = {
        __typename?: 'ViewerEntitiesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEzcDeviceDefinitionsConnection = {
        __typename?: 'ViewerEzcDeviceDefinitionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEzcDeviceDefinitionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerEzcDeviceDefinitionsEdge = {
        __typename?: 'ViewerEzcDeviceDefinitionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDeviceDefinition>;
    };

    /** A connection to a list of items. */
    export type ViewerEzcDeviceLinksConnection = {
        __typename?: 'ViewerEzcDeviceLinksConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEzcDeviceLinksEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEzcDeviceLinksMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEzcDeviceLinksEdge = {
        __typename?: 'ViewerEzcDeviceLinksEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDeviceLink>;
    };

    export type ViewerEzcDeviceLinksMeta = {
        __typename?: 'ViewerEzcDeviceLinksMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEzcDevicesConnection = {
        __typename?: 'ViewerEzcDevicesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEzcDevicesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerEzcDevicesEdge = {
        __typename?: 'ViewerEzcDevicesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCDevice>;
    };

    /** A connection to a list of items. */
    export type ViewerEzcServiceProviderLinksConnection = {
        __typename?: 'ViewerEzcServiceProviderLinksConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEzcServiceProviderLinksEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerEzcServiceProviderLinksMeta>;
    };

    /** An edge in a connection. */
    export type ViewerEzcServiceProviderLinksEdge = {
        __typename?: 'ViewerEzcServiceProviderLinksEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCFranchiseLink>;
    };

    export type ViewerEzcServiceProviderLinksMeta = {
        __typename?: 'ViewerEzcServiceProviderLinksMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerEzcServiceProvidersConnection = {
        __typename?: 'ViewerEzcServiceProvidersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerEzcServiceProvidersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerEzcServiceProvidersEdge = {
        __typename?: 'ViewerEzcServiceProvidersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EZCServiceProvider>;
    };

    /** A connection to a list of items. */
    export type ViewerFeatureFlagVariationsConnection = {
        __typename?: 'ViewerFeatureFlagVariationsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFeatureFlagVariationsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFeatureFlagVariationsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFeatureFlagVariationsEdge = {
        __typename?: 'ViewerFeatureFlagVariationsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FeatureFlagVariation>;
    };

    export type ViewerFeatureFlagVariationsMeta = {
        __typename?: 'ViewerFeatureFlagVariationsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerFeatureFlagsConnection = {
        __typename?: 'ViewerFeatureFlagsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFeatureFlagsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerFeatureFlagsEdge = {
        __typename?: 'ViewerFeatureFlagsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FeatureFlag>;
    };

    /** A connection to a list of items. */
    export type ViewerFilesConnection = {
        __typename?: 'ViewerFilesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFilesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFilesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFilesEdge = {
        __typename?: 'ViewerFilesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FileUpload>;
    };

    export type ViewerFilesMeta = {
        __typename?: 'ViewerFilesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerFranchiseSequenceStoreConnection = {
        __typename?: 'ViewerFranchiseSequenceStoreConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFranchiseSequenceStoreEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFranchiseSequenceStoreMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFranchiseSequenceStoreEdge = {
        __typename?: 'ViewerFranchiseSequenceStoreEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseSequenceStore>;
    };

    export type ViewerFranchiseSequenceStoreMeta = {
        __typename?: 'ViewerFranchiseSequenceStoreMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerFranchiseValuesStoreConnection = {
        __typename?: 'ViewerFranchiseValuesStoreConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFranchiseValuesStoreEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFranchiseValuesStoreMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFranchiseValuesStoreEdge = {
        __typename?: 'ViewerFranchiseValuesStoreEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseValueStore>;
    };

    export type ViewerFranchiseValuesStoreMeta = {
        __typename?: 'ViewerFranchiseValuesStoreMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerFranchiseWarrantersConnection = {
        __typename?: 'ViewerFranchiseWarrantersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFranchiseWarrantersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFranchiseWarrantersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFranchiseWarrantersEdge = {
        __typename?: 'ViewerFranchiseWarrantersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseWarranter>;
    };

    export type ViewerFranchiseWarrantersMeta = {
        __typename?: 'ViewerFranchiseWarrantersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerFranchisesConnection = {
        __typename?: 'ViewerFranchisesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerFranchisesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerFranchisesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerFranchisesEdge = {
        __typename?: 'ViewerFranchisesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Franchise>;
    };

    export type ViewerFranchisesMeta = {
        __typename?: 'ViewerFranchisesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerHeaterRecommendationsConnection = {
        __typename?: 'ViewerHeaterRecommendationsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerHeaterRecommendationsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerHeaterRecommendationsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerHeaterRecommendationsEdge = {
        __typename?: 'ViewerHeaterRecommendationsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<HeaterRecommendation>;
    };

    export type ViewerHeaterRecommendationsMeta = {
        __typename?: 'ViewerHeaterRecommendationsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerInstallableProductsConnection = {
        __typename?: 'ViewerInstallableProductsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerInstallableProductsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerInstallableProductsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerInstallableProductsEdge = {
        __typename?: 'ViewerInstallableProductsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<InstallableProduct>;
    };

    export type ViewerInstallableProductsMeta = {
        __typename?: 'ViewerInstallableProductsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerJobTodoTemplatesConnection = {
        __typename?: 'ViewerJobTodoTemplatesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerJobTodoTemplatesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerJobTodoTemplatesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerJobTodoTemplatesEdge = {
        __typename?: 'ViewerJobTodoTemplatesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<JobTodoTemplate>;
    };

    export type ViewerJobTodoTemplatesMeta = {
        __typename?: 'ViewerJobTodoTemplatesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerLocalesConnection = {
        __typename?: 'ViewerLocalesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerLocalesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerLocalesEdge = {
        __typename?: 'ViewerLocalesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Locales>;
    };

    /** A connection to a list of items. */
    export type ViewerManualProblemsConnection = {
        __typename?: 'ViewerManualProblemsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerManualProblemsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerManualProblemsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerManualProblemsEdge = {
        __typename?: 'ViewerManualProblemsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ManualProblem>;
    };

    export type ViewerManualProblemsMeta = {
        __typename?: 'ViewerManualProblemsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerMeasurementTypesConnection = {
        __typename?: 'ViewerMeasurementTypesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerMeasurementTypesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerMeasurementTypesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerMeasurementTypesEdge = {
        __typename?: 'ViewerMeasurementTypesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Measurement>;
    };

    export type ViewerMeasurementTypesMeta = {
        __typename?: 'ViewerMeasurementTypesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerMeasurementUnitsConnection = {
        __typename?: 'ViewerMeasurementUnitsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerMeasurementUnitsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerMeasurementUnitsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerMeasurementUnitsEdge = {
        __typename?: 'ViewerMeasurementUnitsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Unit>;
    };

    export type ViewerMeasurementUnitsMeta = {
        __typename?: 'ViewerMeasurementUnitsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerOrganisationTypesConnection = {
        __typename?: 'ViewerOrganisationTypesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerOrganisationTypesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerOrganisationTypesEdge = {
        __typename?: 'ViewerOrganisationTypesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<OrganisationType>;
    };

    export type ViewerPermissions = {
        __typename?: 'ViewerPermissions';
        AbstractDocument?: Maybe<NodeAbstractDocumentPermissions>;
        Address?: Maybe<NodeAddressPermissions>;
        Announcement?: Maybe<NodeAnnouncementPermissions>;
        Appointment?: Maybe<NodeAppointmentPermissions>;
        AppointmentGroup?: Maybe<NodeAppointmentGroupPermissions>;
        AppointmentInstance?: Maybe<NodeAppointmentInstancePermissions>;
        AppointmentItem?: Maybe<NodeAppointmentItemPermissions>;
        Brand?: Maybe<NodeBrandPermissions>;
        ConsumableProduct?: Maybe<NodeConsumableProductPermissions>;
        Contact?: Maybe<NodeContactPermissions>;
        ContactType?: Maybe<NodeContactTypePermissions>;
        CustomContract?: Maybe<NodeCustomContractPermissions>;
        Customer?: Maybe<NodeCustomerPermissions>;
        CustomerNote?: Maybe<NodeCustomerNotePermissions>;
        CustomerTag?: Maybe<NodeCustomerTagPermissions>;
        EZCDeviceLink?: Maybe<NodeEZCDeviceLinkPermissions>;
        EZCFranchiseLink?: Maybe<NodeEZCFranchiseLinkPermissions>;
        EmailGlobalSelection?: Maybe<NodeEmailGlobalSelectionPermissions>;
        EmailMessage?: Maybe<NodeEmailMessagePermissions>;
        EmailTemplate?: Maybe<NodeEmailTemplatePermissions>;
        EmailTemplateOrganisationSelection?: Maybe<NodeEmailTemplateOrganisationSelectionPermissions>;
        EmailTemplateSelection?: Maybe<NodeEmailTemplateSelectionPermissions>;
        Entity?: Maybe<NodeEntityPermissions>;
        FeatureFlag?: Maybe<NodeFeatureFlagPermissions>;
        FeatureFlagDescription?: Maybe<NodeFeatureFlagDescriptionPermissions>;
        FeatureFlagVariation?: Maybe<NodeFeatureFlagVariationPermissions>;
        FileUpload?: Maybe<NodeFileUploadPermissions>;
        Franchise?: Maybe<NodeFranchisePermissions>;
        FranchiseSequenceStore?: Maybe<NodeFranchiseSequenceStorePermissions>;
        FranchiseShop?: Maybe<NodeFranchiseShopPermissions>;
        FranchiseTestTreatmentView?: Maybe<NodeFranchiseTestTreatmentViewPermissions>;
        FranchiseValueStore?: Maybe<NodeFranchiseValueStorePermissions>;
        FranchiseWarranter?: Maybe<NodeFranchiseWarranterPermissions>;
        HeaterRecommendation?: Maybe<NodeHeaterRecommendationPermissions>;
        InstallableProduct?: Maybe<NodeInstallableProductPermissions>;
        Item?: Maybe<NodeItemPermissions>;
        JobTodo?: Maybe<NodeJobTodoPermissions>;
        JobTodoItem?: Maybe<NodeJobTodoItemPermissions>;
        JobTodoTemplate?: Maybe<NodeJobTodoTemplatePermissions>;
        JobTodoTemplateAssoc?: Maybe<NodeJobTodoTemplateAssocPermissions>;
        JobTodoTemplateItem?: Maybe<NodeJobTodoTemplateItemPermissions>;
        ManualProblem?: Maybe<NodeManualProblemPermissions>;
        Measurement?: Maybe<NodeMeasurementPermissions>;
        Permission?: Maybe<NodePermissionPermissions>;
        Pool?: Maybe<NodePoolPermissions>;
        PoolAttachedFlagField?: Maybe<NodePoolAttachedFlagFieldPermissions>;
        PoolBottle?: Maybe<NodePoolBottlePermissions>;
        PoolBottleLease?: Maybe<NodePoolBottleLeasePermissions>;
        PoolCalibration?: Maybe<NodePoolCalibrationPermissions>;
        PoolDeclarationFlagField?: Maybe<NodePoolDeclarationFlagFieldPermissions>;
        PoolNote?: Maybe<NodePoolNotePermissions>;
        PoolProductDeclaration?: Maybe<NodePoolProductDeclarationPermissions>;
        PoolType?: Maybe<NodePoolTypePermissions>;
        Product?: Maybe<NodeProductPermissions>;
        ProductCompany?: Maybe<NodeProductCompanyPermissions>;
        ProductDeclarationFlagField?: Maybe<NodeProductDeclarationFlagFieldPermissions>;
        ProductDeclarationFloatField?: Maybe<NodeProductDeclarationFloatFieldPermissions>;
        ProductDeclarationIntegerField?: Maybe<NodeProductDeclarationIntegerFieldPermissions>;
        ProductDeclarationMeasurementField?: Maybe<NodeProductDeclarationMeasurementFieldPermissions>;
        ProductDeclarationSelectionChoice?: Maybe<NodeProductDeclarationSelectionChoicePermissions>;
        ProductDeclarationSelectionField?: Maybe<NodeProductDeclarationSelectionFieldPermissions>;
        ProductDeclarationStringField?: Maybe<NodeProductDeclarationStringFieldPermissions>;
        ProductDeclarationUnitField?: Maybe<NodeProductDeclarationUnitFieldPermissions>;
        ProductDocument?: Maybe<NodeProductDocumentPermissions>;
        ProductTrait?: Maybe<NodeProductTraitPermissions>;
        ProductTraitDeclaration?: Maybe<NodeProductTraitDeclarationPermissions>;
        ProductTraitFlagValue?: Maybe<NodeProductTraitFlagValuePermissions>;
        ProductTraitFloatValue?: Maybe<NodeProductTraitFloatValuePermissions>;
        ProductTraitIntegerValue?: Maybe<NodeProductTraitIntegerValuePermissions>;
        ProductTraitMeasurementValue?: Maybe<NodeProductTraitMeasurementValuePermissions>;
        ProductTraitSelectionValue?: Maybe<NodeProductTraitSelectionValuePermissions>;
        ProductTraitStringValue?: Maybe<NodeProductTraitStringValuePermissions>;
        ProductTraitUnitValue?: Maybe<NodeProductTraitUnitValuePermissions>;
        Project?: Maybe<NodeProjectPermissions>;
        ProjectVersion?: Maybe<NodeProjectVersionPermissions>;
        RecommendedTreatment?: Maybe<NodeRecommendedTreatmentPermissions>;
        Role?: Maybe<NodeRolePermissions>;
        RoleFlag?: Maybe<NodeRoleFlagPermissions>;
        Sample?: Maybe<NodeSamplePermissions>;
        SampleConverter?: Maybe<NodeSampleConverterPermissions>;
        SampleConverterTemplate?: Maybe<NodeSampleConverterTemplatePermissions>;
        SampleSet?: Maybe<NodeSampleSetPermissions>;
        SampleSource?: Maybe<NodeSampleSourcePermissions>;
        Sanitiser?: Maybe<NodeSanitiserPermissions>;
        ScheduleEvent?: Maybe<NodeScheduleEventPermissions>;
        ServiceJob?: Maybe<NodeServiceJobPermissions>;
        ServiceJobGroup?: Maybe<NodeServiceJobGroupPermissions>;
        ServiceJobNote?: Maybe<NodeServiceJobNotePermissions>;
        ServiceJobStage?: Maybe<NodeServiceJobStagePermissions>;
        ServiceJobStageHistory?: Maybe<NodeServiceJobStageHistoryPermissions>;
        ServiceJobTemplate?: Maybe<NodeServiceJobTemplatePermissions>;
        SettingStore?: Maybe<NodeSettingStorePermissions>;
        SharedEntity?: Maybe<NodeSharedEntityPermissions>;
        Site?: Maybe<NodeSitePermissions>;
        SiteNote?: Maybe<NodeSiteNotePermissions>;
        Staff?: Maybe<NodeStaffPermissions>;
        StaffAccessToken?: Maybe<NodeStaffAccessTokenPermissions>;
        StaffRegistration?: Maybe<NodeStaffRegistrationPermissions>;
        StaffTeam?: Maybe<NodeStaffTeamPermissions>;
        StaffTeamLink?: Maybe<NodeStaffTeamLinkPermissions>;
        StaffValueStore?: Maybe<NodeStaffValueStorePermissions>;
        TestCriteria?: Maybe<NodeTestCriteriaPermissions>;
        TestProblem?: Maybe<NodeTestProblemPermissions>;
        TestRecommendation?: Maybe<NodeTestRecommendationPermissions>;
        TestReport?: Maybe<NodeTestReportPermissions>;
        TestReportAttachment?: Maybe<NodeTestReportAttachmentPermissions>;
        TestReportSolution?: Maybe<NodeTestReportSolutionPermissions>;
        TestTreatment?: Maybe<NodeTestTreatmentPermissions>;
        TestTreatmentFranchiseBlacklist?: Maybe<NodeTestTreatmentFranchiseBlacklistPermissions>;
        TestTreatmentFranchiseGroup?: Maybe<NodeTestTreatmentFranchiseGroupPermissions>;
        TestTreatmentFranchisePriority?: Maybe<NodeTestTreatmentFranchisePriorityPermissions>;
        TestTreatmentGroup?: Maybe<NodeTestTreatmentGroupPermissions>;
        TreatmentAffect?: Maybe<NodeTreatmentAffectPermissions>;
        Unit?: Maybe<NodeUnitPermissions>;
        UnitConversion?: Maybe<NodeUnitConversionPermissions>;
        UniversalCriteria?: Maybe<NodeUniversalCriteriaPermissions>;
        User?: Maybe<NodeUserPermissions>;
        VendAPI?: Maybe<NodeVendAPIPermissions>;
        VendAppointmentSaleType?: Maybe<NodeVendAppointmentSaleTypePermissions>;
        VendCustomer?: Maybe<NodeVendCustomerPermissions>;
        VendLink?: Maybe<NodeVendLinkPermissions>;
        VendSale?: Maybe<NodeVendSalePermissions>;
        WarrantyClaim?: Maybe<NodeWarrantyClaimPermissions>;
        WarrantyClaimCase?: Maybe<NodeWarrantyClaimCasePermissions>;
        WarrantyClaimFileAttach?: Maybe<NodeWarrantyClaimFileAttachPermissions>;
        WarrantyClaimMessage?: Maybe<NodeWarrantyClaimMessagePermissions>;
        WarrantyClaimStatusHistory?: Maybe<NodeWarrantyClaimStatusHistoryPermissions>;
        WaterTestingRevision?: Maybe<NodeWaterTestingRevisionPermissions>;
        WorkOrder?: Maybe<NodeWorkOrderPermissions>;
        WorkOrderFeedItem?: Maybe<NodeWorkOrderFeedItemPermissions>;
        WorkOrderNote?: Maybe<NodeWorkOrderNotePermissions>;
        WorkOrderStageHistory?: Maybe<NodeWorkOrderStageHistoryPermissions>;
        WorkOrderTemplate?: Maybe<NodeWorkOrderTemplatePermissions>;
        WorkOrderTemplateAssoc?: Maybe<NodeWorkOrderTemplateAssocPermissions>;
    };

    /** A connection to a list of items. */
    export type ViewerPermissionsConnection = {
        __typename?: 'ViewerPermissionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPermissionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerPermissionsEdge = {
        __typename?: 'ViewerPermissionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Permission>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolBottleLeasesConnection = {
        __typename?: 'ViewerPoolBottleLeasesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolBottleLeasesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolBottleLeasesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolBottleLeasesEdge = {
        __typename?: 'ViewerPoolBottleLeasesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolBottleLease>;
    };

    export type ViewerPoolBottleLeasesMeta = {
        __typename?: 'ViewerPoolBottleLeasesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolBottlesConnection = {
        __typename?: 'ViewerPoolBottlesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolBottlesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolBottlesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolBottlesEdge = {
        __typename?: 'ViewerPoolBottlesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolBottle>;
    };

    export type ViewerPoolBottlesMeta = {
        __typename?: 'ViewerPoolBottlesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolDeclarationFlagFieldsConnection = {
        __typename?: 'ViewerPoolDeclarationFlagFieldsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolDeclarationFlagFieldsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolDeclarationFlagFieldsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolDeclarationFlagFieldsEdge = {
        __typename?: 'ViewerPoolDeclarationFlagFieldsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolDeclarationFlagField>;
    };

    export type ViewerPoolDeclarationFlagFieldsMeta = {
        __typename?: 'ViewerPoolDeclarationFlagFieldsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolProductDeclarationsConnection = {
        __typename?: 'ViewerPoolProductDeclarationsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolProductDeclarationsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolProductDeclarationsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolProductDeclarationsEdge = {
        __typename?: 'ViewerPoolProductDeclarationsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolProductDeclaration>;
    };

    export type ViewerPoolProductDeclarationsMeta = {
        __typename?: 'ViewerPoolProductDeclarationsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolSanitisersConnection = {
        __typename?: 'ViewerPoolSanitisersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolSanitisersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolSanitisersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolSanitisersEdge = {
        __typename?: 'ViewerPoolSanitisersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Sanitiser>;
    };

    export type ViewerPoolSanitisersMeta = {
        __typename?: 'ViewerPoolSanitisersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolTransfersConnection = {
        __typename?: 'ViewerPoolTransfersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolTransfersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolTransfersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolTransfersEdge = {
        __typename?: 'ViewerPoolTransfersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolOwnershipHistory>;
    };

    export type ViewerPoolTransfersMeta = {
        __typename?: 'ViewerPoolTransfersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolTypesConnection = {
        __typename?: 'ViewerPoolTypesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolTypesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolTypesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolTypesEdge = {
        __typename?: 'ViewerPoolTypesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<PoolType>;
    };

    export type ViewerPoolTypesMeta = {
        __typename?: 'ViewerPoolTypesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerPoolsConnection = {
        __typename?: 'ViewerPoolsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerPoolsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerPoolsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerPoolsEdge = {
        __typename?: 'ViewerPoolsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Pool>;
    };

    export type ViewerPoolsMeta = {
        __typename?: 'ViewerPoolsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerProductCompanyConnection = {
        __typename?: 'ViewerProductCompanyConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerProductCompanyEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerProductCompanyMeta>;
    };

    /** An edge in a connection. */
    export type ViewerProductCompanyEdge = {
        __typename?: 'ViewerProductCompanyEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductCompany>;
    };

    export type ViewerProductCompanyMeta = {
        __typename?: 'ViewerProductCompanyMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerProductDeclarationsConnection = {
        __typename?: 'ViewerProductDeclarationsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerProductDeclarationsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerProductDeclarationsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerProductDeclarationsEdge = {
        __typename?: 'ViewerProductDeclarationsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductTraitDeclaration>;
    };

    export type ViewerProductDeclarationsMeta = {
        __typename?: 'ViewerProductDeclarationsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerProductDocumentsConnection = {
        __typename?: 'ViewerProductDocumentsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerProductDocumentsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerProductDocumentsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerProductDocumentsEdge = {
        __typename?: 'ViewerProductDocumentsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ProductDocument>;
    };

    export type ViewerProductDocumentsMeta = {
        __typename?: 'ViewerProductDocumentsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerProductsConnection = {
        __typename?: 'ViewerProductsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerProductsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerProductsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerProductsEdge = {
        __typename?: 'ViewerProductsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Product>;
    };

    export type ViewerProductsMeta = {
        __typename?: 'ViewerProductsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerProjectHostingConnection = {
        __typename?: 'ViewerProjectHostingConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerProjectHostingEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerProjectHostingMeta>;
    };

    /** An edge in a connection. */
    export type ViewerProjectHostingEdge = {
        __typename?: 'ViewerProjectHostingEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Project>;
    };

    export type ViewerProjectHostingMeta = {
        __typename?: 'ViewerProjectHostingMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerReportsConnection = {
        __typename?: 'ViewerReportsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerReportsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerReportsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerReportsEdge = {
        __typename?: 'ViewerReportsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestReport>;
    };

    export type ViewerReportsMeta = {
        __typename?: 'ViewerReportsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerRoleFlagsConnection = {
        __typename?: 'ViewerRoleFlagsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerRoleFlagsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerRoleFlagsEdge = {
        __typename?: 'ViewerRoleFlagsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<RoleFlag>;
    };

    /** A connection to a list of items. */
    export type ViewerRolesConnection = {
        __typename?: 'ViewerRolesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerRolesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerRolesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerRolesEdge = {
        __typename?: 'ViewerRolesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Role>;
    };

    export type ViewerRolesMeta = {
        __typename?: 'ViewerRolesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerSampleSetsConnection = {
        __typename?: 'ViewerSampleSetsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerSampleSetsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerSampleSetsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerSampleSetsEdge = {
        __typename?: 'ViewerSampleSetsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleSet>;
    };

    export type ViewerSampleSetsMeta = {
        __typename?: 'ViewerSampleSetsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerSampleSourcesConnection = {
        __typename?: 'ViewerSampleSourcesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerSampleSourcesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerSampleSourcesEdge = {
        __typename?: 'ViewerSampleSourcesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SampleSource>;
    };

    /** A connection to a list of items. */
    export type ViewerSentEmailsConnection = {
        __typename?: 'ViewerSentEmailsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerSentEmailsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerSentEmailsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerSentEmailsEdge = {
        __typename?: 'ViewerSentEmailsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<EmailMessage>;
    };

    export type ViewerSentEmailsMeta = {
        __typename?: 'ViewerSentEmailsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerServiceJobGroupsConnection = {
        __typename?: 'ViewerServiceJobGroupsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerServiceJobGroupsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerServiceJobGroupsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerServiceJobGroupsEdge = {
        __typename?: 'ViewerServiceJobGroupsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobGroup>;
    };

    export type ViewerServiceJobGroupsMeta = {
        __typename?: 'ViewerServiceJobGroupsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerServiceJobStagesConnection = {
        __typename?: 'ViewerServiceJobStagesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerServiceJobStagesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerServiceJobStagesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerServiceJobStagesEdge = {
        __typename?: 'ViewerServiceJobStagesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobStage>;
    };

    export type ViewerServiceJobStagesMeta = {
        __typename?: 'ViewerServiceJobStagesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerServiceJobTemplatesConnection = {
        __typename?: 'ViewerServiceJobTemplatesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerServiceJobTemplatesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerServiceJobTemplatesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerServiceJobTemplatesEdge = {
        __typename?: 'ViewerServiceJobTemplatesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJobTemplate>;
    };

    export type ViewerServiceJobTemplatesMeta = {
        __typename?: 'ViewerServiceJobTemplatesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerServiceJobsConnection = {
        __typename?: 'ViewerServiceJobsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerServiceJobsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerServiceJobsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerServiceJobsEdge = {
        __typename?: 'ViewerServiceJobsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ServiceJob>;
    };

    export type ViewerServiceJobsMeta = {
        __typename?: 'ViewerServiceJobsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerSettingStoreConnection = {
        __typename?: 'ViewerSettingStoreConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerSettingStoreEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerSettingStoreMeta>;
    };

    /** An edge in a connection. */
    export type ViewerSettingStoreEdge = {
        __typename?: 'ViewerSettingStoreEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<SettingStore>;
    };

    export type ViewerSettingStoreMeta = {
        __typename?: 'ViewerSettingStoreMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerShopsConnection = {
        __typename?: 'ViewerShopsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerShopsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerShopsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerShopsEdge = {
        __typename?: 'ViewerShopsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<FranchiseShop>;
    };

    export type ViewerShopsMeta = {
        __typename?: 'ViewerShopsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerSitesConnection = {
        __typename?: 'ViewerSitesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerSitesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerSitesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerSitesEdge = {
        __typename?: 'ViewerSitesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Site>;
    };

    export type ViewerSitesMeta = {
        __typename?: 'ViewerSitesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerStaffConnection = {
        __typename?: 'ViewerStaffConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerStaffEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerStaffMeta>;
    };

    /** An edge in a connection. */
    export type ViewerStaffEdge = {
        __typename?: 'ViewerStaffEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Staff>;
    };

    export type ViewerStaffMeta = {
        __typename?: 'ViewerStaffMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerStaffTeamsConnection = {
        __typename?: 'ViewerStaffTeamsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerStaffTeamsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerStaffTeamsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerStaffTeamsEdge = {
        __typename?: 'ViewerStaffTeamsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<StaffTeam>;
    };

    export type ViewerStaffTeamsMeta = {
        __typename?: 'ViewerStaffTeamsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerStaffValuesStoreConnection = {
        __typename?: 'ViewerStaffValuesStoreConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerStaffValuesStoreEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerStaffValuesStoreMeta>;
    };

    /** An edge in a connection. */
    export type ViewerStaffValuesStoreEdge = {
        __typename?: 'ViewerStaffValuesStoreEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<StaffValueStore>;
    };

    export type ViewerStaffValuesStoreMeta = {
        __typename?: 'ViewerStaffValuesStoreMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTestCriteriaConnection = {
        __typename?: 'ViewerTestCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTestCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerTestCriteriaMeta>;
    };

    /** An edge in a connection. */
    export type ViewerTestCriteriaEdge = {
        __typename?: 'ViewerTestCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestCriteria>;
    };

    export type ViewerTestCriteriaMeta = {
        __typename?: 'ViewerTestCriteriaMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTestProblemsConnection = {
        __typename?: 'ViewerTestProblemsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTestProblemsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerTestProblemsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerTestProblemsEdge = {
        __typename?: 'ViewerTestProblemsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestProblem>;
    };

    export type ViewerTestProblemsMeta = {
        __typename?: 'ViewerTestProblemsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTestTreatmentGroupForFranchisesConnection = {
        __typename?: 'ViewerTestTreatmentGroupForFranchisesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTestTreatmentGroupForFranchisesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerTestTreatmentGroupForFranchisesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerTestTreatmentGroupForFranchisesEdge = {
        __typename?: 'ViewerTestTreatmentGroupForFranchisesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatmentFranchiseGroup>;
    };

    export type ViewerTestTreatmentGroupForFranchisesMeta = {
        __typename?: 'ViewerTestTreatmentGroupForFranchisesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTestTreatmentGroupsConnection = {
        __typename?: 'ViewerTestTreatmentGroupsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTestTreatmentGroupsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerTestTreatmentGroupsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerTestTreatmentGroupsEdge = {
        __typename?: 'ViewerTestTreatmentGroupsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatmentGroup>;
    };

    export type ViewerTestTreatmentGroupsMeta = {
        __typename?: 'ViewerTestTreatmentGroupsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTestTreatmentsConnection = {
        __typename?: 'ViewerTestTreatmentsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTestTreatmentsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerTestTreatmentsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerTestTreatmentsEdge = {
        __typename?: 'ViewerTestTreatmentsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<TestTreatment>;
    };

    export type ViewerTestTreatmentsMeta = {
        __typename?: 'ViewerTestTreatmentsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerTimezonesConnection = {
        __typename?: 'ViewerTimezonesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerTimezonesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerTimezonesEdge = {
        __typename?: 'ViewerTimezonesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<Timezone>;
    };

    /** A connection to a list of items. */
    export type ViewerUnitConversionsConnection = {
        __typename?: 'ViewerUnitConversionsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerUnitConversionsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerUnitConversionsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerUnitConversionsEdge = {
        __typename?: 'ViewerUnitConversionsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<UnitConversion>;
    };

    export type ViewerUnitConversionsMeta = {
        __typename?: 'ViewerUnitConversionsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerUnitConvertersConnection = {
        __typename?: 'ViewerUnitConvertersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerUnitConvertersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerUnitConvertersEdge = {
        __typename?: 'ViewerUnitConvertersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<UnitConverter>;
    };

    /** A connection to a list of items. */
    export type ViewerUniversalCriteriaConnection = {
        __typename?: 'ViewerUniversalCriteriaConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerUniversalCriteriaEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerUniversalCriteriaEdge = {
        __typename?: 'ViewerUniversalCriteriaEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<UniversalCriteria>;
    };

    /** A connection to a list of items. */
    export type ViewerUsersConnection = {
        __typename?: 'ViewerUsersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerUsersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerUsersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerUsersEdge = {
        __typename?: 'ViewerUsersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<User>;
    };

    export type ViewerUsersMeta = {
        __typename?: 'ViewerUsersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerWarranterClaimMessagesConnection = {
        __typename?: 'ViewerWarranterClaimMessagesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWarranterClaimMessagesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerWarranterClaimMessagesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerWarranterClaimMessagesEdge = {
        __typename?: 'ViewerWarranterClaimMessagesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaimMessage>;
    };

    export type ViewerWarranterClaimMessagesMeta = {
        __typename?: 'ViewerWarranterClaimMessagesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerWarrantyClaimCasesConnection = {
        __typename?: 'ViewerWarrantyClaimCasesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWarrantyClaimCasesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerWarrantyClaimCasesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerWarrantyClaimCasesEdge = {
        __typename?: 'ViewerWarrantyClaimCasesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaimCase>;
    };

    export type ViewerWarrantyClaimCasesMeta = {
        __typename?: 'ViewerWarrantyClaimCasesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerWarrantyClaimsConnection = {
        __typename?: 'ViewerWarrantyClaimsConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWarrantyClaimsEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerWarrantyClaimsMeta>;
    };

    /** An edge in a connection. */
    export type ViewerWarrantyClaimsEdge = {
        __typename?: 'ViewerWarrantyClaimsEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WarrantyClaim>;
    };

    export type ViewerWarrantyClaimsMeta = {
        __typename?: 'ViewerWarrantyClaimsMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerWorkOrderFeedConnection = {
        __typename?: 'ViewerWorkOrderFeedConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWorkOrderFeedEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type ViewerWorkOrderFeedEdge = {
        __typename?: 'ViewerWorkOrderFeedEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderFeedItem>;
    };

    /** A connection to a list of items. */
    export type ViewerWorkOrderTemplatesConnection = {
        __typename?: 'ViewerWorkOrderTemplatesConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWorkOrderTemplatesEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerWorkOrderTemplatesMeta>;
    };

    /** An edge in a connection. */
    export type ViewerWorkOrderTemplatesEdge = {
        __typename?: 'ViewerWorkOrderTemplatesEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderTemplate>;
    };

    export type ViewerWorkOrderTemplatesMeta = {
        __typename?: 'ViewerWorkOrderTemplatesMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type ViewerWorkOrdersConnection = {
        __typename?: 'ViewerWorkOrdersConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<ViewerWorkOrdersEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<ViewerWorkOrdersMeta>;
    };

    /** An edge in a connection. */
    export type ViewerWorkOrdersEdge = {
        __typename?: 'ViewerWorkOrdersEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrder>;
    };

    export type ViewerWorkOrdersMeta = {
        __typename?: 'ViewerWorkOrdersMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type WarrantyClaim = Node & {
        __typename?: 'WarrantyClaim';
        addedBy?: Maybe<Staff>;
        /** Address for a location of claimed item */
        address?: Maybe<Address>;
        assetInstallDate?: Maybe<Scalars['Date']>;
        assetLocation?: Maybe<Scalars['String']>;
        assetName?: Maybe<Scalars['String']>;
        assetSerial?: Maybe<Scalars['String']>;
        case?: Maybe<WarrantyClaimCase>;
        checkMutations?: Maybe<WarrantyClaimMutationCheck>;
        claimNumber?: Maybe<Scalars['String']>;
        claimedAt?: Maybe<Scalars['Date']>;
        comment?: Maybe<Scalars['String']>;
        confirmedAt?: Maybe<Scalars['Date']>;
        createdAt?: Maybe<Scalars['Date']>;
        customerCompanyName?: Maybe<Scalars['String']>;
        customerEmail?: Maybe<Scalars['String']>;
        customerFirstName?: Maybe<Scalars['String']>;
        customerLastName?: Maybe<Scalars['String']>;
        customerPhone?: Maybe<Scalars['String']>;
        emailCC?: Maybe<Scalars['String']>;
        emailReplyTo?: Maybe<Scalars['String']>;
        entity?: Maybe<Entity>;
        externalRMA?: Maybe<Scalars['String']>;
        externalSO?: Maybe<Scalars['String']>;
        externalSRN?: Maybe<Scalars['String']>;
        faultDescription?: Maybe<Scalars['String']>;
        files?: Maybe<FilesForWarrantyClaimToWarrantyClaimFileAttachConnection>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        installerName?: Maybe<Scalars['String']>;
        messages?: Maybe<MessagesForWarrantyClaimToWarrantyClaimMessageConnection>;
        receiverEmail?: Maybe<Scalars['String']>;
        receiverStaff?: Maybe<Staff>;
        receiverWarranter?: Maybe<FranchiseWarranter>;
        refAsset?: Maybe<Item>;
        refCustomer?: Maybe<Customer>;
        refSite?: Maybe<Site>;
        siteAccessKey?: Maybe<Scalars['String']>;
        status?: Maybe<WarrantyClaimStatusEnum>;
        statusChangedAt?: Maybe<Scalars['Date']>;
        statusGroup?: Maybe<WarrantyClaimStatusGroupEnum>;
        statusHistory?: Maybe<StatusHistoryForWarrantyClaimToWarrantyClaimStatusHistoryConnection>;
        statusMessage?: Maybe<Scalars['String']>;
        submittedAt?: Maybe<Scalars['Date']>;
        updatedAt?: Maybe<Scalars['Date']>;
        warrantyClaimNumber?: Maybe<Scalars['String']>;
    };

    export type WarrantyClaimFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimFileAttachWarrantyClaimFilesSearch>;
        sort?: InputMaybe<WarrantyClaimFileAttachWarrantyClaimFilesSort>;
    };

    export type WarrantyClaimMessagesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimMessageWarrantyClaimMessagesSearch>;
        sort?: InputMaybe<WarrantyClaimMessageWarrantyClaimMessagesSort>;
    };

    export type WarrantyClaimStatusHistoryArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WarrantyClaimStatusHistoryWarrantyClaimStatusHistorySearch>;
        sort?: InputMaybe<WarrantyClaimStatusHistoryWarrantyClaimStatusHistorySort>;
    };

    export type WarrantyClaimCase = Node & {
        __typename?: 'WarrantyClaimCase';
        assignedTo?: Maybe<Staff>;
        checkMutations?: Maybe<WarrantyClaimCaseMutationCheck>;
        claim?: Maybe<WarrantyClaim>;
        createdAt?: Maybe<Scalars['Date']>;
        externalRMA?: Maybe<Scalars['String']>;
        externalSO?: Maybe<Scalars['String']>;
        externalSRN?: Maybe<Scalars['String']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        lastMessageAt?: Maybe<Scalars['Date']>;
        lastMessageReadAt?: Maybe<Scalars['Date']>;
        note?: Maybe<Scalars['String']>;
        serviceJob?: Maybe<ServiceJob>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type WarrantyClaimCaseMarkMessagesAsReadMutationInput = {
        /** Expects node: **WarrantyClaimCase** */
        id: Scalars['ID'];
    };

    export type WarrantyClaimCaseMutationCheck = {
        __typename?: 'WarrantyClaimCaseMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        markMessagesAsRead?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
        updateStatus?: Maybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimCaseMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimCaseMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWarrantyClaimCaseMutationInput>>>;
        markMessagesAsRead?: InputMaybe<Array<InputMaybe<WarrantyClaimCaseMarkMessagesAsReadMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimCaseMutationInput>>>;
        updateStatus?: InputMaybe<Array<InputMaybe<WarrantyClaimCaseUpdateStatusMutationInput>>>;
    };

    export type WarrantyClaimCaseMutationPayload = {
        __typename?: 'WarrantyClaimCaseMutationPayload';
        WarrantyClaimCase?: Maybe<WarrantyClaimCase>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWarrantyClaimCaseMutationOutput>>>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedFranchise?: Maybe<Franchise>;
        updatedServiceJob?: Maybe<ServiceJob>;
        viewer?: Maybe<Viewer>;
    };

    export type WarrantyClaimCaseUpdateStatusMutationInput = {
        /** Expects node: **WarrantyClaimCase** */
        id: Scalars['ID'];
        message?: InputMaybe<Scalars['String']>;
        status: WarrantyClaimStatusEnum;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearch = {
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        claim?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaim>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaim = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        claimNumber?: InputMaybe<Scalars['String']>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **WarrantyClaim** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WarrantyClaim** */
        not?: InputMaybe<Scalars['ID']>;
        receiverStaff?: InputMaybe<StaffNodeMatchSearchCommon>;
        receiverWarranter?: InputMaybe<FranchiseWarranterNodeMatchSearchCommon>;
        refAsset?: InputMaybe<ItemNodeMatchSearchCommon>;
        refCustomer?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomer>;
        refSite?: InputMaybe<SiteNodeMatchSearchCommon>;
        status?: InputMaybe<WarrantyClaimStatusEnumEnumMatchSearchCommon>;
        statusGroup?: InputMaybe<WarrantyClaimStatusGroupEnumEnumMatchSearchCommon>;
        submittedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomer = {
        addedBy?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerPrimaryAddress>;
        tags?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerTags>;
        user?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUser>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByRegistration>;
        teams?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByTeams>;
        user?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUser>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntity = {
        addresses?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPools>;
        serviceJob?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJob>;
        sites?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteria =
        {
            actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualTarget?: InputMaybe<FloatMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringMatchSearchCommon>;
            higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            id?: InputMaybe<Scalars['ID']>;
            lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            poolTypes?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
            targetValue?: InputMaybe<FloatMatchSearchCommon>;
            toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJob = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobWorkOrders>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolReports =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **TestReport** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestReport** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            refId?: InputMaybe<StringMatchSearchCommon>;
            reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
            sampleSet?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolReportsSampleSet>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
            workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiser =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteria>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteria =
        {
            actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualTarget?: InputMaybe<FloatMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringMatchSearchCommon>;
            higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            id?: InputMaybe<Scalars['ID']>;
            lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            poolTypes?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
            targetValue?: InputMaybe<FloatMatchSearchCommon>;
            toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolSiteAddress =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            city?: InputMaybe<StringLikeOrMatchSearchCommon>;
            country?: InputMaybe<StringMatchSearchCommon>;
            destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
            entity?: InputMaybe<EntityNodeMatchSearchCommon>;
            /** Expects node: **Address** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **Address** */
            not?: InputMaybe<Scalars['ID']>;
            poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
            state?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
            streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
            suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteria =
        {
            actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualTarget?: InputMaybe<FloatMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringMatchSearchCommon>;
            higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            id?: InputMaybe<Scalars['ID']>;
            lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            sanitisers?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
            targetValue?: InputMaybe<FloatMatchSearchCommon>;
            toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntitySites = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaff>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntity = {
        addresses?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPools>;
        serviceJob?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJob>;
        sites?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJob = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobWorkOrders>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSite>;
        type?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolOwnershipHistory =
        {
            /** Expects node: **PoolOwnershipHistory** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **PoolOwnershipHistory** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
            transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
            transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolReportsSampleSet =
        {
            addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **SampleSet** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **SampleSet** */
            not?: InputMaybe<Scalars['ID']>;
            pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteria =
        {
            actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualTarget?: InputMaybe<FloatMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringMatchSearchCommon>;
            higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            id?: InputMaybe<Scalars['ID']>;
            lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            poolTypes?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
            targetValue?: InputMaybe<FloatMatchSearchCommon>;
            toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSite = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisers =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntitySites = {
        address?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaffRegistration>;
        teams?: InputMaybe<WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaffTeams>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSearchClaimRefCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimCaseViewerWarrantyClaimCasesSort = {
        claim?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaimCase** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        lastMessageAt?: InputMaybe<Scalars['Boolean']>;
        lastMessageReadAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimExecuteWarrantyClaimRequestMutationInput = {
        /** Expects node: **WarrantyClaim** */
        id: Scalars['ID'];
    };

    export type WarrantyClaimFileAttach = Node & {
        __typename?: 'WarrantyClaimFileAttach';
        checkMutations?: Maybe<WarrantyClaimFileAttachMutationCheck>;
        claim?: Maybe<WarrantyClaim>;
        createdAt?: Maybe<Scalars['Date']>;
        file?: Maybe<FileUpload>;
        id: Scalars['ID'];
        label?: Maybe<Scalars['String']>;
    };

    export type WarrantyClaimFileAttachMutationCheck = {
        __typename?: 'WarrantyClaimFileAttachMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimFileAttachMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimFileAttachMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWarrantyClaimFileAttachMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimFileAttachMutationInput>>>;
    };

    export type WarrantyClaimFileAttachMutationPayload = {
        __typename?: 'WarrantyClaimFileAttachMutationPayload';
        WarrantyClaimFileAttach?: Maybe<WarrantyClaimFileAttach>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWarrantyClaimFileAttachMutationOutput>>>;
        updatedClaim?: Maybe<WarrantyClaim>;
        updatedFile?: Maybe<FileUpload>;
        viewer?: Maybe<Viewer>;
    };

    export type WarrantyClaimFileAttachWarrantyClaimFilesSearch = {
        file?: InputMaybe<FileUploadNodeMatchSearchCommon>;
    };

    export type WarrantyClaimFileAttachWarrantyClaimFilesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaimFileAttach** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type WarrantyClaimFixUnsharedClaimsMutationInput = {
        stub?: InputMaybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimForceClaimCaseMutationInput = {
        /** Expects node: **WarrantyClaim** */
        id: Scalars['ID'];
    };

    export type WarrantyClaimMessage = Node & {
        __typename?: 'WarrantyClaimMessage';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<WarrantyClaimMessageMutationCheck>;
        claim?: Maybe<WarrantyClaim>;
        createdAt?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        isRead?: Maybe<Scalars['Boolean']>;
        message?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type WarrantyClaimMessageMutationCheck = {
        __typename?: 'WarrantyClaimMessageMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimMessageMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWarrantyClaimMessageMutationInput>>>;
        postMessage?: InputMaybe<Array<InputMaybe<WarrantyClaimMessagePostMessageMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimMessageMutationInput>>>;
    };

    export type WarrantyClaimMessageMutationPayload = {
        __typename?: 'WarrantyClaimMessageMutationPayload';
        WarrantyClaimMessage?: Maybe<WarrantyClaimMessage>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWarrantyClaimMessageMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedClaim?: Maybe<WarrantyClaim>;
        viewer?: Maybe<Viewer>;
    };

    export type WarrantyClaimMessagePostMessageMutationInput = {
        /** Expects node: **WarrantyClaim** */
        claimId: Scalars['ID'];
        message: Scalars['String'];
    };

    export type WarrantyClaimMessageViewerWarranterClaimMessagesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        claim?: InputMaybe<WarrantyClaimNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        origin?: InputMaybe<WarrantyMessageOrigin>;
    };

    export type WarrantyClaimMessageViewerWarranterClaimMessagesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaimMessage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimMessageWarrantyClaimMessagesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimMessageWarrantyClaimMessagesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaimMessage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimMutationCheck = {
        __typename?: 'WarrantyClaimMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        executeWarrantyClaimRequest?: Maybe<Scalars['Boolean']>;
        forceClaimCase?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WarrantyClaimMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWarrantyClaimMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWarrantyClaimMutationInput>>>;
        executeWarrantyClaimRequest?: InputMaybe<
            Array<InputMaybe<WarrantyClaimExecuteWarrantyClaimRequestMutationInput>>
        >;
        fixUnsharedClaims?: InputMaybe<Array<InputMaybe<WarrantyClaimFixUnsharedClaimsMutationInput>>>;
        forceClaimCase?: InputMaybe<Array<InputMaybe<WarrantyClaimForceClaimCaseMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWarrantyClaimMutationInput>>>;
    };

    export type WarrantyClaimMutationPayload = {
        __typename?: 'WarrantyClaimMutationPayload';
        WarrantyClaim?: Maybe<WarrantyClaim>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWarrantyClaimMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedAddress?: Maybe<Address>;
        updatedFranchise?: Maybe<Franchise>;
        updatedReceiverStaff?: Maybe<Staff>;
        updatedReceiverWarranter?: Maybe<FranchiseWarranter>;
        updatedRefAsset?: Maybe<Item>;
        updatedRefCustomer?: Maybe<Customer>;
        updatedRefSite?: Maybe<Site>;
        viewer?: Maybe<Viewer>;
    };

    export type WarrantyClaimNodeMatchSearchCommon = {
        /** Expects node: **WarrantyClaim** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WarrantyClaim** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export enum WarrantyClaimStatusEnum {
        CLOSED_NO_FIX = 'CLOSED_NO_FIX',
        CLOSED_OTHER = 'CLOSED_OTHER',
        CLOSED_OUT_OF_WARRANTY = 'CLOSED_OUT_OF_WARRANTY',
        CLOSED_REPAIRED = 'CLOSED_REPAIRED',
        CLOSED_REPLACED = 'CLOSED_REPLACED',
        NEW = 'NEW',
        ON_HOLD_MISSING_INFO = 'ON_HOLD_MISSING_INFO',
        PROCESSING = 'PROCESSING',
    }

    export type WarrantyClaimStatusEnumEnumMatchSearchCommon = {
        is?: InputMaybe<WarrantyClaimStatusEnum>;
        not?: InputMaybe<WarrantyClaimStatusEnum>;
    };

    export enum WarrantyClaimStatusGroupEnum {
        CLOSED = 'CLOSED',
        NEW = 'NEW',
        ON_HOLD = 'ON_HOLD',
        PROCESSING = 'PROCESSING',
    }

    export type WarrantyClaimStatusGroupEnumEnumMatchSearchCommon = {
        is?: InputMaybe<WarrantyClaimStatusGroupEnum>;
        not?: InputMaybe<WarrantyClaimStatusGroupEnum>;
    };

    export type WarrantyClaimStatusHistory = Node & {
        __typename?: 'WarrantyClaimStatusHistory';
        changedBy?: Maybe<Staff>;
        claim?: Maybe<WarrantyClaim>;
        createdAt?: Maybe<Scalars['Date']>;
        group?: Maybe<WarrantyClaimStatusGroupEnum>;
        id: Scalars['ID'];
        message?: Maybe<Scalars['String']>;
        status?: Maybe<WarrantyClaimStatusEnum>;
    };

    export type WarrantyClaimStatusHistoryWarrantyClaimStatusHistorySearch = {
        changedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        group?: InputMaybe<WarrantyClaimStatusGroupEnumEnumMatchSearchCommon>;
        status?: InputMaybe<WarrantyClaimStatusEnumEnumMatchSearchCommon>;
    };

    export type WarrantyClaimStatusHistoryWarrantyClaimStatusHistorySort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaimStatusHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        claimNumber?: InputMaybe<Scalars['String']>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        receiverStaff?: InputMaybe<StaffNodeMatchSearchCommon>;
        receiverWarranter?: InputMaybe<FranchiseWarranterNodeMatchSearchCommon>;
        refAsset?: InputMaybe<ItemNodeMatchSearchCommon>;
        refCustomer?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomer>;
        refSite?: InputMaybe<SiteNodeMatchSearchCommon>;
        status?: InputMaybe<WarrantyClaimStatusEnumEnumMatchSearchCommon>;
        statusGroup?: InputMaybe<WarrantyClaimStatusGroupEnumEnumMatchSearchCommon>;
        submittedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomer = {
        addedBy?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerPrimaryAddress>;
        tags?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerTags>;
        user?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUser>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByRegistration>;
        teams?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByTeams>;
        user?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUser>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntity = {
        addresses?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPools>;
        serviceJob?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJob>;
        sites?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJob = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobWorkOrders>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSite>;
        type?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSite = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisers =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntitySites = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaff>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntity = {
        addresses?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPools>;
        serviceJob?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJob>;
        sites?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJob = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<CustomerNodeMatchSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
        workOrders?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobWorkOrders>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolReports>;
        sanitiser?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiser>;
        site?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSite>;
        type?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSite = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntityServiceJobWorkOrders = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<ServiceJobNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntitySites = {
        address?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaffRegistration>;
        teams?: InputMaybe<WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaffTeams>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSearchRefCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WarrantyClaimViewerWarrantyClaimsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WarrantyClaim** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        submittedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export enum WarrantyMessageOrigin {
        Any = 'Any',
        External = 'External',
        Internal = 'Internal',
    }

    export type WaterTestReportSummary = Node & {
        __typename?: 'WaterTestReportSummary';
        id: Scalars['ID'];
        selectedProducts?: Maybe<Array<Maybe<ProductWithDosage>>>;
        testReport?: Maybe<TestReport>;
    };

    export type WaterTestingRevision = Node & {
        __typename?: 'WaterTestingRevision';
        id: Scalars['ID'];
        organisation?: Maybe<OrganisationType>;
        waterTestRevision?: Maybe<Scalars['Int']>;
    };

    export type WaterTestingRevisionMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWaterTestingRevisionMutationInput>>>;
    };

    export type WaterTestingRevisionMutationPayload = {
        __typename?: 'WaterTestingRevisionMutationPayload';
        WaterTestingRevision?: Maybe<WaterTestingRevision>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWaterTestingRevisionMutationOutput>>>;
        updatedOrganisation?: Maybe<OrganisationType>;
        viewer?: Maybe<Viewer>;
    };

    /** A connection to a list of items. */
    export type WaterTestsForHQFranchisesStatsToActivitySampleConnection = {
        __typename?: 'WaterTestsForHQFranchisesStatsToActivitySampleConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<WaterTestsForHQFranchisesStatsToActivitySampleEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
    };

    /** An edge in a connection. */
    export type WaterTestsForHQFranchisesStatsToActivitySampleEdge = {
        __typename?: 'WaterTestsForHQFranchisesStatsToActivitySampleEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<ActivitySample>;
    };

    export enum WeekIndexEnum {
        First = 'First',
        Fourth = 'Fourth',
        Last = 'Last',
        Second = 'Second',
        Third = 'Third',
    }

    export enum WeekdayEnum {
        Friday = 'Friday',
        Monday = 'Monday',
        Saturday = 'Saturday',
        Sunday = 'Sunday',
        Thursday = 'Thursday',
        Tuesday = 'Tuesday',
        Wednesday = 'Wednesday',
    }

    export type WorkOrder = Node & {
        __typename?: 'WorkOrder';
        abstractDocuments?: Maybe<AbstractDocumentsForWorkOrderToAbstractDocumentConnection>;
        address?: Maybe<Address>;
        appointmentItem?: Maybe<AppointmentItem>;
        archivedAt?: Maybe<Scalars['Date']>;
        assignedTo?: Maybe<Staff>;
        checkMutations?: Maybe<WorkOrderMutationCheck>;
        checkedInAt?: Maybe<Scalars['Date']>;
        checkedOutAt?: Maybe<Scalars['Date']>;
        createdAt?: Maybe<Scalars['Date']>;
        createdBy?: Maybe<Staff>;
        customer?: Maybe<Customer>;
        description?: Maybe<Scalars['String']>;
        dueRangeEnd?: Maybe<Scalars['Date']>;
        dueRangeStart?: Maybe<Scalars['Date']>;
        ezcDeviceLink?: Maybe<EZCDeviceLink>;
        files?: Maybe<FilesForWorkOrderToFileUploadConnection>;
        followUpFrom?: Maybe<WorkOrder>;
        id: Scalars['ID'];
        jobs?: Maybe<JobsForWorkOrderToJobTodoConnection>;
        notes?: Maybe<NotesForWorkOrderToWorkOrderNoteConnection>;
        paymentUrl?: Maybe<Scalars['String']>;
        pool?: Maybe<Pool>;
        preferredContact?: Maybe<Contact>;
        requiresAddress?: Maybe<Scalars['Boolean']>;
        requiresAppointment?: Maybe<Scalars['Boolean']>;
        requiresAssignedTo?: Maybe<Scalars['Boolean']>;
        requiresPool?: Maybe<Scalars['Boolean']>;
        requiresPreferredContact?: Maybe<Scalars['Boolean']>;
        requiresTestReport?: Maybe<Scalars['Boolean']>;
        requiresWorkFor?: Maybe<Scalars['Boolean']>;
        serviceJob?: Maybe<ServiceJob>;
        stage?: Maybe<ServiceJobStage>;
        stageCandidates?: Maybe<Array<Maybe<ServiceJobStage>>>;
        stageChanges?: Maybe<StageChangesForWorkOrderToWorkOrderStageHistoryConnection>;
        stageHistory?: Maybe<StageHistoryForWorkOrderToWorkOrderStageDurationConnection>;
        statusClosedAt?: Maybe<Scalars['Date']>;
        testReports?: Maybe<TestReportsForWorkOrderToTestReportConnection>;
        /** Required if no template provided */
        title?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
        usedTemplate?: Maybe<WorkOrderTemplate>;
        workFor?: Maybe<Entity>;
        workOrderNumber?: Maybe<Scalars['String']>;
    };

    export type WorkOrderAbstractDocumentsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<AbstractDocumentWorkOrderAbstractDocumentsSearch>;
        sort?: InputMaybe<AbstractDocumentWorkOrderAbstractDocumentsSort>;
    };

    export type WorkOrderFilesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<FileUploadWorkOrderFilesSearch>;
        sort?: InputMaybe<FileUploadWorkOrderFilesSort>;
    };

    export type WorkOrderJobsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoWorkOrderJobsSearch>;
        sort?: InputMaybe<JobTodoWorkOrderJobsSort>;
    };

    export type WorkOrderNotesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderNoteWorkOrderNotesSearch>;
        sort?: InputMaybe<WorkOrderNoteWorkOrderNotesSort>;
    };

    export type WorkOrderStageChangesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderStageHistoryWorkOrderStageChangesSearch>;
        sort?: InputMaybe<WorkOrderStageHistoryWorkOrderStageChangesSort>;
    };

    export type WorkOrderStageHistoryArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        reverse?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderTestReportsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<TestReportWorkOrderTestReportsSearch>;
        sort?: InputMaybe<TestReportWorkOrderTestReportsSort>;
    };

    export type WorkOrderAddJobTodoFromTemplateMutationInput = {
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Expects node: **JobTodoTemplate** */
        jobTodoTemplate: Scalars['ID'];
    };

    export type WorkOrderAddVendSaleMutationInput = {
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
    };

    export type WorkOrderAppendWorkOrderTemplateMutationInput = {
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Expects node: **WorkOrderTemplate** */
        template: Scalars['ID'];
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJob>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJob = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomer = {
        addedBy?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerTags>;
        user?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUser>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUser>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntity = {
        addresses?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPools>;
        sites?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteria =
        {
            actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            actualTarget?: InputMaybe<FloatMatchSearchCommon>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringMatchSearchCommon>;
            higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            id?: InputMaybe<Scalars['ID']>;
            lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
            measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestCriteria** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            poolTypes?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
            targetValue?: InputMaybe<FloatMatchSearchCommon>;
            toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Sanitiser** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Sanitiser** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntitySites = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaff>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntity = {
        addresses?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPools>;
        sites?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **PoolType** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **PoolType** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            publishedAt?: InputMaybe<DateRangeSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntitySites = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaffTeams>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntity = {
        addresses?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPools>;
        sites?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntitySites>;
        user?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSite>;
        type?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSite = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntitySites = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaff>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomer = {
        addedBy?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerTags>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedByTeams>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaffRegistration>;
        teams?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaffTeams>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolReports>;
        sanitiser?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiser>;
        site?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSite>;
        type?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSite = {
        address?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderAppointmentInstanceWorkOrdersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueRangeEnd?: InputMaybe<Scalars['Boolean']>;
        dueRangeStart?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        statusClosedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
        workOrderNumber?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderAssignAppointmentItemMutationInput = {
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Expects node: **AppointmentItem** */
        itemId?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderBookingCommon = {
        create?: InputMaybe<WorkOrderBookingCommonCreate>;
        /** Expects node: **AppointmentItem** */
        id?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderBookingCommonCreate = {
        color?: InputMaybe<Scalars['String']>;
        duration: Scalars['Int'];
        /** Expects node: **AppointmentGroup** */
        group?: InputMaybe<Scalars['ID']>;
        startDate: Scalars['Date'];
    };

    export type WorkOrderFeedItem = Node & {
        __typename?: 'WorkOrderFeedItem';
        /** If appointment item exists, value is taken from appointment item, otherwise taken from work order */
        address?: Maybe<Address>;
        appointmentItem?: Maybe<AppointmentItem>;
        checkMutations?: Maybe<WorkOrderFeedItemMutationCheck>;
        /** If appointment item exists, value is taken from appointment item, otherwise taken from work order */
        customer?: Maybe<Customer>;
        date?: Maybe<Scalars['Date']>;
        id: Scalars['ID'];
        /** If appointment item exists, value is taken from appointment item, otherwise taken from work order */
        pool?: Maybe<Pool>;
        serviceJob?: Maybe<ServiceJob>;
        /** If appointment item exists, value is taken from appointment item, otherwise taken from work order */
        staff?: Maybe<Staff>;
        title?: Maybe<Scalars['String']>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type WorkOrderFeedItemMutationCheck = {
        __typename?: 'WorkOrderFeedItemMutationCheck';
        startWorkOrder?: Maybe<Scalars['Boolean']>;
    };

    export type WorkOrderFeedItemMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        startWorkOrder?: InputMaybe<Array<InputMaybe<WorkOrderFeedItemStartWorkOrderMutationInput>>>;
    };

    export type WorkOrderFeedItemMutationPayload = {
        __typename?: 'WorkOrderFeedItemMutationPayload';
        WorkOrderFeedItem?: Maybe<WorkOrderFeedItem>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWorkOrderFeedItemMutationOutput>>>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedWorkOrder?: Maybe<WorkOrder>;
        viewer?: Maybe<Viewer>;
    };

    export type WorkOrderFeedItemStartWorkOrderMutationInput = {
        /** Expects node: **WorkOrderFeedItem** */
        id: Scalars['ID'];
    };

    export type WorkOrderFeedItemViewerWorkOrderFeedSearch = {
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        customer?: InputMaybe<Array<Scalars['ID']>>;
        dateRange?: InputMaybe<WorkOrderFeedItemViewerWorkOrderFeedSearchDateRange>;
        /** Expects node: **Franchise** */
        franchiseId?: InputMaybe<Scalars['ID']>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        hasWorkOrder?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJob** */
        serviceJob?: InputMaybe<Array<Scalars['ID']>>;
        /** Expects node: **ServiceJobGroup** */
        serviceJobGroup?: InputMaybe<Array<Scalars['ID']>>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        staff?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        /** Expects node: **ServiceJobTemplate** */
        usedTemplate?: InputMaybe<Array<Scalars['ID']>>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderFeedItemViewerWorkOrderFeedSearchDateRange = {
        from: Scalars['Date'];
        to?: InputMaybe<Scalars['Date']>;
    };

    export type WorkOrderMutationCheck = {
        __typename?: 'WorkOrderMutationCheck';
        addJobTodoFromTemplate?: Maybe<Scalars['Boolean']>;
        addVendSale?: Maybe<Scalars['Boolean']>;
        appendWorkOrderTemplate?: Maybe<Scalars['Boolean']>;
        assignAppointmentItem?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        replaceWorkOrderTemplate?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WorkOrderMutationInput = {
        addJobTodoFromTemplate?: InputMaybe<Array<InputMaybe<WorkOrderAddJobTodoFromTemplateMutationInput>>>;
        addVendSale?: InputMaybe<Array<InputMaybe<WorkOrderAddVendSaleMutationInput>>>;
        appendWorkOrderTemplate?: InputMaybe<Array<InputMaybe<WorkOrderAppendWorkOrderTemplateMutationInput>>>;
        assignAppointmentItem?: InputMaybe<Array<InputMaybe<WorkOrderAssignAppointmentItemMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWorkOrderMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWorkOrderMutationInput>>>;
        replaceWorkOrderTemplate?: InputMaybe<Array<InputMaybe<WorkOrderReplaceWorkOrderTemplateMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderMutationInput>>>;
    };

    export type WorkOrderMutationPayload = {
        __typename?: 'WorkOrderMutationPayload';
        WorkOrder?: Maybe<WorkOrder>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWorkOrderMutationOutput>>>;
        updatedAddress?: Maybe<Address>;
        updatedAssignedTo?: Maybe<Staff>;
        updatedCreatedBy?: Maybe<Staff>;
        updatedEzcDeviceLink?: Maybe<EZCDeviceLink>;
        updatedFollowUpFrom?: Maybe<WorkOrder>;
        updatedPool?: Maybe<Pool>;
        updatedPreferredContact?: Maybe<Contact>;
        updatedServiceJob?: Maybe<ServiceJob>;
        updatedStage?: Maybe<ServiceJobStage>;
        updatedUsedTemplate?: Maybe<WorkOrderTemplate>;
        updatedWorkFor?: Maybe<Entity>;
        viewer?: Maybe<Viewer>;
    };

    export type WorkOrderNodeMatchSearchCommon = {
        /** Expects node: **WorkOrder** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrder** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderNote = Node & {
        __typename?: 'WorkOrderNote';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<WorkOrderNoteMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        franchise?: Maybe<Franchise>;
        id: Scalars['ID'];
        note?: Maybe<Scalars['String']>;
        parent?: Maybe<WorkOrder>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type WorkOrderNoteMutationCheck = {
        __typename?: 'WorkOrderNoteMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WorkOrderNoteMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWorkOrderNoteMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWorkOrderNoteMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderNoteMutationInput>>>;
    };

    export type WorkOrderNoteMutationPayload = {
        __typename?: 'WorkOrderNoteMutationPayload';
        WorkOrderNote?: Maybe<WorkOrderNote>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWorkOrderNoteMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedFranchise?: Maybe<Franchise>;
        updatedParent?: Maybe<WorkOrder>;
        viewer?: Maybe<Viewer>;
    };

    export type WorkOrderNoteWorkOrderNotesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderNoteWorkOrderNotesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderNote** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderReplaceWorkOrderTemplateMutationInput = {
        autoInflate?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        id: Scalars['ID'];
        /** Expects node: **WorkOrderTemplate** */
        template: Scalars['ID'];
    };

    export type WorkOrderServiceJobWorkOrdersSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderServiceJobWorkOrdersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueRangeEnd?: InputMaybe<Scalars['Boolean']>;
        dueRangeStart?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        statusClosedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
        workOrderNumber?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJob>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJob = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerTags>;
        user?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUser>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntity = {
        addresses?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPools>;
        sites?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntitySites = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntity = {
        addresses?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPools>;
        sites?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntitySites>;
        user?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSite>;
        type?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSite = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntitySites = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerTags>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolReports>;
        sanitiser?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiser>;
        site?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSite>;
        type?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSite = {
        address?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffAssignedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderStaffAssignedWorkOrdersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueRangeEnd?: InputMaybe<Scalars['Boolean']>;
        dueRangeStart?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        statusClosedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
        workOrderNumber?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJob>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJob = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerTags>;
        user?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUser>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntity = {
        addresses?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPools>;
        sites?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntitySites = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntity = {
        addresses?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPools>;
        sites?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntitySites>;
        user?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSite>;
        type?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSite = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntitySites = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomer = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerTags>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolReports>;
        sanitiser?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiser>;
        site?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSite>;
        type?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolReportsSampleSet = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSite = {
        address?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderStaffCreatedWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderStaffCreatedWorkOrdersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueRangeEnd?: InputMaybe<Scalars['Boolean']>;
        dueRangeStart?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        statusClosedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
        workOrderNumber?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderStageDuration = {
        __typename?: 'WorkOrderStageDuration';
        endedAt?: Maybe<Scalars['Date']>;
        fromStage?: Maybe<ServiceJobStage>;
        startedAt?: Maybe<Scalars['Date']>;
        startedBy?: Maybe<Staff>;
        toStage?: Maybe<ServiceJobStage>;
        workOrder?: Maybe<WorkOrder>;
    };

    export type WorkOrderStageHistory = Node & {
        __typename?: 'WorkOrderStageHistory';
        changedAt?: Maybe<Scalars['Date']>;
        changedBy?: Maybe<Staff>;
        fromStage?: Maybe<ServiceJobStage>;
        id: Scalars['ID'];
        workOrder?: Maybe<WorkOrder>;
    };

    export type WorkOrderStageHistoryWorkOrderStageChangesSearch = {
        fromStage?: InputMaybe<WorkOrderStageHistoryWorkOrderStageChangesSearchFromStage>;
    };

    export type WorkOrderStageHistoryWorkOrderStageChangesSearchFromStage = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        groups?: InputMaybe<WorkOrderStageHistoryWorkOrderStageChangesSearchFromStageGroups>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJobStage** */
        id?: InputMaybe<Scalars['ID']>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJobStage** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderStageHistoryWorkOrderStageChangesSearchFromStageGroups = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderStageHistoryWorkOrderStageChangesSort = {
        changedAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderStageHistory** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    };

    export type WorkOrderTemplate = Node & {
        __typename?: 'WorkOrderTemplate';
        addedBy?: Maybe<Staff>;
        archivedAt?: Maybe<Scalars['Date']>;
        assignedStages?: Maybe<Array<Maybe<ServiceJobStage>>>;
        checkMutations?: Maybe<WorkOrderTemplateMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        defaultStage?: Maybe<ServiceJobStage>;
        franchise?: Maybe<Franchise>;
        group?: Maybe<ServiceJobGroup>;
        hideStageActionRequired?: Maybe<Scalars['Boolean']>;
        hideStageInProgress?: Maybe<Scalars['Boolean']>;
        hideStageSuspended?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        includedInWorkOrderTemplateAssocs?: Maybe<IncludedInWorkOrderTemplateAssocsForWorkOrderTemplateToWorkOrderTemplateAssocConnection>;
        jobTodoTemplateAssocs?: Maybe<JobTodoTemplateAssocsForWorkOrderTemplateToJobTodoTemplateAssocConnection>;
        onlyOwnedStages?: Maybe<Scalars['Boolean']>;
        organisationType?: Maybe<OrganisationType>;
        /** If null then meant for sharing with many ServiceJobTemplate */
        ownedByServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        ownerOfJobTodoTemplates?: Maybe<OwnerOfJobTodoTemplatesForWorkOrderTemplateToJobTodoTemplateConnection>;
        providedDescription?: Maybe<Scalars['String']>;
        providedTitle?: Maybe<Scalars['String']>;
        requiresAddress?: Maybe<Scalars['Boolean']>;
        requiresContact?: Maybe<Scalars['Boolean']>;
        requiresCustomer?: Maybe<Scalars['Boolean']>;
        requiresPool?: Maybe<Scalars['Boolean']>;
        requiresStaff?: Maybe<Scalars['Boolean']>;
        stageReport?: Maybe<Array<Maybe<ServiceJobStageTemplateOwnership>>>;
        tag?: Maybe<Scalars['String']>;
        templateDescription?: Maybe<Scalars['String']>;
        templateTitle?: Maybe<Scalars['String']>;
        updatedAt?: Maybe<Scalars['Date']>;
    };

    export type WorkOrderTemplateAssignedStagesArgs = {
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderTemplateAssignedStagesSearch>;
        sort?: InputMaybe<WorkOrderTemplateAssignedStagesSort>;
    };

    export type WorkOrderTemplateIncludedInWorkOrderTemplateAssocsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<WorkOrderTemplateAssocWorkOrderTemplateIncludedInWorkOrderTemplateAssocsSearch>;
        sort?: InputMaybe<WorkOrderTemplateAssocWorkOrderTemplateIncludedInWorkOrderTemplateAssocsSort>;
    };

    export type WorkOrderTemplateJobTodoTemplateAssocsArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSearch>;
        sort?: InputMaybe<JobTodoTemplateAssocWorkOrderTemplateJobTodoTemplateAssocsSort>;
    };

    export type WorkOrderTemplateOwnerOfJobTodoTemplatesArgs = {
        after?: InputMaybe<Scalars['String']>;
        before?: InputMaybe<Scalars['String']>;
        first?: InputMaybe<Scalars['Int']>;
        last?: InputMaybe<Scalars['Int']>;
        page?: InputMaybe<Scalars['Int']>;
        search?: InputMaybe<JobTodoTemplateWorkOrderTemplateOwnerOfJobTodoTemplatesSearch>;
        sort?: InputMaybe<JobTodoTemplateWorkOrderTemplateOwnerOfJobTodoTemplatesSort>;
    };

    export type WorkOrderTemplateArchiveMutationInput = {
        /** Expects node: **WorkOrderTemplate** */
        id: Scalars['ID'];
    };

    export type WorkOrderTemplateAssignedStagesSearch = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        groups?: InputMaybe<WorkOrderTemplateAssignedStagesSearchGroups>;
        hidden?: InputMaybe<BooleanMatchSearchCommon>;
        isDefault?: InputMaybe<BooleanMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        type?: InputMaybe<ServiceJobStageTypeEnumMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderTemplateAssignedStagesSearchGroups = {
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ServiceJobGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **ServiceJobGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderTemplateAssignedStagesSort = {
        archivedAt?: InputMaybe<Scalars['Boolean']>;
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **ServiceJobStage** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        title?: InputMaybe<Scalars['Boolean']>;
        type?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderTemplateAssoc = Node & {
        __typename?: 'WorkOrderTemplateAssoc';
        addedBy?: Maybe<Staff>;
        checkMutations?: Maybe<WorkOrderTemplateAssocMutationCheck>;
        createdAt?: Maybe<Scalars['Date']>;
        forwardAddress?: Maybe<Scalars['Boolean']>;
        forwardContact?: Maybe<Scalars['Boolean']>;
        forwardPool?: Maybe<Scalars['Boolean']>;
        forwardStaff?: Maybe<Scalars['Boolean']>;
        forwardWorkFor?: Maybe<Scalars['Boolean']>;
        id: Scalars['ID'];
        isDefault?: Maybe<Scalars['Boolean']>;
        isSuggestion?: Maybe<Scalars['Boolean']>;
        priority?: Maybe<Scalars['Int']>;
        serviceJobTemplate?: Maybe<ServiceJobTemplate>;
        updatedAt?: Maybe<Scalars['Date']>;
        workOrderTemplate?: Maybe<WorkOrderTemplate>;
    };

    export type WorkOrderTemplateAssocMutationCheck = {
        __typename?: 'WorkOrderTemplateAssocMutationCheck';
        delete?: Maybe<Scalars['Boolean']>;
        setDefault?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WorkOrderTemplateAssocMutationInput = {
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWorkOrderTemplateAssocMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWorkOrderTemplateAssocMutationInput>>>;
        setDefault?: InputMaybe<Array<InputMaybe<WorkOrderTemplateAssocSetDefaultMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderTemplateAssocMutationInput>>>;
    };

    export type WorkOrderTemplateAssocMutationPayload = {
        __typename?: 'WorkOrderTemplateAssocMutationPayload';
        WorkOrderTemplateAssoc?: Maybe<WorkOrderTemplateAssoc>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWorkOrderTemplateAssocMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        updatedWorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        isSuggestion?: InputMaybe<BooleanMatchSearchCommon>;
        workOrderTemplate?: InputMaybe<WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSearchWorkOrderTemplate>;
    };

    export type WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSearchWorkOrderTemplate = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrderTemplate** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        ownedByServiceJobTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        templateTitle?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderTemplateAssocServiceJobTemplateWorkOrderTemplateAssocsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplateAssoc** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderTemplateAssocSetDefaultMutationInput = {
        /** Expects node: **WorkOrderTemplateAssoc** */
        id: Scalars['ID'];
    };

    export type WorkOrderTemplateAssocWorkOrderTemplateIncludedInWorkOrderTemplateAssocsSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        isSuggestion?: InputMaybe<BooleanMatchSearchCommon>;
        serviceJobTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
    };

    export type WorkOrderTemplateAssocWorkOrderTemplateIncludedInWorkOrderTemplateAssocsSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplateAssoc** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        priority?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocConnection = {
        __typename?: 'WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocMeta>;
    };

    /** An edge in a connection. */
    export type WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocEdge = {
        __typename?: 'WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrderTemplateAssoc>;
    };

    export type WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocMeta = {
        __typename?: 'WorkOrderTemplateAssocsForServiceJobTemplateToWorkOrderTemplateAssocMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    export type WorkOrderTemplateMutationCheck = {
        __typename?: 'WorkOrderTemplateMutationCheck';
        archive?: Maybe<Scalars['Boolean']>;
        delete?: Maybe<Scalars['Boolean']>;
        update?: Maybe<Scalars['Boolean']>;
    };

    export type WorkOrderTemplateMutationInput = {
        archive?: InputMaybe<Array<InputMaybe<WorkOrderTemplateArchiveMutationInput>>>;
        clientMutationId?: InputMaybe<Scalars['String']>;
        /** Creates an instance */
        create?: InputMaybe<Array<InputMaybe<CreateWorkOrderTemplateMutationInput>>>;
        /** Deletes an instance */
        delete?: InputMaybe<Array<InputMaybe<DeleteWorkOrderTemplateMutationInput>>>;
        /** Updates an instance */
        update?: InputMaybe<Array<InputMaybe<UpdateWorkOrderTemplateMutationInput>>>;
    };

    export type WorkOrderTemplateMutationPayload = {
        __typename?: 'WorkOrderTemplateMutationPayload';
        WorkOrderTemplate?: Maybe<WorkOrderTemplate>;
        clientMutationId?: Maybe<Scalars['String']>;
        results?: Maybe<Array<Maybe<BulkWorkOrderTemplateMutationOutput>>>;
        updatedAddedBy?: Maybe<Staff>;
        updatedDefaultStage?: Maybe<ServiceJobStage>;
        updatedFranchise?: Maybe<Franchise>;
        updatedGroup?: Maybe<ServiceJobGroup>;
        updatedOrganisationType?: Maybe<OrganisationType>;
        updatedOwnedByServiceJobTemplate?: Maybe<ServiceJobTemplate>;
        viewer?: Maybe<Viewer>;
    };

    export type WorkOrderTemplateNodeMatchSearchCommon = {
        /** Expects node: **WorkOrderTemplate** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **WorkOrderTemplate** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderTemplateServiceJobTemplateOwnerOfWorkOrderTemplatesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        templateTitle?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderTemplateServiceJobTemplateOwnerOfWorkOrderTemplatesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        templateTitle?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderTemplateViewerWorkOrderTemplatesSearch = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        ownedByServiceJobTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        templateTitle?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderTemplateViewerWorkOrderTemplatesSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrderTemplate** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        templateTitle?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
    };

    export type WorkOrderViewerWorkOrdersSearch = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        followUpFrom?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
        hasAppointment?: InputMaybe<Scalars['Boolean']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        serviceJob?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJob>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        statusClosedAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        usedTemplate?: InputMaybe<WorkOrderTemplateNodeMatchSearchCommon>;
        workFor?: InputMaybe<EntityNodeMatchSearchCommon>;
        workOrderNumber?: InputMaybe<Scalars['String']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJob = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        closedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        createdBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        customer?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomer>;
        description?: InputMaybe<StringMatchSearchCommon>;
        dueDate?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntity>;
        ezcDeviceLink?: InputMaybe<EZCDeviceLinkNodeMatchSearchCommon>;
        group?: InputMaybe<ServiceJobGroupNodeMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        id?: InputMaybe<Scalars['ID']>;
        implicitType?: InputMaybe<ServiceJobImplicitTypeEnumEnumMatchSearchCommon>;
        isImplicit?: InputMaybe<Scalars['Boolean']>;
        isRecurring?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects node: **ServiceJob** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPool>;
        serviceJobNumber?: InputMaybe<Scalars['String']>;
        staff?: InputMaybe<StaffNodeMatchSearchCommon>;
        stage?: InputMaybe<ServiceJobStageNodeMatchSearchCommon>;
        stageType?: InputMaybe<Array<ServiceJobStageType>>;
        stopsAt?: InputMaybe<DateRangeSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
        usedTemplate?: InputMaybe<ServiceJobTemplateNodeMatchSearchCommon>;
        workOrderStageType?: InputMaybe<Array<ServiceJobStageType>>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomer = {
        addedBy?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerTags>;
        user?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUser>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByRegistration>;
        teams?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByTeams>;
        user?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUser>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntity = {
        addresses?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPools>;
        sites?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaMeasurementType =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            /** Expects node: **Measurement** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Measurement** */
            not?: InputMaybe<Scalars['ID']>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblem =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            description?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestProblem** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntitySites = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerAddedByUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        entity?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntity>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaff>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntity = {
        addresses?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPools>;
        sites?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntitySites>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite>;
        type?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSite = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarations =
        {
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringMatchSearchCommon>;
            /** Expects node: **ProductTraitDeclaration** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
            products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
            tag?: InputMaybe<StringMatchSearchCommon>;
            updatedAt?: InputMaybe<DateRangeSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatments =
        {
            affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            archivedAt?: InputMaybe<DateRangeSearchCommon>;
            consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
            franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
            franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
            group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
            /** Expects node: **TestTreatment** */
            id?: InputMaybe<Scalars['ID']>;
            instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isRadical?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **TestTreatment** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            unit?: InputMaybe<UnitNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntitySites = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaffRegistration>;
        teams?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaffTeams>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobCustomerUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntity = {
        addresses?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityAddresses>;
        chainUpdate?: InputMaybe<DateRangeSearchCommon>;
        contacts?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityContacts>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects nodes: **User**, **Customer**, **Staff**, **StaffRegistration**, **FranchiseShop**, **WarrantyClaim**, **ServiceJob**, **Entity** */
        not?: InputMaybe<Scalars['ID']>;
        pools?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPools>;
        sites?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntitySites>;
        user?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUser>;
        uuid?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityAddresses = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityContacts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        data?: InputMaybe<StringLikeOrMatchSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Contact** */
        id?: InputMaybe<Scalars['ID']>;
        isPrimary?: InputMaybe<BooleanMatchSearchCommon>;
        label?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **Contact** */
        not?: InputMaybe<Scalars['ID']>;
        type?: InputMaybe<ContactTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPools = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory>;
        reports?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsReports>;
        sanitiser?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiser>;
        site?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSite>;
        type?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsAffects =
        {
            measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
            primary?: InputMaybe<BooleanMatchSearchCommon>;
            treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
            value?: InputMaybe<FloatMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserCriteriaToLowValueProblemTreatmentsGroup =
        {
            active?: InputMaybe<BooleanMatchSearchCommon>;
            createdAt?: InputMaybe<DateRangeSearchCommon>;
            /** Expects node: **TestTreatmentGroup** */
            id?: InputMaybe<Scalars['ID']>;
            /** Expects node: **TestTreatmentGroup** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSite = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProducts =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
            customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            id?: InputMaybe<Scalars['ID']>;
            identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
            isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
            not?: InputMaybe<Scalars['ID']>;
            organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
            retiredAt?: InputMaybe<DateRangeSearchCommon>;
            sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
            supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrand =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **Brand** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **Brand** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityPoolsTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntitySites = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntitySitesAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntitySitesAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        customer?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomer>;
        firstName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        id?: InputMaybe<Scalars['ID']>;
        lastName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **Customer**, **Staff**, **StaffRegistration**, **User** */
        not?: InputMaybe<Scalars['ID']>;
        staff?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaff>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomer = {
        addedBy?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedBy>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        assignedTo?: InputMaybe<StaffNodeMatchSearchCommon>;
        billAddress?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerBillAddress>;
        companyName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        contactName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        crn?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Customer** */
        id?: InputMaybe<Scalars['ID']>;
        inVend?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **Customer** */
        not?: InputMaybe<Scalars['ID']>;
        primaryAddress?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress>;
        tags?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerTags>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedBy = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedByRegistration>;
        teams?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedByTeams>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedByRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerAddedByTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerBillAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerPrimaryAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserCustomerTags = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **CustomerTag** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaff = {
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        id?: InputMaybe<Scalars['ID']>;
        isSystemHidden?: InputMaybe<BooleanMatchSearchCommon>;
        /** Expects nodes: **StaffRegistration**, **Staff** */
        not?: InputMaybe<Scalars['ID']>;
        registration?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaffRegistration>;
        teams?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaffTeams>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaffRegistration = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        disabledAt?: InputMaybe<DateRangeSearchCommon>;
        email?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **StaffRegistration** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffRegistration** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobEntityUserStaffTeams = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **StaffTeam** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **StaffTeam** */
        not?: InputMaybe<Scalars['ID']>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        title?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPool = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        bottleNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Pool** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Pool** */
        not?: InputMaybe<Scalars['ID']>;
        ownershipHistory?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolOwnershipHistory>;
        reports?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolReports>;
        sanitiser?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiser>;
        site?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSite>;
        type?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolType>;
        volume?: InputMaybe<FloatRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolOwnershipHistory = {
        /** Expects node: **PoolOwnershipHistory** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **PoolOwnershipHistory** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        transferredBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        transferredFrom?: InputMaybe<EntityNodeMatchSearchCommon>;
        transferredTo?: InputMaybe<EntityNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolReports = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **TestReport** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestReport** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
        refId?: InputMaybe<StringMatchSearchCommon>;
        reportBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        reportTo?: InputMaybe<EntityNodeMatchSearchCommon>;
        sampleSet?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolReportsSampleSet>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
        workOrder?: InputMaybe<WorkOrderNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolReportsSampleSet = {
        addedBy?: InputMaybe<StaffNodeMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **SampleSet** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **SampleSet** */
        not?: InputMaybe<Scalars['ID']>;
        pool?: InputMaybe<PoolNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiser = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteria>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        poolTypes?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaPoolTypes = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSanitiserRelatedDeclarationsProductsBrandCompany = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **ProductCompany** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSite = {
        address?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolSiteAddress>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Site** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Site** */
        not?: InputMaybe<Scalars['ID']>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolSiteAddress = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        buildingName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        city?: InputMaybe<StringLikeOrMatchSearchCommon>;
        country?: InputMaybe<StringMatchSearchCommon>;
        destinationName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        detailsSortDirection?: InputMaybe<Scalars['Boolean']>;
        entity?: InputMaybe<EntityNodeMatchSearchCommon>;
        /** Expects node: **Address** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **Address** */
        not?: InputMaybe<Scalars['ID']>;
        poBoxNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        postCode?: InputMaybe<StringLikeOrMatchSearchCommon>;
        state?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetName?: InputMaybe<StringLikeOrMatchSearchCommon>;
        streetNumber?: InputMaybe<StringLikeOrMatchSearchCommon>;
        suburb?: InputMaybe<StringLikeOrMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        criteria?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteria>;
        /** Expects node: **PoolType** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **PoolType** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteria = {
        actualHigherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualLowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        actualTarget?: InputMaybe<FloatMatchSearchCommon>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringMatchSearchCommon>;
        higherThreshold?: InputMaybe<FloatMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        id?: InputMaybe<Scalars['ID']>;
        lowerThreshold?: InputMaybe<FloatMatchSearchCommon>;
        measurementType?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestCriteria** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        sanitisers?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers>;
        targetValue?: InputMaybe<FloatMatchSearchCommon>;
        toLowValueProblem?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaMeasurementType = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Measurement** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Measurement** */
        not?: InputMaybe<Scalars['ID']>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        tagIdentifier?: InputMaybe<StringMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisers = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        /** Expects node: **Sanitiser** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Sanitiser** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        publishedAt?: InputMaybe<DateRangeSearchCommon>;
        relatedDeclarations?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarations = {
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringMatchSearchCommon>;
        /** Expects node: **ProductTraitDeclaration** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        parent?: InputMaybe<ProductTraitDeclarationNodeMatchSearchCommon>;
        products?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts>;
        tag?: InputMaybe<StringMatchSearchCommon>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProducts = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        brand?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand>;
        customSku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        id?: InputMaybe<Scalars['ID']>;
        identification?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isOneOff?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects nodes: **ConsumableProduct**, **InstallableProduct**, **Product** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        retiredAt?: InputMaybe<DateRangeSearchCommon>;
        sku?: InputMaybe<StringLikeOrMatchSearchCommon>;
        supplier?: InputMaybe<ProductCompanyNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrand = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        company?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany>;
        franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
        /** Expects node: **Brand** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **Brand** */
        not?: InputMaybe<Scalars['ID']>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaSanitisersRelatedDeclarationsProductsBrandCompany =
        {
            anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            id?: InputMaybe<Scalars['ID']>;
            name?: InputMaybe<StringLikeOrMatchSearchCommon>;
            /** Expects node: **ProductCompany** */
            not?: InputMaybe<Scalars['ID']>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblem = {
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        description?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        id?: InputMaybe<Scalars['ID']>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestProblem** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        treatments?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments>;
        updatedAt?: InputMaybe<DateRangeSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatments = {
        affects?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects>;
        anyString?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
        archivedAt?: InputMaybe<DateRangeSearchCommon>;
        consumableProduct?: InputMaybe<ConsumableProductNodeMatchSearchCommon>;
        franchiseBlacklists?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists>;
        franchisePriorities?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities>;
        group?: InputMaybe<WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup>;
        /** Expects node: **TestTreatment** */
        id?: InputMaybe<Scalars['ID']>;
        instruction?: InputMaybe<StringLikeOrMatchSearchCommon>;
        isRadical?: InputMaybe<BooleanMatchSearchCommon>;
        name?: InputMaybe<StringLikeOrMatchSearchCommon>;
        /** Expects node: **TestTreatment** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
        unit?: InputMaybe<UnitNodeMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsAffects = {
        measurementType?: InputMaybe<MeasurementNodeMatchSearchCommon>;
        primary?: InputMaybe<BooleanMatchSearchCommon>;
        treatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        value?: InputMaybe<FloatMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchiseBlacklists =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsFranchisePriorities =
        {
            franchise?: InputMaybe<FranchiseNodeMatchSearchCommon>;
            priority?: InputMaybe<IntMatchSearchCommon>;
            testTreatment?: InputMaybe<TestTreatmentNodeMatchSearchCommon>;
        };

    export type WorkOrderViewerWorkOrdersSearchServiceJobPoolTypeCriteriaToLowValueProblemTreatmentsGroup = {
        active?: InputMaybe<BooleanMatchSearchCommon>;
        createdAt?: InputMaybe<DateRangeSearchCommon>;
        /** Expects node: **TestTreatmentGroup** */
        id?: InputMaybe<Scalars['ID']>;
        /** Expects node: **TestTreatmentGroup** */
        not?: InputMaybe<Scalars['ID']>;
        organisationType?: InputMaybe<OrganisationTypeNodeMatchSearchCommon>;
        priority?: InputMaybe<IntMatchSearchCommon>;
    };

    export type WorkOrderViewerWorkOrdersSort = {
        createdAt?: InputMaybe<Scalars['Boolean']>;
        dueRangeEnd?: InputMaybe<Scalars['Boolean']>;
        dueRangeStart?: InputMaybe<Scalars['Boolean']>;
        /** Expects node: **WorkOrder** */
        first?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
        statusClosedAt?: InputMaybe<Scalars['Boolean']>;
        updatedAt?: InputMaybe<Scalars['Boolean']>;
        workOrderNumber?: InputMaybe<Scalars['Boolean']>;
    };

    /** A connection to a list of items. */
    export type WorkOrdersForAppointmentInstanceToWorkOrderConnection = {
        __typename?: 'WorkOrdersForAppointmentInstanceToWorkOrderConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<WorkOrdersForAppointmentInstanceToWorkOrderEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<WorkOrdersForAppointmentInstanceToWorkOrderMeta>;
    };

    /** An edge in a connection. */
    export type WorkOrdersForAppointmentInstanceToWorkOrderEdge = {
        __typename?: 'WorkOrdersForAppointmentInstanceToWorkOrderEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrder>;
    };

    export type WorkOrdersForAppointmentInstanceToWorkOrderMeta = {
        __typename?: 'WorkOrdersForAppointmentInstanceToWorkOrderMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };

    /** A connection to a list of items. */
    export type WorkOrdersForServiceJobToWorkOrderConnection = {
        __typename?: 'WorkOrdersForServiceJobToWorkOrderConnection';
        /** A list of edges. */
        edges?: Maybe<Array<Maybe<WorkOrdersForServiceJobToWorkOrderEdge>>>;
        /** Information to aid in pagination. */
        pageInfo: PageInfo;
        pageMeta?: Maybe<WorkOrdersForServiceJobToWorkOrderMeta>;
    };

    /** An edge in a connection. */
    export type WorkOrdersForServiceJobToWorkOrderEdge = {
        __typename?: 'WorkOrdersForServiceJobToWorkOrderEdge';
        /** A cursor for use in pagination */
        cursor: Scalars['String'];
        /** The item at the end of the edge */
        node?: Maybe<WorkOrder>;
    };

    export type WorkOrdersForServiceJobToWorkOrderMeta = {
        __typename?: 'WorkOrdersForServiceJobToWorkOrderMeta';
        pageCount?: Maybe<Scalars['Int']>;
        totalCount?: Maybe<Scalars['Int']>;
    };
}
