export const defaultModuleAccess = {
    SuperAdmin: {
        enabled: false,
    },
    OrgAdmin: {
        enabled: false,
    },
    FranchiseAdmin: {
        enabled: false,
        companyProfile: false,
        staff: true,
        integrationConf: false,
    },
    System: {
        enabled: false,
        printAccess: true,
        fileUpload: true,
        emailReports: true,
        appProfile: 'default' as 'default' | 'davey',
    },
    Sidebar: {
        enabled: true,
        showNewCustomer: true,
        showSearch: true,
    },
    Emails: {
        enabled: false,
        admin: false,
    },
    Customers: {
        enabled: true,
        nameTitle: false, // allow "Mr/Ms/etc" in customer name
        contracts: false,
        cxofficer: false,
        registrationDate: false,
        forceContactsOnNewForm: false,
    },
    Staff: {
        enabled: false,
        teams: false,
    },
    Pools: {
        enabled: true,
        poolList: false,
        siteOwnershipChange: true,
        barcodedBottles: false,
        poolFlagFields: false,
        installedEquipment: false,
    },
    Calculators: {
        enabled: false,
        admin: false,
        heatpumpZaneSwimartBrandingSelect: false,
        heatpumpAquatightBrandingSelect: false,
        heatpump: false,
        heatpumpAutopool: false,
        gasheat: false,
        saveRecommendations: false,
    },
    Reports: {
        enabled: true,
        customer: false,
    },
    ProductCatalog: {
        enabled: true,
        admin: false,
    },
    FieldServices: {
        enabled: false,
        // Allow calendar and appointment
        appointments: false,
        // Allow Recurring Appointments
        recurringAppointment: false,
        // Allows Service Cases (non-Recurring)
        serviceJobs: false,
        // Enables/disables recurring service cases
        recurringCases: false,

        // Shows/hides sidebar link to the recurring services section
        recurringCasesShowPageLink: false,

        // Shows/hides sidebar link to work orders
        workOrderShowPageLink: false,

        // Allow creations of multiple schedules for a single recurring case
        multiScheduledRecurringCases: false,

        // Allow Service Case Templates
        caseTemplates: false,
        // Allow Service Job Task Templates
        serviceJobTaskTemplates: false,

        // Enables/disables booking of unscheduled work orders by
        // drag-and-dropping them to the calendar from a sidebar
        calendarWorkOrderDND: false,
        //if set true, users will only see appointments assigned to them
        calendarSingleStaffMode: false,
        // Show list of aggregated streets on sidebar
        calendarStreetsSummary: false,

        // Automatically create work order for new appointments
        calendarAutoCreateWo: false,

        // Enable doc mode from desctopXL size.
        calendarDockModeScreenSizeXL: false,

        // Add water tests to work orders
        waterTestFromWorkOrder: false,

        // Invoices
        invoices: false,

        // Show/hide customer notes section in appointment print page
        printAppointmentCustomerNotes: false,

        // New mobile layout
        mobileLayoutV2: false,
    },
    VendIntegration: {
        enabled: false,
        admin: false,
        // allows to delete Vend integration
        allowDelete: false,
    },
    WaterlinkIntegration: {
        enabled: true,
        admin: false,
        mockAPI: false,
    },
    WaterTest: {
        enabled: false,
        admin: false,
        // enables waterlink integration
        waterlink: false,
        // allows sending reports via email
        sendViaEmail: false,
    },
    EZConnectIntegration: {
        enabled: false,
        admin: false,
        accessDeviceView: false,
        accessDeviceRegistration: false,
    },
    Warranties: {
        enabled: true,
        franchiseMsgInbox: true,
    },
    SDS_LIST: {
        enabled: false,
    },
    Downloads: {
        enabled: false,
    },
    Export: {
        enabled: false,
        singleFranchise: false,
        bulkExport: false,
        // TODO: move to Import section, or rename current
        singleFranchiseImport: false,
    },
    Support: {
        enabled: true,
    },
    HQAdmin: {
        enabled: false,
    },
    Other: {
        enabled: true,
        swimartTrainingForms: false,
    },
};

type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export type ModulesAccess = RecursivePartial<typeof defaultModuleAccess>;
