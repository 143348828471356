import * as React from 'react';
import { useContext } from 'react';
import { VStack } from '../Stack';
import tw, { styled } from 'twin.macro';
import { FullScreen } from '../FullScreen';
import { PageHeader, PageHeaderSub } from '../PageHeader';
import { ThreeColumnManaged } from './ThreeColumnManaged';
import { SideBarLayout } from './SideBarLayout';
import { cva, VariantProps } from 'class-variance-authority';
import { clsxm } from '../../utils';

export type PageLayoutWidthType =
    | 'full'
    | 'screen-sm'
    | 'screen-md'
    | 'screen-lg'
    | 'screen-xl'
    | 'screen-2xl'
    | 'screen-wide';

export type PagePaddingType = boolean | 'sm' | 'md' | 'lg';

export type PageLayoutCtxType = {
    width?: PageLayoutWidthType;
    sectionPadding?: PagePaddingType;
};
const PageLayoutCtx = React.createContext<PageLayoutCtxType>({ width: 'screen-wide' });

export const usePageLayoutContext = () => useContext(PageLayoutCtx);

export const PageLayoutTwoColumns = styled.div<{ mobileColReverse?: boolean }>(({ mobileColReverse }) => [
    tw`flex flex-col gap-4 pb-4 lg:grid lg:grid-cols-2`,
    mobileColReverse && tw`flex-col-reverse`,
]);

const widthVariants = {
    full: 'w-full max-w-full',
    'screen-sm': 'max-w-screen-sm',
    'screen-md': 'max-w-screen-md',
    'screen-lg': 'max-w-screen-lg',
    'screen-xl': 'max-w-screen-xl',
    'screen-2xl': 'max-w-screen-2xl',
    'screen-wide': 'max-w-screen-wide',
};

const paddingVariants = {
    false: 'p-0',
    true: 'p-2',
    sm: 'p-1',
    md: 'p-4',
    lg: 'p-8',
};

const pageWrapperVariants = cva('w-full mx-auto max-w-[140rem]', {
    variants: {
        width: widthVariants,
        padding: paddingVariants,
    },
    defaultVariants: {
        padding: true,
    },
});

export interface PageWrapperProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof pageWrapperVariants> {}

export const PageWrapper: React.FC<PageWrapperProps> = ({ width, padding, children, className, ...rest }) => {
    return (
        <div
            data-testid={'PageLayout.PageWrapper'}
            className={clsxm(pageWrapperVariants({ width, padding }), className)}
            {...rest}
        >
            {children}
        </div>
    );
};

const bodySectionVariants = cva('w-full mx-auto px-0', {
    variants: {
        width: widthVariants,
        padding: paddingVariants,
        vStack: {
            true: 'flex flex-col gap-4',
        },
    },
});

export interface BodySectionProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof bodySectionVariants> {
    bodySectionPadded?: boolean | string | number;
}

export const BodySection: React.FC<BodySectionProps> = ({ width, vStack, children, className, ...rest }) => {
    const ctx = usePageLayoutContext();
    const w = width || ctx?.width;
    return (
        <div
            className={clsxm(bodySectionVariants({ width: w, vStack: vStack, padding: ctx.sectionPadding }), className)}
            {...rest}
        >
            {children}
        </div>
    );
};

export interface PageLayoutProps extends PageWrapperProps {
    sectionWidth?: PageLayoutWidthType;
    sectionPadding?: PagePaddingType;
}

export class PageLayout extends React.Component<PageLayoutProps> {
    public static FullScreen = FullScreen;
    public static SideBarLayout = SideBarLayout;
    public static ThreeColumnManaged = ThreeColumnManaged;
    public static TwoColumns = PageLayoutTwoColumns;
    public static PageHeader = PageHeader;
    public static PageHeaderSub = PageHeaderSub;
    public static BodySection = BodySection;

    private static defaultProps = {
        width: 'screen-wide',
        sectionWidth: 'full',
        sectionPadding: false,
    };

    constructor(props: PageLayoutProps) {
        super(props);
    }

    render() {
        const ctxValue: PageLayoutCtxType = {
            width: this.props.sectionWidth || this.props.width,
            sectionPadding: this.props.sectionPadding,
        };

        return (
            <PageLayoutCtx.Provider value={ctxValue}>
                <PageWrapper className={this.props.className} width={this.props.width} padding={this.props.padding}>
                    <VStack>{this.props.children}</VStack>
                </PageWrapper>
            </PageLayoutCtx.Provider>
        );
    }
}
