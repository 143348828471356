import * as React from 'react';
import { useField } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { FormField, FormikFieldProps } from './FormField';
import { CheckboxRadix, CheckboxRadixProps } from '../radix';
import { useId } from './useId';

export const FormikCheckboxField: React.FC<CheckboxRadixProps & FormikFieldProps> = ({
    id,
    label,
    name,
    required,
    ...rest
}) => {
    const fieldId = useId(id);
    const [field, meta, helpers] = useField(name);

    const value = field.value || false;
    const touched = meta.touched;
    const error = meta.error;
    let hasError = touched && !!error;

    return (
        <FormField className="flex-row items-center space-x-1">
            <CheckboxRadix
                id={fieldId}
                checked={value}
                onCheckedChange={helpers.setValue}
                {...rest}
                aria-invalid={hasError}
            />
            <FormikFormFieldLabel
                htmlFor={fieldId}
                label={label}
                name={name}
                required={required}
                className={'font-normal hover:cursor-pointer '}
            />
        </FormField>
    );
};
