import gql from 'graphql-tag';
import { createUseQueryNodeHook } from '@ez/api-core';
import { addressFragment, NodeType, poolFragment } from '@poolware/api';

const QL = gql`
    query ($id: ID!) {
        node(id: $id) {
            id
            ... on EZCDeviceLink {
                id
                createdAt
                deviceName
                label
                franchise {
                    id
                    name
                    organisationType {
                        id
                        name
                    }
                }
                device {
                    id
                    title
                    deviceId
                    ezcId
                    version
                    createdAt
                    definition {
                        id
                        definitionId
                        name
                    }
                }
                pool {
                    id
                    ...PoolFragment
                }
                owner {
                    id
                    contactName
                    crn
                    user {
                        id
                        firstName
                        lastName
                    }
                    primaryEmail {
                        id
                        data
                        label
                    }
                    primaryPhone {
                        id
                        data
                        label
                    }
                    primaryAddress {
                        id
                        ...AddressFragment
                    }
                }
                checkMutations {
                    update
                    delete
                }
            }
        }
    }
    ${addressFragment}
    ${poolFragment}
`;

export const useQueryEzcDeviceLink = createUseQueryNodeHook<NodeType.EZCDeviceLink>(QL);
