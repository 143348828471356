import * as React from 'react';
import { AppointmentDetailsProps, AppointmentPreviewControl } from './AppointmentDetailsContent';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';

export interface AppointmentPreviewPageProps extends Omit<AppointmentDetailsProps, 'mobileLayoutMode'> {
    onClose: () => any | Promise<any>;
    onEdit: (item: NodeType.AppointmentItem) => any | Promise<any>;
    onDidDelete?: () => any | Promise<any>;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    open: boolean;
}

export const AppointmentMobilePage: React.FC<AppointmentPreviewPageProps> = (props) => {
    const { AppNavigator } = useAppNavigator();

    const handleClose = () => {
        AppNavigator.navigateToOrigin(`/scheduler`);
    };

    const onAppointmentItemEdit = (item: NodeType.AppointmentItem) => {
        AppNavigator.replace(`/scheduler/${item.id}/edit`, {
            setOrigin: AppNavigator.returnTo || '/scheduler',
        });
    };

    let { appointmentItem, onShowInCalendar, appointmentItemId, onDidDelete, showServiceJobLink } = props;

    return (
        <div className={'pb-12'}>
            <AppointmentPreviewControl
                mode={'page'}
                onDidDelete={onDidDelete}
                appointmentItem={appointmentItem}
                appointmentItemId={appointmentItemId}
                onClose={handleClose}
                onEdit={onAppointmentItemEdit}
                onShowInCalendar={onShowInCalendar}
                showServiceJobLink={showServiceJobLink}
            />
        </div>
    );
};
