import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';

const baseColor = '#F4F4F4';
const shineColor = 'rgba(229,229,229,0.8)';
const animationDuration = '2.0s';

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
const backgroundGradient = () => `
    background-image: linear-gradient(90deg, ${baseColor} 0px, ${shineColor} 40px, ${baseColor} 80px);
    background-size: 1280px;
`;

const ShineLines = keyframes`
    0% {
        background-position: -160px;
    }
    100%{
        background-position: 1200px;
    }
`;

const ContentContainer = styled.div`
    height: 100%;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const BlockContainer = styled.div`
    width: 100%;
    //height: 80px;
`;

const Line = styled.div<{ lineHeight?: number }>`
    float: left;
    width: 100%;
    height: ${({ lineHeight }) => (lineHeight ? lineHeight : 16)}px;
    margin-top: ${({ lineHeight }) => (lineHeight ? lineHeight * 0.75 : 8)}px;
    border-radius: 7px;

    ${backgroundGradient()};
    animation: ${ShineLines} ${animationDuration} infinite ease-out;
`;

export interface PageSkeletonLoaderProps {
    lineCount?: number;
    lineHeight?: number;
}

export const PageSkeletonLoader: React.FC<PageSkeletonLoaderProps> = ({ lineCount = 3, lineHeight = 16 }) => {
    return (
        <Segment basic clearing>
            <ContentContainer>
                <BlockContainer>
                    {Array.from(Array(lineCount)).map((i, index) => {
                        return <Line key={index} lineHeight={lineHeight} />;
                    })}
                </BlockContainer>
            </ContentContainer>
        </Segment>
    );
};
