import * as React from 'react';
import { NodeType, useVendMutators } from '@poolware/api';
import {
    Alert,
    Button,
    ButtonWithConfirm,
    ConnectionErrorMessage,
    Display,
    Modal,
    PageSkeletonLoader,
    Panel,
    toast,
    toastError,
    useModalCtrl,
    VStack,
} from '@ez/components';
import _truncate from 'lodash/truncate';
import { useQueryCustomer } from './use-query-customer-vend';
import { FormLinkWithVendCustomer } from './FormLinkWithVendCustomer';

const PanelVendCustomerDetails: React.FC<{ vendLink: NodeType.VendLink }> = ({ vendLink }) => {
    const { vendCustomer } = vendLink || {};
    if (!vendCustomer) {
        return null;
    }
    const formatVendAddress = (address: NodeType.VendPhysicalAddress | NodeType.VendPostalAddress) => {
        if (!address) {
            return '--';
        }
        return [
            address.streetLine1,
            address.streetLine2,
            address.suburb,
            address.city,
            address.state,
            address.postCode,
            address.countryId,
        ].join(', ');
    };

    return (
        <Panel>
            <Panel.Header icon={'tag'} content={'Vend Record'} color={'green'} />
            <Panel.Body>
                {vendCustomer.deletedAt && (
                    <Panel.Item label={'Deleted'} color={'red'}>
                        <Display.Date value={vendCustomer.deletedAt} />
                    </Panel.Item>
                )}
                <Panel.Item label={'Customer Code'}>{vendCustomer.customerCode}</Panel.Item>
                <Panel.Item label={'First Name'}>{vendCustomer.firstName || '--'}</Panel.Item>
                <Panel.Item label={'Last Name'}>{vendCustomer.lastName || '--'}</Panel.Item>
                <Panel.Item label={'Company Name'}>{vendCustomer.companyName || '--'}</Panel.Item>
                <Panel.Item label={'Email'}>{vendCustomer.email || '--'}</Panel.Item>
                <Panel.Item label={'Phone'}>{vendCustomer.phone || '--'}</Panel.Item>
                <Panel.Item label={'Physical Address'}>{formatVendAddress(vendCustomer.physicalAddress)}</Panel.Item>
                <Panel.Item label={'Postal Address'}>{formatVendAddress(vendCustomer.postalAddress)}</Panel.Item>
                <Panel.Divider />
                <Panel.Item label={'Balance'}>{vendCustomer.balance || '--'}</Panel.Item>
                <Panel.Item label={'Loyalty Balance'}>{vendCustomer.loyaltyBalance || '--'}</Panel.Item>
                <Panel.Item label={'Do not email'}>{vendCustomer.doNotEmail ? 'Y' : 'N'}</Panel.Item>

                <Panel.Divider />
                <Panel.Item label={'Vend Link'}>
                    <a href={vendLink.vendURL} rel="noopener noreferrer" target="_blank">
                        {_truncate(vendLink.vendURL, { length: 60 })}
                    </a>
                </Panel.Item>
                <Panel.Item label={'Vend UUID'}>{vendLink?.vendUUID}</Panel.Item>
                <Panel.Item label={'Custom field 1'}>{vendCustomer.customField1}</Panel.Item>
                <Panel.Item label={'Custom field 2'}>{vendCustomer.customField2}</Panel.Item>
                <Panel.Item label={'Custom field 3'}>{vendCustomer.customField3}</Panel.Item>
                <Panel.Item label={'Custom field 4'}>{vendCustomer.customField4}</Panel.Item>
            </Panel.Body>
            <Panel.Footer>
                <Alert type={'info'}>Customer details fetched from Vend</Alert>
            </Panel.Footer>
        </Panel>
    );
};

export interface VendCustomerDetailsProps {
    customerId: NodeType.ID;
}

export const VendCustomerDetails: React.FC<VendCustomerDetailsProps> = ({ customerId }) => {
    const { loading, error, node, refetchQuery } = useQueryCustomer(customerId);
    const { VendMutator } = useVendMutators(['CustomerVendQuery', 'CustomerQuery', refetchQuery]);
    const modalCtrl = useModalCtrl();

    if (loading) {
        return (
            <Panel>
                <Panel.Header icon={'tag'} content={'Vend Record'} />
                <Panel.Body>
                    <PageSkeletonLoader />
                </Panel.Body>
            </Panel>
        );
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <Alert type={'warning'}>Unable to load</Alert>;
    }

    const pushToVend = async () => {
        try {
            await VendMutator.pushCustomerToVend(customerId);
        } catch (error) {
            console.error(error);
            toastError({
                time: 4000,
                title: 'Failed to push customer to VEND',
                description: error.message,
            });
        }
    };

    const restoreOnVend = async () => {
        try {
            await VendMutator.resyncVendCustomer(node.vendLink);
        } catch (error) {
            console.error(error);
            toast({
                type: 'error',
                time: 4000,
                title: 'Failed to restore customer on VEND',
                description: error.message,
            });
        }
    };

    const vendAPI = node?.root?.franchise?.vend;

    if (!node.vendLink || !node.vendLink?.vendURL) {
        // Not synced yet. Show push button.
        return (
            <Panel>
                <Panel.Header icon={'tag'} content={'Vend Record'} />
                <Panel.Body>
                    <Alert type={'info'}>Customer is not synced with Vend yet</Alert>
                </Panel.Body>
                <Panel.Footer className={'justify-start gap-2'}>
                    <Button
                        size={'small'}
                        color={'purple'}
                        icon={'upload'}
                        onClick={pushToVend}
                        content={'Push To Vend'}
                    />
                    {vendAPI && (
                        <Button
                            size={'small'}
                            color={'blue'}
                            icon={'linkify'}
                            onClick={modalCtrl.onOpen}
                            content={'Link with existing Vend customer'}
                        />
                    )}
                </Panel.Footer>
                <Modal {...modalCtrl} size={'tiny'}>
                    {modalCtrl.open && (
                        <FormLinkWithVendCustomer
                            customer={node}
                            vendAPI={vendAPI}
                            onDone={modalCtrl.onClose}
                            refetchQueries={[refetchQuery]}
                        />
                    )}
                </Modal>
            </Panel>
        );
    }

    if (node.vendLink?.isDeleted) {
        // "Reactivate" (sync mutation)
        return (
            <VStack>
                <Panel>
                    <Panel.Header icon={'unlink'} color={'red'} content={'Vend Sync Error'} />
                    <Panel.Body>
                        <Alert type={'warning'}>
                            <p>
                                Our system has detected that a previously existed record of this customer does not exist
                                on VEND anymore. The most likely reason is that the customer was manually deleted from
                                VEND. Sales history may be lost forever!
                            </p>
                            <p>If you need to recover the deleted customer on Vend, please contact Vend support.</p>
                            <p>If you wish to create a new record of this customer on VEND, press the button below .</p>
                            <p>IMPORTANT: New record will be create on VEND.</p>
                        </Alert>
                        <Panel.Divider />
                    </Panel.Body>
                    <Panel.Footer>
                        <ButtonWithConfirm
                            size={'small'}
                            color={'orange'}
                            icon={'upload'}
                            onClick={restoreOnVend}
                            content={'Create New Vend Record'}
                            confirm={{
                                confirmMessage: {
                                    header: 'Create New Customer on Vend?',
                                    content: <p>New record will be create on VEND.</p>,
                                },
                                confirmButton: { content: 'Create New Customer', icon: 'upload' },
                                cancelButton: { content: 'Cancel' },
                            }}
                        />
                    </Panel.Footer>
                </Panel>
                {node.vendLink?.vendCustomer && <PanelVendCustomerDetails vendLink={node.vendLink} />}
            </VStack>
        );
    }

    return <PanelVendCustomerDetails vendLink={node.vendLink} />;
};
