import * as React from 'react';
import { Alert, PageLayout, PageSkeletonLoader } from '@ez/components';
import { NodeType } from '@poolware/api';
import { DeviceStateLifeGuard } from './Lifeguard/DeviceStateLifeGuard';
import { DeviceStateGeneric } from './Generic/DeviceStateGeneric';
import { EZCDevicesLinkWithState } from '../../common';

const deviceStateRenderer = (device: NodeType.EZCDeviceState) => {
    switch (device.device?.definition?.definitionId) {
        case 'DAVEY:DV_LG:DAVEY_LG:DAVEY_LG_IOT':
            return DeviceStateLifeGuard;
        default:
            return DeviceStateGeneric;
    }
};

export const TabDeviceState: React.FC<{ device: EZCDevicesLinkWithState; refetchQueries: any[] }> = ({
    device,
    refetchQueries,
}) => {
    if (device.isLoadingShadow) {
        return <PageSkeletonLoader />;
    }
    // TODO: handle error
    // if (error) {
    //     return <ConnectionErrorMessage error={error} />;
    // } else
    else if (!device.deviceState) {
        return <Alert type={'warning'}>Failed to load device status</Alert>;
    }

    const DeviceStateComponent = deviceStateRenderer(device.deviceState);

    return (
        <PageLayout.BodySection width={'screen-wide'} vStack={true} className={'px-0'}>
            <DeviceStateComponent device={device} refetchQueries={refetchQueries} />
        </PageLayout.BodySection>
    );
};
