import * as React from 'react';
import {
    AppSidebarMenuItem,
    LayoutConf,
    LayoutState,
    LayoutWrapper,
    LayoutWrapperGlobalStyle,
    Modal,
} from '@ez/components';
import { restoreAppLayout, saveAppLayout } from './utils';
import { isScreenWider } from '@ez/tools';
import { withRouter } from 'react-router';
import { AppErrorBoundary } from '../components';

const defaultLayoutConf: LayoutConf = {
    layoutMask: {
        backgroundColor: 'rgb(0,0,0)',
    },
    sidebar: {
        width: '170px',
        widthMini: '50px',
        widthMobile: '280px',
    },
    navbar: {
        height: 'var(--topBar, 46px)',
    },
    layoutState: {
        mobileCollapsed: true,
        desktopCollapsed: false,
        mini: false,
        animationEnabled: true,
        isDesktop: true,
    },
    desktopBreakpoint: 768,
};

export interface LayoutControlProps {
    layoutState: LayoutState;
    showSidebarToggle?: boolean;
    onToggleMenu?: (close?: boolean) => any;
    onSidebarMenuClick?: (item: AppSidebarMenuItem) => any;
}

export interface PageLayoutManagerComponentProps {
    children: (props: { location }) => React.ReactElement<any>;
    defaultLayoutState?: LayoutState;
    onLayoutChange?: (newState: LayoutState) => any;
}

export interface PageLayoutManagerInjectedProps {
    location?: any;
}

export interface PageLayoutManagerExternalProps extends PageLayoutManagerComponentProps {
    AppNavbar: React.ComponentType<LayoutControlProps>;
    AppSidebar: React.ComponentType<LayoutControlProps>;
}

export interface PageLayoutManagerProps extends PageLayoutManagerInjectedProps, PageLayoutManagerExternalProps {
    history: any;
}

export interface State {
    isDesktop: boolean;
    sidebarMinimized: boolean;
    desktopSidebarCollapsed: boolean;
    mobileSidebarCollapsed: boolean;
    animationEnabled: boolean;
    innerWidth: number;
}

class AppShellLayoutManagerC extends React.Component<PageLayoutManagerProps, State> {
    private rqf: any;
    private previousChildren = undefined;
    private previousLocation = this.props.location;

    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false, // Set to false by default, to avoid visual glitch on mobile devices.
            desktopSidebarCollapsed: false,
            mobileSidebarCollapsed: true,
            animationEnabled: true,
            sidebarMinimized: props.defaultLayoutState !== undefined ? props.defaultLayoutState.mini : false,
            innerWidth: window.innerWidth,
        };
    }

    public componentDidMount() {
        this.updateDimensionsImmediate();
        window.addEventListener('resize', this.onResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.rqf = null;
    }

    public onResize = () => {
        if (this.rqf) {
            return;
        }
        this.rqf = window.requestAnimationFrame(() => {
            this.rqf = null;
            this.updateDimensions();
        });
    };

    private isDesktop = () => {
        return window.innerWidth > defaultLayoutConf.desktopBreakpoint;
    };

    public updateDimensionsImmediate = () => {
        const { isDesktop: prevIsDesktop } = this.state;
        const newIsDesktop = this.isDesktop();

        if (prevIsDesktop == newIsDesktop) {
            return;
        }

        this.setState(
            {
                desktopSidebarCollapsed: false,
                mobileSidebarCollapsed: true,
                isDesktop: newIsDesktop,
                animationEnabled: false,
            },
            () =>
                setTimeout(() => {
                    if (!this.rqf) return;
                    this.setState({ animationEnabled: true });
                }, 500)
        );

        this.notifyLayoutStateObserver();
    };

    // public updateDimensions = _.debounce(this.updateDimensionsImmediate, 20);
    public updateDimensions = this.updateDimensionsImmediate;

    componentDidUpdate(prevProps: Readonly<PageLayoutManagerProps>, prevState: Readonly<State>) {
        let { location } = this.props;

        if (!location) {
            // Well..
            return;
        }

        if (!this.props.history) {
            // Most likely react-router v3
            if (
                this.props.location.key !== location.key &&
                this.props.location.state &&
                this.props.location.state.modal
            ) {
                // save the old children & location
                this.previousLocation = prevProps.location;
                this.previousChildren = prevProps.children;
            }
            return;
        }

        // set previousLocation if props.location is not modal
        if (this.props.history?.action !== 'POP' && (!location.state || !location.state.modal)) {
            this.previousLocation = this.props.location;
            this.previousChildren = this.props.children;
        }
    }

    isModal = (): boolean => {
        const isWidescreen = isScreenWider(1024);
        let { location } = this.props;

        if (!isWidescreen) {
            return false;
        }

        if (!location) {
            return false;
        }

        return location.state && location.state.modal == true && !!this.previousChildren;
    };

    public onSidebarMenuClick = (_: AppSidebarMenuItem) => {
        if (this.state.isDesktop) {
        } else {
            this.setState(
                {
                    mobileSidebarCollapsed: true,
                },
                this.notifyLayoutStateObserver
            );
        }
    };

    public onToggleMenu = (shouldClose?: boolean) => {
        // console.log('onToggleMenu', shouldClose);
        if (this.state.isDesktop) {
            const nextState = shouldClose !== undefined ? shouldClose : !this.state.sidebarMinimized;
            this.setState(
                {
                    sidebarMinimized: nextState,
                    desktopSidebarCollapsed: false,
                    mobileSidebarCollapsed: true,
                },
                this.notifyLayoutStateObserver
            );
        } else {
            const nextState = shouldClose !== undefined ? shouldClose : !this.state.mobileSidebarCollapsed;
            this.setState(
                {
                    mobileSidebarCollapsed: nextState,
                },
                this.notifyLayoutStateObserver
            );
        }
    };

    getLayoutState = (): LayoutState => {
        return {
            mobileCollapsed: this.state.mobileSidebarCollapsed,
            desktopCollapsed: this.state.desktopSidebarCollapsed,
            mini: this.state.sidebarMinimized,
            isDesktop: this.state.isDesktop,
            animationEnabled: this.state.animationEnabled,
        };
    };

    notifyLayoutStateObserver = () => {
        const { onLayoutChange } = this.props;
        if (onLayoutChange) {
            onLayoutChange(this.getLayoutState());
        }
    };

    render() {
        const { AppNavbar, AppSidebar } = this.props;

        let isModal = this.isModal();

        const renderChildren = (children, location) => {
            if (!children) return null;
            const isFn = typeof children === 'function';
            return isFn ? children({ location: location }) : children;
        };

        const children = renderChildren(this.props.children, this.props.location);
        const previousChildren = renderChildren(this.previousChildren, this.previousLocation);
        const layoutState = this.getLayoutState();

        return (
            <LayoutWrapper data-testid={'AppShell.LayoutWrapper'} {...defaultLayoutConf} layoutState={layoutState}>
                <LayoutWrapperGlobalStyle {...defaultLayoutConf} layoutState={layoutState} />
                {AppNavbar && (
                    <AppNavbar showSidebarToggle={true} onToggleMenu={this.onToggleMenu} layoutState={layoutState} />
                )}
                {AppSidebar && (
                    <AppSidebar
                        showSidebarToggle={false}
                        onSidebarMenuClick={this.onSidebarMenuClick}
                        onToggleMenu={this.onToggleMenu}
                        layoutState={layoutState}
                    />
                )}
                <main id="content">
                    <AppErrorBoundary>
                        {isModal ? previousChildren : children}
                        {/*<div className={'w-full flex flex-col m-0 flex-grow p-1 sm:p-2 xl:p-3'}>*/}
                        {/*</div>*/}
                        {isModal && (
                            <Modal centered={false} size={'small'} open={true}>
                                <div style={{ minHeight: '50px' }}>{children}</div>
                            </Modal>
                        )}
                    </AppErrorBoundary>
                </main>
            </LayoutWrapper>
        );
    }
}

const AppShellLayoutManager = withRouter<any, React.ComponentType<PageLayoutManagerProps>>(AppShellLayoutManagerC);

// Helper method
const defaultLayoutState = restoreAppLayout();

export const createPageLayout =
    (components: {
        AppNavbar: React.ComponentType<LayoutControlProps>;
        AppSidebar: React.ComponentType<LayoutControlProps>;
    }): React.FC<PageLayoutManagerComponentProps> =>
    (props) => {
        return (
            <AppShellLayoutManager
                onLayoutChange={saveAppLayout}
                defaultLayoutState={defaultLayoutState}
                AppNavbar={components.AppNavbar}
                AppSidebar={components.AppSidebar}
                {...props}
            />
        );
    };
