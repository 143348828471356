import * as React from 'react';
import { Alert, AlertHeader, Button, Form, Segment } from '@ez/components';
import styled from 'styled-components';
import { AuthConnectProps } from '@ez/api-core';
import { IAppNavigatorProps } from '@poolware/react-app-navigator';
import { ThemeStateProps } from '../../app-theme';
import { getAppConfig, WithAppConfigProps } from '../../app-config';

const PoolwareIcon = styled.div<{ path?: string }>`
    background-image: url(${({ path }) => path || '/default/icon.png'});
    background-position: center center;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin-top: -8px;
    margin-bottom: -7px;
`;

const InvisibleDiv = styled.div`
    width: 1.5em;
    height: 1.5em;
    visibility: hidden;
`;

const LoginHeaderContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;

    > * {
        margin-left: 0.25em;
        margin-right: 0.25em;
    }
`;

const LoginFormPage = styled.div`
    position: fixed;
    background-color: rgba(179, 220, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
`;

const LoginFormContainer = styled.div`
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: 500px) {
        width: 90%;
        padding-top: 40px;
    }

    @media screen and (max-height: 420px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`;

const config = getAppConfig();

export interface PageVariantDefaultProps
    extends AuthConnectProps,
        IAppNavigatorProps,
        ThemeStateProps,
        WithAppConfigProps {
    onSubmit: (email: string, password: string) => Promise<void>;
}

export const PageVariantDefault: React.FC<PageVariantDefaultProps> = (props) => {
    const { AuthState, ThemeState } = props;
    const { isAuthenticating } = AuthState;
    const [state, setState] = React.useState({
        email: '',
        password: '',
        authenticating: false,
    });

    const handleInput = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: e.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { email, password } = state;
        await props.onSubmit(email, password);
    };

    const { theme } = ThemeState;
    return (
        <LoginFormPage>
            <LoginFormContainer>
                <Form>
                    {AuthState.authError && (
                        <Alert type={'error'}>
                            <AlertHeader>Error</AlertHeader>
                            <p>{AuthState.authError}</p>
                        </Alert>
                    )}

                    <Segment className={'bg-gray-100 rounded-b-none'} color={'blue'}>
                        <LoginHeaderContainer>
                            <PoolwareIcon path={theme.iconPath} />
                            <div>
                                <h3>Login</h3>
                            </div>
                            <InvisibleDiv />
                        </LoginHeaderContainer>
                    </Segment>
                    <div className={'bg-white grid gap-3 p-4 rounded-b'}>
                        <Form.Input
                            label="Email"
                            id={'email'}
                            value={state.email}
                            type="email"
                            autoComplete={'username'}
                            disabled={isAuthenticating}
                            onChange={handleInput('email')}
                            placeholder="Email"
                        />
                        <Form.Input
                            label="Password"
                            id={'password'}
                            onChange={handleInput('password')}
                            value={state.password}
                            type="password"
                            autoComplete={'current-password'}
                            disabled={isAuthenticating}
                            placeholder="Password"
                        />

                        <Button
                            variant={'primary'}
                            name="submitButton"
                            color="primary"
                            type={'submit'}
                            size={'md'}
                            className={'w-full'}
                            disabled={isAuthenticating}
                            loading={isAuthenticating}
                            onClick={handleSubmit}
                        >
                            Login
                        </Button>

                        <div className={'my-2 text-right'}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                id={'forgot-password'}
                                href={config.api.passwordResetURL}
                            >
                                Forgot password?
                            </a>
                        </div>
                    </div>
                </Form>
            </LoginFormContainer>
        </LoginFormPage>
    );
};
