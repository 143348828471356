import * as React from 'react';
import { PropsWithChildren } from 'react';
import { PopupProps, StrictPopupProps } from 'semantic-ui-react';
import {
    ButtonWithDropdownMenu,
    ButtonWithDropdownMenuProps,
    ButtonWithPopup,
    ButtonWithPopupProps,
    DropdownMenuOptionType,
} from '../buttons/';
import { Icon } from '../Icon';

export const HeaderButtonGroup: React.FC<PropsWithChildren<any>> = (props) => (
    <div className={'flex flex-nowrap flex-row'} {...props} />
);

export const HeaderButton: React.FC<ButtonWithPopupProps> = (props) => {
    return (
        <ButtonWithPopup //
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export const HeaderDropdownButton: React.FC<ButtonWithDropdownMenuProps> = (props) => {
    return (
        <ButtonWithDropdownMenu
            iconRight={'ellipsis vertical'}
            variant={'secondary'}
            color={'tertiary'}
            size={'sm'}
            {...props}
        />
    );
};

export interface HeaderButtonProps extends ButtonWithPopupProps {
    menuItems?: DropdownMenuOptionType[];
    popup?: StrictPopupProps;
    autoHideContent?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    // locked?: boolean | string;
    hidden?: boolean;

    // TODO:
    active?: boolean;
}

export type HeaderButtonType = HeaderButtonProps | React.ReactNode;

const isButtonProps = (button: HeaderButtonType): button is HeaderButtonProps => {
    return !React.isValidElement(button);
};

export const HeaderButtons: React.FC<{
    autoHideButtonsContent?: boolean;
    buttons?: HeaderButtonType | HeaderButtonType[];
}> = ({ buttons, autoHideButtonsContent }) => {
    const renderButtons = (button) => {
        if (!button || button.hidden) {
            return null;
        }

        if (button instanceof Array) {
            return (
                <div className={'flex flex-row gap-1 items-center'}>
                    {button
                        .filter((b) => !!b) // remove undefined
                        .map((b, i) => ({
                            ...renderButtons(b),
                            key: i,
                        }))}
                </div>
            );
        }

        if (!isButtonProps(button)) {
            return button;
        }

        let { popup, content, locked, autoHideContent = true, icon, disabled, menuItems, ...bp } = button;

        let _icon = icon;
        let _popup: PopupProps = popup;
        if (locked) {
            if (!_popup && !!locked) {
                if (typeof locked === 'string') {
                    _popup = { content: locked };
                } else {
                    _popup = { content: 'No Permission' };
                }
            }
            _icon = <Icon name={'lock'} />;
        }

        if (autoHideContent != false && autoHideButtonsContent && bp && icon) {
            // Don't show `content` on mobile screens, only icon if icon is set.
            content = undefined;
        }

        // Note: if content=='', then convert it to undefined.
        // It prevents from printing a whitespace in button.
        content = content ? content : undefined;

        const buttonProps: ButtonWithPopupProps = {
            size: 'sm',
            icon: _icon,
            disabled: disabled || !!locked,
            color: 'tertiary',
            ...bp,
            content: content,
        };

        if (menuItems) {
            return <HeaderDropdownButton {...buttonProps} popup={_popup} options={menuItems} />;
        } else {
            return <HeaderButton popup={_popup} {...buttonProps} />;
        }

        //
        // const renderButton = () => {
        //     // WARNING!!!
        //     // IMPORTANT: DON'T DELETE SPAN!!
        //     // Wrap in span so that Popup could appear when button is disabled.
        //
        //     const { menu, menuItems } = button;
        //     if (menu || menuItems) {
        //         const options = menuItems?.map((item) => {
        //             return {
        //                 key: item.key,
        //                 icon: item.icon as string,
        //                 text:  item.content || item.name,
        //                 value: item.value,
        //                 locked: item.locked,
        //                 onClick: item.onClick,
        //             };
        //         });
        //         return (
        //             <>
        //                 <ButtonWithDropdownMenu {...buttonProps} popup={_popup} options={options} />
        //                 {/*<MenuButton {...buttonProps} menu={menu} menuItems={menuItems} />*/}
        //             </>
        //         );
        //     }
        //     return (
        //         <Button popup={_popup} {...buttonProps} />
        //     );
        //     // END OF WARNING!!
        // };
        //
        // return renderButton();
        // // if (_popup) {
        // //     return <Popup trigger={renderButton()} {..._popup} />;
        // // } else {
        // //     return renderButton();
        // // }
    };

    return renderButtons(buttons);
};
