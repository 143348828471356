import * as React from 'react';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import * as URLBuilder from '../../../../routes/url-builder';
import {
    Alert,
    AlertHeader,
    Icon,
    Modal,
    PageLayout,
    Panel,
    PhotoFileGalleryPanel,
    SectionHeader,
    Segment,
    toastError,
    toastSuccess,
    VStack,
} from '@ez/components';
import Instructions from './Components/Instructions';
import WaterTestMeasurementTable from './WaterTestMeasurementTable';
import TreatmentRecommendations from './TreatmentRecommendations';
import { PageControlProps } from './Page.Control';
import { fromEdges, NodeType } from '@poolware/api';
import MenuBar from './MenuBar';
import EmailReportPanel from './EmailReportPanel';
import { FileAttachmentUploader } from '@poolware/app-shell';
import { ModuleLink } from '@poolware/react-app-navigator';
import { ServiceJobModuleRouterID } from '@poolware/app-service-jobs';

const ManualProblemsList = ({ manualProblems }) => {
    return (
        <Segment>
            <div className={'grid gap-2'}>
                {manualProblems.map((p) => {
                    return (
                        <div key={p.id}>
                            <div className={'font-bold'}>{p.name}</div>
                            <div>
                                <Instructions usePre={true} content={p.solution} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </Segment>
    );
};

const WaterTestNotes = ({ report }) => (
    <Segment>
        <Instructions style={{ backgroundColor: '#ffffed' }}>{report.note}</Instructions>
    </Segment>
);

interface TestReportViewState {
    photoPickerOpen: boolean;
    sendEmailOpen: boolean;
}

export default class Page extends React.Component<PageControlProps, TestReportViewState> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sendEmailOpen: false,
            photoPickerOpen: false,
        };
    }

    getLinkToPool = () => {
        let { customerId, poolId } = this.props;
        let poolLink = null;
        if (customerId && poolId) {
            poolLink = URLBuilder.Customer(customerId).Pool(poolId).view;
        }
        return poolLink;
    };

    onBackToPool = () => {
        let poolLink = this.getLinkToPool();
        if (poolLink) {
            this.props.AppNavigator.navigate(poolLink);
        }
    };

    onBackToWorkOrder = () => {
        const { report } = this.props;
        this.props.AppNavigator.navigateRelative(`/wo/${report?.workOrder?.id}`, {
            moduleId: ServiceJobModuleRouterID,
        });
    };

    getBackConf = () => {
        const poolLink = this.getLinkToPool();
        if (poolLink) {
            return {
                onBackFn: this.onBackToPool,
                onBackTitle: 'To Pool',
            };
        }
        // TODO: temporary hack.
        if (this.props.report?.workOrder?.id) {
            return {
                onBackFn: this.onBackToWorkOrder,
                onBackTitle: 'To Work Order',
            };
        }

        return {
            onBackFn: null,
            onBackTitle: null,
        };
    };

    editReport = () => {
        const { customerId, poolId, testId } = this.props;

        this.props.AppNavigator.navigateRelative(`${testId}/edit`, {
            state: {
                customerId: customerId,
                poolId: poolId,
                testId: testId,
            },
            setOrigin: true,
        });
    };

    archiveReport = async () => {
        const { report } = this.props;

        try {
            await this.props.TestReportMutator.archiveReport(report);
            toastSuccess({
                icon: 'archive',
                title: 'Archived Successfully',
                description: 'This report has been archived. It is read-only now.',
                time: 4000,
            });
        } catch (err) {
            console.error(err);
            toastError({
                icon: 'archive',
                title: 'Failed to archived',
                description: JSON.stringify(err.message),
                time: 4000,
            });
        }
    };

    deleteReport = async () => {
        const { report } = this.props;

        try {
            await this.props.TestReportMutator.deleteReport(report);
            toastSuccess({
                icon: 'delete',
                title: 'Report deleted',
                description: 'The water test report was successfully deleted.',
                time: 4000,
            });
            const { onBackFn } = this.getBackConf();
            if (onBackFn) {
                onBackFn();
            } else {
                this.props.AppNavigator.replace('/');
            }
        } catch (err) {
            console.error(err);
            toastError({
                icon: 'archive',
                title: 'Failed to archived',
                description: JSON.stringify(err.message),
                time: 4000,
            });
        }
    };

    onEmailPanelOpen = () => {
        this.setState({ sendEmailOpen: true });
    };

    onEmailPanelClose = () => {
        this.setState({ sendEmailOpen: false });
    };

    onPhotoPickerClose = () => {
        this.setState({ photoPickerOpen: false });
    };

    onPhotoPickerOpen = () => {
        this.setState({ photoPickerOpen: true });
    };

    onDeleteAttachment = async (files: NodeType.FileUpload[]) => {
        const attachments = fromEdges(this.props.report.attachments);
        const attachmentIds = files
            .map((file) => attachments.find((attachment) => attachment.file.id === file.id))
            .map((attachment) => attachment.id);
        return this.props.TestReportMutator.deleteAttachments(this.props.report, attachmentIds);
    };

    onViewEmailLog = () => {
        const { customerId, poolId, testId } = this.props;
        this.props.AppNavigator.navigateRelative(`${testId}/email-logs`, {
            state: {
                customerId: customerId,
                poolId: poolId,
                testId: testId,
            },
            setOrigin: true,
        });
    };

    renderMenu() {
        const { report } = this.props;
        const { isArchived } = report;

        // defaults to true. The server will reject mutaion if not allowed
        const canDelete = report?.checkMutations?.delete || true;
        const canUpdate = report?.checkMutations?.update || true;

        const { onBackFn, onBackTitle } = this.getBackConf();

        return (
            <MenuBar
                PDFPreviewURL={report?.pdfUrl}
                isArchived={isArchived}
                onViewEmailLog={this.onViewEmailLog}
                disableDelete={!canDelete}
                disableUpdate={!canUpdate}
                onAddPhoto={this.onPhotoPickerOpen}
                onReportEdit={this.editReport}
                onArchive={this.archiveReport}
                onDelete={this.deleteReport}
                onSendReport={this.onEmailPanelOpen}
                onBack={onBackFn}
                onBackTitle={onBackTitle}
            />
        );
    }

    render() {
        let { report, contacts, manualProblems = [], chemicalTargets = [] } = this.props;

        if (!report) return <div>Not found</div>;

        const { isArchived } = report;

        const samples = fromEdges(report?.sampleSet?.samples);

        const files = fromEdges(report.attachments)
            .map((attachment) => attachment.file)
            .filter((f) => f?.isImage)
            .map((f) => {
                if (f.imageUrl) {
                    return { ...f, urlThumbnail: f.imageUrl + '?size=sm' };
                } else {
                    return f;
                }
            });

        return (
            <PageLayout>
                {this.renderMenu()}
                <PageLayout.BodySection vStack={true} width={'screen-xl'}>
                    <>
                        {isArchived && (
                            <Alert type={'warning'}>
                                <AlertHeader>
                                    <Icon name="lock" /> This report has been archived!
                                </AlertHeader>
                            </Alert>
                        )}

                        <SectionHeader size={'large'} dividing>
                            <span>
                                <Icon name="file text outline" />
                                Water test report
                            </span>
                        </SectionHeader>

                        <Panel>
                            <Panel.Header content="Submitted Data" />
                            <Panel.Body>
                                <Panel.ItemDate label="Date" content={report.createdAt} />
                                <Panel.ItemEntity label="Submitted by" content={report.reportBy} />
                                {report?.workOrder && (
                                    <>
                                        <Panel.Item label={'Work Order'}>
                                            <ModuleLink
                                                moduleId={ServiceJobModuleRouterID}
                                                to={`/wo/${report.workOrder?.id}`}
                                            >
                                                {report.workOrder?.workOrderNumber} - {report.workOrder.title}
                                            </ModuleLink>
                                        </Panel.Item>
                                    </>
                                )}
                                <Panel.Divider />

                                <PoolDetails pool={report.pool} />

                                <SectionHeader size={'small'} dividing>
                                    <Icon name="flask" /> Measurements
                                </SectionHeader>
                                <WaterTestMeasurementTable
                                    samples={samples}
                                    report={report}
                                    editable={false}
                                    TestReportMutator={this.props.TestReportMutator}
                                    chemicalTargets={chemicalTargets}
                                />

                                {manualProblems.length > 0 && (
                                    <>
                                        <SectionHeader size={'small'} dividing>
                                            <Icon name="eye" /> Observations
                                        </SectionHeader>
                                        <ManualProblemsList manualProblems={manualProblems} />
                                        <div className={'mb-4'} />
                                    </>
                                )}

                                {report.note && (
                                    <React.Fragment>
                                        <SectionHeader size={'small'} dividing>
                                            <Icon name="clipboard" /> Notes
                                        </SectionHeader>
                                        <WaterTestNotes report={report} />
                                    </React.Fragment>
                                )}
                            </Panel.Body>
                        </Panel>

                        <PhotoFileGalleryPanel files={files} onDeleteFiles={this.onDeleteAttachment} />

                        <SectionHeader size={'large'} dividing>
                            <Icon name="treatment" />
                            Problems and Recommendations
                        </SectionHeader>

                        <TreatmentRecommendations testId={this.props.testId} />
                    </>
                </PageLayout.BodySection>

                <Modal
                    closeOnDimmerClick={false}
                    open={this.state.sendEmailOpen}
                    size={'small'}
                    onClose={this.onEmailPanelClose}
                >
                    <EmailReportPanel
                        onCancel={this.onEmailPanelClose}
                        onFinish={this.onEmailPanelClose}
                        report={report}
                        contacts={contacts}
                    />
                </Modal>

                <Modal centered={false} open={this.state.photoPickerOpen} onClose={this.onPhotoPickerClose}>
                    <Panel>
                        <Panel.Header
                            content={'Upload Photos'}
                            button={{ content: 'Close', icon: 'cancel', onClick: this.onPhotoPickerClose }}
                        />
                        <Panel.Body>
                            <FileAttachmentUploader
                                onDone={this.onPhotoPickerClose}
                                onFileUploadComplete={(fileId) => {
                                    return this.props.TestReportMutator.attachFiles(report, [{ file: fileId }]);
                                }}
                                dzOptions={{ accept: 'image/*' }}
                            />
                        </Panel.Body>
                    </Panel>
                </Modal>
                <div>
                    {report?.refId && <span className={'mt-8 text-sm text-gray-400'}>Ref. ID: {report?.refId}</span>}
                </div>
            </PageLayout>
        );
    }
}
