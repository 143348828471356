import * as React from 'react';
import { Button, MenuBar, PageLayout, StickyMenuBar, toastError } from '@ez/components';
import { BottomButtonContainer, SectionHeader } from '../components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryWorkOrder } from '../../../ServiceJobs/WorkOrderView/WorkOrderNotesPanel';
import { useMobileAppointmentCtx } from '../Root';
import { fromEdges, useMutationWorkOrderNote } from '@poolware/api';
import { pageSpinner } from '@poolware/app-shell';
import { NotesFeed } from '../../../components/NotesFeed';
import { JobNote } from '../../../components/NotesFeed/NotesFeedEvent';

export interface PageJobNotesProps {}

const maxHeight = 2000;

export const PageJobNotes: React.FC<PageJobNotesProps> = ({}) => {
    const { workOrder, refetchQueries } = useMobileAppointmentCtx();
    const { AppNavigator } = useAppNavigator();

    const mutator = useMutationWorkOrderNote({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueries,
    });

    const onGoBack = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };
    const notes = fromEdges(workOrder?.notes);

    const onUpdate = async (values: JobNote) => {
        try {
            await mutator.update({ id: values.id, note: values.note });
        } catch (e) {
            toastError(e);
        }
    };

    const onDelete = async (values: JobNote) => {
        try {
            await mutator.delete({ id: values.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onAdd = async (values) => {
        try {
            await mutator.create({
                parent: workOrder?.id,
                note: values.note,
            });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <>
            {/*<StickyMenuBar>*/}
            {/*    <MenuBar.Section>*/}
            {/*        <MenuBar.Item icon={'chevron left'} onClick={onGoBack} />*/}
            {/*        <MenuBar.HeaderItem content={'Pool Cleaning'} />*/}
            {/*    </MenuBar.Section>*/}
            {/*    <MenuBar.Section position={'right'}></MenuBar.Section>*/}
            {/*</StickyMenuBar>*/}

            <PageLayout.BodySection>
                <SectionHeader>Notes</SectionHeader>
                <NotesFeed
                    mode={'inputbottom'}
                    maxHeight={maxHeight}
                    onUpdate={onUpdate}
                    onAdd={onAdd}
                    notes={notes}
                    onDelete={onDelete}
                />
            </PageLayout.BodySection>
            <BottomButtonContainer>
                <Button
                    size={'lg'}
                    content={'Back'}
                    className={'w-full h-[48px] border-2 rounded-none'}
                    onClick={onGoBack}
                />
            </BottomButtonContainer>
        </>
    );
};
