export const stringToArr = (str: string) => {
    if (typeof str !== 'string') return [];
    return str
        ? str
              .split(',')
              ?.map((s) => s.trim())
              ?.filter(Boolean)
        : [];
};

const __ENV__ = import.meta.env || process.env;

export const loadManifest = () => {
    const serverManifest = window['serverManifest'] || {};
    const gitSha = __ENV__.REACT_APP_CODE_VERSION || 'latest';

    const getByKey = (key: string) => {
        return serverManifest[key] || __ENV__[key];
    };

    return {
        NODE_ENV: __ENV__.NODE_ENV,
        APP_ENV: __ENV__.APP_ENV,
        REACT_APP_BACKEND_URL: __ENV__.REACT_APP_BACKEND_URL,
        REACT_APP_THEME_NAME: getByKey('REACT_APP_THEME_NAME'),
        REACT_APP_THEME_NAME_ADMIN: getByKey('REACT_APP_THEME_NAME_ADMIN'),
        REACT_APP_APP_NAME: getByKey('REACT_APP_APP_NAME'),
        REACT_APP_DISABLE_ORGS: getByKey('REACT_APP_DISABLED_ORGS'),
        codeVersion: gitSha,
        disabledOrgs: stringToArr(getByKey('REACT_APP_DISABLED_ORGS')),
        appProfile: {
            profile: getByKey('APP_PROFILE') || 'default',
            variant:
                (getByKey('REACT_APP_INSTANCE_VARIANT') as 'franchises' | 'dealers' | string | undefined) ||
                'franchises',
        },
        autoUpdater: {
            reloadOnNavigation: getByKey('REACT_APP_AUTO_UPDATER_RELOAD_ON_NAVIGATION') !== 'false',
        },
        apps: {
            frontend: getByKey('REACT_APP_FRONTEND_URL'),
            admin: getByKey('REACT_APP_ADMIN_URL'),
            configurator: getByKey('REACT_APP_CONFIGURATOR_URL'),
            account: getByKey('REACT_APP_ACCOUNT_URL'),
            warrantyHQ: getByKey('REACT_APP_WARRANTY_HQ_URL'),
            ezConnectAdmin: getByKey('REACT_APP_EZ_CONNECT_URL'),
            lifeguardPortal: getByKey('REACT_APP_LIFEGUARD_URL'),
        },
        hotjar: {
            enabled: getByKey('REACT_APP_HOTJAR_ENABLED') === 'true',
        },
        zendesk: {
            // zendesk is enabled by default
            enabled: !(getByKey('REACT_APP_ZENDESK_DISABLED') === 'false'),
        },
        rollbar: {
            // rollbar is enabled by default
            enabled: !(getByKey('REACT_APP_ROLLBAR_DISABLED') === 'true'),
            accessToken: getByKey('REACT_APP_ROLLBAR_ACCESS_KEY'),
            code_version: gitSha,
            environment: __ENV__.NODE_ENV,
        },
        google_maps: {
            enabled: !!getByKey('REACT_APP_GOOGLE_MAPS_ENABLED'),
            apiToken: getByKey('REACT_APP_GOOGLE_MAPS_API_TOKEN'),
        },
        google_analytics: {
            enabled: getByKey('REACT_APP_GOOGLE_ANALYTICS_ENABLED') === 'true',
            analyticsId: getByKey('REACT_APP_GOOGLE_ANALYTICS_ID'),
        },
        serverManifest,
    };
};
