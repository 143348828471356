import * as React from 'react';
import { WorkOrdersTable } from './WorkOrdersTable';
import { NodeType } from '@poolware/api';
import { MenuBarHeaderItem, MenuBarItem, MenuBarSection, PageLayout, StickyMenuBar, VStack } from '@ez/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { FilterBar } from './FilterBar';
import { ToolbarQuickFilters } from './ToolbarQuickFilters';
import { useStateSidebar } from '@poolware/app-shell';

export const WorkOrdersList: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { showSidebar, setShowSidebar } = useStateSidebar('service-jobs.wo-list.sidebar');

    const onView = (item: NodeType.WorkOrder) => {
        AppNavigator.navigate(`/wo/${item.id}`, {
            relativeToModule: true,
            setOrigin: true,
        });
    };

    const onNew = () => {
        AppNavigator.navigateRelative('/sj/single/new', {
            setOrigin: true,
            modal: false,
        });
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem color={ModuleColorNames.WorkOrder} icon={ModuleIconNames.WorkOrder}>
                        Work Orders
                    </MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem onClick={onNew} color={'green'} icon={'plus'} title={'New Service Case'} />
                </MenuBarSection>
            </StickyMenuBar>

            <ToolbarQuickFilters />

            <PageLayout.SideBarLayout
                leftShow={showSidebar}
                leftOnClose={() => setShowSidebar(false)}
                leftSidebar={<FilterBar />}
                main={<WorkOrdersTable onView={onView} />}
            />
        </PageLayout>
    );
};
