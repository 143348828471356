import * as React from 'react';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../../../../../routes/url-builder';
import _truncate from 'lodash/truncate';
import { NodeType } from '@poolware/api';
import { withAppNavigator } from '@poolware/react-app-navigator';
import { Button, DebugJsonButton } from '@ez/components';
import { fromEdges } from '@ez/api-core';
import { useViewer } from '@poolware/app-shell';

const PoolDescription: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    const ezcDevices = fromEdges(pool.ezcDeviceLinks);

    return (
        <div className={'flex flex-col gap-0'}>
            {pool.type && (
                <div className={'flex flex-row'}>
                    <b className={'w-20 text-strong'}>Type:</b> <span>{pool?.type?.name}</span>
                </div>
            )}
            {pool.volume && (
                <div className={'flex flex-row'}>
                    <b className={'w-20'}>Volume:</b> <span>{pool?.volume} L</span>
                </div>
            )}
            {pool.sanitiser && (
                <div className={'flex flex-row'}>
                    <b className={'w-20'}>Sanitiser:</b> <span>{pool?.sanitiser?.name}</span>
                </div>
            )}
            {ezcDevices.length > 0 && (
                <div className={'flex flex-row'}>
                    <b className={'w-20'}>Devices:</b>
                    <ul>
                        {ezcDevices.map((ezcDevice) => {
                            return <li key={ezcDevice.id}>{ezcDevice.device?.title}</li>;
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

interface ActionButtonProps {
    customerId;
    poolId;
}

const NewTestButton = withAppNavigator<ActionButtonProps>()(({ customerId, poolId, AppNavigator }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest().new;
    const onClick = () => {
        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                poolId: poolId,
            },
        });
    };

    return (
        <Button
            color="primary"
            variant={'primary'}
            // icon={"lab"}
            onClick={onClick}
            icon={'plus'}
            content={'New Test'}
        />
    );
});

const PoolDetailsButton = withAppNavigator<ActionButtonProps>()(({ customerId, poolId, AppNavigator }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).view;
    const onClick = () => {
        AppNavigator.navigate(link, {
            query: {
                poolId: poolId,
            },
        });
    };

    return <Button color="primary" basic={true} onClick={onClick} content={'View Pool'} />;
});

const PoolRow: React.FC = ({ children }) => {
    return (
        <div
            className={
                'flex flex-col md:flex-row gap-2 border-0 border-l-4 rounded border-solid shadow bg-panel-section'
            }
        >
            {children}
        </div>
    );
};

const PoolList: React.FC<{ pools: NodeType.Pool[]; customerId: string; onAddPool: () => any }> = ({
    pools,
    customerId,
    onAddPool,
}) => {
    const { modulesAccess } = useViewer();

    return (
        <>
            <div className={'mt-2'}>
                <div className={'flex flex-col gap-3 w-full'}>
                    {pools.length === 0 && (
                        <PoolRow>
                            <div className={'flex-grow p-2'}>
                                <div className={'h-full flex flex-col justify-center'}>
                                    <div className={'italic text-tertiary'}>No pools registered for this site</div>
                                </div>
                            </div>
                            <div className={'flex-initial flex p-2'}>
                                <div className={'flex flex-row gap-2 justify-end w-full items-center'}>
                                    <Button
                                        onClick={onAddPool}
                                        color="primary"
                                        variant={'primary'}
                                        icon={'plus'}
                                        content={'Register Pool'}
                                    />
                                </div>
                            </div>
                        </PoolRow>
                    )}
                    {pools.map((pool) => {
                        const linkViewPool = URLBuilder.Customer(customerId).Pool(pool.id).view;
                        const bottleNumber = pool.bottleNumber;

                        return (
                            <PoolRow key={pool.id}>
                                <Link to={linkViewPool} className={'hover:bg-accent p-2 w-full md:max-w-lg md:w-1/5'}>
                                    <div className={'flex flex-col gap-1'}>
                                        <div style={{ color: '#4183c4' }}>{_truncate(pool.name, { length: 50 })}</div>
                                        {bottleNumber && <div className={'text-sm text-tertiary'}>{bottleNumber}</div>}
                                    </div>
                                </Link>

                                <div className={'flex-grow p-2'}>
                                    <PoolDescription pool={pool} />
                                </div>

                                <div className={'flex-initial flex p-2'}>
                                    <div className={'flex flex-row gap-2 justify-end w-full items-center'}>
                                        <PoolDetailsButton poolId={pool.id} customerId={customerId} />
                                        {modulesAccess.WaterTest?.enabled && (
                                            <NewTestButton poolId={pool.id} customerId={customerId} />
                                        )}
                                    </div>
                                </div>
                            </PoolRow>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PoolList;
