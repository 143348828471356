import * as React from 'react';
import { useState } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as URLBuilder from '../../../routes/url-builder';
import { StaffTable } from './StaffTable';
import { DebouncedInput, MenuBarHeaderItem, MenuBarItem, PageLayout, StickyMenuBar, VStack } from '@ez/components';
import { useViewerContext } from '@poolware/app-shell';
import { MenuBar } from '@ez/components';

const StaffListPage: React.FC = () => {
    const nav = useAppNavigator();
    const viewerCtx = useViewerContext();
    const [filterName, setFilterName] = useState('');
    const canRegister = viewerCtx.viewer.Permissions && viewerCtx.viewer.Permissions.Staff.create;

    const _addNew = () => {
        nav.AppNavigator.navigate(URLBuilder.Staff().new, {
            setOrigin: true,
            modal: true,
        });
    };

    const popup = canRegister
        ? undefined
        : {
              content: 'Not enough permissions',
          };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section position={'left'}>
                    <MenuBarHeaderItem icon={'spy'}>Staff</MenuBarHeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBarItem disabled={!canRegister} popup={popup} icon={'plus'} color={'green'} onClick={_addNew}>
                        Register
                    </MenuBarItem>
                </MenuBar.Section>
            </StickyMenuBar>

            <div style={{ maxWidth: '380px' }}>
                <DebouncedInput
                    rightIcon={'search'}
                    initialValue={filterName}
                    placeholder={'Search by name...'}
                    onChange={(v) => {
                        setFilterName(v);
                    }}
                />
            </div>

            <StaffTable userName={filterName} />
        </PageLayout>
    );
};

export default StaffListPage;
