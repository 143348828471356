import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from '@ez/tools';
import { FormLabel } from './form-components';
import { SemanticICONS } from './Icon';
import { SemanticIcon } from './semantic-ui';
import { FormField } from './formik-components-base';
import { Input } from './radix';

interface DebouncedInputProps<T = string> {
    initialValue?: T;
    onChange: (value: T) => any;
    label?: string;
    rightIcon?: SemanticICONS;
    placeholder?: string;
    id?: string;
    ariaLabel?: string;
    type?: 'number' | 'text';
    step?: number;
    className?: string;
}

export const DebouncedInput: React.FC<DebouncedInputProps> = ({
    initialValue,
    onChange,
    placeholder,
    label,
    id,
    step,
    type,
    ariaLabel,
    rightIcon,
    className,
    ...props
}) => {
    const [term, setTerm] = useState(initialValue);

    useEffect(() => {
        setTerm(initialValue);
    }, [initialValue]);

    const [debouncedCallback] = useDebouncedCallback((value) => {
        onChange(value);
    }, 800);

    const handleChange = (e) => {
        const value = e.target.value;
        setTerm(value);
        debouncedCallback(value);
    };

    const handleOnClear = (value) => {
        // console.log('onClear', value);
        setTerm(value);
        onChange && onChange(value);
    };

    const ariaText = ariaLabel || ariaLabel || label || placeholder;

    return (
        <FormField style={{ minWidth: '160px' }} className={className}>
            {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
            <Input
                id={id}
                type={type || 'text'}
                step={step}
                aria-label={ariaText}
                placeholder={placeholder}
                value={term || ''}
                onChange={handleChange}
                iconRight={rightIcon && <SemanticIcon name={rightIcon} />}
                clearable={true}
                onClear={() => handleOnClear('')}
                {...props}
            />
        </FormField>
    );
};
