import * as React from 'react';
import { useMemo } from 'react';
import { DeviceLogsChart } from './DeviceLogsChart';
import { EZCDevicesLinkWithState } from '../../common';
import { useQueryEZCDeviceMetrics } from './use-query-ezcdevice-metrics';
import { DateRange, DebugJsonButton, Display, Icon, SectionHeader } from '@ez/components';
import { prepareGraphData } from './prepare-graph-data';
import { usePersistedState } from '@ez/tools';
import { DateRangePickerButton, DisplayRangeEnum } from './DateRangePickerButton';
import moment from 'moment/moment';

const calculateDateRange = (range: DisplayRangeEnum, selectedDate: Date): DateRange | undefined => {
    switch (range) {
        case DisplayRangeEnum.RANGE_12h:
            return {
                startDate: moment(selectedDate).subtract(12, 'h').toDate(),
                endDate: moment(selectedDate).add(1, 'h').toDate(),
            };
        case DisplayRangeEnum.RANGE_1D:
            return {
                startDate: moment(selectedDate).subtract(1, 'd').toDate(),
                endDate: moment(selectedDate).add(2, 'h').toDate(),
            };
        case DisplayRangeEnum.RANGE_3D:
            return {
                startDate: moment(selectedDate).subtract(3, 'd').toDate(),
                endDate: moment(selectedDate).toDate(),
            };
        case DisplayRangeEnum.RANGE_1W:
            return {
                startDate: moment(selectedDate).subtract(1, 'w').toDate(),
                endDate: moment(selectedDate).toDate(),
            };
        case DisplayRangeEnum.RANGE_1M:
            return {
                startDate: moment(selectedDate).subtract(1, 'M').toDate(),
                endDate: moment(selectedDate).toDate(),
            };
        case DisplayRangeEnum.RANGE_3M:
            return {
                startDate: moment(selectedDate).subtract(3, 'M').toDate(),
                endDate: moment(selectedDate).toDate(),
            };
        default:
        case DisplayRangeEnum.RANGE_ALL:
            return undefined;
    }
};

export interface TabGraphsProps {
    device: EZCDevicesLinkWithState;
}

const getNow = () => new Date();

export const TabGraphs: React.FC<TabGraphsProps> = ({ device }) => {
    const [displayRangeType, setDisplayRangeType] = usePersistedState<DisplayRangeEnum>(
        'pw.devices.graph.date-range',
        DisplayRangeEnum.RANGE_1W
    );
    const [selectedDate, setSelectedDate] = React.useState<Date | null | undefined>(getNow);

    const dateRange = useMemo(
        () => calculateDateRange(displayRangeType, selectedDate),
        [displayRangeType, selectedDate]
    );

    const { loading, metrics, error } = useQueryEZCDeviceMetrics(device.device?.id, dateRange);

    const chartData = useMemo(() => prepareGraphData(metrics, dateRange), [metrics, dateRange]);

    const handleDateRangeChange = (rangeType: DisplayRangeEnum, date: Date) => {
        setDisplayRangeType(rangeType);
        setSelectedDate(date);
    };

    return (
        <div>
            <SectionHeader
                content={
                    <span>
                        <span content={'pr-2'}>
                            <Display.Date value={dateRange.startDate} /> - <Display.Date value={dateRange.endDate} />{' '}
                        </span>
                        {loading && <Icon name={'spinner'} loading={true} />}
                    </span>
                }
                button={[
                    <DebugJsonButton data={{ chartData, metrics }} />,
                    <DateRangePickerButton
                        rangeType={displayRangeType}
                        date={selectedDate}
                        onChange={handleDateRangeChange}
                    />,
                ]}
            />
            <DeviceLogsChart chartData={chartData} dateRange={dateRange} />
        </div>
    );
};
