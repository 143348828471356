import * as React from 'react';
import { css, styled } from 'twin.macro';
import {
    HeaderButton,
    HeaderButtonGroup,
    HeaderButtons,
    HeaderButtonType,
    HeaderDropdownButton,
} from './HeaderButtons';
import { HeaderIconProp, iconPropToComp } from '../Icon/helpers';
import { useAppBreakpoints } from '../use-app-breakpoints';
import { Segment } from '../Segment';
import { clsxm } from '../utils';

const basicCss = css`
    background: ${(props) => props.theme?.panel?.body?.background};
    background-color: ${(props) => props.theme?.panel?.body?.backgroundColor || '#fefefe'};
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
    //border-bottom: #f5f5f5;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;

const nonBasicCss = css`
    background: ${(props) => props.theme?.panel?.header?.background};
    background-color: var(--ez-panel-header-background-color, '#f0f0f0');
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
`;

const StyledHeader = styled(Segment)<{ $isBasic?: boolean }>`
    &&& {
        min-height: 2.5em;
        font-size: 1.1em;
        font-weight: bold;

        //border-right: none !important;
        //border-left: none !important;
        border-bottom: none !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 0.125em;
        padding-bottom: 0.125em;
        //padding: 0.125em 0.5em 0.125em 0.75em;
        width: 100%;
        margin: 0;

        ${(props) => (props.$isBasic ? basicCss : nonBasicCss)}

        @media screen and (max-width: 479px) {
            font-size: 1.1em;
        }
        display: flex !important;
        flex-direction: row;
        align-items: center;
    }
`;

// type PanelHeaderSizeType = 'small' | 'normal';
//
// const LeftSpan = styled.span<{ size?: PanelHeaderSizeType }>`
//     flex-grow: 0;
//     font-size: ${({ size }) => (size === 'small' ? '0.95em' : undefined)};
// `;

// const RightSpan = styled.span`
//     flex-grow: 2;
//     text-align: right;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     position: relative;
//
//     &&& > * {
//         margin: 0;
//     }
// `;
//
export interface PanelHeaderProps {
    content?: string | React.ReactNode;
    headerComp?: any;
    icon?: HeaderIconProp;
    color?: any; // TODO: fix typing
    button?: HeaderButtonType | HeaderButtonType[];
    size?: 'small';
    style?: any;
    basic?: boolean;
}

const PanelHeaderComp: React.FC<PanelHeaderProps> = (props) => {
    let { headerComp, content, icon, color, button, children, size, style, basic } = props;

    const { isMobile } = useAppBreakpoints();

    let iconComp = iconPropToComp(icon);
    return (
        <StyledHeader
            color={color}
            style={style}
            $isBasic={basic}
            className={clsxm('ez-panel-header', size == 'small' && 'text-sm')}
        >
            <div className={'flex flex-row flex-grow'}>
                {iconComp}
                {content}
                {children}
                {headerComp}
            </div>
            <div className={'print:hidden flex flex-row'}>
                <HeaderButtons buttons={button} autoHideButtonsContent={isMobile} />
            </div>
        </StyledHeader>
    );
};

export class PanelHeader extends React.Component<PanelHeaderProps> {
    public static ButtonGroup = HeaderButtonGroup;
    public static Button = HeaderButton;
    public static DropdownButton = HeaderDropdownButton;

    render() {
        return <PanelHeaderComp {...this.props} />;
    }
}

export interface PanelHeader extends React.Component<PanelHeaderProps> {
    ButtonGroup: typeof HeaderButtonGroup;
    Button: typeof HeaderButton;
    DropdownButton: typeof HeaderDropdownButton;
}
