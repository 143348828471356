import * as React from 'react';
import { clsxm, cn } from '../utils';
import { Slot } from '@radix-ui/react-slot';
import { Icon } from '../Icon';
import { Button } from '../buttons';

const baseClassnames = 'focusable rounded border border-input bg-input  flex w-full px-2.5 py-2 ';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    hasError?: boolean;
    clearable?: boolean;
    onClear?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, iconLeft, iconRight, hasError, clearable, onClear, disabled, value, ...props }, ref) => {
        const showClear = !!value && clearable && !disabled && !props.readOnly && onClear;
        return (
            <div className={'relative flex-grow'}>
                {iconLeft && <Slot className={'absolute top-2.5 left-2.5 w-5 h-5 text-blue_25'}>{iconLeft}</Slot>}
                <input
                    type={type}
                    className={cn(
                        baseClassnames,
                        'h-input-base',
                        iconLeft ? 'pl-9' : 'pl-2.5',
                        iconRight ? 'pr-9' : 'pr-2.5',
                        className
                    )}
                    ref={ref}
                    aria-invalid={hasError || props['aria-invalid']}
                    disabled={disabled}
                    value={value || ''}
                    {...props}
                />
                {showClear && (
                    <Icon
                        color={'grey'}
                        className={cn(
                            'hover:bg-accent rounded-full cursor-pointer absolute top-2.5',
                            iconRight ? 'right-9' : 'right-2.5'
                        )}
                        name={'cancel'}
                        onClick={onClear}
                    />
                )}
                {iconRight && <Slot className={'absolute top-2.5 right-2.5 size-5 text-blue_25'}>{iconRight}</Slot>}
            </div>
        );
    }
);
Input.displayName = 'Input';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    iconLeft?: React.ReactNode;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ className, iconLeft, ...props }, ref) => {
    return (
        <div className={'relative'}>
            {iconLeft && <Slot className={'absolute top-2.5 left-2.5 w-5 h-5 text-blue_25'}>{iconLeft}</Slot>}
            <textarea
                className={cn(baseClassnames, 'min-h-[60px]', iconLeft ? 'pl-9' : 'pl-2.5', className)}
                ref={ref}
                {...props}
            />
        </div>
    );
});
Textarea.displayName = 'Textarea';

export interface InputFileProps extends React.InputHTMLAttributes<HTMLInputElement> {
    size?: any;
}

const InputFile = React.forwardRef<HTMLInputElement, InputFileProps>(({ className, type, ...props }, ref) => {
    return (
        <input
            type={'file'}
            className={cn(
                baseClassnames,
                'h-input-base p-0 pr-2.5',
                'cursor-pointer file:cursor-pointer file:mr-2 file:rounded-l file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-main file:text-tertiary hover:file:bg-violet-100',
                className
            )}
            ref={ref}
            {...props}
        />
    );
});
InputFile.displayName = 'InputFile';

const InputWithAction: React.FC<
    InputProps & {
        isClearable?: boolean;
        actionIcon?: string;
        actionLabel?: string;
        onClick?: () => void;
        onClear?: () => void;
    }
> = ({
    required,
    disabled,
    readOnly,
    isClearable = true,
    onClick,
    actionIcon,
    actionLabel,
    value,
    onClear,
    id,
    ...rest
}) => {
    const showClear = value && isClearable;
    const isDisabled = disabled || readOnly;
    const hasAction = actionLabel || actionIcon;
    return (
        <div className={'flex flex-row flex-nowrap w-full'}>
            <Input
                readOnly={true}
                onClick={(e) => onClick?.()}
                disabled={isDisabled}
                value={value || ''}
                iconRight={
                    showClear &&
                    !isDisabled && (
                        <Icon
                            className={'hover:bg-accent rounded-full cursor-pointer'}
                            name={'cancel'}
                            onClick={onClear}
                        />
                    )
                }
                className={clsxm('hover:cursor-pointer flex-grow', hasAction && 'border-r-0 rounded-r-none')}
                {...rest}
            />
            {hasAction && (
                <Button
                    aria-invalid={rest.hasError}
                    segmentPosition={'last'}
                    icon={actionIcon}
                    content={actionLabel}
                    size={'md'}
                    disabled={isDisabled}
                    className={'border-input h-input-base text-default'}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

export { Input, InputFile, Textarea, InputWithAction };
