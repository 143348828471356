import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DebouncedInput,
    DefaultConnectionTable,
    Icon,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    VStack,
} from '@ez/components';
import { NodeType, useQueryCustomerTagsConnection } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { TagOwnershipLabel } from './components';
import { useViewer } from '@poolware/app-shell';

export interface PageProps {}

export const PageList: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const [filterName, setFilterName] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const { connectionData, connectionState } = useQueryCustomerTagsConnection({ searchName: filterName });
    const { viewer } = useViewer();
    const canCreateTag = viewer.Permissions?.CustomerTag?.create;

    const onView = (item) => {
        AppNavigator.navigateRelative(`/${item.id}`);
    };

    const onNew = () => {
        AppNavigator.navigateRelative(`/new`, { modal: true });
    };

    const tableDef: ConnectionTableDef<NodeType.CustomerTag> = [
        {
            header: 'Tag Name',
            sortKey: 'name',
            cell: (r) => (
                <>
                    <Icon name={'tag'} /> {r.name}
                </>
            ),
        },
        {
            header: 'Ownership',
            cellProps: { width: '6' },
            cell: (r) => {
                return (
                    <span>
                        <TagOwnershipLabel franchise={r.franchise} />
                    </span>
                );
            },
        },
    ];

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'tags'}>Customer Tags</MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem
                        icon={'search'}
                        onClick={() => {
                            setShowSearch(!showSearch);
                        }}
                    />
                    {canCreateTag && <MenuBarItem onClick={onNew} icon={'plus'} color={'green'} title={'New'} />}
                </MenuBarSection>
            </StickyMenuBar>

            {showSearch && (
                <div style={{ maxWidth: '380px' }}>
                    <DebouncedInput
                        rightIcon={'search'}
                        initialValue={filterName}
                        placeholder={'Search...'}
                        onChange={(v) => {
                            setFilterName(v);
                        }}
                    />
                </div>
            )}

            <DefaultConnectionTable
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </PageLayout>
    );
};
